import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { systemHub } from "@common/services/systemHub"
import { Heartbeat } from "@api"
import { formatDateTimeLong, parseDateFromString } from "@common/services/datetime/dateTimeFormattingLocale"

@customElement('heartbeat-indicator')
export class HeartbeatIndicator extends LitElement {
    static styles = css`
        :host {
            display: inline-flex;
            flex-direction: row;
            gap: 1rem;

        }
        @keyframes rubberBand {
            from {transform: scale3d(1, 1, 1);}

            30% {transform: scale3d(1.25, 0.75, 1);}

            40% {transform: scale3d(0.75, 1.25, 1);}

            50% {transform: scale3d(1.15, 0.85, 1);}

            65% {transform: scale3d(.95, 1.05, 1);}

            75% { transform: scale3d(1.05, .95, 1); }

            to { transform: scale3d(1, 1, 1);}
        }
        .active {
            color: var(--digilean-danger);
            animation: 1s linear 1s infinite rubberBand;
        }
    `

    intervalId = 0
    _lastHeartBeat: Date | undefined

    @property({attribute: true})
    get lastheartbeat() {
        if (!this._lastHeartBeat)
            return ""

        const isDate = (this._lastHeartBeat instanceof Date)
        if (isDate)
            return this._lastHeartBeat.toISOString()
        return ""
    }
    set lastheartbeat(value: string) {
        if (!value)
            return

        const date = parseDateFromString(value)
        if (date) {
            this.setLast(date)
            const diff = this.getDiff()
            if (diff < 15000)
                this.isActive = true
        }
    }

    @property({attribute: true})
    connectorid = ""

    @property({attribute: true, type: Boolean})
    showlabel = false
    
    connectedCallback(): void {
        super.connectedCallback()

        systemHub.on("HeartbeatReceived", (heartbeat: Heartbeat) => {
            if (heartbeat.id == this.connectorid) {
                this.isActive = true
                
                this.setLast(new Date())
                if (this.showlabel)
                    this.requestUpdate()
            }
        })

        this.startInterval()
    }

    setLast(date: Date) {
        this._lastHeartBeat = date
        this.title = formatDateTimeLong(this._lastHeartBeat)
    }
    disconnectedCallback(): void {
        if (this.intervalId)
            window.clearInterval(this.intervalId)
    }

    startInterval() {
        if (this.intervalId === 0)
            this.intervalId = window.setInterval(() => this.checkIfStillAlive(), 5000)
    }

    checkIfStillAlive() {
        if (!this.isActive || !this._lastHeartBeat)
            return
        
        const diff = this.getDiff()
        if (diff > 15000)
            this.isActive = false
    }

    getDiff() {
        if (!this._lastHeartBeat)
            return 10000000
        const timeNow = new Date().getTime()
        const timeLast = this._lastHeartBeat.getTime()
        const diff = timeNow - timeLast
        return diff
    }

    @state()
    isActive = false
    
    render() {
        let icon = "fa fa-heart fa-2x"
        // <translate-label label="COMMON_HEARTBEAT"></translate-label>
        const lastBeat = this._lastHeartBeat ? formatDateTimeLong(this._lastHeartBeat) : ""
        return html`
            <fa-icon .icon=${icon} class="${this.isActive ? 'active': ''}"></fa-icon>
            ${this.showlabel ? html`<label>${lastBeat}</label>` : ""}
        `
    }
}
