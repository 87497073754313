import { getDefaultAppTheme, getDefaultAppStyle, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as documentService from "@common/services/documentService"

angular
    .module('DigiLean')
    .directive("imageDisplayer", ['debounce',
        function (debounce) {
            return {
                templateUrl: 'imagedisplayer.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)
                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }

                    scope.imageUrl = null;
                    scope.imageFileName = "";
                    
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (scope.settings) {
                            scope.title = settings.title;
                            scope.imageFileName = settings.imageFileName;
                            loadImageUrl(settings.imageFileName)
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                        }
                    });

                    scope.isMenuActive = false;
                    scope.menuActive = function(open) {
                        scope.isMenuActive = open;
                    }


                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    function resizeImage() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height()-60;
                        var img = $(elem).find("img");
                        $(img).css("height", height);
                        $(img).css("width", width);
                    }
                    setTimeout(function() {
                        resizeImage();
                    }, 100);
                    
                    scope.$on("widget-resized", function (event, args) {
                        resizeImage();
                    });

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            imageFileName: scope.imageFileName,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }

                    var loadImageUrl = function(fileName) {
                        scope.loadImageUrl = "https://digileanfiles.blob.core.windows.net/public-assets/images/Loading_icon.gif";
                        documentService.getFileUrl(fileName).then(function(fileUrl) {
                            scope.imageUrl = fileUrl;
                        });
                    }

                    scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);
                    scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
                    scope.fileUploaded = function ($event) {
                        const files = $event.detail
                        const file = files[0]
                        scope.imageFileName = file.fileName
                        scope.updateSettings()
                        loadImageUrl(scope.imageFileName)
                    }
                }
            }
        }]);
