﻿import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as translationService from "@common/services/translationService"
import * as profileImageService from "@common/services/user/profileImageService"

angular
    .module('DigiLean')
    .directive("strategyBoard", ['$filter', '$translate', 'strategyService', 'strategyGoalService', 'projectService', '$uibModal', 'activityService', 'modalService', 'orderByFilter', 'authService',
        function ($filter, $translate, strategyService, strategyGoalService, projectService, $uibModal, activityService, modalService, orderByFilter, authService) {
            return {
                templateUrl: 'strategyBoard.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'parentGoal': '=',
                    'isAdminMode': '=',
                    'options': '<?'
                },
                link: function ($scope, elem, attrs) {
                    $scope.editable = false;
                    $scope.isSubGoals = false;
                    $scope.projects = [];
                    //$scope.canEdit = false; currently not in use
                    $scope.showtitle = true;
                    $scope.query = "";
                    $scope.sortColumnIndex = null;
                    $scope.isAscedning = true;
                    $scope.sortColumn = null;

                    var activityLogLoaded = false;

                    $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                    // if page is refreshed we need to refresh
                    $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                        $scope.hasBoardDesignerPrivileges = $scope.isAdmin;
                    });

                    $scope.orderBy = function (column) {
                        if ($scope.sortColumn && $scope.sortColumn != column) {
                            $scope.sortColumn.sort = "none";
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        $scope.sortColumn = column;
                        $scope.sortColumnIndex = $scope.projectColumns.indexOf(column);
                        if (column.sort == "none") {
                            $scope.projects = orderByFilter($scope.projects, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            $scope.projects = orderByFilter($scope.projects, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            $scope.projects = orderByFilter($scope.projects, getSortValue, true);
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (project) {
                        if (project.cellModels) {
                            var cell = project.cellModels[$scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return project.name;

                    }
                    // load users
                    async function getAllUsers() {
                        $scope.users = await profileImageService.getAllUsersIncludeProfileImage()
                    }
                    getAllUsers();

                    function getAssetList() {
                        // Asset cell need all asset to be able to match
                        assetService.getAllAssets(true).then(function (assets) {
                            $scope.assets = assets;
                            for (var k = 0; k < $scope.assets.length; k++) {
                                $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;
                            }
                        });
                    };
                    getAssetList();

                    $scope.$watch("parentGoal", function () {
                        if ($scope.parentGoal) {
                            $scope.isSubGoals = true;
                        }
                    });
                    $scope.$watch("isAdminMode", function () {
                        $scope.editable = $scope.isAdminMode;
                    });

                    $scope.loadSubGoals = function (projectModel) {
                        projectModel.showSubGoals = !projectModel.showSubGoals;
                        strategyService().getStrategyBoardById(projectModel.goal.boardId).then(function (board) {
                            projectModel.subGoalsModel = board;
                        });
                    }

                    $scope.showMoreColumns = function () {

                        var options = {
                            context: "strategy"
                        }

                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'projectCellSelection.html',
                            controller: 'projectCellSelectionController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options:options
                            }
                        });
                        modalInstance.result.then(function (result) {
                            if (result) {
                                $scope.addColumn(result);
                            }
                        });
                    };


                    var updatedEditable = function () {
                        if ($scope.isAdminMode) {
                            $scope.editable = true;
                        } else {
                            $scope.editable = false;
                        }
                        $scope.editable = true;
                    }
                    var swapArrayElements = function(arr, indexA, indexB) {
                        var temp = arr[indexA];
                        arr[indexA] = arr[indexB];
                        arr[indexB] = temp;
                      };

                    function animate(project, animation){
                            project.animationClass = "animated " + animation;
                            setTimeout(function () {
                                project.animationClass = "";
                                // Make sure animated class is removed
                                $(elem).find(".projectModel[data-id=" + project.id + "]").removeClass("animated");
                            }, 2000);
                    }
                    $scope.moveUp = function(project){
                        var index = $scope.projects.indexOf(project);
                        if(index-1 >= 0){
                            var sortOrder = [];
                            animate(project, "bounce");
                            swapArrayElements($scope.projects, index-1, index); 
                            for (var i = 0; i < $scope.projects.length; i++) {
                                var row = $scope.projects[i];
                                var rowOrder = {
                                    rowId: row.id,
                                    sortOrder: i,
                                    goalId: row.goal.id
                                }
                                sortOrder.push(rowOrder)
                            }
                            strategyService().updateStrategyGoalSortOrder($scope.board.id, sortOrder).then(function () {
                            }); 
                        }
                    }
                    $scope.moveDown = function(project){
                        var index = $scope.projects.indexOf(project);
                        if(index+1 < $scope.projects.length){
                            var sortOrder = [];
                            animate(project, "bounce");
                            swapArrayElements($scope.projects, index, index+1); 
                            for (var i = 0; i < $scope.projects.length; i++) {
                                var row = $scope.projects[i];
                                var rowOrder = {
                                    rowId: row.id,
                                    sortOrder: i,
                                    goalId: row.goal.id
                                }
                                sortOrder.push(rowOrder)
                            }
                            strategyService().updateStrategyGoalSortOrder($scope.board.id, sortOrder).then(function () {
                            }); 
                            
                        }
                    }
                    // Drag and drop of rows events
                    /* REMOVED since drag and drop do not work with <tr ng-repeat-end ng-show="projectModel.showSubGoals"
                    $scope.$on('row-header-bag.drop-model', function (e, el) {
                        var sortOrder = [];
                        for (var i = 0; i < $scope.projects.length; i++) {
                            var row = $scope.projects[i];
                            var rowOrder = {
                                rowId: row.id,
                                sortOrder: i,
                                goalId: row.goal.id
                            }
                            sortOrder.push(rowOrder)
                        }
                        strategyService().updateStrategyGoalSortOrder($scope.board.id, sortOrder).then(function () {

                        });
                    });*/
                    // Drag and drop of columns events
                    $scope.$on('column-header-bag.drop-model', function (e, el) {
                        var sortOrder = [];
                        for (var i = 0; i < $scope.projectColumns.length; i++) {
                            var column = $scope.projectColumns[i];
                            var columnOrder = {
                                boardAttributeId: column.id,
                                sortOrder: i
                            }
                            sortOrder.push(columnOrder)
                        }
                        projectService().updateProjectBoardSortOrder($scope.board.id, sortOrder).then(function () {

                        });

                    });

                    $scope.moveLeft = function(col){
                        var index = $scope.projectColumns.indexOf(col);
                        if(index-1 >= 0){
                            var sortOrder = [];
                            swapArrayElements($scope.projectColumns, index-1, index); 
                            for (var i = 0; i < $scope.projectColumns.length; i++) {
                                var column = $scope.projectColumns[i];
                                var columnOrder = {
                                    boardAttributeId: column.id,
                                    sortOrder: i
                                }
                                sortOrder.push(columnOrder);                               
                            }
                            projectService().updateProjectBoardSortOrder($scope.board.id, sortOrder).then(function () {

                            });
                        }
                    }
                    
                    $scope.moveRight = function(col){
                        var index = $scope.projectColumns.indexOf(col);
                        if(index+1 < $scope.projectColumns.length){
                            var sortOrder = [];
                            swapArrayElements($scope.projectColumns, index, index+1); 
                            for (var i = 0; i < $scope.projectColumns.length; i++) {
                                var column = $scope.projectColumns[i];
                                var columnOrder = {
                                    boardAttributeId: column.id,
                                    sortOrder: i
                                }
                                sortOrder.push(columnOrder);
                            }
                            projectService().updateProjectBoardSortOrder($scope.board.id, sortOrder).then(function () {

                            }); 
                            
                        }
                    }

                    $scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });

                    $scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    $scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });

                    $scope.$watch("model", function () {
                        if ($scope.model == null) return;
                        $scope.board = $scope.model.board;

                        //$scope.hasBoardDesignerPrivileges = true; //full edit right for all users
                        $scope.hasBoardDesignerPrivileges = $scope.isAdmin;
                        
                        $scope.projectColumns = $scope.model.attributes;
                        $scope.projects = $scope.model.projects;
                        $scope.title = $scope.board.name;
                        loadGoals();

                    });

                    function loadGoals() {
                        if ($scope.projects) {
                            for (var i = 0; i < $scope.projects.length; i++) {
                                var project = $scope.projects[i];
                                project.cellModels = [];
                                calculateProjectAttributes(project, $scope.projectColumns);
                            }
                        }
                    }

                    function reloadBoard(){
                        // Reload board
                        if($scope.isSubGoals){      
                            strategyService().getStrategyBoardById($scope.board.id).then(function (board) {
                                $scope.model = board;
                            });
                        } else {
                            strategyService().getStrategyBoard().then(function (board) {
                                $scope.model = board;
                            });
                        }
                    }

                    // StrategyGoalCreated
                    $scope.subscribe("StrategyGoalCreated", function (goal) {
                        if (goal.parentBoardId === $scope.board.id) {

                            reloadBoard();
                        }
                    });
                    $scope.subscribe("StrategyGoalDeleted", function (goal) {
                        var foundProject = null;
                        for (var p = 0; p < $scope.projects.length; p++) {
                            if ($scope.projects[p].goal.id === goal.id) {
                                foundProject = $scope.projects[p];
                                foundProject.cssState = "animated slideOutRight";
                                $scope.projects.splice(p, 1);
                                break;
                            }
                        }
                    });



                    $scope.subscribe("ProjectBoardAttributeUpdated", function (boardattribute) {
                        if (boardattribute.boardId === $scope.board.id) {
                            // $scope.projectColumns
                            var hasColumn = $filter('filter')($scope.projectColumns, { id: boardattribute.id }, true);
                            if (hasColumn.length > 0) {
                                var column = hasColumn[0];
                                if (column.name !== boardattribute.name) {
                                    column.name = boardattribute.name;
                                }
                                if (boardattribute.settings) {
                                    column.settings = JSON.parse(boardattribute.settings);
                                }
                                //TODO: antar vi kan legge til endring i kolonnerekkefølgen også på dette tidspunktet
                            }
                        }
                    });



                    $scope.subscribe("ProjectBoardSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.board.id) {
                            reloadBoard();
                        }
                    });

                    $scope.subscribe("ProjectBoardRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.tableId) {
                            reloadBoard();
                        }
                    });

                    // Replace with popup
                    $scope.addGoal = function () {
                        var parentGoalId = 0; // Breakthorughobjective
                        if ($scope.parentGoal) {
                            parentGoalId = $scope.parentGoal.id;
                        }
                        var options = {
                            goalId:parentGoalId
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'goalCreatePopup.html',
                            controller: 'goalCreatePopup',
                            resolve: {
                                options: function () {
                                    return options;
                                }

                            }
                        });
                        modalInstance.result.then(function (result) {

                        });
                    }
                    var calculateProjectAttributes = function (project, boardAttributes) {
                        project.id = project.project.id;
                        for (let index = 0; index < boardAttributes.length; index++) {
                            let boardAttribute = boardAttributes[index];
                            var cellModel = {
                                project: project.project,
                                goal: project.goal,
                                column: boardAttribute,
                                boardAttributeId: boardAttribute.id,
                                projectAttribute: null
                            }
                            var hasAttribute = $filter('filter')(project.attributes, { projectBoardAttributeId: boardAttribute.id }, true);
                            if (hasAttribute.length > 0) {
                                var attribute = hasAttribute[0];
                                if (attribute.value) {
                                    attribute.value = JSON.parse(attribute.value);
                                }
                                cellModel.projectAttribute = attribute;
                            } else {
                                // Create default attribute
                                cellModel.projectAttribute = {
                                    id: 0,
                                    goalId: project.goal.id,
                                    projectId: project.project.id,
                                    projectBoardAttributeId: boardAttribute.id,
                                    value: null
                                }
                            }
                            if (cellModel.column.settings && typeof (cellModel.column.settings) === "string") {
                                cellModel.column.settings = JSON.parse(cellModel.column.settings);
                            }
                            // calculateColumnSum(boardAttributes[index]);
                            project.cellModels.push(cellModel)
                        }
                    }

                    var addAttributeToProjects = function (attribute) {
                        for (let index = 0; index < $scope.projects.length; index++) {
                            var project = $scope.projects[index];
                            var cellModel = {
                                project: project.project,
                                goal: project.goal,
                                column: attribute,
                                boardAttributeId: attribute.id,
                                projectAttribute: null
                            }
                            // Create default attribute
                            cellModel.projectAttribute = {
                                id: 0,
                                goalId: project.goal.id,
                                projectId: project.project.id,
                                projectBoardAttributeId: attribute.id,
                                value: null
                            }
                            project.cellModels.push(cellModel)
                        }
                    }



                    $scope.toggleAdminMode = function () {
                        $scope.isAdminMode = !$scope.isAdminMode;
                        updatedEditable();
                        if($scope.isAdminMode){
                            var myDiv = document.getElementById("strategyboardtable");

                            setTimeout(function() {
                                myDiv.scrollLeft = myDiv.scrollWidth;
                            }, 500);
                        }
                    }
                    $scope.columnWidthChangeHandler = function(col){
                        if(col){
                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                            })
                        }
                    }
                    $scope.columnNameUpdated = function (column) {
                        projectService().updateProjectBoardAttribute(column).then(function (attribute) {

                        })
                    }
                    $scope.titleUpdated = function (title) {
                        var boardName = {
                            boardId: $scope.board.id,
                            name: title
                        }
                        boardService.updateName($scope.board.id, boardName).then(function (result) {
                        });
                    }


                    $scope.resetName = function () {
                        $scope.newName = "";
                    }
                    $scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        } else if (type === "textfromapp") {
                            addTextFromAppColumn(type);
                        } else if (type === "targetfromapp") {
                            addTargetFromAppColumn(type);
                        } else {
                            var headertext = translationService.getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var attribute = {
                                boardId: $scope.board.id,
                                name: headertext,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1
                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {

                            })

                        }
                    }

                    $scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                        
                            var payload = {
                                boardAttributeId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            $scope.publish("ProjectNumberCellAutomaticTimeframeUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })

                        });

                    }
                    $scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }
                    $scope.predefinedIcons = function (col) {
                        if (!col.settings) {
                            col.settings = {};
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'predefinedIcons.html',
                            controller: 'predefinedIcons',
                            resolve: {
                                labels: function () {
                                    return col.settings.predefinedIcons;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (!col.settings) {
                                col.settings = {};
                            }
                            col.settings.predefinedIcons = result;
                            var payload = {
                                type: 'project',
                                boardAttributeId: col.id,
                                columnId: col.id,
                                predefinedIcons: col.settings.predefinedIcons
                            }
                            $scope.publish("SmartIconCellSelectorUpdated", payload);
                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })

                        });
                    }
                    $scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var dataSource = null;
                            var filters = null;
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    boardAttributeId: col.id,
                                    predefinedLabels: col.settings.predefinedLabels,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                $scope.publish("ProjectTextCellLabelUpdated", payload);

                                projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                                })

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                projectAttributeId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            $scope.publish("ProjectTextCellLabelUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        }
                    }


                    $scope.changeDateColor = function (col) {

                        if (col.settings.changeColor) {
                            if (!col.settings.changeColorXDaysBefore) {
                                col.settings.changeColorXDaysBefore = 1;
                            }
                            var payload = {
                                projectAttributeId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            $scope.publish("ProjectDateColorCellUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        } else {
                            col.settings.changeColor = false;
                            var payload = {
                                projectAttributeId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            $scope.publish("ProjectDateColorCellUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        }
                    }

                    $scope.updateChangeColorXDaysBefore = function (col) {
                        col.settings.changeColor = true;
                        var payload = {
                            projectAttributeId: col.id,
                            changeColor: col.settings.changeColor,
                            changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                        }
                        $scope.publish("ProjectDateColorCellUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                        })

                    }

                    function addTextFromAppColumn(type) {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'cellCloneSelectorForm.html',
                            controller: 'cellCloneSelectorController',
                            resolve: {
                                celltype: function () {
                                    return type
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            var test = result;
                            if (result) {
                                /*var cellSettings = JSON.stringify({
                                    visualizationType: result.name, 
                                    cloneId: result.id
                                });*/
                                var cellSettings = JSON.stringify({
                                    visualizationType: result
                                })
                                var attribute = {
                                    boardId: $scope.board.id,
                                    name: result,
                                    attributeType: type,
                                    sortOrder: $scope.projectColumns.length - 1,
                                    settings: cellSettings,

                                }
                                projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                                });
                            }

                        }, function () {

                        });
                    };
                    function addTargetFromAppColumn(type) {

                        //var visualizationType = null;
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'targetVisualizationSelectorForm.html',
                            controller: 'targetVisualizationSelectorController'
                        });

                        modalInstance.result.then(function (result) {

                            var cellSettings = JSON.stringify({
                                visualizationType: result
                            })
                            var attribute = {
                                boardId: $scope.board.id,
                                name: result,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1,
                                settings: cellSettings,

                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                            });

                        }, function () {

                        });
                    };
                    function addNumberAutomaticColumn(type) {

                        var dataSource = null;
                        var filters = null;
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return false;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return "PROJECT";
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                            var timePeriod = {
                                timeframe: "all",
                                period: timeService.getTimePeriod("all")
                            }

                            var unit = null;
                            if (result.dataSource.valueElement) {
                                unit = result.dataSource.valueElement.unit;
                            }


                            var cellSettings = JSON.stringify({
                                dataSource: result.dataSource,
                                timePeriod: timePeriod,
                                filters: result.filters,
                                aggregateType: "SUM",
                                unit: unit,
                                unitPlacement: "right"
                            })
                            var attribute = {
                                boardId: $scope.board.id,
                                name: result.dataSource.title,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1,
                                settings: cellSettings,

                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                            })

                        });
                    };
                    $scope.deleteGoal = function (goal) {
                        var goalId = goal.id;
                        swal({
                            title: $translate.instant("STRATEGY_GOAL_DELETE_QUESTION"),
                            text: $translate.instant("STRATEGY_GOAL_DELETE_DESCRIPTION"),
                            icon: "error",
                            buttons: [$translate.instant("COMMON_CANCEL"),
                            $translate.instant("STRATEGY_GOAL_DELETE_BUTTON")],
                            dangerMode: true,
                            closeOnConfirm: false
                        }).then((value) => {
                            switch (value) {
                                case true: // Yes to delete
                                    strategyGoalService().deleteGoal(goalId).then(function () {

                                    });
                                    break;
                                default:
                            }
                        });
                    }
                    $scope.deleteColumn = function (column) {
                        projectService().deleteProjectAttribute(column);
                    }

                    $scope.updateAggregateType = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        $scope.publish("ProjectNumberCellAggregateTypeUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateUnit = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            unit: col.settings.unit
                        }
                        $scope.publish("ProjectNumberCellUnitUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateUnitPlacement = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        $scope.publish("ProjectNumberCellUnitPlacementUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateDecimals = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            decimals: col.settings.decimals
                        }
                        $scope.publish("ProjectNumberCellDecimalUpdated", payload);


                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                        })

                    };



                    $scope.subscribe("ProjectBoardAttributeAdded", function (attribute) {
                        if (attribute.boardId === $scope.board.id) {
                            var hasColumn = $filter('filter')($scope.projectColumns, { id: attribute.id }, true);
                            if (hasColumn.length === 0) {
                                if (attribute.settings) {
                                    attribute.settings = JSON.parse(attribute.settings);
                                }
                                addAttributeToProjects(attribute);
                                $scope.projectColumns.push(attribute);
                            }
                        }
                    });
                    $scope.subscribe("ProjectBoardAttributeDeleted", function (attribute) {
                        if (attribute.boardId === $scope.board.id) {
                            reloadBoard();
                        }
                    });

                    $scope.projectAttributeChangeHandler = function (projectAttribute) {
                        if (projectAttribute.value) {
                            projectAttribute.value = JSON.stringify(projectAttribute.value);
                        }

                        projectService().updateProjectAttribute(projectAttribute).then(function (savedAttribute) {
                            // Parse value again
                            projectAttribute.id = savedAttribute.id;
                            if (savedAttribute.value) {
                                projectAttribute.value = JSON.parse(savedAttribute.value);
                            }
                        });
                    }





                    function getHighestSortOrder() {
                        var highestSortOrder = 1;
                        for (var i = 0; i < $scope.projects.length; i++) {
                            if ($scope.projects[i].sortOrder >= highestSortOrder) {
                                highestSortOrder = $scope.projects[i].sortOrder + 1;
                            }
                        }

                        return highestSortOrder;
                    }

                    $scope.delete = function (goal) {
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'STRATEGY_MESSAGE_DELETE_GOAL_DESC']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.COMMON_DELETE,
                                headerText: translations.COMMON_DELETE + ' ' + goal.name + '?',
                                bodyText: translations.STRATEGY_MESSAGE_DELETE_GOAL_DESC
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                strategyGoalService().deleteGoal(goal.id).then(function (data) {

                                });
                            });

                        });
                    }


                    $scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.projectAttributes.length; i++) {
                            /*var cellNumber = $filter('filter')(project.attributes, { projectBoardAttributeId: attribute.id }, true);
                            if (hasAttribute.length > 0) {
                                var attribute = hasAttribute[0];
                                if (attribute.value) {
                                    attribute.value = JSON.parse(attribute.value);
                                }
                                cellModel.projectAttribute = attribute;
                            }*/
                            var value = colDef.projectAttributes[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    // History
                    $scope.getActivityLog = function (){
                        if (!activityLogLoaded) {
                            var activityRequest = {
                                activity: "StrategyGoal",
                                activityId: 0,
                                //parentId: $scope.board.id
                            };
                            var $translate = $filter('translate');
                            var translateOptions = {
                                month: $translate('COMMON_TIMEELAPSED_MONTH'),
                                days: $translate('COMMON_TIMEELAPSED_DAYS'),
                                hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                                mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                                seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                                now: $translate('COMMON_TIMEELAPSED_NOW')
                            };
                            activityService().getLogForStrategy(activityRequest).then(function (logItems) {
                                logItems.forEach(function (activity) {
                                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                                    if (activity.payLoad && typeof activity.payLoad === "string") {
                                        activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                                    }
                                });
                                $scope.activityLog = logItems;
                                activityLogLoaded = true;
                            });
                        }
                    }

                }
            }
        }]);