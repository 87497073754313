import * as dashboardService from "@common/services/dashboardService"

angular
    .module("DigiLean")
    .directive('threeCTableApp', ['$translate', 'threeCService', 'debounce',
        function ($translate, threeCService, debounce) {
            return {
                templateUrl: 'threeCTableApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'assetId': '<',
                    'settingsChangeHandler': '&',
                    'context': '@'
                },
                link: function (scope, elem, attrs) {

                    scope.theme = {
                        background: "transparent",
                        color: "inherit"
                    };
                    scope.postIts = dashboardService.getThemes();
                    scope.title = $translate.instant("COMMON_TITLE");
                    scope.style = {
                        background: "transparent",
                        color: "inherit"
                    }
                    scope.isLoading = false;
                    scope.useResizing = true;
                    scope.isStandAlone = false; // Indicate if this app is running outside flexiboard.
                    scope.boardId =  null;


                    // Resolve 
                    if (scope.context && scope.context == "standAlone") {
                        scope.isStandAlone = true;
                        scope.useResizing = false;
                    } else {
                        scope.isStandAlone = false;
                    }
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            scope.title = settings.title;
                            scope.style = settings.style;
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            if (settings.boardId) {
                                scope.boardId = settings.boardId;
                            }
                            // ThreeCTableOptions
                            scope.options = {
                                boardId: scope.boardId,
                                isStandAlone: false
                            }
                        }
                        else {
                            scope.createLog();
                        }
                    });

                    scope.createLog = function() {
                        var options = {
                            name: scope.title,
                            assetId: scope.assetId
                        }
                        threeCService().createLog(options).then(function(board) {
                            scope.boardId = board.id;
                            scope.options = {
                                boardId: scope.boardId,
                                isStandAlone: false
                            }
                            scope.updateSettings();
                        });
                    }

                    scope.changeTheme = function (theme) {
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            style: scope.style,
                            theme: scope.theme,
                            boardId: scope.boardId
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }
                    scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);
                }
            }
        }]);

