import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("deviationFilter", ['$filter', '$translate', 'navigationService', 'areaService', 'projectService', 'deviationService', 'authService',
        function ($filter, $translate, navigationService, areaService, projectService, deviationService, authService) {
            return {
                templateUrl: 'deviationFilter.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'filterChangedHandler': '&',
                    'new': '=',
                    'inprogress': '=',
                    'implemented': '=',
                    'rejected': '=',
                    'timePeriod': '=',
                    'showArchive': '='
                },
                link: function (scope, elem, attrs) {
                    scope.isFilterActive = false;
                    scope.consequenceFilterActive = false;
                    scope.myResponsibleFilterActive = false;
                    scope.myRegistrationFilterActive = false;
                    scope.assets = [];
                    scope.categories = [];
                    scope.projects = [];
                    scope.areas = [];
                    scope.deviationtypes = [];

                    scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    });

                    scope.$watch('filter', function (filter) {
                        if (filter) {
                            scope.filterPreferences = filter;
                            filterByUserPreference();
                        }
                    });
                    scope.subscribe("DeviationArchived", function (deviation) {
                        var existingDeviation = getExistingDeviation(deviation.id);
                        if (existingDeviation) {
                            existingDeviation.archivedDate = deviation.archivedDate;                   
                            filter();
                        }
                    });
        
                    scope.subscribe("DeviationUnArchived", function (deviation) {
                        var existingDeviation = getExistingDeviation(deviation.id);
                        if (existingDeviation) {
                            existingDeviation.archivedDate = null;
                            filter();
                        }
                    });

                    scope.subscribe("DeviationCreated", function (deviation) {
                        var isPartOfContainer = $filter('filter')(scope.new.list, { id: deviation.id });
                        if (isPartOfContainer.length === 0) {
                            deviationService().get(deviation.id, false).then(function (deviation) {
                                deviation.cssState = "animated slideInDown"; // Add animation
                                if (deviation.status == 20) {
                                    var isPartOfImpContainer = $filter('filter')(scope.implemented.list, { id: deviation.id });
                                    if (isPartOfImpContainer.length === 0) {
                                        scope.implemented.list.unshift(deviation);
                                    }
                                } else {
                                    scope.new.list.unshift(deviation);
                                }
                                filter();
                                removeAnmiation(deviation);
                            });
                        } else {
                            filter();
                        }
                    });

                    function removeAnmiation(deviation) {
                        setTimeout(function () {
                            deviation.cssState = "";
                        }, 1000);
                    }
                    
                    scope.toggleArchive = function () {
                        filter();
                    }
                    function updateFilterPreferences() {
                        scope.filterChangedHandler({ filter: scope.filterPreferences });
                    }

                    scope.severityOptions = [
                        {
                            severityDB: 1,
                            translated: $translate.instant('COMMON_LOW'),
                            cssClass: "low-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 2,
                            translated: $translate.instant('COMMON_MEDIUM'),
                            cssClass: "medium-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 3,
                            translated: $translate.instant('COMMON_HIGH'),
                            cssClass: "high-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 0,
                            translated: $translate.instant('COMMON_NOT_SPECIFIED'),
                            cssClass: "btn-default",
                            filteredAndInResultList: false
                        }
                    ];

                    scope.$watch('new', function () {
                        if (scope.new) {
                            loadAssets();
                            loadCategories();
                            loadProjects();
                            loadAreas();
                            loadDeviationTypes();
                        }
                    });

                    scope.$watch('timePeriod', function (value) {
                        if (value) {
                            getAllData();
                            filterByUserPreference();
                        }
                    });



                    function filterByUserPreference() {
                        if (scope.filterPreferences) {
                            if (scope.filterPreferences.severity) {
                                setSeverityFilterPreferences(scope.filterPreferences.severity);
                            }
                            if (scope.filterPreferences.archiveFilter) {
                                setArchiveFilterPreferences(scope.filterPreferences.archiveFilter);
                            }
                            if (scope.filterPreferences.asset) {
                                setAssetFilterPreferences(scope.filterPreferences.asset);
                            }
                            if (scope.filterPreferences.category) {
                                setCategoryFilterPreferences(scope.filterPreferences.category);
                            }
                            if (scope.filterPreferences.deviationtype) {
                                setDeviationTypeFilterPreferences(scope.filterPreferences.deviationtype);
                            }
                            if (scope.filterPreferences.area) {
                                setAreaFilterPreferences(scope.filterPreferences.area);
                            }
                            if (scope.filterPreferences.project) {
                                setProjectFilterPreferences(scope.filterPreferences.project);
                            }
                            if (scope.filterPreferences.myRegistration) {
                                scope.myRegistrationFilterActive = true;
                            }
                            if (scope.filterPreferences.myResponsible) {
                                scope.myResponsibleFilterActive = true;
                            }
                            if (scope.filterPreferences.consequence) {
                                scope.consequenceFilterActive = true;
                            }
                            filter();
                        }
                            
                        
                        //});
                    }

                    function setSeverityFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.severityOptions.length; i++) {
                                if (scope.severityOptions[i].severityDB === filteredId) {
                                    scope.severityOptions[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setArchiveFilterPreferences(preferences) {
                        angular.forEach(preferences, function (option) {
                            for (var i = 0; i < scope.archiveOptions.length; i++) {
                                if (scope.archiveOptions[i].option === option.option) {
                                    scope.archiveOptions[i].isSelected = option.isSelected;
                                    break;
                                }
                            }
                        });
                    }
                    function setDeviationTypeFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredTypeId) {
                            for (var t = 0; t < scope.deviationtypes.length; t++) {
                                if (scope.deviationtypes[t].id === filteredTypeId) {
                                    scope.deviationtypes[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setProjectFilterPreferences(preferences) {
                        angular.forEach(preferences, function (projectId) {
                            for (var t = 0; t < scope.projects.length; t++) {
                                if (scope.projects[t].id === projectId) {
                                    scope.projects[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setAreaFilterPreferences(preferences) {
                        angular.forEach(preferences, function (areaId) {
                            for (var t = 0; t < scope.areas.length; t++) {
                                if (scope.areas[t].id === areaId) {
                                    scope.areas[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setAssetFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredAssetId) {
                            for (var a = 0; a < scope.assets.length; a++) {
                                if (scope.assets[a].id === filteredAssetId) {
                                    scope.assets[a].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setCategoryFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.categories.length; i++) {
                                if (scope.categories[i].id === filteredId) {
                                    scope.categories[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function getData(deviationStatus) {
                        deviationService().getListByStatus(deviationStatus.status, scope.timePeriod).then(function (result) {
                            for (var s = 0; s < result.length; s++) {
                                result[s].dateStyleClass = getDateStyle(result[s].dueDate);
                                // result[s].unitLabel = unitService().getUnitLabel(result[s].unit);
                            }
                            deviationStatus.list = result;
                            deviationStatus.filteredList = result;
                            if (scope.isFilterActive) {
                                filter();
                            }
                        });
                    }


                    function getAllData() {
                        getData(scope.new);
                        getData(scope.inprogress);
                        getData(scope.implemented);
                        getData(scope.rejected);
                    }


                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check
                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }

                    function loadAssets() {
                        assetService.getAssetsWithDeviation().then(function (assets) {
                            scope.assets = assets;
                            resetAssetFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadCategories() {
                        deviationService().getProblemCategoriesWithDeviation().then(function (items) {
                            scope.categories = items;
                            resetCategoryFilter();
                            filterByUserPreference();
                        });
                    }


                    function loadProjects() {
                        projectService().getProjectListWithDeviation().then(function (items) {
                            scope.projects = items;
                            resetProjectFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadAreas() {
                        areaService().getAreasWithDeviation().then(function (items) {
                            scope.areas = items;
                            resetAreaFilter()
                            filterByUserPreference();
                        });
                    }

                    function loadDeviationTypes() {
                        deviationService().getDeviationTypes().then(function (types) {
                            scope.deviationtypes = types;
                            resetDeviationTypeFilter();
                            filterByUserPreference();
                        });

                    }

                    scope.toggleMyRegistrationFilter = function () {
                        scope.myRegistrationFilterActive = !scope.myRegistrationFilterActive;
                        if (scope.myRegistrationFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.myRegistration = true;
                            } else {
                                scope.filterPreferences = {
                                    myRegistration: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.myRegistration = null;
                        }
                        filter();
                        updateFilterPreferences();
                    }

                    scope.toggleMyResponsibleFilter = function () {
                        scope.myResponsibleFilterActive = !scope.myResponsibleFilterActive;
                        if (scope.myResponsibleFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.myResponsible = true;
                            } else {
                                scope.filterPreferences = {
                                    myResponsible: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.myResponsible = null;
                        }
                        filter();
                        updateFilterPreferences();
                    }

                    scope.toggleConsequenceFilter = function () {
                        scope.consequenceFilterActive = !scope.consequenceFilterActive;
                        if (scope.consequenceFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.consequence = true;
                            } else {
                                scope.filterPreferences = {
                                    consequence: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.consequence = null;
                        }
                        filter();
                        updateFilterPreferences();
                    }

                   

                    function resetAssetFilter() {
                        angular.forEach(scope.assets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                    }

                    function resetDeviationTypeFilter() {
                        angular.forEach(scope.deviationtypes, function (type) {
                            type.filteredAndInResultList = false;
                        });
                    }

                    function resetProjectFilter() {
                        angular.forEach(scope.projects, function (project) {
                            project.filteredAndInResultList = false;
                        });
                    }
                    function resetAreaFilter() {
                        angular.forEach(scope.areas, function (area) {
                            area.filteredAndInResultList = false;
                        });
                    }
                    function resetCategoryFilter() {
                        angular.forEach(scope.categories, function (category) {
                            category.filteredAndInResultList = false;
                        });
                    }
                    function resetSeverityFilter() {
                        angular.forEach(scope.severityOptions, function (item) {
                            item.filteredAndInResultList = false;
                        });
                    }
                    function resetArchiveFilter() {
                        scope.showArchive = false;
                    }
                    scope.filterSeverity = function (severity) {
                        severity.filteredAndInResultList = !severity.filteredAndInResultList;
                        if (severity.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.severity) {
                                scope.filterPreferences.severity.push(severity.severityDB);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.severity = [severity.severityDB];
                                } else {
                                    scope.filterPreferences = {
                                        severity: [severity.severityDB]
                                    };
                                }
                                scope.filterPreferences.severity = [severity.severityDB];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.severity) {
                                var index = scope.filterPreferences.severity.indexOf(severity.severityDB);
                                scope.filterPreferences.severity.splice(index, 1);
                            }
                        }
                        filter();
                        updateFilterPreferences();
                    }

                    scope.filterDeviationType = function (deviationtype) {
                        deviationtype.filteredAndInResultList = !deviationtype.filteredAndInResultList;
                        if (deviationtype.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.deviationtype) {
                                scope.filterPreferences.deviationtype.push(deviationtype.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.deviationtype = [deviationtype.id];
                                } else {
                                    scope.filterPreferences = {
                                        deviationtype: [deviationtype.id]
                                    };
                                }
                                scope.filterPreferences.deviationtype = [deviationtype.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.deviationtype) {
                                var index = scope.filterPreferences.deviationtype.indexOf(deviationtype.id);
                                scope.filterPreferences.deviationtype.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filter();
                    }
                    scope.filterArea = function (area) {
                        area.filteredAndInResultList = !area.filteredAndInResultList;
                        if (area.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.area) {
                                scope.filterPreferences.area.push(area.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.area = [area.id];
                                } else {
                                    scope.filterPreferences = {
                                        area: [area.id]
                                    };
                                }
                                scope.filterPreferences.area = [area.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.area) {
                                var index = scope.filterPreferences.area.indexOf(area.id);
                                scope.filterPreferences.area.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filter();
                    }
                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList;
                        if (project.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.project) {
                                scope.filterPreferences.project.push(project.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.project = [project.id];
                                } else {
                                    scope.filterPreferences = {
                                        project: [project.id]
                                    };
                                }
                                scope.filterPreferences.project = [project.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.project) {
                                var index = scope.filterPreferences.project.indexOf(project.id);
                                scope.filterPreferences.project.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filter();
                    }
                    scope.filterAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList;
                        if (asset.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.asset) {
                                scope.filterPreferences.asset.push(asset.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.asset = [asset.id];
                                } else {
                                    scope.filterPreferences = {
                                        asset: [asset.id]
                                    };
                                }
                                scope.filterPreferences.asset = [asset.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.asset) {
                                var index = scope.filterPreferences.asset.indexOf(asset.id);
                                scope.filterPreferences.asset.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filter();
                    }
                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList;
                        if (category.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.category) {
                                scope.filterPreferences.category.push(category.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.category = [category.id];
                                } else {
                                    scope.filterPreferences = {
                                        category: [category.id]
                                    };
                                }
                                scope.filterPreferences.category = [category.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.category) {
                                var index = scope.filterPreferences.category.indexOf(category.id);
                                scope.filterPreferences.category.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filter();
                    }

                    function updateFilterPreferences() {
                        scope.filterChangedHandler({ filter: scope.filterPreferences });
                    }


                    scope.removeFilter = function () {
                        resetAssetFilter();
                        resetCategoryFilter();
                        resetSeverityFilter();
                        resetArchiveFilter();
                        resetProjectFilter();
                        resetAreaFilter();
                        resetDeviationTypeFilter();
                        scope.myResponsibleFilterActive = false;
                        scope.myRegistrationFilterActive = false;
                        scope.consequenceFilterActive = false;
                        filter();
                        resetFilterPreferences();
                    };

                    function resetFilterPreferences() {
                        scope.filterPreferences = null;
                        updateFilterPreferences();
                    }

                    function filter() {
                        scope.new.filteredList = scope.new.list;
                        scope.inprogress.filteredList = scope.inprogress.list;
                        scope.implemented.filteredList = scope.implemented.list;
                        scope.rejected.filteredList = scope.rejected.list;

                        var assetsToShow = $filter('filter')(scope.assets, { filteredAndInResultList: true }, true);
                        if (assetsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByAssets(scope.new.filteredList, assetsToShow);
                            scope.inprogress.filteredList = getFilteredListByAssets(scope.inprogress.filteredList, assetsToShow);
                            scope.implemented.filteredList = getFilteredListByAssets(scope.implemented.filteredList, assetsToShow);
                            scope.rejected.filteredList = getFilteredListByAssets(scope.rejected.filteredList, assetsToShow);
                        }

                        var areasToShow = $filter('filter')(scope.areas, { filteredAndInResultList: true }, true);
                        if (areasToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByAreas(scope.new.filteredList, areasToShow);
                            scope.inprogress.filteredList = getFilteredListByAreas(scope.inprogress.filteredList, areasToShow);
                            scope.implemented.filteredList = getFilteredListByAreas(scope.implemented.filteredList, areasToShow);
                            scope.rejected.filteredList = getFilteredListByAreas(scope.rejected.filteredList, areasToShow);
                        }

                        var typesToShow = $filter('filter')(scope.deviationtypes, { filteredAndInResultList: true }, true);
                        if (typesToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByDeviationTypes(scope.new.filteredList, typesToShow);
                            scope.inprogress.filteredList = getFilteredListByDeviationTypes(scope.inprogress.filteredList, typesToShow);
                            scope.implemented.filteredList = getFilteredListByDeviationTypes(scope.implemented.filteredList, typesToShow);
                            scope.rejected.filteredList = getFilteredListByDeviationTypes(scope.rejected.filteredList, typesToShow);
                        }

                        var prjectsToShow = $filter('filter')(scope.projects, { filteredAndInResultList: true }, true);
                        if (prjectsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByProjects(scope.new.filteredList, prjectsToShow);
                            scope.inprogress.filteredList = getFilteredListByProjects(scope.inprogress.filteredList, prjectsToShow);
                            scope.implemented.filteredList = getFilteredListByProjects(scope.implemented.filteredList, prjectsToShow);
                            scope.rejected.filteredList = getFilteredListByProjects(scope.rejected.filteredList, prjectsToShow);
                        }

                        var categoriesToShow = $filter('filter')(scope.categories, { filteredAndInResultList: true }, true);
                        if (categoriesToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByCategories(scope.new.filteredList, categoriesToShow);
                            scope.inprogress.filteredList = getFilteredListByCategories(scope.inprogress.filteredList, categoriesToShow);
                            scope.implemented.filteredList = getFilteredListByCategories(scope.implemented.filteredList, categoriesToShow);
                            scope.rejected.filteredList = getFilteredListByCategories(scope.rejected.filteredList, categoriesToShow);
                        }

                        var activeSeverityFilter = severityFilterActive();
                        if (activeSeverityFilter.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, valueInList('severity', activeSeverityFilter));
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, valueInList('severity', activeSeverityFilter));
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, valueInList('severity', activeSeverityFilter));
                            scope.rejected.filteredList = $filter('filter')(scope.rejected.filteredList, valueInList('severity', activeSeverityFilter));
                        }


                        scope.isFilterActive = true;
                        scope.new.filteredList = getArchiveDateListByOptions(scope.new.filteredList);
                        scope.inprogress.filteredList = getArchiveDateListByOptions(scope.inprogress.filteredList);
                        scope.implemented.filteredList = getArchiveDateListByOptions(scope.implemented.filteredList);
                        scope.rejected.filteredList = getArchiveDateListByOptions(scope.rejected.filteredList);


                        if (scope.consequenceFilterActive) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, hasValuesInListFilter('consequences'));
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, hasValuesInListFilter('consequences'));
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, hasValuesInListFilter('consequences'));
                            scope.rejected.filteredList = $filter('filter')(scope.rejected.filteredList, hasValuesInListFilter('consequences'));
                        }

                        if (scope.myResponsibleFilterActive) {
                            var userid = authService.getCurrentUser().userId;
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, { ownerUserId: userid });
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, { ownerUserId: userid });
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, { ownerUserId: userid });
                            scope.rejected.filteredList = $filter('filter')(scope.rejected.filteredList, { ownerUserId: userid });
                        }

                        if (scope.myRegistrationFilterActive) {
                            var userid = authService.getCurrentUser().userId;
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, { createdByUserId: userid });
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, { createdByUserId: userid });
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, { createdByUserId: userid });
                            scope.rejected.filteredList = $filter('filter')(scope.rejected.filteredList, { createdByUserId: userid });
                        }
                    }



                    function getArchiveDateListByOptions(list) {

                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            if (!scope.showArchive && !deviation.archivedDate) {
                                filteredList.push(deviation);
                            }
                            if (scope.showArchive && deviation.archivedDate) {
                                filteredList.push(deviation);
                            }
                        }
                        return filteredList;

                    }

                    function valueInList(property, list) {
                        return function (item) {
                            for (var i = 0; i < list.length; i++) {
                                if (item[property] === list[i]) return true;
                            }
                            return false;
                        }
                    }
                    function hasValuesInListFilter(property) {
                        return function (item) {
                            if (item[property] && item[property].length > 0) return true;
                            return false;
                        }
                    }
                    function getFilteredListByAssets(list, assets) {
                        if (assets.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            for (var j = 0; j < assets.length; j++) {
                                var asset = assets[j];
                                if (deviation.followUpAssetId === asset.id) {
                                    filteredList.push(deviation);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByAreas(list, areas) {
                        if (areas.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            for (var j = 0; j < areas.length; j++) {
                                var area = areas[j];
                                if (deviation.areaId === area.id) {
                                    filteredList.push(deviation);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByDeviationTypes(list, deviationtypes) {
                        if (deviationtypes.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            for (var j = 0; j < deviationtypes.length; j++) {
                                var type = deviationtypes[j];
                                if (deviation.deviationTypeId === type.id) {
                                    filteredList.push(deviation);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByProjects(list, projects) {
                        if (projects.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            for (var j = 0; j < projects.length; j++) {
                                var project = projects[j];
                                if (deviation.projectId === project.id) {
                                    filteredList.push(deviation);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByCategories(list, categories) {
                        if (categories.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var deviation = list[i];
                            var deviationCategories = deviation.categories;
                            if (deviationCategories && deviationCategories.length > 0) {
                                for (var j = 0; j < categories.length; j++) {
                                    var match = $filter('filter')(deviationCategories, { problemCategoryId: categories[j].id }, true);
                                    if (match.length === 1) {
                                        filteredList.push(deviation);
                                    }
                                }
                            }
                        }
                        return filteredList;
                    }

                    function severityFilterActive() {
                        var filteredSeverity = [];
                        angular.forEach(scope.severityOptions, function (severity) {
                            if (severity.filteredAndInResultList) {
                                filteredSeverity.push(severity.severityDB);
                            }
                        });
                        return filteredSeverity;
                    }

                    function getExistingDeviation(deviationId) {
                        var isPartOfContainer = $filter('filter')(scope.new.list, { id: deviationId }, true);
                        if (isPartOfContainer.length > 0) { return isPartOfContainer[0] };
                        isPartOfContainer = $filter('filter')(scope.inprogress.list, { id: deviationId }, true);
                        if (isPartOfContainer.length > 0) { return isPartOfContainer[0] };
                        isPartOfContainer = $filter('filter')(scope.implemented.list, { id: deviationId }, true);
                        if (isPartOfContainer.length > 0) { return isPartOfContainer[0] };
                        isPartOfContainer = $filter('filter')(scope.rejected.list, { id: deviationId }, true);
                        if (isPartOfContainer.length > 0) { return isPartOfContainer[0] };
                        return null;
                    }

                }
            }
        }]);
