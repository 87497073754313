import { DlSubject } from "@common/stores/reactive"
import { DigiLeanAuthUser, RoleArray } from "@common/model/types"
import type { User, UserFullProfile } from "@api"
import { cloneDeep } from "lodash"

// auth user, when logging in an collecting from token and userinfo
const authUserSubject = new DlSubject<DigiLeanAuthUser>({loggedIn: false, roles: new RoleArray()})
export function setAuthUser(user: DigiLeanAuthUser) {
    authUserSubject.next(user)
}
export function getAuthUser () {
    return authUserSubject.getClonedValue()
}
export function getAccessToken() {
    return getAuthUser().token
}
export const authUser = authUserSubject.asObservable()

// full user profile containing modules and roles
const userFullProfileSubject = new DlSubject<UserFullProfile>({})
export const userFullProfile = userFullProfileSubject.asObservable()
export function setFullUserProfile(userProfile: UserFullProfile) {
    userFullProfileSubject.next(userProfile)
    userFull = cloneDeep(userProfile)
}
export let userFull: Readonly<UserFullProfile> = {}
export const getUserFullProfile = () => userFull


// simple user profile
const userProfileSubject = new DlSubject<User>({})
export const userProfile = userProfileSubject.asObservable()
export function setUserProfile(userProfile: User) {
    userProfileSubject.next(userProfile)
}
export function getUserProfile() {
    const up = userProfileSubject.getClonedValue()
    return up
}