import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .controller("projectPortfolioController", ['$scope', '$state', '$filter', '$uibModal', 'authService', 'projectService',
        function ($scope, $state, $filter, $uibModal, authService, projectService) {

            $scope.otherprojects = [];
            $scope.quickAccess = [];

            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            // if page is refreshed we need to refresh
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            });

            function loadPortfolio() {
                employeeService.getUserPreference("ProjectPortfolioFavorites").then(function (settings) {
                    if (settings && settings.value) {
                        $scope.quickAccess = settings.value;
                    }
                    projectService().getMemberProjectPortfolio().then(function (portfolios) {
                        angular.forEach(portfolios, function (portfolio) {
                            var isFavorite = $filter('filter')($scope.quickAccess, { id: portfolio.id })
                            if (isFavorite.length > 0) {
                                portfolio.isStar = true;
                            } else {
                                portfolio.isStar = false;
                            }
                            });
                        sortByFavorites(portfolios);
                        $scope.portfoliotables = portfolios;

                    });

                });
            };

            function sortByFavorites(portfolios) {
                portfolios.sort(function(a, b){return b.isStar - a.isStar});
            }

            loadPortfolio();

          

            $scope.removeFromQuickAccess = function (portfolio) {
                portfolio.isStar = false;
                var isFavorite = $filter('filter')($scope.quickAccess, { id: portfolio.id })
                if (isFavorite.length > 0) {
                    var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                    $scope.quickAccess.splice(currentIndex, 1);
                    sortByFavorites($scope.portfoliotables);
                    saveQuickAccess();
                }
            }
            $scope.addToQuickAccess = function (portfolio) {
                portfolio.isStar = true;
                $scope.quickAccess.push(portfolio);
                sortByFavorites($scope.portfoliotables);
                saveQuickAccess();
            }
            function saveQuickAccess() {

                employeeService.updateUserPreference("ProjectPortfolioFavorites", $scope.quickAccess).then(function (settings) {
                    $scope.publish("ProjectPortfolioFavoritesChanged", $scope.quickAccess);
                });
            }

            $scope.createNew = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newProjectPortfolioConfiguration.html',
                    controller: 'newProjectPortfolioConfigurationController'
                });

                modalInstance.result.then(function (newId) {
                    $state.go('board', { id: newId }, { reload: false });
                });

            }


        }]);



