<script setup lang="ts">
    import {onMounted, ref, onUnmounted} from "vue"
    import { uploadFileBlobStorage } from "@common/services/documentService"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    const vCustomEventsEmitter = new CustomEventsEmitter()
    
    const wrapperEl = ref<HTMLDivElement | null>(null)
    const inputEl = ref<HTMLInputElement | null>(null)

    const props = defineProps({
        accept: { type: String, required: false, default: "*" }
    })

    let uploading = ref(false)
    let isDraggingOver = ref(false)
    let errorMsg = ref("")

    let inputField: HTMLInputElement | null = null

    const onChangeInput = (e: Event) => {
        inputField = e.target as HTMLInputElement
        if (inputField.files && inputField.files[0]) {
            uploadFile(inputField.files[0])
        }
    }
    const uploadFile = async (file: File) => {
        uploading.value = true
        if (inputField)
            inputField.disabled = true
        
        const filesUploaded = await uploadFileBlobStorage(file)
        if (filesUploaded)
            vCustomEventsEmitter.emit("files-uploaded", filesUploaded)
        
        if (inputField) {
            inputField.disabled = false
            inputField.value = ""   
        }
        uploading.value = false
    }

    const getAndValidateFileDragging = (ev: DragEvent) => {
        errorMsg.value = ""
        if (ev.dataTransfer?.items && ev.dataTransfer?.items.length > 0) {
            console.log("ev.dataTransfer.items", ev.dataTransfer.items)
            if (ev.dataTransfer.items.length > 1) {
                errorMsg.value = "FILEUPLOADER_ONLY_ONE_FILE"
                return
            }
            const item = ev.dataTransfer.items[0]
            if (item.kind == "file") {
                const file = item.getAsFile()
                return file
            }
        } else if (ev.dataTransfer?.files && ev.dataTransfer?.files.length > 0) {
            console.log("ev.dataTransfer.files", ev.dataTransfer.files)
            if (ev.dataTransfer?.files.length > 1) {
                errorMsg.value = "FILEUPLOADER_ONLY_ONE_FILE"
                return
            }
            const file = ev.dataTransfer.files[0]
            return file
        }
        return null
    }
    const onDrop = (ev: DragEvent) => {
        ev.preventDefault()
        const file = getAndValidateFileDragging(ev)
        if (file)
            uploadFile(file)
        isDraggingOver.value = false
    }
    const onDragEnter = (e: DragEvent) => {
        console.log("onDragEnter")
        e.preventDefault()
        isDraggingOver.value = true
        getAndValidateFileDragging(e)
    }
    const onDragOver = (e: DragEvent) => {
        e.preventDefault()
        isDraggingOver.value = true
    }
    const onDragLeave = (e: DragEvent) => {
        e.preventDefault()
        isDraggingOver.value = false
    }

    onMounted(() => {
        console.log("accept", props.accept)
        if (inputEl.value)
            inputEl.value.addEventListener("change", onChangeInput)
        if (wrapperEl.value) {
            wrapperEl.value.addEventListener("drop", onDrop);
            wrapperEl.value.addEventListener("dragenter", onDragEnter);
            wrapperEl.value.addEventListener("dragover", onDragOver);
            wrapperEl.value.addEventListener("dragleave", onDragLeave);
        }
    })
    onUnmounted(() => {
        if (inputEl.value)
            inputEl.value.removeEventListener("change", onChangeInput)
        if (wrapperEl.value) {
            wrapperEl.value.removeEventListener("drop", onDrop);
            wrapperEl.value.removeEventListener("dragenter", onDragEnter);
            wrapperEl.value.removeEventListener("dragover", onDragOver);
            wrapperEl.value.removeEventListener("dragleave", onDragLeave);
        }
    })
</script>
<style>
    #wrapper {
        background: var(--digilean-secondary-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        border: 2px dashed var(--digilean-primary);
        font-size: 1.2rem;
        color: var(--digilean-primary-text);
    }
    #wrapper.draggingover {
        border-color: var(--digilean-success);
        background: var(--digilean-green-bright);
    }
    input.noshow {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        cursor: pointer;
        margin: 0;
        padding: 6rem 2rem;
    }
    .error {
        color: var(--digilean-error);
    }
</style>
<template>
    <div v-custom-events-emitter id="wrapper" 
        :class="{draggingover: isDraggingOver}" ref="wrapperEl">
        <label v-if="uploading">
            <span>Uploading ...</span>
            <spinner-icon spin="true"></spinner-icon>
        </label>
        <label for="file-input" v-else>
            <translate-label label="COMMON_DROPZONE"></translate-label>
            <span class="error" v-if="errorMsg">
                <br>
                <translate-label .label="errorMsg"></translate-label>
            </span>
        </label>
        
        <input type="file" ref="inputEl"
            :accept="accept"
            id="file-input" 
            class="noshow" />
    </div>
</template>
