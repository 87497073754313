﻿import { parseColumnSettings } from "@common/services/settingsService"

angular.module("DigiLean").service('smartActionListService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        function smartActionListService() {
            var resource = "/smartActionList";
            var resourceUrl = webApiUrl + resource;
            return {
                createsmartActionList: function(smartActionListTemplate) {
                    var url = resourceUrl + "/createsmartActionList/";
                    return $http.post(url, smartActionListTemplate).then(function (result) {
                        return result.data;
                    });
                },
                createSmartActionListFromTemplate: function(id, targetOptions) {
                    resourceUrl = resourceUrl + "/createsmartActionListFromTemplate/" + id;
                    return $http.post(resourceUrl, targetOptions).then(function (result) {
                        return result.data;
                    });
                },
                get: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.get(url).then(function (result) {
                        const smartActionList = result.data
                        parseColumnSettings(smartActionList.columns)
                        return smartActionList;
                    });
                },
                getArchivedTasks: function (id) {
                    var url = resourceUrl + "/getArchivedTasks/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                addTask: function (task) {
                    var url = resourceUrl + "/addTask/";
                    return $http.post(url, task).then(function (result) {
                        return result.data;
                    });
                },
                updateTaskSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/updateTaskSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateCell: function (cell) {
                    var url = resourceUrl + "/updateCell/";
                    return $http.put(url, cell).then(function (result) {
                        return result.data;
                    });
                },
                addColumn: function (column) {
                    var url = resourceUrl + "/addColumn/";
                    return $http.post(url, column).then(function (result) {
                        return result.data;
                    });
                },
 
                deleteColumn: function(id) {
                    var url = resourceUrl + "/deleteColumn/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                updateColumn: function (col) {
                    var updated = {
                        id: col.id,
                        name: col.name,
                        boardId: col.boardId
                    }
                    if (col.settings) {  
                        updated.settings = JSON.stringify(col.settings);
                    }
                    var url = resourceUrl + "/updateColumn/";
                    return $http.put(url, updated).then(function (result) {
                        return result.data;
                    });
                },
                updateColumnSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateColumnSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
            }
        }
        return smartActionListService;
    }]);

