<script setup lang="ts">
    import { onMounted, ref } from "vue"
    import { getBusinessUnitUsers } from "@common/services/businessUnitService"
    import { User } from "@api"

    const props = defineProps({
        id: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: false
        }
    })

    let users = ref<User[]>([])

    let getUsers = async () => {
        if (!props.id)
            return
        
        let us = await getBusinessUnitUsers(props.id)
        users.value = us
    }
    onMounted(() => {
        getUsers()
    })
</script>

<template>
    <h1>{{ name }}</h1>

    <p v-for="user in users">
        {{user.firstName}} {{user.lastName}}
    </p>
</template>