import { UserInfoModel, ApplicationUser, Asset, AuthenticationMode, CustomerAccount, UserModel, UserCheckupResult, UserCheckupStatus, AzureAdDigiLeanUser, BusinessUnit, AssetBusinessUnit } from "@api"
import * as userAdmin from "@common/services/user/userAdminService"
import * as customerService from "@common/services/customers/customerService"
import { getAllUnitsCached } from "@common/services/businessUnitService"
import dialog from "@common/components/digilean/DigileanDialog"
import angular from "angular"
import { DigiLeanNgScope, IHasModules } from "@common/model/angularModel"
import swal from "sweetalert"
import toastr from "toastr"
import * as assetService from "@common/services/assetService"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import { getUserProfile } from "@common/stores/userStore"

interface ILicenseData {
    noOfUsers: number
    userLicenses: number
}

interface IUserEditScope extends DigiLeanNgScope, IHasModules {
    showEditUser: boolean
    savedSuccessfully: boolean
    message: string
    warning: string
    userLicenses: number
    numberOfUsers: number
    mobileLicenses: number
    numberOfMobileUsers: number
    authenticationMode: AuthenticationMode
    maxUserLicencesText: string
    maxMobileUserLicencesText: string
    isUsingAD: boolean
    increaseMobileLicences: number
    increaseRegularLicences: number
    user: UserInfoModel
    registration: UserModel
    account: CustomerAccount
    assets: AssetBusinessUnit[]
    animationsEnabled: boolean
    existInAnotherInstallation: boolean
    headline: string
    translationLicensesData: ILicenseData
    hasAzureAdModule: boolean

    selectedBusinessUnit: BusinessUnit
    businessUnits: BusinessUnit[]
    businessUnitsEnabled: boolean
    useAD: (user: UserModel) => boolean
    toggleUseAdmin: () => void
    setAdminCheckboxes: (user: UserModel) => void
    canAddUser: () => boolean
    canAddMobileUser: () => boolean
    canAddAnyUser: () => boolean
    confirmMobileLicences: (no: number) => void
    confirmRegularLicences: (no: number) => void
    openAccessForEdit: (user: ApplicationUser) => void
    checkEmail: (email: string) => void
    checkUserName: (username: string) => void
    checkExistingUser: (userNameOrEmail: string) => void
    checkExistingRights: (user: UserInfoModel) => void
    resetPassword: (user: UserInfoModel) => void
    delete: (user: UserInfoModel) => void
    save: () => void
}
angular
.module('DigiLean')
.directive("userEdit", ['$uibModal', 'authService', 'modalService', 'navigationService',
    function ($uibModal, authService, modalService, navigationService) {
        return {
            templateUrl: 'userEdit.html',
            restrict: 'E',
            scope: {
                'user': '=',
                'show': '='
            },
            link: function ($scope: IUserEditScope, elem, attrs) {
                $scope.showEditUser = false
                $scope.savedSuccessfully = false
                $scope.message = ""
                $scope.warning = ""
                $scope.userLicenses = 0
                $scope.numberOfUsers = 0
                $scope.mobileLicenses = 0
                $scope.numberOfMobileUsers = 0
                $scope.authenticationMode = 0
                $scope.maxUserLicencesText = ""
                $scope.maxMobileUserLicencesText = ""
                $scope.isUsingAD = false

                $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
                var businessUnitId = 0;

                $scope.selectedBusinessUnit = {}
                $scope.businessUnits = [{name: ""}]
                $scope.businessUnitsEnabled = false
                getAllUnitsCached().then(units => {
                    if (units.length > 0)
                        $scope.businessUnitsEnabled = true
                    $scope.businessUnits.push(...units)
                })

                let initialUserName = ""
                let initialEmail = ""
                let initialBusinessUnitId = 0

                $scope.hasAzureAdModule = navigationService().hasModule("AZUREAD")
                
                $scope.toggleUseAdmin = function() {
                    $scope.isUsingAD = !$scope.isUsingAD
                }

                $scope.increaseMobileLicences = 10
                $scope.increaseRegularLicences = 10

                $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
                $scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
                $scope.hasA3Module = navigationService().hasModule("A3")
                $scope.hasStrategy = navigationService().hasModule("STRATEGY")
                $scope.hasProject = navigationService().hasModule("PROJECT")
                $scope.hasLearningModule = navigationService().hasModule("LEARNING")
                    
                // if page is refreshed we need to refresh the access to modules
                $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                    $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
                    $scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
                    $scope.hasA3Module = navigationService().hasModule("A3")
                    $scope.hasProject = navigationService().hasModule("PROJECT")
                    $scope.hasStrategy = navigationService().hasModule("STRATEGY")
                    $scope.hasLearningModule = navigationService().hasModule("LEARNING")
                })

                $scope.$watch("show", function(showEditUser) {
                    if (!showEditUser)
                        clearActiveUser()
                })
                $scope.$watch("user", function (user) {
                    if($scope.user && $scope.user.id){
                        $scope.registration = $scope.user
                        initialUserName = $scope.registration.userName
                        initialEmail = $scope.registration.email!
                        if ($scope.user.businessUnitId) {
                            $scope.selectedBusinessUnit = {id: $scope.user.businessUnitId}
                            initialBusinessUnitId = $scope.user.businessUnitId
                        }
                        $scope.message = ""
                        $scope.warning = "" 
                        $scope.isUsingAD = $scope.useAD($scope.registration)       
                        $scope.setAdminCheckboxes($scope.registration)
                    } else if($scope.user && !$scope.user.id){
                        clearActiveUser()
                    }
                })
                $scope.businessUnitChanged = function(unit: BusinessUnit) {
                    $scope.registration.businessUnitId = unit.id
                    checkBusinessunitAdminSetting()
                }
                var getSubscription = async function() {
                    $scope.account = await customerService.getAccount()
                }
                getSubscription()

                assetService.getAllAssets().then(function (assets) {
                    $scope.assets = assets
                    for (let k = 0; k < $scope.assets.length; k++) {
                        ///@ts-ignore
                        $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level                 
                    }           
                })

                function hasChangedBusinessUnit(){
                    if(!$scope.user && $scope.registration.businessUnitId) return true
                    return $scope.registration.businessUnitId != initialBusinessUnitId
                }
                const clearActiveUser = function () {
                    let isMobileUser = false
                    
                    if (!$scope.canAddUser() && $scope.canAddMobileUser()) {
                        isMobileUser = true
                    }
                    $scope.message = ""
                    $scope.registration = {
                        
                        useAD: $scope.authenticationMode == 0 ? false : true,
                        firstName: "",
                        lastName: "",
                        userName: "",
                        email: "",
                        isAdmin: false,
                        isBoardDesigner: false,
                        isImprovementAdmin: false,
                        isDataAdmin: false,
                        isMessageWriter: false,
                        isDeviationAdmin: false,
                        isProjectAdmin: false,
                        isA3Admin: false,
                        isStrategyAdmin: false,
                        isLearningAdmin: false,
                        isInfoScreenUser: false,
                        isMobileUser: isMobileUser,
                        businessUnitId: null
                    }
                    initialUserName = $scope.registration.userName
                    initialEmail = $scope.registration.email!
                    initialBusinessUnitId = 0
                    $scope.isUsingAD = $scope.useAD($scope.registration)
                    $scope.selectedBusinessUnit = {}
                    if($scope.isBusinessunitAdmin){
                        var businessUnitId = getBusinessUnitAsBusinessUnitAdmin()
                        $scope.registration.businessUnitId = businessUnitId
                        $scope.selectedBusinessUnit = {id: businessUnitId}
                    }
                }

                function getBusinessUnitAsBusinessUnitAdmin(){
                    const currentUser = getUserProfile()
                    businessUnitId = currentUser.businessUnitId || 0; // Set a default value of 0 if businessUnitId is undefined
                    return businessUnitId
                }

                $scope.useAD = function(user) {
                    if (!user){
                        clearActiveUser()
                        user = $scope.registration
                    }
                    if ($scope.authenticationMode == 0)
                        return false
                    if ($scope.authenticationMode == 2 && !user.useAD)
                        return false
                    return true
                }
                customerService.getAuthenticationMode().then(function(result) {
                    $scope.authenticationMode = result
                    if($scope.user && $scope.user.id) {
                        $scope.registration.useAD = $scope.authenticationMode == 0 ? false : true
                    } else {
                        clearActiveUser()
                    }
                })

                $scope.canAddUser = function() {
                    return $scope.numberOfUsers < $scope.userLicenses
                }
                $scope.canAddMobileUser = function() {
                    if ($scope.mobileLicenses == 0) return false
                    return $scope.numberOfMobileUsers < $scope.mobileLicenses
                }
                $scope.canAddAnyUser = function() {         
                    return $scope.canAddUser() || $scope.canAddMobileUser()
                }
                

                $scope.confirmMobileLicences = function (no) {
                    if(!no){
                        $scope.increaseMobileLicences = 10
                        no = 10;
                    } else {
                        const modalOptions = {
                            closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                            actionButtonText: translateLabelInstant("ADMIN_CONFIRM_ORDER"),
                            headerText: translateLabelInstant("ADMIN_CUSTOMER_INCREASE_MOBILE_LICENCES") + ': ' + no,
                            bodyText: translateLabelInstant("ADMIN_SUBMIT_ORDER_DESC")
                        }
    
                        modalService.showModal({}, modalOptions).then(function (result) {
                            customerService.increaceMobileLicenses(no).then(function (result) {
                                $scope.mobileLicenses = $scope.mobileLicenses + no
                                $scope.maxMobileUserLicencesText = ""
                            })
                        })   
                    }            
                }

                $scope.decreaseRegularLicences = function(){
                    if($scope.increaseRegularLicences > 10){
                        $scope.increaseRegularLicences = $scope.increaseRegularLicences - 10;
                    }
                }
                $scope.increaseRegular = function(){
                    $scope.increaseRegularLicences = $scope.increaseRegularLicences + 10;   
                }
                
                $scope.decreaseMobile = function(){
                    if($scope.increaseMobileLicences > 10){
                        $scope.increaseMobileLicences = $scope.increaseMobileLicences - 10;
                    }
                }
                $scope.increaseMobile = function(){
                    $scope.increaseMobileLicences = $scope.increaseMobileLicences + 10;   
                }

             
                $scope.confirmRegularLicences = function (no) {
                    if(!no){
                        $scope.increaseRegularLicences = 10
                        no = 10;
                    } else {
                        const modalOptions = {
                            closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                            actionButtonText: translateLabelInstant("ADMIN_CONFIRM_ORDER"),
                            headerText: translateLabelInstant("ADMIN_CUSTOMER_INCREASE_REGULAR_LICENCES") + ': ' + no,
                            bodyText: translateLabelInstant("ADMIN_SUBMIT_ORDER_DESC")
                        }
    
                        modalService.showModal({}, modalOptions).then(function (result) {
                            customerService.increaceUserLicenses(no).then(function (result) {
                                $scope.userLicenses = $scope.userLicenses + no
                                $scope.maxUserLicencesText = ""
                            })
                        })
                    }              
                }

                $scope.openAccessForEdit = function (user: ApplicationUser) {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: $scope.animationsEnabled,
                        templateUrl: 'useraccessform.html',
                        controller: 'userAccessController',
                        resolve: {
                            user: function () {
                                return user
                            }
                        }
                    })

                    modalInstance.result.then(function (user) {

                    }, function () {

                    })
                }

                $scope.checkEmail = function (input) {
                    if(input !== initialEmail){
                        $scope.checkExistingUser(input)
                    }
                }

                $scope.checkUserName = function (input) {
                    if(input !== initialUserName){
                        $scope.checkExistingUser(input)
                    }
                }

                $scope.checkExistingUser = async function (input) {
                    if (!$scope.canAddAnyUser()){
                        swal({
                            title: translateLabelInstant('ADMIN_ERROR_MESSAGE_USER_LIMIT'),
                            icon: "error",
                            dangerMode: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        })
                        
                    } else {   
                        $scope.warning = ""
                        const response = await customerService.checkExistingUser(input)
                        if(response) {
                            if(response.status == UserCheckupStatus.ActiveUser) {
                                if(!(response.user && response.user.id === $scope.user.id)){
                                    
                                    const showuserinfo = await swal({
                                        title: translateLabelInstant('COMMON_EXISTS'),
                                        text: input,
                                        icon: "info",
                                        buttons: {showuserinfo: { text: translateLabelInstant('COMMON_SHOW'), className:"sa-digilean-button"}},
                                        dangerMode: false,
                                        closeOnClickOutside: false,
                                        closeOnEsc: false
                                    })
                                    
                                    if (showuserinfo) {
                                        if (response.user) {
                                            $scope.registration = response.user
                                            $scope.user = response.user
                                        }
                                        
                                        $scope.registration
                                        initialUserName = $scope.registration.userName
                                        initialEmail = $scope.registration.email!
                                    }
                                        
                                }          
                            } else if (response.status == UserCheckupStatus.ExistInCurrentInstallation){
                                
                                const reactivate = await swal({
                                    title: translateLabelInstant('COMMON_EXISTS'),
                                    text: input + translateLabelInstant('ADMIN_USER_EXIST_DESCRIPTION'),
                                    icon: "info",
                                    buttons: {reactivate: {text: translateLabelInstant('COMMON_GET'), className:"sa-digilean-button"}},
                                    dangerMode: false,
                                    closeOnClickOutside: false,
                                    closeOnEsc: false
                                })

                                if (reactivate) {
                                    if (response.user) {
                                        $scope.registration = response.user
                                        $scope.user = response.user
                                    }
                                    
                                    initialUserName = $scope.registration.userName
                                    initialEmail = $scope.registration.email!
                                    if(!$scope.canAddUser()){ //only mobile licenses available
                                        $scope.setTypeMobile();
                                    } else if(!$scope.canAddMobileUser()){ // only regular licenses available
                                        $scope.setTypeRegular();
                                    }
                                }
                                    
                            } else if (response.status == UserCheckupStatus.ExistInAnotherInstallation){
                                $scope.existInAnotherInstallation = true
                                $scope.warning = input + translateLabelInstant('ADMIN_ERROR_MESSAGE_ADD_EXISTING_USER')
                                clearActiveUser()
                            }
                        }
                    }
                }
                
                $scope.checkExistingRights = function(user: UserInfoModel) {
                    if(user.isAdmin) {
                        swal({
                            title: translateLabelInstant('ADMIN_USER_ALREADY_HAS_ADMIN_RIGHTS'),
                            text: "",
                            icon: "info",
                            buttons: {showuserinfo: { text: translateLabelInstant('COMMON_CLOSE'), className:"sa-digilean-button"}},
                            dangerMode: false,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        })
                        $scope.setAdminCheckboxes(user)
                    } else {
                        checkBusinessunitAdminSetting();
                    }
                }

                function checkBusinessunitAdminSetting(){
                    if($scope.registration.isBusinessunitAdmin && !$scope.registration.businessUnitId){
                        swal({
                            title: translateLabelInstant('ADMIN_DATA_FIELD_MISSING'),
                            text: translateLabelInstant('ADMIN_BUSINESS_UNIT_ADMIN_MISSING'),
                            icon: "error",
                            buttons: {showuserinfo: { text: translateLabelInstant('COMMON_CLOSE'), className:"sa-digilean-button"}},
                            dangerMode: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        })
                        $scope.registration.isBusinessunitAdmin = false; //reset the checkbox until a business unit is selected
                    }
                }
                
                $scope.checkActiveUsers = async function () {
                    $scope.maxUserLicencesText = ""
                    if($scope.isBusinessunitAdmin){
                        var availableBusinessUnitLicenses = await customerService.getAvailableUserLicensesForBusinessUnit(businessUnitId)
                        if(availableBusinessUnitLicenses < 1){
                            $scope.maxUserLicencesText = translateLabelInstant('ADMIN_BUSINESS_UNIT_LICENSES')
                        }
                    } else {
                        customerService.getActiveUserLicenses().then(function (active) {
                            $scope.numberOfUsers = active
                            if ($scope.numberOfUsers >= $scope.userLicenses) {
                                $scope.maxUserLicencesText = translateLabelInstant('ADMIN_ERROR_MESSAGE_USER_LIMIT')
                            }
                        })
                    }
                }

                $scope.checkActiveMobileUsers = function () {
                    $scope.maxMobileUserLicencesText = ""
                    customerService.getActiveMobileLicenses().then(function (active) {
                        $scope.numberOfMobileUsers = active
                        if ($scope.mobileLicenses > 0 && $scope.numberOfMobileUsers >= $scope.mobileLicenses) {
                            $scope.maxMobileUserLicencesText = translateLabelInstant('ADMIN_ERROR_MESSAGE_USER_LIMIT_MOBILE')
                        }
                    })
                }

                async function loadAccount () {
                    $scope.maxUserLicencesText = ""
                    const account = await customerService.getAccount()
                    $scope.userLicenses = account.userLicenses!
                    $scope.mobileLicenses = account.mobileLicenses!
                    $scope.checkActiveUsers()
                    $scope.checkActiveMobileUsers()
                }
                loadAccount()

                $scope.translationLicensesData = {
                    noOfUsers: $scope.numberOfMobileUsers + $scope.numberOfUsers,
                    userLicenses: $scope.userLicenses
                }

                function setCorrectAdminSettingsBeforeSave(item){
                    if(item.isAdmin){
                        item.isBoardDesigner = false
                        item.isImprovementAdmin = false
                        item.isDataAdmin = false
                        item.isMessageWriter = false
                        item.isDeviationAdmin = false
                        item.isProjectAdmin = false
                        item.isA3Admin = false
                        item.isStrategyAdmin = false
                        item.isLearningAdmin = false
                    }
                }

                $scope.setAdminCheckboxes = setCorrectAdminSettingsBeforeSave

                
                $scope.save = async function () {
                    setCorrectAdminSettingsBeforeSave($scope.registration)
                    $scope.registration.useAD = $scope.isUsingAD
                    
                    // If user is associated to a business unit, check that the business unit has changed and then if there are available licenses 
                    if($scope.registration.businessUnitId){
                        if(hasChangedBusinessUnit()){
                            $scope.maxUserLicencesText = ""
                            var availableBusinessUnitLicenses = await customerService.getAvailableUserLicensesForBusinessUnit($scope.registration.businessUnitId)
                            if(availableBusinessUnitLicenses < 1){
                                $scope.savedSuccessfully = false
                                $scope.message = translateLabelInstant('ADMIN_BUSINESS_UNIT_LICENSES')
                                return
                            }
                        }
                    }

                    if ($scope.user && $scope.user.id)
                        changeUser()
                    else
                        registerNewUser()
                    
                    window.scrollTo(0, 0)
                }

                $scope.resetPassword = function (user) {
                    $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'forgotPasswordForm.html',
                        controller: 'forgotPasswordModalController',
                        resolve: {
                            user: function () {
                                return {
                                    email: user.email,
                                }
                            }
                        }
                    })
                }

                const changeUser = async function () {
                    try {
                        const user = await userAdmin.updateUser($scope.user.id!, $scope.registration)
                        $scope.savedSuccessfully = true
                        $scope.message = translateLabelInstant('ADMIN_MESSAGE_CHANGE_SUCCESSFULLY')
                        
                        clearActiveUser()
                        const msg = translateLabelInstant('ADMIN_MESSAGE_USER_INFO_CHANGED')
                        toastr.success(msg)
                        loadAccount()
                    }
                    catch (error) {
                        $scope.message= translateLabelInstant('ADMIN_ERROR_MESSAGE_CHANGE_USER')
                        $scope.message += error
                    }
                }

                const registerNewUser = async function () {
                    try {
                        const newUser = await userAdmin.createNewUser($scope.registration)
                        $scope.savedSuccessfully = true
                        $scope.openAccessForEdit(newUser)
                        clearActiveUser()
                        loadAccount()
                        const msg = translateLabelInstant("ADMIN_MESSAGE_REGISTER_SUCCESSFULLY")
                        toastr.success(msg)
                    }
                    catch (error) {
                        $scope.message = translateLabelInstant('ADMIN_ERROR_MESSAGE_CHANGE_USER')
                        $scope.message += error
                    }
                }

                $scope.delete = function (user: UserInfoModel) {
                    if (!user.id)
                        return
                    
                    const modalOptions = {
                        closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                        actionButtonText: translateLabelInstant("COMMON_DELETE"),
                        headerText: translateLabelInstant("COMMON_DELETE") + ' ' + user.firstName + ' ' + user.lastName + '?',
                        bodyText: translateLabelInstant("ADMIN_MESSAGE_DELETE_USER_CONFIRMATION")
                    }

                    modalService.showModal({}, modalOptions).then(function (result) {
                        userAdmin.deleteUser(user.id!).then(function (data) {
                            loadAccount()
                            const msg = translateLabelInstant('ADMIN_MESSAGE_USER_DELETED')
                            toastr.info(msg)
                            
                        }, function (err) {
                            const msg = translateLabelInstant('ADMIN_ERROR_MESSAGE_DELETE_USER')
                            toastr.error(msg)
                        })
                    })
                }

                $scope.setTypeMobile = function() {
                    $scope.registration.isMobileUser = true
                    clearAllAdminRoles()
                }

                $scope.setTypeRegular = function() {
                    $scope.registration.isMobileUser = false
                }

                $scope.azureLookup = async function() {
                    let user: AzureAdDigiLeanUser = {}
                    const template = "<user-azure-lookup></<user-azure-lookup>"
                    try {
                        user = await dialog.openForm({
                            title: "COMMON_AZURE_AD",
                            hideOkBtn: true,
                            booleanResult: false
                        }, template, user)
                        
                        if (user && user.userPrincipalName) {
                            $scope.registration.email = user.mail!
                            $scope.registration.userName = user.userPrincipalName
                            $scope.registration.firstName = user.givenName!
                            $scope.registration.lastName = user.surname!
                            $scope.registration.azureAdObjectId = user.id
                            $scope.checkExistingUser(user.userPrincipalName)
                        }
                    }
                    catch(err) {
                        if (err != "cancel")
                            console.log(err)
                    }
                }
                function clearAllAdminRoles(){
                    $scope.registration.isAdmin = false
                    $scope.registration.isBoardDesigner = false
                    $scope.registration.isMessageWriter = false
                    $scope.registration.isImprovementAdmin = false
                    $scope.registration.isDataAdmin = false
                    $scope.registration.isDeviationAdmin = false
                    $scope.registration.isProjectAdmin = false
                    $scope.registration.isA3Admin = false
                    $scope.registration.isStrategyAdmin = false
                    $scope.registration.isLearningAdmin = false
                }
            }
        }
    }])