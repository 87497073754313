import { DigiLeanSettings } from "@common/model/types"

///@ts-ignore all these will be replaced by webpack on build
const configSettings: Required<DigiLeanSettings> = {}
///@ts-ignore
configSettings.digiLeanBuildVersion = digiLeanBuildVersion
///@ts-ignore
configSettings.backendUrl = backendUrl
///@ts-ignore
configSettings.authServerUrl = authServerUrl
///@ts-ignore
configSettings.websiteUrl = websiteUrl
///@ts-ignore
configSettings.webApiUrl = webApiUrl
///@ts-ignore
configSettings.signalrUrl = signalrUrl
///@ts-ignore
configSettings.fileStoreUrl = fileStoreUrl
///@ts-ignore
configSettings.pdfExportUrl = pdfExportUrl
///@ts-ignore
configSettings.isTeamsMode = isTeamsMode
///@ts-ignore
configSettings.teamsAppId = teamsAppId
///@ts-ignore
configSettings.digiLeanUrl = digiLeanUrl
///@ts-ignore
configSettings.appInsightsConnectionString = appInsightsConnectionString
///@ts-ignore
configSettings.diagramsUrl = diagramsUrl

export default configSettings