<script setup lang="ts">
    import { computed } from "vue"
    import { authUser } from "@common/stores/userStore"
    import { observableComposable } from "@common/directives/rxstate"

    const authUserObs = observableComposable(authUser)
    const authUserFormatted = computed(() => {
        if (authUserObs.value) {
            return JSON.stringify(authUserObs.value, null, 4)
        }
    })
</script>

<template>
    <label>auth user</label><br>
    <textarea cols="100" rows="30" :value="authUserFormatted"></textarea>
</template>
