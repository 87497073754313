﻿import { parseColumnSettings } from "@common/services/settingsService"

angular.module("DigiLean").service('a3TableService', ['$translate', '$http', 'webApiUrl',
    function ($translate, $http, webApiUrl) {
        function a3TableService() {
            var resource = "/a3Table";
            var resourceUrl = webApiUrl + resource;
            return {
                createA3Table: function(template) {
                    var url = resourceUrl + "/CreateA3Table/";
                    return $http.post(url, template).then(function (result) {
                        return result.data;
                    });
                },
                get: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.get(url).then(function (result) {
                        const a3Table = result.data
                        parseColumnSettings(a3Table.columns)
                        return a3Table;
                    });
                },
                updateColumn: function (col) {
                    var updated = {
                        id: col.id,
                        name: col.name,
                        boardId: col.boardId
                    }
                    if (col.settings) {  
                        updated.settings = JSON.stringify(col.settings);
                    }
                    var url = resourceUrl + "/updateColumn/";
                    return $http.put(url, updated).then(function (result) {
                        return result.data;
                    });
                },
                addA3ToTable: function (a3Id, boardId) {
                    var url = resourceUrl + "/addA3ToTable/";
                    var a3TableRow = {
                        a3Id: a3Id,
                        boardId: boardId
                    }
                    return $http.post(url, a3TableRow).then(function (result) {
                        return result.data;
                    });
                },
                updateColumnSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateColumnSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateRowSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateRowSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                deleteColumn: function(id) {
                    var url = resourceUrl + "/deleteColumn/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addColumn: function (column) {
                    var url = resourceUrl + "/addColumn/";
                    return $http.post(url, column).then(function (result) {
                        return result.data;
                    });
                },
                updateCell: function (cell) {
                    var url = resourceUrl + "/updateCell/";
                    return $http.put(url, cell).then(function (result) {
                        return result.data;
                    });
                },/*
                deleteRow: function(id) {
                    var url = resourceUrl + "/deleteRow/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },*/
                deleteRow: function (a3Row) {
                    var url = resourceUrl + "/deleteRow/";
                    
                    return $http.post(url, a3Row).then(function (result) {
                        return result.data;
                    });
                },
                getStandardA3Columns: function () {

                    var columns = [
                        {
                            name: $translate.instant("COMMON_TITLE"),
                            sortOrder: 0,
                            columnType: "a3Title"
                        },
                        {
                            name: $translate.instant("COMMON_GOAL"),
                            sortOrder: 1,
                            columnType: "a3Goal"
                        },
                        {
                            name: $translate.instant("COMMON_STATUS"),
                            sortOrder: 2,
                            columnType: "a3Status"
                        },
                        {
                            name: $translate.instant("COMMON_RESPONSIBLE"),
                            sortOrder: 3,
                            columnType: "owner"
                        }
                    ]
                    return columns;
                },
/*
                getStandardSmartTableColumns: function () {

                    var columns = [
                        {
                            name: $translate.instant("COMMON_DESCRIPTION"),
                            sortOrder: 0,
                            attributeType: "name"
                        }
                    ]
                    return columns;
                },*/

            }
        }
        return a3TableService;
    }]);

