import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { getAllScopes } from "./apiClientsService"
import { ScopeInfo } from "@api"

@customElement('api-scopes-dialog')
export class CronInputGenerator extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        .display {
            color: var(--digilean-primary-text);
            font-size: 0.9rem;
        }
    `
    
    @property({attribute: false})
    clientScopes: string[] = []

    @state()
    allScopes: ScopeInfo[] = []

    connectedCallback(): void {
        super.connectedCallback()
        this.loadScopes()
    }
    async loadScopes() {
        const scps = await getAllScopes()
        this.allScopes = scps
    }

    isChecked(scope:ScopeInfo) {
        if (this.clientScopes == null) {
            this.clientScopes = []
            return false
        }
        return this.clientScopes.some(cs => cs === scope.name)
    }

    toggleCheck(scope:ScopeInfo) {
        const isCheck = this.isChecked(scope)
        if (isCheck)
            this.clientScopes = this.clientScopes.filter(cs => cs !== scope.name)
        else
            this.clientScopes.push(scope.name!)
        this.emitScopeChanges()
    }
    render() {
        return html`
            <table>
            <tbody>
                ${this.allScopes.map(scope => {
                    return html`
                    <tr>
                        <td>
                            <input type="checkbox" 
                                .checked=${this.isChecked(scope)} 
                                @click=${() => this.toggleCheck(scope)}
                            />
                        </td>
                        <td>
                            ${scope.name}
                        </td>
                        <td>
                            <span class="display">${scope.displayName}</span>
                        </td>
                    </tr>
                    `
                })}
            </tbody>
        </table>
        `
    }

    emitScopeChanges() {
        const options = {
            detail: { scopes: this.clientScopes},
            bubbles: false,
            composed: true
        }
        this.dispatchEvent(new CustomEvent("api-scopes-changed", options))
    }
}
