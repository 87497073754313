angular.module('DigiLean').factory('actionListRowProvider', ['$filter',
    function ( $filter) {
        function actionListRowProvider() {
            function getTargetColumn(smartActionList, category) {
                // Find target column
                var targetColumn = {
                    title: "All",
                    columnIndex: 0,
                    column: null

                };
                if(category && category.name) {
                    var column = $filter('filter')(smartActionList.columns, { name: category.name }, true);
                    if (column.length > 0) {
                        var columnIndex = smartActionList.columns.indexOf(column[0]);
                        targetColumn.columnIndex = columnIndex;
                        targetColumn.column = column[0];
                    }
                }

                return targetColumn;
            }

            var createCategories = function (smartActionList) {
                // For now we will support text columns
                var textColumns = $filter('filter')(smartActionList.columns, { attributeType: "text" }, true);
                var categories = [];
                textColumns.forEach(function (column) {
                    if (column.settings && column.settings.usePredefinedLabels && column.settings.predefinedLabels) {
                        categories.push({
                            id: "",
                            name: column.name,
                            model: column,
                            columnIndex: smartActionList.columns.indexOf(column)
                        });
                    }
                });
                var responsibleColumns = $filter('filter')(smartActionList.columns, { attributeType: "responsible" }, true);

                responsibleColumns.forEach(function (column) {
                    categories.push({
                        id: "",
                        name: column.name,
                        model: column,
                        columnIndex: smartActionList.columns.indexOf(column)
                    });

                });


                return categories;

            }
            var createdRowsFromCategory = function (model, category) {
                var rows = [];
                var rowIndex = 0;

                if (category) {
                    var targetColumn = category.model;
                    // Find distinct values to build rows, eith predefined or unique values pr task
                    if (targetColumn.attributeType == "text" && targetColumn.settings) {
                        var settings = targetColumn.settings;
                        if (settings.usePredefinedLabels && settings.predefinedLabels) {
                            settings.predefinedLabels.forEach(element => {
                                rows.push({
                                    id: element.title,
                                    name: element.title,
                                    smartColumnId: targetColumn.id,
                                    index: rowIndex,
                                    type :"text",
                                    isDefault: false,
                                    rowModel: {
                                        background: element.background,
                                        color: element.color,
                                        text: element.title
                                    },
                                    categoryStyle: {
                                        backgroundColor: element.background,
                                        foregroundColor: element.color
                                    },
                                    updateTask: function (category, task) {
                                        var model = task.attributes[category.columnIndex];
                                        var value = this.rowModel;
                                        model.setValue(value);
                                    },
                                    getAttribute: function() {
                                        var attribute = {
                                            type: "text",
                                            value: {
                                                smartTableColumnId: this.smartColumnId,
                                                value: JSON.stringify(this.rowModel)
                                            }
                                        }
                                        return attribute;
                                    }
                                })
                                rowIndex += 1;
                            });
                        }
                    }
                    if (targetColumn.attributeType == "responsible") {
                        // Create responsible rows
                        var tasks = model.actionList.tasks;
                        var uniqueResponsibleList = [];
                        tasks.forEach(task => {
                            if (task.responsibleUserId) {
                                var inList = $filter('filter')(uniqueResponsibleList, { userId: task.responsibleUserId }, true);
                                if (inList.length == 0) {
                                    uniqueResponsibleList.push({
                                        userId: task.responsibleUserId,
                                        fullName: task.responsibleUser,
                                        displayName: task.responsibleDisplayName
                                    })
                                }
                            }
                        });
                        // Create rows based on users
                        uniqueResponsibleList.forEach(user => {
                            rows.push({
                                id: user.userId,
                                name: user.fullName,
                                index: rowIndex,
                                isDefault: false,
                                rowModel: user,
                                type :"responsible",
                                categoryStyle: {
                                    backgroundColor: "#fcfcfc",
                                    foregroundColor: "default"
                                },
                                updateTask: function (category, task) {
                                    var model = task.attributes[category.columnIndex];
                                    var value = this.rowModel;
                                    model.setValue(value);
                                },
                                getAttribute: function() {
                                    var attribute = {
                                        type: "responsible",
                                        value: this.rowModel
                                    }
                                    return attribute;
                                }
                            })
                            rowIndex += 1;
                        });
                    }
                }
                // Create default row for task not matching any other rows
                rows.push({
                    id: null,
                    name: "",
                    type: "default",
                    index: rowIndex,
                    isDefault: true,
                    updateTask: function (category, task) {
                        return;
                    },
                    getAttribute: function() {
                        return;
                    }
                });

                return rows;
            }
            return {
                createCategories: function (model) {
                    return createCategories(model.actionList);
                },
                createRows: function (model, selectedCategory) {
                    return createdRowsFromCategory(model, selectedCategory);
                },
                getRowForTask: function (model, category, rows, task) {
                    var row = $filter('filter')(rows, { isDefault: true })[0];
                    var targetColumn = getTargetColumn(model.actionList, category);
                    // Map to correct row
                    if (targetColumn.column && targetColumn.column.attributeType == "text") {
                        var taskRowValue = task.attributes[targetColumn.columnIndex].cell;
                        if (taskRowValue.value) {
                            var targetRow = $filter('filter')(rows, { name: taskRowValue.value.text });
                            if (targetRow.length > 0) {
                                row = targetRow[0];
                            }
                        }
                    }
                    if (targetColumn.column && targetColumn.column.attributeType == "responsible") {
                        var targetRow = $filter('filter')(rows, { id: task.responsibleUserId });
                        if (targetRow.length > 0) {
                            row = targetRow[0];
                        }
                    }

                    return row;
                }
        
            };
        }
        return actionListRowProvider;
    }]);

