import * as customerService from "@common/services/customers/customerService"
angular
.module('DigiLean')
.directive("navnewtask", ['$uibModal',
    function ($uibModal) {
        return {
            templateUrl: 'navnewtask.html',
            restrict: 'E',
            scope: {
                'mode': '@'
            },
            link: function (scope, element, attrs) {
                scope.createNew = function () {
                    var task = {
                        id: 0,
                        boardId: 0,
                        title: "",
                        text: "",
                        boardDate: moment().startOf('day'),
                        status: 'blank',
                        tags: ""
                    }
                    customerService.getAllUsers().then(function (users) {
                        scope.users = users;
                    });
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'newBoardTask.html',
                        controller: 'newBoardTaskController',
                        resolve: {
                            task: function () {
                                return task;
                            },
                            options: function () {
                                var options = {
                                    canChangeBoard: true,
                                    showDueDate: true,
                                    users: scope.users
                                }
                                return options;
                            }
                        }
                    });
                }
            }
        }
    }]);
