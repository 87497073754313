import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("threeCBoardSelector", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'threeCBoardSelector.html',
                restrict: 'E',
                scope: {
                    'boardSelected': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.selectedBoard = null;

                    boardService.getBoardsOfType('3C').then(function (data) {
                        scope.boards = data;
                    });

                    scope.changeBoard = function (board) {
                        scope.selectedBoard = board;
                        // Raise event
                        scope.boardSelected({
                            board: board
                        });
                    }
                }
            }
        }]);
