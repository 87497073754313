angular
    .module('DigiLean')
    .directive("greencross", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var greencrossDirective = {
                name: 'greencross',
                templateUrl: 'greencross.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<',
                    'userAccess': '<',

                }
            };

            greencrossDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        //scope.setVisualizationType("GREENCROSS");
                        scope.visualizationType = "GREENCROSS";

                   
                    }
                };
            };

            return greencrossDirective;
        }]);
