angular
    .module('DigiLean').directive("projectImprovementSummary", ['suggestionService','$uibModal',
        function (suggestionService, $uibModal) {
            return {
                templateUrl: 'projectImprovementSummary.html',
                restrict: 'E',
                scope: {
                    'projectId': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = true;


                    scope.openSuggestions = 0;
                    scope.noImplementedSuggestions = 0;
                    scope.noEvaluatedSuggestions = 0;
                    scope.noTotalSuggestions = 0;

                    scope.$watch("projectId", function (projectId) {
                        if (!projectId) return;

                        suggestionService().getNoProjectImprovementPerStatus(scope.projectId).then(function (statusdata) {
                            scope.openSuggestions = statusdata.new + statusdata.planned + statusdata.inProgress;
                            scope.noImplementedSuggestions = statusdata.implemented;
                            scope.noEvaluatedSuggestions = statusdata.evaluated;
                            scope.noTotalSuggestions = scope.openSuggestions + scope.noImplementedSuggestions + scope.noEvaluatedSuggestions + statusdata.archived;
                        });

                    });
        
                    scope.showImprovements = function () {
                        var currentfilter = [{
                            sourceColumn: "ProjectId",
                            operator: "InList",
                            items: [scope.projectId]
        
                        }];
                        var order = [{
                            sourceColumn: "Status",
                            SortDirection: "DESC"
                        }, {
                            sourceColumn: "PriorityStatus",
                            SortDirection: "DESC"
                        }];
                        var options = {
                            filters: currentfilter,
                            sortOrders: order
                        };
        
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'suggestionListForm.html',
                            controller: 'suggestionListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                    };

                }

            }
        }]);
