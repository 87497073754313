angular
.module('DigiLean')
.controller('learningDesignerController', ['$scope', '$stateParams', '$filter', 'learningService', 'authService', '$state',
    function ($scope, $stateParams, $filter, learningService, authService, $state) {
        $scope.id = parseInt($stateParams.id);
        $scope.selectedPageId = null;
        $scope.canRepublish = false
        $scope.isKaizen = authService.hasRole('KaizenAdmin')

        if ($stateParams.pageid) {
            $scope.selectedPageId = parseInt($stateParams.pageid);
        }
        $scope.isEditMode = true;
        
        $scope.setEditMode = function (mode) {
            $scope.isEditMode = mode;
        }

        async function setPublishStatus(status) {
            $scope.material.publishStatus = status;
            let respone = await learningService().updatePublishStatus($scope.id, status)
            if(respone.errorVectorizingPageIds && respone.errorVectorizingPageIds.length > 0)
            {
                let errorPageIds = respone.errorVectorizingPageIds.join(", ");
                alert(`Pages with Id(s) ${errorPageIds} could not be vectorized.`)
            }
        }
        $scope.setPublishStatus = setPublishStatus
        $scope.republish = function() {
            setPublishStatus($scope.material.publishStatus).then(res => {
                checkReplishState()
            })
        }
        $scope.pages = [];
        function checkReplishState() {
            learningService().checkRepublish($scope.id).then(function (publishStatus) {
                if (publishStatus)
                    $scope.canRepublish = publishStatus.canRepublish
            })
        }
        function getItems() {
            learningService().getItems($scope.id).then(function (summary) {
                $scope.material = summary.material;
                summary.items.forEach(element => {
                    $scope.pages = $scope.pages.concat(element.pages) ;   
                });
                // Set currentpage
                setCurrentPage($scope.selectedPageId)
            })
        }
        getItems()
        checkReplishState()

        $scope.$on('$locationChangeSuccess', function(e, newUrl) {
            // Use $state.params to update as needed
            // Needed to handle browser back/forward
            if (!newUrl.includes("learningdesigner") )return; 

            if ($stateParams.pageid) {
                $scope.selectedPageId = parseInt($stateParams.pageid);
                setCurrentPage($scope.selectedPageId);
            } else {
                $scope.selectFrontPage();
            }
          });

        $scope.frontPage = null;
        $scope.selectedPage = null;
        $scope.selectedPageType = "frontPage";
        $scope.selectFrontPage = function (page) {
            $scope.selectedPageType = "frontPage";
            $scope.selectedPage = null;
            $scope.selectedPageId = null;
            $state.go('.', {pageid: ""});
        }

        $scope.selectPage = function (page) {
            $scope.selectedPage = page;
            $scope.selectedPageType = "normalPage";
            $scope.selectedPageId = page.id;
            $state.go('.', {pageid: page.id});
        }

        $scope.pageSelectHandler = function (page) {
            $scope.selectPage(page);
        }

        var setCurrentPage = function(pageId) {

            if (!pageId) return;
            var currentPage = $filter('filter')($scope.pages, { id: pageId });
            if (currentPage.length > 0) {
                $scope.selectPage(currentPage[0]);
            }
            
        }
        $scope.subscribe('LearningItemTitleChanged', checkReplishState)
        $scope.subscribe('LearningMaterialDeleted', checkReplishState)
        $scope.subscribe('LearningItemDeleted', checkReplishState)
        $scope.subscribe('LearningItemSectionUpdated', checkReplishState)
        
    }])