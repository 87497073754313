import angular from "angular"
import { NgComponent } from "@common/model/angularModel"
import { BoardInfoSimple } from "@api"
import * as boardService from "@common/services/boardService"

const DigiLean = angular.module('DigiLean')
DigiLean.component('taskBoardSelector', {
    templateUrl: "taskBoardSelector.html",
    bindings: {
        'boards': '<',
        'canEdit': '<',
        'thisBoard': '<'
    },
    controller: ['$element', function(this: NgComponent, $element: JQuery) {
        let $ctrl = this

        let popoverEl: HTMLDivElement | undefined

        $ctrl.newBoardSelected = false
        $ctrl.showColumnRow = false
        $ctrl.selectedBoard = null
        
        
        $ctrl.selectBoard = (board: BoardInfoSimple) => {
            dispatchCustomEvent("task-board-selected", board)

            $ctrl.selectedBoard = board

            if (board.boardType === 'SmartActionList')
                return closePopover()
            
            $ctrl.newBoardSelected = true
        }

        $ctrl.selectThisBoard = () => {
            dispatchCustomEvent("task-board-select-this")
            
            if (!$ctrl.thisBoard)
                return
            
            $ctrl.selectedBoard = $ctrl.thisBoard

            if ($ctrl.thisBoard.boardType === 'SmartActionList')
                return closePopover()
            
            $ctrl.newBoardSelected = true
        }

        $ctrl.clearBoard = () => {
            dispatchCustomEvent("task-board-clear", {})
            closePopover()
        }
        
        $ctrl.placeTaskInBoard = () => {
            //$event.stopPropagation()

            $ctrl.showColumnRow = true
            getBoardData($ctrl.selectedBoard.id)
            // event
        }
        $ctrl.moveTaskToRowColumn = () => {
            //$event.stopPropagation()
            const moveTask = {
                selectedColumn: $ctrl.selectedColumn,
                selectedRow: $ctrl.selectedRow
            }
            dispatchCustomEvent("task-board-move-row-col", moveTask)
            closePopover()
        }
        
        function dispatchCustomEvent(name: string, detail?: any) {
            const options = { detail, bubbles: false, composed: true }
            $element[0].dispatchEvent(new CustomEvent(name, options))
        }

        async function getBoardData(boardId: number) {
            const categories = await boardService.getBoardCategories(boardId)
            if (!categories)
                return

            if (categories.columns) {
                categories.columns.forEach(col => {
                    if (col.responsibleUserId) {
                        ///@ts-ignore
                        col.displayName = col.name + " (" + col.responsibleDisplayName + ")"
                    } else {
                        ///@ts-ignore
                        col.displayName = col.name
                    }
                })
                $ctrl.columns = categories.columns
                $ctrl.selectedColumn = categories.columns[0]
            }
            
            if (categories.rows) {
                categories.rows.forEach(row => {
                    if (row.responsibleUserId) {
                        ///@ts-ignore
                        row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                    } else {
                        ///@ts-ignore
                        row.displayName = row.name;
                    }
                })
                $ctrl.rows = categories.rows;
                $ctrl.selectedRow = categories.rows[0];
            }
        }

        function closePopover() {
            popoverEl?.hidePopover()
        }
        function togglePopoverEvent() {
            //console.log("popover new state", e.newState)
            // reset every time popup toggles
            $ctrl.newBoardSelected = false
            $ctrl.showColumnRow = false
        }
        $ctrl.$onInit = () => {
            popoverEl = $element[0].querySelector("#boards-selector-popover") as HTMLDivElement
            popoverEl.addEventListener("beforetoggle", togglePopoverEvent)
        }
        $ctrl.$onDestroy = () => {
            popoverEl?.removeEventListener("beforetoggle", togglePopoverEvent)
        }
    }]
})