<script setup lang="ts">
    import {ref, onMounted} from "vue"
    import { lookupUserAd } from "@common/services/user/userAzureAdService"
    import { DialogResult } from "@common/components/digilean/DigileanDialog"
    import { AzureAdDigiLeanUser } from "@api"

    const users = ref<AzureAdDigiLeanUser[]>([])
    const inputField = ref<HTMLInputElement | undefined>()

    const performSearch = () => {
        if (inputField.value) {
            search(inputField.value.value)
        }
    }
    const loading = ref(false)
    
    const showError = ref(false)
    let errorLabel = ref("COMMON_NO_RESULTS")

    const search = async (searchTerm: string) => {
        showError.value = false
        if (!searchTerm || !searchTerm.trim())
            return
        
        loading.value = true
        try {
            const us = await lookupUserAd(searchTerm)
            if (us && us.length > 0)
                users.value = us
            else {
                errorLabel.value = "COMMON_NO_RESULTS"
                showError.value = true
                users.value = []
            }
        }
        catch(err) {
            errorLabel.value = "COMMON_ERROR_MSG"
            showError.value = true
            users.value = []
        }
        finally {
            loading.value = false
        }
    }
    const select = (user: AzureAdDigiLeanUser) => {
        const options = { detail: user, bubbles: true, composed: true }
        inputField.value?.dispatchEvent(new CustomEvent(DialogResult.dialogOkFromOutside, options))
    }
    onMounted(() => {
        setTimeout(() => {
            if (inputField.value)
                inputField.value.focus()
        }, 100)
    })
</script>
<template>
    <h2>
        <translate-label label="COMMON_SEARCH"></translate-label> <translate-label label="COMMON_AZURE_AD"></translate-label> <translate-label label="COMMON_USERS"></translate-label>
    </h2>
    <div class="search-row">
        <digilean-input type="text" placeholder="search name" ref="inputField" @keyup.enter="performSearch"></digilean-input>
        <digilean-button @click="performSearch">
            <translate-label label="COMMON_SEARCH"></translate-label>
        </digilean-button>
    </div>
    <div v-if="loading" class="loading">
        <spinner-icon spin="true"></spinner-icon>
    </div>
    <div v-if="showError" class="error">
        <translate-label :label="errorLabel"></translate-label>
    </div>
    <table v-if="users.length>0">
        <thead>
            <tr>
                <th><translate-label label="COMMON_USERNAME"></translate-label></th>
                <th><translate-label label="COMMON_EMAIL"></translate-label></th>
                <th><translate-label label="ADMIN_FIRST_NAME"></translate-label></th>
                <th><translate-label label="ADMIN_LAST_NAME"></translate-label></th>
                <th><translate-label label="COMMON_ADMIN_DEPARTMENT"></translate-label></th>
                <th><translate-label label="COMMON_ADMIN_JOBTITLE"></translate-label></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in users" class="users" @click="() => select(user)">
                <td>
                    {{user.userPrincipalName}}
                </td>
                <td>
                    {{user.mail}}
                </td>
                <td>
                    {{user.givenName}}
                </td>
                <td>
                    {{user.surname}}
                </td>
                <td>
                    {{user.department}}
                </td>
                <td>
                    {{user.jobTitle}}
                </td>
            </tr>
        </tbody>
    </table>
    
</template>
<style>
    .search-row {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    .loading, .error {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem 0;
    }
    .error {
        color: var(--digilean-danger);
        font-weight: bold;
    }
    digilean-input {
        width: 300px;
    }
    table {
        border-collapse: collapse;
    }
    th, td {
        text-align: left;
        padding: 8px;
    }
    tr.users {
        cursor: pointer;
    }
    tr.users:hover {
        background: var(--digilean-primary);
        color: var(--digilean-main-menu-text);
    }
    
    tr.active {
        background: var(--digilean-selected);
    }
</style>