interface FileExtension {
    type: string
    extension: string
}
const viewerFileExtensions: FileExtension[] = [
    { type: 'image', extension: '.jpg' },
    { type: 'image', extension: '.jpeg' },
    { type: 'image', extension: '.png' },
    { type: 'image', extension: '.jfif' },
    { type: 'pdf', extension: '.pdf' },
    { type: 'excel', extension: '.xlsx' },
    { type: 'excel', extension: '.xls' },
    { type: 'word', extension: '.doc' },
    { type: 'word', extension: '.docx' },
    { type: 'video', extension: '.mp4' },
    { type: 'powerpoint', extension: '.ppt' },
    { type: 'powerpoint', extension: '.pptx' },
]

export function getFileType(ext: string) {
    const fileExt = viewerFileExtensions.find(fe => fe.extension === ext.toLocaleLowerCase())
    return fileExt
}

function saveFileAs(data: ArrayBuffer, fileName: string, type: string) {
    const blobData = new Blob([data], {type})
    const objectUrl = window.URL.createObjectURL(blobData)
    
    const anchor = document.createElement('a')
    anchor.href = objectUrl
    anchor.setAttribute('download', fileName)
    document.body.appendChild(anchor)
    anchor.click()
    
    // cleanup
    URL.revokeObjectURL(objectUrl)
    document.body.removeChild(anchor)
}

export function saveArrayBufferAsPdf(data: ArrayBuffer, fileName: string) {
    return saveFileAs(data, fileName, "application/pdf")
}
export function saveArrayBufferAsExcel(data: ArrayBuffer, fileName: string) {
    return saveFileAs(data, fileName, "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
}