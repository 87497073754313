import backend from "@common/services/backendHttp"
import { translateLabel } from "@common/services/language/DigiLeanLang"
import { createGuid } from "@common/services/helperFunctions"
import { appInsights } from "@common/services/webclientErrorLogging"
import * as documentService from "@common/services/documentService"

const DigiLean = angular.module("DigiLean")
DigiLean.service('froalaInsertDiagram', 
    ['$uibModal', '$translate', function ($uibModal, $translate) {

        const uploadTemplateAsNewFile = async (name, fileContent) => {
            
            const url = "api/document/UploadStringAsBlobFile"
            const data = {name, fileContent, contentType: "image/svg+xml"}
            const file = await backend.post(url, data)
            const urlSas = await documentService.getFileUrl(file.fileName, file.id)
            return urlSas
        }

        const fetchSvg = async (url) => {
            const res = await fetch(url)
            const svg = await res.text()
            return svg
        }
        async function initialize() {
            let insertLabel = "Insert diagram"
            let editLabel = "Edit diagram"
            try {
                insertLabel = await translateLabel("DIAGRAM_INSERT")
                editLabel = await translateLabel("DIAGRAMEDITOR")
            }
            catch (e) {}
            FroalaEditor.RegisterCommand('insertDiagram', {
                title: insertLabel,
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function() {
                    const modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'DiagramTemplateSelector.html',
                        controller: 'DiagramTemplateSelectorController',
                        windowClass: "digilean-diagram-template-selector",
                    })
                    modalInstance.result.then(async (res) => {
                        console.log(res.name)
                        let newFileName = createGuid() + ".svg"
                        try {
                            const svg = await openEditDialog(res.templateSvg)
                            if (svg) {
                                const urlSAS = await uploadTemplateAsNewFile(newFileName, svg)
                                const img = `<img src="${urlSAS}">`
                                this.html.insert(img)
                                this.undo.saveStep(); // VERY IMPORTANT, because trigger change event
                            }
                        }
                        catch(err) {
                            appInsights?.trackException(err, { place: "Froala Diagram Upload" })
                        }
                        
                    }, (er) => {
                        console.error(er)   
                    })
                }
            })

            const openEditDialog = (svg) => {
                const modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'DiagramEditorFullscreen.html',
                    controller: 'DiagramEditorFullscreenController',
                    windowClass: "digilean-diagram-edit",
                    resolve: {
                        data: () => svg,
                        title: () => "",
                        showTemplates: () => false
                    }
                })
                return modalInstance.result.then(function (newSvg) {
                    return newSvg
                })
            }
            FroalaEditor.RegisterCommand('editDiagram', {
                title: editLabel,
                focus: false,
                undo: false,
                refreshAfterCallback: true,
                callback: async function () {
                    const $img = this.image.get()
                    const src = $img.attr('src')
                    const url = new URL(src)
                    const fileName = url.pathname.split("/").pop()
                    console.log("filename", fileName)
                    const fileExtension = fileName.split(".").pop()
                    
                    if (fileExtension.toLowerCase() !== "svg")
                        return
                    
                    const svg = await fetchSvg(src)

                    const newSvg = await openEditDialog(svg)
                    if (newSvg) {
                        const urlSAS = await uploadTemplateAsNewFile(fileName, newSvg)
                        $img.attr('src', urlSAS)
                        this.undo.saveStep(); // VERY IMPORTANT, because trigger change event
                    }
                }
            })
        }
        
        return { initialize }
    }]
)
