import { UserProfileImageInfo, ProfileImage, User, FileInfo, UserInfo } from "@api"
import backend from "@common/services/backendHttp"
import { cachedGetRequest } from "@common/services/cacheService"
const resourceUrl = "api/profile"

 
export function getUsersWithProfileImage() {
    const url = resourceUrl + "/GetUsersWithProfileImage/"
    return cachedGetRequest<UserProfileImageInfo[]>(url, 60)
}

export function getProfileImage(userId: string) {
    const url = resourceUrl + "/getProfileImage/" + userId
    return backend.get<ProfileImage>(url)
}
export function getMyProfile() {
    const url = resourceUrl + "/getMyProfile/"
    return backend.get<User>(url)
}
export function updateProfileInfo(profileInfo: UserInfo) {
    var url = resourceUrl + "/updateProfileInfo/"
    return backend.put(url, profileInfo)
}
export function updateProfileImage(imageId: string) {
    var url = resourceUrl + "/updateProfileImage/" + imageId
    return backend.put<User>(url, {nothing: ""})
}

export function uploadProfileImage(formData: FormData) {
    var url = resourceUrl + "/uploadProfileImage"
    return backend.postForm<FileInfo[]>(url, formData)
}
