import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import { computed, ref } from "vue"
    import configSettings from "@common/config"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AppConfigDebug',
  setup(__props) {

    const configRef = ref(configSettings)
    const configFormatted = computed(() => {
        if (configRef.value) {
            return JSON.stringify(configRef.value, null, 4)
        }
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("label", null, "config", -1 /* HOISTED */)),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
    _createElementVNode("textarea", {
      cols: "90",
      rows: "15",
      value: configFormatted.value
    }, null, 8 /* PROPS */, _hoisted_1)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})