<script setup lang="ts">
import { onMounted, ref, PropType } from "vue"
import moment from "moment"
import * as assetservice from "@common/services/assetService"
import * as strategy from "@common/services/strategy/strategyService"
//import * as time from "@common/services/timeService"
import { translateLabelInstant} from "@common/services/language/DigiLeanLang"
import { StrategyGoal } from "@api";

const year = ref<number>(0)
const assetid = ref(0)
const editable = ref<boolean>(true) //(false)
const isAdmin = ref<boolean>(false) 
const groupByInterval = ref("month")
let strategygoals: StrategyGoal[] | null = null

const props = defineProps({
    goals: {
        type: Object as PropType<StrategyGoal>,
    }
})
onMounted(() => {
    console.log("mounted vue comp")
    year.value = moment().year();
    //isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
    getAssetList()
    //const timecells = time.getMonths
})

let previousPeriod = async function () {
    try {
        year.value = year.value - 1;
    }
    catch (err) {
        if (err != "cancel")
            console.error(err)
    }
}

let toggleEdit = async function () {
    try {
        editable.value = !editable.value
    }
    catch (err) {
        if (err != "cancel")
            console.error(err)
    }
}

let loadData = async function () {
    strategygoals = await strategy.getGoalsWithKPIsByAsset(assetid.value, year.value)
    if(strategygoals){
        strategygoals.forEach((goal, goalindex) => {
            if(goal.kpIs){
                goal.kpIs.forEach((kpi, kpiindex) => {
                    if (kpi.dataSourceId) {
                        //getValuesForSerie(kpi);
                    }
                    else if (kpi.targetDataSourceId) {
                        //getTargetValuesForSerie(kpi);
                    }
                })
            }
        })              
    }
}

let getAssetList = async function () {
    if(isAdmin){
        const assets = await assetservice.getAllAssets(true)    
        if(!assets) return

        for (var k = 0; k < assets.length; k++) {
            ///@ts-ignore
            assets[k].cssLevel = "level-" + assets[k].level                    
        }  
        const topAsset = {
            id : null,
            name: translateLabelInstant('STRATEGY_TOP_LEVEL')
        }
        const assetname = topAsset.name
        const assetid = topAsset.id
        loadData(); 
       
    } else {
        const assets = await assetservice.getList()  
        if(!assets || assets.length == 0) return

        for (var k = 0; k < assets.length; k++) {
            ///@ts-ignore
            assets[k].cssLevel = "level-" + assets[k].level                    
        }  
        const asset = assets[0]
        const assetname = assets[0].name
        const assetid = assets[0].id
        loadData();
    }
    
}


</script>
<style>
p {
    color: orange;
}
</style>
<template>
    <p>{{ year }}</p>
    <digilean-button @click="() => previousPeriod()"><translate-label
            label="COMMON_ENABLED"></translate-label></digilean-button>
            <div class="row" style="margin-bottom: 150px;">
                <table class="table table-hover bowler-chart">
                    <thead>
                        <tr>
                            <th><translate-label label="STRATEGY_BREAKTHROUGH_OBJECTIVES"></translate-label></th>
                            <th>KPI</th>
                            <th><translate-label label="ADMIN_DATA_DATASOURCE_UNIT"></translate-label></th>
                            <th></th>
                            <!--
                            <th style="width: 80px" translate="COMMON_MONTH_JAN"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_FEB"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_MAR"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_APR"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_MAY"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_JUN"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_JUL"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_AUG"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_SEP"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_OCT"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_NOV"></th>
                            <th style="width: 80px" translate="COMMON_MONTH_DEC"></th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="log in run?.logs" ng-repeat-start="item in strategygoals" ng-show="editable || item.kpIs.length > 0">
                            <td colspan="16" style="background:white;border-left:3px solid;"
                                ng-style="{'border-left-color':item.color}">
                                <i class="fa fa-circle m-r" style="font-size:18px;" ng-style="{'color':item.color}"></i>
                                <span class="bold">{{"item.name"}}</span>
                                <div class="btn-group m-l">
                                    <button ng-show="editable" type="button" ng-click="addNewKPI(item)" class="btn btn-xs btn-default"><translate-label label="STRATEGY_KPI_NEW"></translate-label>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr ng-repeat-start="kpi in item.kpIs">
                            <td rowspan="2" style="background: white;border-left:3px solid"
                                ng-style="{'border-left-color':item.color}">
                                <div ng-if="!editable">
                                    {{"kpi.title"}} &nbsp;
                                </div>
                                <div ng-if="editable" ng-click="openKPIConfiguration(kpi,item)">
                                    <a>{{"kpi.title"}} &nbsp;</a>
                                </div>
                            </td>
                            <td rowspan="2">{{"kpi.target"}}</td>
                            <td rowspan="2">{{"kpi.unit"}}</td>
                            <td><translate-label label="STRATEGY_PLAN"></translate-label></td>
                            <!--
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="1" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="2" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="3" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="4" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="5" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="6" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="7" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="8" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="9" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="10" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="11" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-plan kpi="kpi" year="year" month="12" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-plan></td>
                                -->
                        </tr>
                        <tr ng-repeat-end>
                            <td><translate-label label="STRATEGY_ACTUAL"></translate-label></td>
                            <!--
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="1" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="2" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="3" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="4" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="5" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="6" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="7" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="8" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="9" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="10" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="11" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                            <td class="bowler-chart-value-cell"><strategy-bowler-monthly-actual kpi="kpi" year="year" month="12" valuechanged="kpi.valueChanged"></strategy-bowler-monthly-actual></td>
                       -->
                        </tr>
                        <tr ng-repeat-end>

                        </tr>
                    </tbody>
                </table>
            </div>



</template>