import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = {
  key: 1,
  class: "title"
}
const _hoisted_4 = [".value", "placeholder"]
const _hoisted_5 = [".show"]
const _hoisted_6 = {
  class: "menu",
  slot: "content"
}
const _hoisted_7 = { class: "well" }
const _hoisted_8 = [".url", ".zoomoption"]

import { PropType, ref, watch } from "vue"
    import { FileInfo } from "@api"
    import { Theme } from "@common/model/types"
    import { getFileUrl } from "@common/services/documentService"
    import { translateLabel } from "@common/services/language/DigiLeanLang"
    import { PdfZoomOptions } from "@common/components/viewers/pdfViewer"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    import { settingsPdfApp } from "./pdfDisplayer"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'pdfDisplayerApp',
  props: {
        settings: { type: Object as PropType<settingsPdfApp>, required: true },
        adminmode: { type: Boolean, required: false, default: false }
    },
  setup(__props) {

    const vCustomEventsEmitter = new CustomEventsEmitter()

    let popupShow = ref(false)
    const togglePopup = () => {
        popupShow.value = !popupShow.value
    }

    const props = __props
    const internal = ref<settingsPdfApp>({
        title: "",
        pdfFileName: "",
        pdfFileInfoId: "",
        theme: { background: "var(--digilean-primary-text)", color: "inherit", fillColor: "var(--digilean-secondary-background)" }
    })
    const pdfSasUrl = ref("")
    const setPdfSasUrl = async () => {
        const { pdfFileInfoId, pdfFileName } = internal.value
        const sasUrl = await getFileUrl(pdfFileName, pdfFileInfoId)
        pdfSasUrl.value = sasUrl
    }

    let placeholderLbl = ref("")
    translateLabel("COMMON_EDIT_TITLE").then(label => placeholderLbl.value = label)

    watch(() => props.settings, (s) => {
        if (!s) return
        internal.value = {
            title: s.title ?? "",
            pdfFileName: s.pdfFileName ?? "",
            pdfFileInfoId: s.pdfFileInfoId ?? ""
        }
        if (s.theme)
            internal.value.theme = s.theme
        if (s.pdfZoomOption)
            internal.value.pdfZoomOption = s.pdfZoomOption
        if (s.pdfFileInfoId)
            setPdfSasUrl()
    })

    const updateTitle = (e: Event) => {
        const title = (e.target as HTMLInputElement).value
        internal.value.title = title
    }
    const fileUploaded = (e: CustomEvent) => {
        const files = e.detail as FileInfo[]
        const file = files[0]
        internal.value.pdfFileName = file.fileName!
        internal.value.pdfFileInfoId = file.id!
        setPdfSasUrl()
        popupShow.value = false
        updateSettings()
    }
    const zoomOptionChanged = (e: CustomEvent) => {
        const option = e.detail as PdfZoomOptions
        internal.value.pdfZoomOption = option
        updateSettings()
    }
    const updateTheme = (e: CustomEvent) => {
        const theme = e.detail as Theme
        internal.value.theme = theme
        popupShow.value = false
        updateSettings()
    }
    const updateSettings = () => {
        if (internal.value)
            vCustomEventsEmitter.emit("settings-changed", { settings: internal.value })
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("link", {
      href: "/assets/global.css",
      rel: "stylesheet"
    }, null, -1 /* HOISTED */)),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "header",
        style: _normalizeStyle({background: internal.value.theme?.fillColor, color: internal.value.theme?.background})
      }, [
        (!__props.adminmode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h5", null, _toDisplayString(internal.value.title), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        (__props.adminmode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("input", {
                type: "text",
                class: "form-control",
                onBlur: _cache[0] || (_cache[0] = ($event: any) => (updateSettings())),
                onChange: updateTitle,
                ".value": internal.value.title,
                placeholder: _unref(placeholderLbl)
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4),
              _createElementVNode("digilean-popup", { ".show": _unref(popupShow) }, [
                _createElementVNode("fa-icon", {
                  icon: "fa fa-wrench",
                  slot: "button",
                  onClick: togglePopup
                }),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("file-uploader", {
                      onFilesUploaded: fileUploaded,
                      accept: ".pdf"
                    }, null, 32 /* NEED_HYDRATION */)
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
                  _createElementVNode("theme-selector", { onThemeSelected: updateTheme }, null, 32 /* NEED_HYDRATION */)
                ])
              ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_5)
            ]))
          : _createCommentVNode("v-if", true)
      ], 4 /* STYLE */),
      (pdfSasUrl.value)
        ? (_openBlock(), _createElementBlock("pdf-viewer", {
            key: 0,
            ".url": pdfSasUrl.value,
            ".zoomoption": internal.value.pdfZoomOption,
            onZoomOptionChanged: zoomOptionChanged
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8))
        : _createCommentVNode("v-if", true)
    ])), [
      [_unref(vCustomEventsEmitter)]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
}

})