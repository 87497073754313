import { JobMonitor, JobRun, JobRunLog, JobRunResult, JobRunState, LogMessage, LogMessageType } from "@api"
import { getJobRunLogs } from "@common/services/data/monitoringService"

interface JobRunMatrix {
    nextRun?: string
    main: JobRun[]
    parts: Record<number, JobRun[]>
}

export interface JobMatrix extends Record<number, JobRunMatrix> {}

export function CapJobs (jobs: JobMonitor[], showRuns: number) {
    if (jobs && Array.isArray(jobs)) {
        jobs = jobs.map(j => {
            if (j.runs && Array.isArray(j.runs) && j.runs.length > showRuns)
                j.runs = j.runs.slice(0, showRuns)
            return j
        })
    }
    return jobs
}

export function UpdateRun (jobs: JobMonitor[], showRuns: number, jobId: number, run: JobRunLog) {
    if (jobs && Array.isArray(jobs)) {
        const job = jobs.find(j => j.id == jobId)
        if (job) {
            if (job.runs && Array.isArray(job.runs)) {
                const runExists = job.runs.find(r => r.runId == run.runId)
                if (runExists) {
                    const index = job.runs.indexOf(runExists)
                    job.runs.splice(index, 1, run)
                } else {
                    job.runs.unshift(run)
                }
            }
            else {
                job.runs = [run]
            }
        }
    }
    CapJobs(jobs, showRuns)
    return jobs
}

export function UpdateRunByLog(jobs: JobMonitor[], showRuns: number, newLog: LogMessage) {
    let { jobId, runId, jobPartId } = newLog
    if (!jobId || !runId) 
        return

    if (!jobs || !Array.isArray(jobs))
        return
    
    const job = jobs.find(j => j.id == jobId)
    if (!job)
        return
    
    if (job.runs && Array.isArray(job.runs)) {
        const runExists = job.runs.find(r => r.runId == runId)
        if (runExists) {
            if (jobPartId) {
                const jobPartRun = runExists.jobParts.find(p => p.jobPartId == jobPartId)
                if (jobPartRun) {
                    updateRunByLog(jobPartRun, newLog)
                } else if (newLog.type == LogMessageType.JobStarted) {
                    let newJobPartRun: JobRun = {
                        jobId: newLog.jobId!,
                        jobPartId,
                        runId,
                        state: JobRunState.Running,
                        result: JobRunResult.Success,
                        started: new Date().toISOString()
                    }
                    runExists.jobParts.push(newJobPartRun)
                }
            } else {
                updateRunByLog(runExists, newLog)
            }
        } else if (newLog.type == LogMessageType.JobStarted || !jobPartId) {
            let newRun: JobRunLog = {
                jobId: newLog.jobId!,
                runId,
                state: JobRunState.Running,
                result: JobRunResult.Success,
                started: new Date().toISOString(),
                jobParts: []
            }
            job.runs.unshift(newRun)
        }
    }
    CapJobs(jobs, showRuns)
    return jobs
}

function updateRunByLog(jobPartRun: JobRun, log: LogMessage) {
    if (log.type == LogMessageType.JobFinished) {
        jobPartRun.state = JobRunState.Completed
        jobPartRun.finished = new Date().toISOString()
    }
    else if (log.type == LogMessageType.JobFailed || log.type == LogMessageType.Error) {
        jobPartRun.state = JobRunState.Completed
        jobPartRun.result = JobRunResult.Failed
        jobPartRun.finished = new Date().toISOString()
    }
    
}

export const refreshRunLog = async (jobRun: JobRun) => {
    if (!jobRun || !jobRun.runId)
        return []
    
    const logs = await getJobRunLogs(jobRun.runId, jobRun.jobPartId)
    return logs
}
