angular
    .module('DigiLean')
    .directive("smartactionlistPersonCell", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'smartactionlistPersonCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.$watch('model', function (model) {
                        var value = scope.model.cell.value;
                        if (value) {
                            setValue(value);
                            mapUser();
                        }
                        model.getSortValue = function() {
                            return scope.displayName;
                        }
                    });

                    var setValue = function (value) {
                        scope.displayName = value.displayName;
                        scope.user = value;
                   
                    }

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user && scope.users) {
                            var matchingUser = scope.users.find(u => u.userId == scope.user.userId)
                            if (matchingUser) {
                                scope.displayName = matchingUser.displayName;
                                scope.user = matchingUser
                            }
                        }
                    }

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (cell.boardTaskId == scope.model.cell.boardTaskId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });

                    scope.clearSelectedUser = function () {
                        scope.selectUser(null);
                        
                    };
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.model.user = user;
                        scope.model.cell.value = user;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    };
                }
            }
        }]);