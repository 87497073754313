import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { JobPart } from "@api"

@customElement('edit-jobpart-dialog')
export class EditJobPartDialog extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
        }
        .display {
            color: var(--digilean-primary-text);
            font-size: 0.9rem;
        }
    `
    
    @property({attribute: false})
    jobPart: JobPart = {name: "", config: "{}", jobPartType: "", source: ""}

    nameChange(e: Event) {
        const value = (e.target as HTMLInputElement).value
        console.log("nameChange:", value)
        
    }
    configChange(e) {
        const value = (e.target as HTMLInputElement).value
        console.log("configChange:", value)
    }
    
    render() {
        if (!this.jobPart)
            return html`<p>loading</p>`

        return html`
            <digilean-input label="COMMON_NAME" data-ref="name" value=${this.jobPart.name!}>
            </digilean-input>
            <digilean-input label="COMMON_SOURCE" data-ref="source" value=${this.jobPart.source!}>
            </digilean-input>
            <digilean-input label="Job Part Type" data-ref="jobPartType" value=${this.jobPart.jobPartType!}>
            </digilean-input>
            <digilean-json-editor data-ref="config" .value=${this.jobPart.config!}>
            </digilean-json-editor>
        `
    }
}
