<script setup lang="ts">
    import { PropType, ref, onMounted, watch } from "vue"
    import { ConnectorMonitor, JobRun, JobPart, JobMonitor, LogMessage, LogMessageType } from "@api"
    import dialog from "@common/components/digilean/DigileanDialog"
    import { translateLabel } from "@common/services/language/DigiLeanLang"
    import * as monitoringService from "@common/services/data/monitoringService"
    import { systemHub } from "@common/services/systemHub"
    import { UpdateRunByLog } from "@common/components/monitoring/integrations/loggMonitor"

    const props = defineProps({
        data: {
            type: Object as PropType<ConnectorMonitor>,
            required: true
        },
        numruns: {
            type: Number,
            default: 7
        }
    })

    let connectorId = ""
    watch(() => props.data, (data) => {
        if (!data) return
        updateJobRuns()
    })
    let jobs = ref<JobMonitor[]>([])

    async function updateJobRuns() {
        if (!props.data?.jobsSetup)
            return

        jobs.value = []
        for (let i = 0; i < props.data.jobsSetup.length; i++) {
            const job = props.data.jobsSetup[i]
            await getRuns(job.id)
        }
        
    }

    let getRuns = async (jobId: number) => {
        if (!props.data?.jobsSetup)
            return
        let job = props.data.jobsSetup.find(j => j.id == jobId)
        if (!job)
            return
        try {
            connectorId = job.connectorId!
            const runs = await monitoringService.getJobRuns(job.connectorId!, job.id)
            if (runs)
                jobs.value.push(runs)
        }
        catch (e) {}
    }

    let getPartJobs = (jobId: number) => {
        const job = props.data?.jobsSetup?.find(j => j.id == jobId)
        if (job && job.jobParts)
            return job.jobParts
        return []
    }
    let getname = (jobId: number, jobPartId: number) => {
        if (!props.data)
            return ""
        const job = props.data.jobsSetup?.find(j => j.id == jobId)
        if (!job) return ""
        if (jobPartId) {
            const jobPart = job.jobParts?.find(p => p.id == jobPartId)
            if (jobPart)
                return jobPart.name
        }
        return job.name
    }

    let openLog = async (jobId: number, jobRun: JobRun) => {
        let name = getname(jobId, jobRun.jobPartId!)
        const id = `joblog${jobId}${jobRun ? jobRun.jobPartId : ""}`
        let el = document.createElement("monitor-job-log")
        el.id = id
        el.run = jobRun

        const closeLbl = await translateLabel("COMMON_CLOSE")
        dialog.openElement({
            title: `<i>${name}</i>, <translate-label label="ADMIN_MONITOR_RUN_STARTED"></translate-label>: 
                    <datetime-viewer date="${jobRun.started}"></datetime-viewer>`,
            hideOkBtn: true,
            cancelBtnText: closeLbl
        }, el)
    }
    let showConfig = async (jobPart: JobPart) => {
        let jsonString = jobPart.config ? jobPart.config : ""
        if (jsonString) {
            const json = JSON.parse(jsonString)
            jsonString = JSON.stringify(json, null, 4)
        }
        const closeLbl = await translateLabel("COMMON_CLOSE")
        const configLbl = await translateLabel("COMMON_CONFIGURATION")
        const template = `<textarea cols="100" rows="20">${jsonString}</textarea>`
        dialog.openHtml({
            title: configLbl,
            hideOkBtn: true,
            cancelBtnText: closeLbl
        }, template)
    }

    async function updateNextRunTime(log: LogMessage) {
        if (log.jobId && (log.type == LogMessageType.JobStarted || log.type == LogMessageType.JobFinished) && !log.jobPartId) {
            let nextRun = await monitoringService.getNextRun(log.jobId).catch(err => {
                console.log("could not get next run")
            })
            if (nextRun) {
                let job = jobs.value.find(j => j.id == log.jobId)
                if (job) {
                    /// @ts-ignore
                    job.nextTime = nextRun
                }
            }
        }
    }
    function handleLog(log: LogMessage) {
        if (log.connectorId ==  connectorId && (log.type == LogMessageType.JobStarted || 
                                                log.type == LogMessageType.JobFinished ||
                                                log.type == LogMessageType.JobFailed))
        {
            if (log.jobId && log.runId)
                updateRunFromLog(log)
            // else
            //     loadData()
        }
    }
    async function updateRunFromLog(log: LogMessage) {
        const updatedData = UpdateRunByLog(jobs.value, props.numruns, log)
        if (updatedData) {
            // $ctrl.data = updatedData
            updateNextRunTime(log)
        }
    }
    function setupEvents() {
        systemHub.on("NewLogMessage", handleLog)
    }
    onMounted(() => {
        updateJobRuns()
        setupEvents()
    })
</script>
<style>
    .table {
        display:table;
        width: 100%;
    }
    .table-header-group { display: table-header-group; }
    .table-row-group {
        display: table-row-group;
        margin-bottom: 0.5rem;
    }
    .table-row {
        display:table-row;
        border: 1px solid var(--digilean-white);
    }
    .table-cell {
        display: table-cell;
        padding: 3px;
        border: 1px solid var(--digilean-white);
    }
    .table-header-group .table-cell {
        font-weight: bold;
    }
    a.table-cell {
        background-color: var(--digilean-white-box);
    }
    .div-row {
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }
    .div-centre {
        display: inline-flex;
        box-sizing: border-box;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }
    jobrun-state {
        cursor: pointer;
    }
    .part-name {
        cursor: pointer;
    }
    .disabled {
        text-decoration: line-through;
    }
    .partjobs {
        display: flex;
        flex-direction: column;
    }
    .job-run-cell {
        cursor: pointer;
        background: var(--digilean-white);
    }
    .partjob-cell {
        padding: 3px;
        cursor: pointer;
        height: 3rem;
        border-bottom: 1px solid var(--digilean-white);
    }
    .partjob-cell:hover, .job-run-cell:hover {
        background: var(--digilean-white);
    }
    
</style>
<template>

    <div class="table">
        <div class="table-header-group">
            <div class="table-row">
                <div class="table-cell">
                    <translate-label label="COMMON_JOBTYPE"></translate-label>
                </div>
                <div class="table-cell">
                    <!-- <h2> -->
                    <translate-label label="COMMON_NEXT_RUN"></translate-label>
                    <!-- </h2> -->
                </div>
            </div>
        </div>
        <div class="table-row-group" v-for="job in jobs">
            <div class="table-row">

                <a class="table-cell" :href="'#/joblogs/'+job.id">{{job.name}}</a>
                <div class="table-cell job-run-cell">
                    <datetime-viewer .date="job.nextTime"></datetime-viewer>
                </div>
                <div class="table-cell job-run-cell" v-for="run in job.runs" @click="()=>openLog(job.id, run)">
                    <jobrun-state .state="run.state" .result="run.result" .date="run.started"></jobrun-state>&nbsp;
                    <datetime-viewer .date="run.started"></datetime-viewer>&nbsp;
                    <jobrun-duration .jobrun="run"></jobrun-duration>
                </div>

            </div>
            <div class="table-row">
                <div class="table-cell"></div>
                <div class="table-cell">
                    <div class="part-name partjob-cell" v-for="jobPart in getPartJobs(job.id)"
                        @click="()=>showConfig(jobPart)"
                        :class="{ disabled: !jobPart.enabled }"
                        :title="jobPart.enabled ? 'enabled, click for config' : 'jobpart disabled'">
                        {{jobPart.name}}
                    </div>
                </div>

                <div class="table-cell" v-for="run in job.runs">
                    <div class="partjob-cell" v-for="jobPartRun in run.jobParts" @click="()=>openLog(job.id, jobPartRun)">
                        <jobrun-state .state="jobPartRun.state" .result="jobPartRun.result" .date="jobPartRun.started"></jobrun-state>&nbsp;
                        <jobrun-duration .jobrun="jobPartRun"></jobrun-duration>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>