<script setup lang="ts">
    import { computed, ref } from "vue"
    import configSettings from "@common/config"

    const configRef = ref(configSettings)
    const configFormatted = computed(() => {
        if (configRef.value) {
            return JSON.stringify(configRef.value, null, 4)
        }
    })
</script>

<template>
    <label>config</label><br>
    <textarea cols="90" rows="15" :value="configFormatted"></textarea>
</template>
