import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .controller("favoritesController", ['$scope','$filter','projectService','a3Service','folderService',
        function (scope, $filter, projectService, a3Service, folderService) {
          
                    scope.flexiBoards = [];
                    scope.a3s = [];
                    scope.projectPortfolios = [];
                    scope.projects = [];
                    scope.isLoading = true;
                    employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                        scope.isLoading = false;
                        folderService().getBoardsInfo().then(function (member){
                            checkBoard(settings.value, member);
                        });
                    });
                    employeeService.getUserPreference("FavoriteA3s").then(function (settings) {
                        scope.isLoading = false;
                        if (settings && settings.value) {
                            a3Service().getA3MiniInfo().then(function (accessA3){
                                checkA3(settings.value, accessA3);
                            });
                        }
                    });
                    employeeService.getUserPreference("ProjectPortfolioFavorites").then(function (settings) {
                        scope.isLoading = false;
                        if (settings && settings.value) {
                            projectService().getMemberProjectPortfolioInfo().then(function (memberportfolio){
                                checkPortfolio(settings.value, memberportfolio);
                            });         
                        }
                    });
                    employeeService.getUserPreference("ProjectFavorites").then(function (settings) {
                        scope.isLoading = false;
                        if (settings && settings.value) {
                            projectService().GetMyProjectsMiniInfo().then(function (member){
                                checkProject(settings.value, member);
                            });
                        }
                    });

                    function checkPortfolio(favorites, memberportfolio){
                        var checked = [];
                        var needToUpdate = false;
                        if(favorites){
                            for(var i=0; i<favorites.length; i++){
                                var matching = $filter('filter')(memberportfolio, { id: favorites[i].id }, true);
                                if (matching.length == 1) {
                                    if(matching[0].name && favorites[i].name != matching[0].name){
                                        favorites[i].name = matching[0].name;
                                        needToUpdate = true;
                                    }
                                    checked.push(favorites[i]);
                                } else {
                                    needToUpdate = true; 
                                }
                            }
                        }
                        scope.projectPortfolios = checked;

                        if(needToUpdate){
                            employeeService.updateUserPreference("ProjectPortfolioFavorites", scope.projectPortfolios)
                        }
                    }

                    function checkA3(favorites, accessA3){
                        var checked = [];
                        var needToUpdate = false;
                        if(favorites){
                            for(var i=0; i<favorites.length; i++){
                                var matching = $filter('filter')(accessA3, { id: favorites[i].id }, true);
                                if (matching.length == 1) {
                                    if(matching[0].title && favorites[i].title != matching[0].title){
                                        favorites[i].title = matching[0].title;
                                        needToUpdate = true;
                                    }
                                    checked.push(favorites[i]);
                                } else {
                                    needToUpdate = true; 
                                }
                            }
                        }
                        scope.a3s = checked;

                        if(needToUpdate){
                            employeeService.updateUserPreference("FavoriteA3s", scope.a3s)
                        }
                    }

                    function checkProject(favorites, memberproject){
                        var checked = [];
                        var needToUpdate = false;
                        if(favorites){
                            for(var i=0; i<favorites.length; i++){
                                var matching = $filter('filter')(memberproject, { id: favorites[i].id }, true);
                                if (matching.length == 1) {
                                    if(matching[0].name && favorites[i].name != matching[0].name){
                                        favorites[i].name = matching[0].name;
                                        needToUpdate = true;
                                    }
                                    checked.push(favorites[i]);
                                } else {
                                    needToUpdate = true; 
                                }
                            }
                        }
                        scope.projects = checked;

                        if(needToUpdate){
                            employeeService.updateUserPreference("ProjectFavorites", scope.projects)
                        }
                    }   

                    function checkBoard(favorites, memberboard){
                        var checked = [];
                        var needToUpdate = false;
                        if(favorites){
                            for(var i=0; i<favorites.length; i++){
                                var matching = $filter('filter')(memberboard, { id: favorites[i].id }, true);
                                if (matching.length > 0) {
                                    if(matching[0].name && favorites[i].name != matching[0].name 
                                        && favorites[i].boardType == matching[0].boardType){
                                        favorites[i].name = matching[0].name;
                                        needToUpdate = true;
                                    }
                                    checked.push(favorites[i]);
                                } else if(favorites[i].boardType == 'PERSONAL' || favorites[i].boardType == 'MYSPACE' ||
                                        favorites[i].boardType == 'TEAMSPACE' || favorites[i].boardType == 'SYSTEMACTIVITY' ||
                                        favorites[i].boardType == 'TASKSEARCH'){
                                    // Preventing duplicate boards (bug fix)
                                    var exists = $filter('filter')(checked, { id: favorites[i].id }, true);
                                    if (exists.length == 0) {
                                        checked.push(favorites[i]);
                                    } else {
                                        needToUpdate = true; 
                                    }
                                     
                                }else {
                                    needToUpdate = true; 
                                }
                            }
                        }
                        scope.flexiBoards = checked;

                        if(needToUpdate){
                            employeeService.updateUserPreference("FlexiboardFavoriteBoards", scope.flexiBoards)
                        }
                    }

                    scope.subscribe("FlexiboardFavoriteBoardsChanged", function(flexiboards){
                        scope.flexiBoards = flexiboards;
                    })
                    scope.subscribe("FavoriteA3sChanged", function(a3s){
                        scope.a3s = a3s;
                    })
                    scope.subscribe("ProjectPortfolioFavoritesChanged", function(projectPortfolios){
                        scope.projectPortfolios = projectPortfolios;
                    })
                    scope.subscribe("ProjectFavoritesChanged", function(projects){
                        scope.projects = projects;
                    })

                    scope.subscribe("BoardNameUpdated", function (boardname) {
                        var matchingboard = $filter('filter')(scope.flexiBoards, { id: boardname.boardId}, true);
                        if (matchingboard.length == 1) {
                            matchingboard[0].name = boardname.name;
                        } else {
                            var matchingportfolio = $filter('filter')(scope.projectPortfolios, { id: boardname.boardId}, true);
                            if (matchingportfolio.length == 1) {
                                matchingportfolio[0].name = boardname.name;
                            }
                        }                                 
                    });

                    scope.subscribe("DashboardTitleUpdated", function (dashboardtitle) {
                        var matching = $filter('filter')(scope.flexiBoards, { id: dashboardtitle.dashboardId}, true);
                        if (matching.length == 1) {
                            matching[0].name = dashboardtitle.title;
                        }                                 
                    });

                    scope.subscribe("ProjectNameUpdated", function (project) {
                        var matching = $filter('filter')(scope.projects, { id: project.id}, true);
                        if (matching.length == 1) {
                            if (matching[0].name) {
                                matching[0].name = project.name;
                            } else {
                                matching[0].name = "";
                            }
                        }
                    });

                    scope.subscribe("A3TitleChanged", function (a3) {
                        var matching = $filter('filter')(scope.a3s, { id: a3.a3Id}, true);
                        if (matching.length == 1) {
                            if (matching[0].title) {
                                matching[0].title = a3.title;
                            } else {
                                matching[0].title = "";
                            }
                        }
                    });
        }]);


        