import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import { computed } from "vue"
    import { authUser } from "@common/stores/userStore"
    import { observableComposable } from "@common/directives/rxstate"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'UserDebug',
  setup(__props) {

    const authUserObs = observableComposable(authUser)
    const authUserFormatted = computed(() => {
        if (authUserObs.value) {
            return JSON.stringify(authUserObs.value, null, 4)
        }
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("label", null, "auth user", -1 /* HOISTED */)),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
    _createElementVNode("textarea", {
      cols: "100",
      rows: "30",
      value: authUserFormatted.value
    }, null, 8 /* PROPS */, _hoisted_1)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})