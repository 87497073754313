import { BusinessUnit, User } from "@api"
import backend from "@common/services/backendHttp"
import { cachedGetRequest } from "@common/services/cacheService"

const resourceUrl = "api/BusinessUnit"
let unitsCached: BusinessUnit[] | null = null

export const getAllUnits = () => {
    const url = resourceUrl
    return cachedGetRequest<BusinessUnit[]>(url, 60).then(units => {
        return units
    })
}

export const getAllUnitsCached = async () => {
    if (!unitsCached) {
        const units = await getAllUnits()
        unitsCached = units ? units : []
    }
    return unitsCached
}

export const getBusinessUnitUsers = (id: number) => {
    const url = `${resourceUrl}/${id}/members`
    return backend.get<User[]>(url)
}