<script setup lang="ts">
    import { onUnmounted, computed, ref, onMounted } from "vue"
    import { getAllUnitsCached } from "@common/services/businessUnitService"
    import { Subscription } from "rxjs"
    import { BusinessUnit } from "@api"

    let sub: Subscription | null = null
    
    const props = defineProps({
        id: {
            type: Number,
            required: true
        }
    })
    
    let units = ref<BusinessUnit[]>([])
    const getAllUnits = async () => {
        units.value = await getAllUnitsCached()
    }
    
    const unitNameComputed = computed(() => {
        if (props.id && units.value.length > 0) {
            const unit = units.value.find(u => u.id == props.id)
            if (unit)
                return unit.name
        }
        return ""
    })
    onMounted(() => {
        getAllUnits()
    })

    
</script>

<template>
    <span>{{unitNameComputed}}</span>
</template>