import * as assetService from "@common/services/assetService"

const digiLean = angular.module('DigiLean');
digiLean.controller('userAccessController', ['$scope','$rootScope','$translate','$filter','$uibModalInstance', 'navigationService','projectService','user',
    function($scope,  $rootScope,  $translate,  $filter,  $uibModalInstance,  navigationService,  projectService,  user) {

        $scope.hasProject = navigationService().hasModule("PROJECT")
        $scope.steps = [];
        $scope.projects = [];

        var userId;
        if(user && user.userId){
            userId = user.userId;
        } else if (user && user.id){
            userId = user.id;
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('COMMON_ASSETS');
            $scope.steps[1].title = $translate.instant('COMMON_PROJECTS');
            $scope.currentStep = $scope.steps[0];
        });

        var loadAccess = function () {
            $translate(['COMMON_ASSETS', 'COMMON_PROJECTS']).then(function (translations) {
                $scope.steps.push({ id: 'COMMON_ASSETS', title: translations.COMMON_ASSETS });
                $scope.steps.push({ id: 'COMMON_PROJECTS', title: translations.COMMON_PROJECTS });
                $scope.currentStep = $scope.steps[0];
            });
            

            assetService.getUserAssets(userId).then(function (tree) {
                tree.forEach(function (asset) {
                    asset.cssLevel = "level-" + asset.level;
                });
                $scope.assetTree = tree;
            });
        };
        loadAccess();

        var loadProjects = function () {
            if($scope.projects && $scope.projects.length>0) return;
            projectService().getUserProjects(userId).then(function (result) {
                $scope.projects = result;
            });
        };

        $scope.selectStep = function (step) {
            $scope.currentStep = step;
            loadProjects();
        };

        $scope.updateProjectAccess = function (project) {
            project.isupdated = true;
        };

        $scope.updateProjectMemberAccess = function (project) {
            project.isupdated = true;
            var updatedMemberIndicator = !project.isMember;
            if(!updatedMemberIndicator){ //if no longer member, then the user can not be project owner
                project.isProjectOwner = false;
            }
        };
        $scope.ok = function () {
            assetService.saveUserAssets(userId, $scope.assetTree).then(function () {

                if($scope.projects && $scope.projects.length > 0){
                    var updated = $filter('filter')($scope.projects, { isupdated: true }, true);
                    if (updated && updated.length > 0) {
                        return projectService().saveUserProjects(userId, updated).then(function () {
                            $translate('ADMIN_MESSAGE_ASSET_UPDATED').then(function (msg) {
                                toastr.success(msg);
                                $uibModalInstance.close();
                            }, function (translationId) {
                                $scope.headline = translationId;
                            });
                        });
                    } else {
                        $translate('ADMIN_MESSAGE_ASSET_UPDATED').then(function (msg) {
                            toastr.success(msg);
                            $uibModalInstance.close();
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    }
                }
                $translate('ADMIN_MESSAGE_ASSET_UPDATED').then(function (msg) {
                    toastr.success(msg);
                    $uibModalInstance.close();
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                
            });
        };
        
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        
        $scope.ownerCheckboxClicked = function(asset) {
            if (asset.isOwner) {
                asset.isViewer = false;
            }
        };

        $scope.viewerCheckboxClicked = function(asset) {
            if (asset.isOwner) {
                asset.isOwner = false;
            }
        };




    }]);