import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onUnmounted, computed, ref, onMounted } from "vue"
    import { getAllUnitsCached } from "@common/services/businessUnitService"
    import { Subscription } from "rxjs"
    import { BusinessUnit } from "@api"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'businessUnit',
  props: {
        id: {
            type: Number,
            required: true
        }
    },
  setup(__props) {

    let sub: Subscription | null = null
    
    const props = __props
    
    let units = ref<BusinessUnit[]>([])
    const getAllUnits = async () => {
        units.value = await getAllUnitsCached()
    }
    
    const unitNameComputed = computed(() => {
        if (props.id && units.value.length > 0) {
            const unit = units.value.find(u => u.id == props.id)
            if (unit)
                return unit.name
        }
        return ""
    })
    onMounted(() => {
        getAllUnits()
    })

    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(unitNameComputed.value), 1 /* TEXT */))
}
}

})