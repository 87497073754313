import * as documentService from "@common/services/documentService"

angular
    .module('DigiLean')
    .component("messageAttachment", {
        templateUrl: 'messageAttachment.html',
        bindings: {
            msg: '<',
            options: '<'
        },
        controller: ['$state',
        function ($state) {
            const $ctrl = this
            $ctrl.showMetaInfo = true;

            $ctrl.$onInit = function () {
                if($ctrl.options){
                    $ctrl.showMetaInfo = $ctrl.options.showMetaInfo;
                }
                if($ctrl.msg && $ctrl.msg.attachments.length == 1){
                    switch ($ctrl.msg.attachments[0].fileInfo.fileExtension) {
                        case '.pdf':
                            setPdfSasUrl();
                        default:
                            return null;
                    }             
                }           
            }

            $ctrl.$onChanges = function (changes) {
                if (changes.options) {
                    $ctrl.showMetaInfo = changes.options.currentValue.showMetaInfo;
                }
            }
            

            $ctrl.zoomOptionChanged = function($event) {
                const option = $event.detail;
                $ctrl.pdfZoomOption = option
            }

            
            async function setPdfSasUrl () {
                const fileInfo= $ctrl.msg.attachments[0].fileInfo;
                documentService.getFileUrl(fileInfo.fileName, fileInfo.id).then(function(sasUrl) {
                    $ctrl.pdfSasUrl = sasUrl
                })
                
                $ctrl.pdfZoomOption = "pageFit"
            }

        }]
    })