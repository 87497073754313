import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString } from "vue"

import type { DateTimeViewMode } from "@common/model/types"
    import { computed, PropType} from "vue"
    import { formatDateTime, formatDateTimeShort, formatDateTimeLong } from "@common/services/datetime/dateTimeFormattingLocale"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeViewer',
  props: {
        date: {
            type: String,
            required: true
        },
        mode: {
            type: String as PropType<DateTimeViewMode>,
            default: "regular"
        }
    },
  setup(__props) {

    // datetime-viewer element

    const props = __props

    const dateFormatted = computed(() => {
        if (props.mode == "short")
            return formatDateTimeShort(props.date)
        if (props.mode == "long")
            return formatDateTimeLong(props.date)
        else
            return formatDateTime(props.date)
    })
    

return (_ctx: any,_cache: any) => {
  return _toDisplayString(dateFormatted.value)
}
}

})