import * as customerService from "@common/services/customers/customerService"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("strategyTaskListResult", ['$filter', '$translate', 'strategyService', '$uibModal', 'NgTableParams', 'boardTaskService', 'isTeamsMode',
        function ($filter, $translate, strategyService, $uibModal, NgTableParams, boardTaskService, isTeamsMode) {
            return {
                templateUrl: 'strategyTaskListResult.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                },
                link: function (scope, elem, attrs) {

                    scope.columns = [];
                    scope.registrations = [];
                    scope.newValuesCounter = 0;
                    //scope.totalValues = 0;
                    scope.totalMatchingRecords = 0;
                    scope.allUsers = [];
                    scope.selectedDataList = [];

                    scope.showFileGenerators = true;
                    if (isTeamsMode) {
                        scope.showFileGenerators = false;
                    }


                    // Filter handling
                    scope.$watch('filter', function (filter) {
                        if (!filter) {
                            scope.filter = [];
                        }
                        scope.getValues();
                    });

                    scope.openTask = function (item) {
                        if(item.assetId){
                            customerService.getUsersForAsset(item.assetId).then(function (assetUsers) {
                                scope.users = assetUsers;
                                openTaskPopup(item.boardTaskId, scope.users)
                            });
                        } else if(scope.allUsers && scope.allUsers.length > 0) {
                            openTaskPopup(item.boardTaskId, scope.allUsers);
                        } else {
                            employeeService.getList().then(function (data) {
                                scope.allUsers = data;
                                openTaskPopup(item.boardTaskId, scope.allUsers);
                            });
                        }
                    }        
                    
                    function openTaskPopup(boardTaskId, users){
                        boardTaskService().get(boardTaskId).then(function (task) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false,
                                            canChangeBoard: false,
                                            users: users
                                        }
                                        return options;
                                    }
                                }
                            });
                            modalInstance.result.then(function (updatedTask) {
                                scope.getValues();
                            });
                        });
                    }


                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }


                    scope.getValues = function (params) {
                        scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: { lastModified: "desc" },
                            count: 15,
                        },{
                            getData: scope.getTableValues
                        });

                    }
                    scope.sorting = "";
                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter
                        }
                        return strategyService().getTableValues(dbParams).then(function (data) {
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // check values
                            data.values.forEach(value => {
                                value.isOverdue = isOverdue(value.boardDate, value.status);
                                if(!value.assetName){
                                    value.assetName = $translate.instant("STRATEGY_TOP_LEVEL")
                                }
                            });
                            return data.values;

                        });
                    }
                    scope.allColumns = [
                        {
                            title: $translate.instant("COMMON_GOAL"),
                            field: 'goal',
                            visible: true,
                            sortable: 'color',
                            getValue: renderValue,
                            dataType: "text"
                        },{
                            title: $translate.instant("COMMON_ACTION"),
                            field: 'title',
                            visible: true,
                            sortable: 'title',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_ASSET"),
                            field: 'assetName',
                            sortable: 'assetName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },{
                            title: $translate.instant("COMMON_STATUS"),
                            field: 'status',
                            visible: true,
                            sortable: 'status',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            field: 'responsibleUser',
                            sortable: 'responsibleUser',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_DUE_DATE"),
                            field: 'boardDate',
                            visible: true,
                            sortable: 'boardDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_LAST_CHANGED"),
                            field: 'lastModified',
                            visible: true,
                            sortable: 'lastModified',
                            getValue: renderValue,
                            dataType: "date"
                        },
                    ];
                    scope.buildColumns = function (datasource) {
                        scope.columns = $filter('filter')(scope.allColumns, { visible: true });
                    }

                    var getColumns = function() {
                        var columns = [];
                        scope.columns.forEach((c) => {
                            columns.push({
                                field : c.field
                            });
                        })
                        return columns;
                    }

                    scope.selectColumn = function(column) {
                        column.visible = !column.visible;
                        scope.buildColumns();
                    }

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate");
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        } 
                        return value;
                    }

                    function isOverdue(date, status) {
                        if (!date) return false;
                        if(status === 'OK') return false;
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            return true;
                        }
                        return false;
                    }

                }

            }
        }]);
