angular
    .module('DigiLean')
    .controller("projectAdminController", ['$scope','$uibModal', 'projectService', '$state',
        function ($scope, $uibModal, projectService, $state) {


            $scope.addProject = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'projectNewModal.html',
                    controller: 'projectNewController',
                    resolve: {
                        existing: function () {
                            return $scope.projects;
                        }
                    }
                });

                modalInstance.result.then(function (newproject) {
                    if (newproject) {
                        projectService().addProject(newproject).then(function (createdProject) {
                            $state.go('selectedProject', { id: createdProject.id }, { reload: false });
                        });
                    }
                }, function () {
                });
            }
        }]);



