﻿import { getUserProfile } from "@common/stores/userStore"
import * as pdfService from "@common/services/pdfService"

angular
    .module('DigiLean')
    .directive("a3stepTemplate", ['$filter', '$translate', 'a3Service', 'debounce',
        function ($filter, $translate, a3Service, pdfService, debounce) {
            return {
                templateUrl: 'a3stepTemplate.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '=',
                    'isAdminMode': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('model', function () {
                        var model = scope.model;
                        if (model.style && typeof (model.style) === "string") {
                            model.style = JSON.parse(model.style);
                        }

                    });

                    scope.froalaInit = false;

                    scope.generatePreview = function (scope) {
                        let lang = "en"
                        const up = getUserProfile()
                        if (up.preferredLanguage) {
                            lang = up.preferredLanguage
                        }
                        // Replace . with - so filename is not corrupt in browser.
                        var name = scope.model.title.replace(/\./g, '-') // Crazy stuff=>https://stackoverflow.com/questions/1967119/why-does-javascript-replace-only-first-instance-when-using-replace
                        pdfService.previewA3(scope.model.a3VersionId, lang);

                    };

                    scope.commitChanges = function (id, content) {
                        var stepContent = {
                            id: id,
                            content: content
                        }
                        a3Service().saveStep(stepContent).then(function (step) {
                            // Synk version step
                            scope.generatePreview(scope);
                        });
                    };

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.commitChanges, 2000, false);
                    scope.contentChanged = function(val) {
                        if (!scope.froalaInit) {
                            scope.froalaInit = true;
                            return;
                        }
                        scope.model.content = val;
                        scope.commitChangesDebounced(scope.model.id, val);
                    };
                    scope.froalaOptions = {

                        toolbarButtons: ['bold', 'italic', 'underline', '|', 'fontFamily', 'fontSize', 'color',
                            'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink',
                            'insertImage', 'insertVideo', 'insertTable', '|', 'emoticons', 'insertHR', 'clearFormatting',
                            '|', 'help', '|', 'undo', 'redo']
                    };

                }

            };
        }]);
