angular
    .module('DigiLean')
    .controller("newFeedbackModalController", ['$scope', '$uibModalInstance', 'suggestion', 'authService',
        function ($scope, $uibModalInstance, suggestion, authService) {

            // the new action object
            $scope.suggestion = suggestion;
            $scope.uploadedFiles = [];

            function isInputfieldsValid(){
                $scope.missingTitle = false
                if(!$scope.suggestion.title){
                    $scope.missingTitle = true
                    return false;
                }
                return true;
            }

            $scope.ok = function () {
                if(!isInputfieldsValid()){
                    return;
                }
                $scope.suggestion.attachments = $scope.uploadedFiles;
                $uibModalInstance.close($scope.suggestion);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }]);