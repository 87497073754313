import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as profileImageService from "@common/services/user/profileImageService"

angular
    .module('DigiLean')
    .directive("subTaskList", ['$uibModal', 'boardTaskService', 'taskDrawingService', 'debounce',
        function ($uibModal, boardTaskService, taskDrawingService, debounce) {
            return {
                templateUrl: 'subTaskList.html',
                restrict: 'E',
                scope: {
                    'parentTask': '<',
                    'targetDate': '<',
                    'isReadOnly': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.tasks = [];
                    scope.boards = [];
                    scope.thisBoard = {id:0}
                    scope.canEdit = true;
                    scope.isLoading = false;
                    scope.isBoardsLoaded = false;
                    scope.isUsersLoaded = false;

                    scope.$watch('parentTask', function (parentTask) {
                        if (scope.parentTask && scope.parentTask.id > 0) {
                            scope.parentId = scope.parentTask.id
                            loadData(scope.parentId);
                        }
                    });
                    scope.$watch('isReadOnly', function (isReadOnly) {
                        if (scope.isReadOnly == true) {
                            scope.canEdit = false;
                        } else {
                            scope.canEdit = true;
                        }

                    });

                    async function loadData(taskId) {
                        await getSubTasks(taskId);
                        await loadBoards();
                        await getAllUsers();
                    }

                    var getSubTasks = function (parentId) {
                        scope.isLoading = true;
                        boardTaskService().getSubTasks(parentId).then(function (tasks) {
                            //
                            for (let index = 0; index < tasks.length; index++) {
                                const task = tasks[index];

                                setupTask(task);
                            }
                            scope.tasks = tasks;
                            scope.isLoading = false;
                        });
                    }

                    var setupTask = function (task) {
                        if (task.boardDate) {
                            task.boardDate = moment(task.boardDate).toDate();
                        }
                        task.datePicker = {
                            opened: false
                        }
                        setResponsible(task);
                        setBoard(task);
                    }

                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(elem).find(".project-name").focus();
                        }, 50);
                    }

                    var getTaskInList = function (id) {
                        var task = scope.tasks.find(t => t.id == id);
                        return task;
                    };
                    
                    scope.subscribe("TaskUpdated", function (updatedTask) {
                        var task = getTaskInList(updatedTask.id);
                        if (task) {
                            task.title = updatedTask.title;
                            task.text = updatedTask.text;
                            task.color = updatedTask.color;
                            if(task.ResponsibleUserId != updatedTask.ResponsibleUserId){
                                task.responsibleUserId = updatedTask.ResponsibleUserId;
                                setResponsible(task);
                            }
                        }
                    });
                    scope.subscribe("TaskCommentAdded", function (comment) {
                        var task = getTaskInList(comment.boardTaskId);
                        if (task) {
                            task.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe("TaskCommentDeleted", function (comment) {
                        var task = getTaskInList(comment.boardTaskId);
                        if (task) {
                            task.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe('TaskAttachmentCountChanged', function (attachment) {
                        var task = getTaskInList(attachment.taskId);
                        if (task) {
                            task.attachmentCount = attachment.attachmentCount;
                        }
                    });

                    scope.subscribe('TaskAssigned', function (status) {
                        var task = getTaskInList(status.taskId);
                        if (task) {
                            task.responsibleUserId = status.userId;
                            setResponsible(task);
                        }
                    });

                    scope.updateDate = function (task, event) {
                        const date = event.originalEvent.detail
                        task.boardDate = date
                        boardTaskService().updateDate(task.id, task.boardDate)
                        mapDateToColumn(task)
                        mapDateToRow(task)
                    }

                    scope.openTask = function (activeTab, subtask) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            canChangeBoard: false,
                            users: scope.users
                        };

                        boardTaskService().get(subtask.id).then(function (task) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        })

                    }

                    async function loadBoards() {
                        if (scope.isBoardsLoaded)
                            return;
                        
                        scope.isBoardsLoaded = true;
                        const data = await boardService.getAllBoardNames()
                        scope.boards = [];
                        for (let b = 0; b < data.length; b++) {
                            if (data[b].boardType != 'Yearly') {
                                scope.boards.unshift(data[b]);
                            }
                        }
                        for (let index = 0; index < scope.tasks.length; index++) {
                            const task = scope.tasks[index];
                            setBoard(task);
                        }
                        scope.thisBoard = scope.boards.find(b => b.id == scope.parentTask.boardId)
                    }

                    // Responsible
                    function setResponsible(task) {
                        if (task.responsibleUserId && scope.users) {
                            var user = scope.users.find(u => u.userId.toLowerCase() == task.responsibleUserId.toLowerCase());
                            if (user) {
                                task.user = user;
                            }
                        }
                    }

                    function setBoard(task) {
                        if (task.boardId && scope.boards) {
                            var existsOutsideParent = false;
                            if (task.boardId == scope.parentTask.boardId) {
                                if (task.isActionItem) {
                                    existsOutsideParent = true;
                                } else if (task.rowCategoryId) {
                                    existsOutsideParent = true;
                                }
                            } else {
                                existsOutsideParent = true;
                            }
                            if (existsOutsideParent) {
                                var board = scope.boards.find(b => b.id == task.boardId);
                                if (board) {
                                    task.board = board;
                                }
                            }
                        }
                    }
                    
                    async function getAllUsers() {
                        if (scope.isUsersLoaded)
                            return;
                        scope.isUsersLoaded = true

                        scope.users = await profileImageService.getAllUsersIncludeProfileImage()
                        for (let index = 0; index < scope.tasks.length; index++) {
                            const task = scope.tasks[index];
                            setResponsible(task);
                        }
                    }
                    
                    scope.clearSelectedUser = function (task) {
                        scope.selectUser(task, {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        });
                    }

                    scope.selectUser = function (task, user) {
                        task.user = user
                        const userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName,
                            previousUserId: task.responsibleUserId,
                            previousUserName: task.responsibleUser
                        };
                        // save
                        const options = {
                            taskId: task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                        task.responsibleUserId = user.userId;
                        task.responsibleDisplayName = user.displayName;
                        task.responsibleUser = user.fullName;
                        mapResponsibleToColumn(task);
                        mapResponsibleToRow(task);
                    };



                    scope.clearBoard = function (task) {

                        var change = {
                            taskId: task.id,
                            fromBoardId: task.boardId,
                            toBoardId: scope.parentTask.boardId
                        };
                        task.boardId = scope.parentTask.boardId;
                        task.board = null;
                        setBoard(task);
                        task.board = null;

                        task.isActionItem = false;
                        task.rowCategoryId = null;
                        task.columnCategoryId = null;

                        boardTaskService().clearSubTaskBoard(task.id, change).then(function (result) {
                            scope.publish("SendTaskChangedBoard", change);
                        });
                    }

                    scope.selectThisBoard = function ($event) {
                        if (scope.thisBoard) {
                            scope.selectBoard(scope.currentTask, scope.thisBoard, $event);
                        }
                    }

                    scope.selectBoard = function (task, board, $event) {
                        task.newBoardSelected = true;
                        scope.columns = null;
                        scope.selectedColumn = null;
                        scope.rows = null;
                        scope.selectedRow = null;

                        if (task.boardId !== board.id) {
                            var change = {
                                taskId: task.id,
                                fromBoardId: task.boardId,
                                toBoardId: board.id
                            };

                            task.boardId = board.id;
                            setBoard(task);
                            if (board.id) {
                                //scope.placeTaskInBoard(task,board);
                                boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            } else {
                                boardTaskService().moveTaskToPersonalBoard(task.id).then(function (result) {
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            }
                        } else { // to the same board
                            if (!task.isActionItem) {
                                if(task.columnCategoryId && task.rowCategoryId) {
                                    task.newBoardSelected = false;
                                    return; // if it is already placed in the same board, do not do anything
                                }
                                boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                    var change = {
                                        taskId: task.id,
                                        fromBoardId: 0,
                                        toBoardId: board.id
                                    };
                                    task.isActionItem = true;
                                    task.boardId = board.id;
                                    setBoard(task);
                                    // remove this such that it can be placed directly in the board without a double selection  task.newBoardSelected = false;
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            }
                        }
                    }

                    var mapDateToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.weekdayName && (row.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedRow = row;
                            }
                        });
                    }
                    var mapDateToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.weekdayName && (column.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.responsibleUserId == task.responsibleUserId) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.responsibleUserId == task.responsibleUserId) {
                                task.selectedRow = row;
                            }
                        });
                    }

                    scope.placeTaskInBoard = function (task) {

                        //task.showColumnRow = true;
                        /*
                            what is this
                            mapDateToColumn(task);
                            mapDateToRow(task);
                            mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                        });*/
                    }

                    scope.moveTaskToRowColumn = function (task) {
                        task.isActionItem = false;

                        var rowId = 0;
                        var columnId = 0;
                        if (task.selectedRow && task.selectedRow.id) {
                            rowId = task.selectedRow.id;
                        }
                        if (task.selectedColumn && task.selectedColumn.id) {
                            columnId = task.selectedColumn.id;
                        }
                        var actionItemToTask = {
                            id: task.id,
                            boardId: task.boardId,
                            boardDate: task.boardDate,
                            rowCategoryId: rowId,
                            columnCategoryId: columnId
                        };
                        boardTaskService().moveActionItemToTask(actionItemToTask).then(function (task) {
                            scope.publish("SendActionItemMovedToTask", task);
                            task.newBoardSelected = false;
                            task.showColumnRow = false;
                        });

                    }
                    scope.add = function () {
                        var boardDate = moment().utc().startOf('day').toDate();
                        if (scope.targetDate) {
                            boardDate = scope.targetDate;
                        }
                        var item = {
                            parentId: scope.parentId,
                            title: "",
                            boardDate: boardDate,
                            taskType: 'SubTask',
                            user: null
                        }
                        boardTaskService().addSubTask(scope.parentId, item).then(function (subTask) {
                            
                            if (subTask.boardDate) {
                                subTask.boardDate = moment(subTask.boardDate).toDate();
                            }
                            subTask.datePicker = {
                                opened: false
                            }
                            scope.tasks.unshift(subTask);
                            scope.setActive(subTask);
                        });
                    }

                    scope.delete = function (task) {
                        // Delete subtask
                        var index = scope.tasks.indexOf(task);
                        scope.tasks.splice(index, 1);
                        boardTaskService().deleteTask(task.id).then(function () {

                        });
                    }

                    scope.changeStatus = function (task) {
                        if (scope.readonly) return;
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var status = {
                            taskId: task.id,
                            status: task.status
                        };
                        statusChangeDebounced(status);
                    }
                    var statusChangeDebounced = debounce(commitStatusChanged, 200, false);
                    function commitStatusChanged(status) {
                        boardTaskService().updateStatus(status.taskId, status)
                    }

                    scope.titleChanged = function (task) {
                        task.active = false;
                        var taskTitle = {
                            taskId: task.id,
                            title: task.title
                        }
                        boardTaskService().updateTitle(task.id, taskTitle)
                    }

                    scope.$on('task-bag.drop-model', function (e, el, target, source) {
                        var sortOrders = [];
                        var counter = 0;
                        scope.tasks.forEach(task => {
                            sortOrders.push({
                                taskId: task.id,
                                sortOrder: counter
                            });
                            counter += 1;
                        });
                        boardTaskService().updateSortOrderForSubtasks(scope.parentId, sortOrders)
                    });

                },
                controller: ['$scope','$element', function ($scope, $element) {

                    let userPopoverId = "users-selector-popover"
                    let boardPopoverId = "boards-selector-popover"

                    let userPopover = null
                    let boardPopover = null
                    function getUserSelectPopover() {
                        if (!userPopover)
                            userPopover = $element[0].querySelector(`#${userPopoverId}`)
                        return userPopover
                    }
                    function getBoardSelectPopover() {
                        if (!boardPopover)
                            boardPopover = $element[0].querySelector(`#${boardPopoverId}`)
                        return boardPopover
                    }

                    $scope.editUser = function($event, task) {
                        $scope.currentTask = task // keep track of task when opening user selector
                        const target = $event.originalEvent.target

                        $scope.showClearUser = task.responsibleUserId ? true : false

                        const userPopover = getUserSelectPopover()

                        const anchorName = `--subtask-user-${task.id}`

                        userPopover.style.positionAnchor = anchorName
                        userPopover.showPopover()
                    }
                    $scope.editBoard = ($event, task) => {
                        $scope.currentTask = task // keep track of task when opening user selector
                        const target = $event.originalEvent.target

                        const boardPopover = getBoardSelectPopover()

                        const anchorName = `--subtask-board-${task.id}`

                        boardPopover.style.positionAnchor = anchorName
                        boardPopover.showPopover()
                    }

                    $scope.selectUserEvent = ($event) => {
                        const user = $event.originalEvent.detail
                        $scope.selectUser($scope.currentTask, user)
                        $scope.currentTask = null
                        getUserSelectPopover().hidePopover()
                    }

                    $scope.clearSelectedUserEvent = ($event) => {
                        $scope.clearSelectedUser($scope.currentTask)
                        $scope.currentTask = null
                        getUserSelectPopover().hidePopover()
                    }

                    $scope.selectBoardEvent = ($event) => {
                        const board = $event.originalEvent.detail
                        $scope.selectBoard($scope.currentTask, board, $event)
                    }
                    $scope.clearBoardEvent = ($event) => {
                        $scope.clearBoard($scope.currentTask)
                    }
                    $scope.moveTaskToRowColumnEvent = ($event) => {
                        const move = $event.originalEvent.detail
                        $scope.currentTask.selectedColumn = move.selectedColumn
                        $scope.currentTask.selectedRow = move.selectedRow
                        $scope.moveTaskToRowColumn($scope.currentTask)
                    }
                }]
            }
        }]);


