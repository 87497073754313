angular
    .module('DigiLean')
    .directive("datasourceFilter", ['$filter', '$translate', 'dataService',
        function ($filter, $translate, dataService) {
            return {
                templateUrl: 'dataSourceFilter.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'datasource': '=',
                    'filterChanged': '&',
                },
                link: function (scope, elem, attrs) {

                 
                    scope.$watch('filters', function () {
                        if (scope.datasource && scope.filters) {
                            setFilter();
                        }
                    });

                    scope.$watch('datasource', function () {
                       if (scope.datasource && scope.filters) {
                           setFilter();
                       }
                    });

                    scope.updateFilter = function (updatedSourceColumn, filters) {
                        var hasFilter = $filter('filter')(scope.filters, { sourceColumn: updatedSourceColumn }, true);
                        // Remove existing filter connected to sourceColumn
                        if (hasFilter && hasFilter.length > 0) {
                            // Remove existing filter
                            angular.forEach(hasFilter, function (filter, key) {
                                var index = scope.filters.indexOf(hasFilter[key]);
                                scope.filters.splice(index, 1);
                            });
                        }
                        angular.forEach(filters, function (filter) {
                            if (filter) {
                                if(scope.filters === undefined){
                                    scope.filters = [];
                                }
                                 // Custom field check
                                if (isCustomField(updatedSourceColumn)) {
                                    var customFieldId = getCustomFieldId(updatedSourceColumn);
                                    filter.customFieldId = customFieldId;
                                }
                                scope.filters.push(filter);
                            }
                        });

                        scope.filterChanged({
                            filters: scope.filters
                        });
                    };
                    

                    function setFilter() {
                        if (scope.filters && scope.datasource) {
                            
                            for (var i = 0; i < scope.datasource.elements.length; i++) {
                                setFilterForElement(scope.datasource.elements[i], scope.filters);
                            }
                            setFilterForMainValueElement(scope.datasource, scope.filters);
                        }
                    }
                   

                    function setFilterForMainValueElement(datasource, filters) {
                        var filter = $filter('filter')(filters, { sourceColumn: "value" });
                        if (filter.length > 0) {
                            datasource.valueElement.filter = filter;
                        } else {
                            datasource.valueElement.filter = null;
                        }
                    }
            
                    function setFilterForElement(element, filters) {
                        var elementFilters = $filter('filter')(filters, { sourceColumn: element.sourceColumn }, true);
                        if (elementFilters.length === 0) {
                            element.filter = null;
                        }
                        if (elementFilters.length > 0) {
                            element.filter = [];
                            angular.forEach(elementFilters, function (filterItem) {
                               
                                element.filter.push(filterItem);
                            });
                        }
                    }

                    function isCustomField(sourceColumn) {
                        if (sourceColumn.length >= 12) {
                            if (sourceColumn.substring(0,11) == "customField") return true;
                        }
                    }

                    function getCustomFieldId(sourceColumn) {
                        return sourceColumn.substring(12, (sourceColumn.length + 1))
                    }
                }
            }
        }]);