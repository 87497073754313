angular
    .module('DigiLean')
    .directive("smartIconCell", ['$filter', 'debounce', 'smarttableService',
        function ($filter, debounce, smarttableService) {
            return {
                templateUrl: 'smartIconCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.icon = "";
                    scope.text = "";
                    scope.color = "#676a6c";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = smarttableService().getIcons();
                    scope.icons = [];

                    var rowId = null;
                    var columnId = null;

                    scope.$watch('model', function (model) {
                        var value = null;
                        var settings = null;
                        if (scope.type == 'smarttable') {
                            settings = model.column.settings; 
                            value = model.cell.value; 
                            columnId = model.column.id;                        
                        } else if (scope.type == 'smartactionlist') {
                            settings = model.column.settings; 
                            value = scope.model.cell.value;
                            columnId = model.column.id;                        
                        }
                        if (scope.type == 'project') {
                            columnId = model.boardAttributeId;
                            rowId = model.project.id;
                            value = model.projectAttribute.value;
                            settings = model.column.settings;              
                            
                        } 
                        /*else if (scope.type == 'a3') {
                            columnId = model.columnId;
                            rowId = model.a3.id;
                            if(model.a3TableCell){
                                value = model.a3TableCell.value;
                            }
                            settings = model.column.settings;              
                        }*/
                        if (value) {
                            setValue(value);
                        } 
                        if (settings && settings.predefinedIcons) {
                            scope.icons = settings.predefinedIcons;      
                        } else {
                            scope.icons = scope.postIts;
                        }
                        model.getSortValue = function () {
                            if(scope.icons){
                                var exist = $filter('filter')(scope.icons, { icon: scope.icon }, true);
                                if (exist.length > 0) {
                                    return scope.icons.indexOf(exist[0]);
                                } else {
                                    return scope.icons.length;
                                }
                            }
                            return -1;
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            $(".project-text-cell").focus();
                        }, 50 );
                    }
                    
                    var setValue = function(value) {
                        scope.background = value.background;
                        scope.text = value.text;
                        scope.color = value.color;
                        scope.icon = value.icon;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (scope.type == 'smartactionlist' && cell.boardTaskId == scope.model.cell.boardTaskId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        } 
                    });

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (scope.type == 'smarttable' && cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        } 
                    });
                    
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        } 
                    });
                    /* Per now this cell type is not available for A3
                scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });*/

                    scope.subscribe("SmartIconCellSelectorUpdated", function (value) {
                        if (value.type == scope.type && columnId === value.columnId) {
                            scope.icons = value.predefinedIcons;
                        }
                    });
                    
                    scope.clear = function() {
                        scope.icon = "";
                        scope.text = "";
                        scope.color = "#676a6c";
                        scope.background = "inherit";
                        scope.commitChangesDebounced();
                    }

                    scope.selectIconAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.text = "";
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                            scope.icon = "";
                        } else {
                            scope.background = postIt.background;
                            scope.color =  postIt.color;    
                            scope.text = postIt.title;
                            scope.icon = postIt.icon;
                        }
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var newvalue = {
                            color: scope.color,
                            text: scope.text,
                            icon: scope.icon
                        }
                        if (scope.type == 'smarttable' ||scope.type == 'smartactionlist') {
                            scope.model.cell.value = newvalue;
                            scope.changeHandler({ cell: scope.model.cell })
                        }
                        else if (scope.type == 'project' || scope.type == 'strategy') {
                            scope.model.projectAttribute.value = newvalue;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = newvalue;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: newvalue
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
