import * as documentService from "@common/services/documentService"

angular
    .module('DigiLean')
    .controller("editLearningMaterialController", ['$scope','$filter', 'learningService', '$uibModalInstance', 'languageAdminService', 'materialId',
        function ($scope,$filter, learningService, $uibModalInstance,languageAdminService, materialId) {

            $scope.title = "";
            $scope.description = "";
            $scope.imageId = null;
            $scope.editImage = false;
            $scope.imageUrl = null;
            $scope.languages = languageAdminService().getSupportedLanguages();
            $scope.selectedLanguage = $scope.languages[0];

            learningService().getLearningMaterial(materialId).then(function(material){
                
                $scope.title = material.title;
                $scope.description = material.description;
                $scope.selectedLanguage = $filter('filter')($scope.languages, { languageCode: material.language })[0];
                $scope.imageId = material.imageId;
                $scope.imageUrl = material.imageUrl;
                if (!$scope.imageUrl) {
                    $scope.editImage = true;
                }
            })

            $scope.setImageViewMode = function () {
                $scope.editImage = false;
            };
            $scope.setImageEditMode = function () {
                $scope.editImage = true;
            };
            $scope.setLanguage = function (language) {
                $scope.selectedLanguage = language;
            };
            $scope.ok = function () {
                var material = {
                    id: materialId,
                    title: $scope.title,
                    description: $scope.description,
                    imageId: $scope.imageId,
                    language: $scope.selectedLanguage.languageCode
                }
                learningService().editLearningMaterial(material).then(function(created){
                    $uibModalInstance.close(created)
                });          
            };

            $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
            $scope.fileUploaded = function (file) {
                $scope.imageId = file.id;
                documentService.getFileUrl(file.fileName).then(function(fileUrl) {
                    $scope.imageUrl = fileUrl;
                    $scope.editImage = false;
                });
            };
            
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
