<script setup lang="ts">
    import { computed } from "vue"

    const classBase =  "fa fa-redo fa-lg"
    const props = defineProps({
        spin: {
            type: Boolean,
            required: true,
            default: false
        }
    })

    const icon = computed(() => {
        if (props.spin)
            return `${classBase} fa-spin`
        return classBase
    })
</script>
<style>
i {
    color: var(--digilean-primary-text);
}
</style>
<template>
    <link href="/assets/fontawesome.all.min.css" rel="stylesheet">
    <fa-icon .icon="icon"></fa-icon>
</template>
