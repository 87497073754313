import { Module, User, UserPreference, UserSettings } from "@api"
import backend from "@common/services/backendHttp"
const resourceUrl = "api/user"

export function getList() {
    return backend.get<User[]>(resourceUrl)
}
export function changeLanguage(language: string) {
    const url = resourceUrl + "/updatePreferredLanguage?language=" + language
    return backend.put(url)
}

export function getMembership(assetId) {
    const url = resourceUrl + "/getMemberShip/" + assetId
    return backend.get(url)
}

export async function getUserSettings() {
    const url = resourceUrl + "/getUserSettings"
    const settings = await backend.get<UserSettings>(url)
    if (settings && typeof settings === "string") {
        const settingsJson = JSON.parse(settings) as UserSettings
        return settingsJson
    }
    return settings
}

export function updateUserSettings(settings: UserSettings | string) {
    const url = resourceUrl + "/updateSettings"
    if (!settings)
        return
    
    if (settings && typeof settings !== "string") {
        settings = JSON.stringify(settings);
    }

    return backend.put(url, { settings: settings })
}

export function updateUserPreference(key: string, value: any) {
    const url = resourceUrl + "/preference";
    if (value && typeof value !== "string") {
        if(key == 'FlexiboardFavoriteBoards'){ // prevent duplicate boards
            let uniqueBoards = new Map()
            value.forEach((item: any) => {
                if(item.id)
                    uniqueBoards.set(item.id, item)
            })
            value = Array.from(uniqueBoards, ([name, value]) => value)
        }
        value = JSON.stringify(value)
    }

    const preference = {
        key: key,
        value: value
    }
    return backend.put<UserPreference>(url, preference)
}

export function deleteUserPreference(key: string) {
    const url = resourceUrl + "/preference/" + key
    return backend.delete(url)
}

export async function getUserPreference(key: string) {
    const url = resourceUrl + "/preference/" + key
    let preference = await backend.get<UserPreference>(url)
    if (preference && preference.value && typeof preference.value === "string") {
        const prefJson = JSON.parse(preference.value)
        preference.value = prefJson
    }
    return preference
}
