angular
    .module('DigiLean')
    .directive("cLetter", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var cLetterDirective = {
                name: 'cLetter',
                templateUrl: 'cLetter.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<',
                    'userAccess': '<',
                }
            };

            cLetterDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        scope.visualizationType = "C";
                   
                    }
                };
            };

            return cLetterDirective;
        }]);
