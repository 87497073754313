import type { TemplateResult } from "lit"

export const html = String.raw

export const tag = (strings: TemplateStringsArray, ...values: unknown[]): TemplateResult<1> => {
    return {
        ['_$litType$']: 1,
        strings,
        values,
    }
}

export const convertStringToLiteral = (str: string) => {
    let open = 0
    let close = 0
    let counter = 0
    let i = 0
    let length = str.length
    let stringsTemp = i < length ? [] : ['""']
    
    let values: string[] = []
    
    while (i < length) {
        open = str.indexOf('${', i)
        if (-1 < open) {
            stringsTemp.push(str.slice(i, open))
            open += 2;
            close = open;
            counter = 1;
            while (close < length) {
                switch (str.charAt(close++)) {
                    case '}': --counter; break
                    case '{': ++counter; break
                }
                if (counter < 1) {
                    values.push(str.slice(open, close - 1))
                    break
                }
            }
            i = close
        } else {
            stringsTemp.push(str.slice(i))
            i = length
        }
    }
    if (stringsTemp.length === values.length)
        stringsTemp.push('""')
    
        let strings = stringsTemp
        ///@ts-ignore
        strings.raw = stringsTemp

        const stringRaw = String.raw({raw:stringsTemp})
        console.log(stringRaw)
    return { "_$litType$": 1, strings, values }
}

export const convertStringToLiteralModel = (str: string, model: any) => {
    let open = 0
    let close = 0
    let counter = 0
    let i = 0
    let length = str.length
    let stringsTemp = i < length ? [] : ['""']
    
    let values: string[] = []
    
    while (i < length) {
        open = str.indexOf('${', i)
        if (-1 < open) {
            stringsTemp.push(str.slice(i, open))
            open += 2;
            close = open;
            counter = 1;
            while (close < length) {
                switch (str.charAt(close++)) {
                    case '}': --counter; break
                    case '{': ++counter; break
                }
                if (counter < 1) {
                    let propName = str.slice(open, close - 1)
                    let propValue = model[propName] ?? ""
                    values.push(propValue)
                    break
                }
            }
            i = close
        } else {
            stringsTemp.push(str.slice(i))
            i = length
        }
    }
    if (stringsTemp.length === values.length)
        stringsTemp.push('""')
    
        let strings = stringsTemp
        ///@ts-ignore
        strings.raw = stringsTemp
    return { "_$litType$": 1, strings, values }
}

export const convertStringToLiteralModelGen = (str: string, model: any) => {

    let div = document.createElement("div")
    div.innerHTML = str

    const dataElements = div.querySelectorAll('[data-ref]') as NodeListOf<HTMLInputElement>
    dataElements.forEach(el => {
        const ref = el.dataset.ref as string
        el.setAttribute("name", ref)
        el.setAttribute("value", "${"+ref+"}")
    })

    const newHtmlStr = div.innerHTML
    return convertStringToLiteralModel(newHtmlStr, model)
}

export const getInputsRecursive = (elements: HTMLCollection) => {
    let inputs: HTMLInputElement[] = []

    for (let i = 0; i< elements.length; i++) {
        let el = elements[i] as HTMLInputElement
        if (el.hasAttribute("value") || el.value)
            inputs.push(el as HTMLInputElement)
        else if (el.shadowRoot && el.shadowRoot.children && el.shadowRoot.children.length > 0) {
            const shEls = getInputsRecursive(el.shadowRoot.children)
            inputs.push(...shEls)
        }
    }
    return inputs
}