angular
    .module('DigiLean')
    .directive("smartColumnWidthConfig", ['debounce',
        function (debounce) {
            return {
                templateUrl: 'smartColumnWidthConfig.html',
                restrict: 'E',
                scope: {
                    'column': '<',
                    'changeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {

                    scope.minwidth = 20;

                    scope.$watch('column', function (column) {
                        scope.col = column;
                        // TODO: 
                        const typeOfSettings = typeof column.settings
                        if (column.settings && typeOfSettings == "string")
                            console.log(`column.settings. Type=${typeOfSettings}. ${column.settings}`)
                    });

                    scope.reduceWidth = function(){
                        scope.col.settings.width = scope.col.settings.width - 10;
                        if(scope.col.settings.width >= scope.minwidth){
                            scope.saveChanges(scope.col);
                        }
                    }

                    scope.increaseWidth = function(){
                        scope.col.settings.width = scope.col.settings.width + 10;
                        if(scope.col.settings.width <= 1000){
                            scope.saveChanges(scope.col);
                        }
                    }

                    scope.updateWidthSetting = function(col){
                        if(col.settings.widthSetting == 'MANUAL'){
                            col.settings.width = 200;
                        }
                        scope.col = col;
                        scope.saveChanges(col);
                    }

                    scope.updateColumnWidth = function(col){
                        scope.col.settings = col.settings;
                        scope.saveChanges(col);
                    }
 
                    scope.saveChanges = function(col) {
                        if(scope.col && scope.col.settings && scope.col.settings.widthSetting){
                            scope.changeHandler({column: col});
                        }
                    }
                }
            }
        }]);
