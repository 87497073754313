﻿import * as documentService from "@common/services/documentService"

angular
    .module('DigiLean')
    .directive("videofileinfo", function () {
            return {
                templateUrl: 'videofileinfo.html',
                restrict: 'E',
                scope: {
                    'file': '=',
                },
                link: function (scope, elem, attrs) {

                    var viewerFileExtensions = [
                        { type: 'video', extension: '.mp4' },
                    ];

                    scope.$watch('file', function (file) {
                        // apply
                        if (!file) return;
                        file.validFileUrl = "https://digileanfiles.blob.core.windows.net/public-assets/images/Loading_icon.gif"; // Default loading image, will be replaced with actual image
                        var fileType = viewerFileExtensions.find(ve => ve.extension == file.fileExtension.toLowerCase());
                        if (fileType && fileType.type == "video") {
                            file.fileType = fileType.type;
                            documentService.getFileUrl(file.fileName, file.id).then(function (fileUrl) {
                                file.fileUrl = fileUrl;
                                file.validFileUrl = fileUrl;
                            });
                        }

                    });
                }
            }
        });
