import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "search-row" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = {
  key: 1,
  class: "error"
}
const _hoisted_4 = ["label"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["onClick"]

import {ref, onMounted} from "vue"
    import { lookupUserAd } from "@common/services/user/userAzureAdService"
    import { DialogResult } from "@common/components/digilean/DigileanDialog"
    import { AzureAdDigiLeanUser } from "@api"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'UserAzureLookup',
  setup(__props) {

    const users = ref<AzureAdDigiLeanUser[]>([])
    const inputField = ref<HTMLInputElement | undefined>()

    const performSearch = () => {
        if (inputField.value) {
            search(inputField.value.value)
        }
    }
    const loading = ref(false)
    
    const showError = ref(false)
    let errorLabel = ref("COMMON_NO_RESULTS")

    const search = async (searchTerm: string) => {
        showError.value = false
        if (!searchTerm || !searchTerm.trim())
            return
        
        loading.value = true
        try {
            const us = await lookupUserAd(searchTerm)
            if (us && us.length > 0)
                users.value = us
            else {
                errorLabel.value = "COMMON_NO_RESULTS"
                showError.value = true
                users.value = []
            }
        }
        catch(err) {
            errorLabel.value = "COMMON_ERROR_MSG"
            showError.value = true
            users.value = []
        }
        finally {
            loading.value = false
        }
    }
    const select = (user: AzureAdDigiLeanUser) => {
        const options = { detail: user, bubbles: true, composed: true }
        inputField.value?.dispatchEvent(new CustomEvent(DialogResult.dialogOkFromOutside, options))
    }
    onMounted(() => {
        setTimeout(() => {
            if (inputField.value)
                inputField.value.focus()
        }, 100)
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", null, [
      _createElementVNode("translate-label", { label: "COMMON_SEARCH" }),
      _createTextVNode(),
      _createElementVNode("translate-label", { label: "COMMON_AZURE_AD" }),
      _createTextVNode(),
      _createElementVNode("translate-label", { label: "COMMON_USERS" })
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("digilean-input", {
        type: "text",
        placeholder: "search name",
        ref_key: "inputField",
        ref: inputField,
        onKeyup: _withKeys(performSearch, ["enter"])
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
      _createElementVNode("digilean-button", { onClick: performSearch }, _cache[0] || (_cache[0] = [
        _createElementVNode("translate-label", { label: "COMMON_SEARCH" }, null, -1 /* HOISTED */)
      ]))
    ]),
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("spinner-icon", { spin: "true" }, null, -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true),
    (showError.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("translate-label", { label: _unref(errorLabel) }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true),
    (users.value.length>0)
      ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "COMMON_USERNAME" })
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "COMMON_EMAIL" })
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "ADMIN_FIRST_NAME" })
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "ADMIN_LAST_NAME" })
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "COMMON_ADMIN_DEPARTMENT" })
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("translate-label", { label: "COMMON_ADMIN_JOBTITLE" })
              ])
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "users",
                onClick: () => select(user)
              }, [
                _createElementVNode("td", null, _toDisplayString(user.userPrincipalName), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(user.mail), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(user.givenName), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(user.surname), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(user.department), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(user.jobTitle), 1 /* TEXT */)
              ], 8 /* PROPS */, _hoisted_6))
            }), 256 /* UNKEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})