import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { DiagramModified, DiagramIncomingMessage, DiagramOutgoingMessage } from "./diagramEditor"
import {getUserProfile} from "@common/stores/userStore"
import { Base64 } from "js-base64"
import config from "@common/config"
// https://www.diagrams.net/doc/faq/embed-mode
// https://www.diagrams.net/doc/faq/configure-diagram-editor

@customElement('digilean-diagram-editor')
export class DigileanDiagramEditor extends LitElement {
    static self: DigileanDiagramEditor
    private config = {
        css: "button.geBigButton { background: #1c84c6; color: #EEE } button.geBigButton:hover { background: #2095d5 }",
        defaultLibraries: "general;uml;er;bpmn;lean_mapping",
        // enabledLibraries: ["general", "uml", "er", "bpmn", "lean_mapping"]
    }
    
    private language = "en"
    private urlParams = {"dark": "0", "pv": "0"}

    private drawDomain = config.diagramsUrl // previously hardcoded to "https://embed.diagrams.net"
    private ui = "min" //"sketch" // 

    private xml: string | null = ""
    private format = "xmlsvg"
    private svg = ""

    private libraries = false
    private frameStyle = "position:absolute;border:0;width:100%;height:100%;"
    //private hideBtns = "saveAndExit=0&noSaveBtn=1&noExitBtn=1"

    private frame: HTMLIFrameElement | null = null
    static styles = css`
        :host {
            display: block;
            height: 100%;
            max-width: 100%;
        }
    `

    constructor() {
        super()
        DigileanDiagramEditor.self = this
    }

    @property({attribute: true})
    title = ""

    @property({attribute: true})
    data = ""

    private ready = false
    getFrameStyle() {
        return this.frameStyle + ';left:' +
            document.body.scrollLeft + 'px;top:' +
            document.body.scrollTop + 'px;'
    }
    getFrameUrl() {
        const params = new URLSearchParams()
        let url = this.drawDomain
        params.append("embed", "1")
        params.append("proto", "json")
        params.append("spin", "1")
        // hide buttons
        params.append("saveAndExit", "1")
        params.append("noSaveBtn", "1")
        params.append("noExitBtn", "0")

        // if (this.ui != null)
        //     params.append("ui", this.ui)
        
        if (this.libraries)
            params.append("libraries", "1")
        
        if (this.config != null) 
            params.append("configure", "1")
        
        if (this.urlParams != null) {
            Object.keys(this.urlParams).map(key => {
                const val = this.urlParams[key]
                params.append(key, val)
            })
        }
        if (this.language) {
            params.append("lang", this.language)
        }
        let parms = params.toString()
        url += "?" + parms
        return url
    }
    
    postMessage(msg: DiagramOutgoingMessage) {
        if (!this.frame)
            this.frame = this.shadowRoot?.querySelector("iframe") as HTMLIFrameElement
        
        if (this.frame) {
            this.frame?.contentWindow?.postMessage(JSON.stringify(msg), '*')
        }
    }
    handleMessageEvent(evt: MessageEvent) {
        const self = DigileanDiagramEditor.self
        
        if (evt.origin != self.drawDomain)
            return

        if (evt.data.length > 0) {
            try {
                const msg = JSON.parse(evt.data)
                if (msg != null)
                    self.handleMessage(msg)
            }
            catch (e) {
                console.error(e)
            }
        }
    }
    dispatchCustomEvent(name: string, detail: any) {
        const options = {
            detail,
            bubbles: true,
            composed: true
        };
        this.dispatchEvent(new CustomEvent(name, options));
    }
    handleMessage(msg: DiagramIncomingMessage) {
        console.log(msg.event)
        if (msg.event == "configure") {
            this.configureEditor()
        }
        else if (msg.event == "init") {
            this.initializeEditor()
        }
        else if (msg.event == "autosave") {
            this.save(msg.xml!, true)
        }
        else if (msg.event == "export") {
            this.saveSvgAndExit(msg.data)
        }
        else if (msg.event == "save") {
            this.save(msg.xml!, false)
            this.xml = msg.xml!
            if (msg.exit) {
                this.export()
            }
            else {
                this.setStatus("allChangesSaved", false)
            }
        }
     
        if (msg.event == "exit") {
            this.stopEditing()
        }
    }

    export() {
        this.postMessage({action: "export", format: this.format, xml: this.xml})
    }
    showTemplateDialog() {
        this.postMessage({action: "template", format: this.format})
    }
    save(xml: string, draft: boolean) {
        this.xml = xml
    }
    saveSvgAndExit(data) {
        const svgBase64 = data.substring(data.indexOf(',') + 1)
        const svg = Base64.decode(svgBase64) // use this for proper encoding, not atob
        this.dispatchCustomEvent("saveandexit", svg)
    }
    stopEditing() {
        this.dispatchCustomEvent("exitnosave", "exitnosave")
    }
    initializeEditor() {
        if (!this.data)
            return
        const data: DiagramOutgoingMessage = {
            action: "load",
            autosave: 0,
            saveAndExit: "1",
            modified: "unsavedChanges",
            xml: this.data,
            title: this.title
        }
        this.postMessage(data)
    }
    configureEditor() {
        this.postMessage({action: 'configure', config: this.config})
    }
    setStatus(messageKey: string, modified: DiagramModified) {
        this.postMessage({action: "status", messageKey: messageKey, modified: modified})
    }
    connectedCallback() {
        super.connectedCallback()
        window.addEventListener('message', this.handleMessageEvent)
        const userProfile = getUserProfile()
        if (userProfile)
            this.language = userProfile.preferredLanguage!
        this.ready = true
    }
    disconnectedCallback() {
        super.disconnectedCallback()
        window.removeEventListener('message', this.handleMessageEvent)
    }
    
    render() {
        if (this.ready) {
            const frameUrl = this.getFrameUrl()
            const frameStyle = this.getFrameStyle()
            return html`
                <iframe src="${frameUrl}" style="${frameStyle}" frameborder="0"></iframe>
            `
        }
        return html`<div class="no-img">Loading ...</div>`
    }
}