<script setup lang="ts">
    // datetime-viewer element

    import type { DateTimeViewMode } from "@common/model/types"
    import { computed, PropType} from "vue"
    import { formatDateTime, formatDateTimeShort, formatDateTimeLong } from "@common/services/datetime/dateTimeFormattingLocale"

    const props = defineProps({
        date: {
            type: String,
            required: true
        },
        mode: {
            type: String as PropType<DateTimeViewMode>,
            default: "regular"
        }
    })

    const dateFormatted = computed(() => {
        if (props.mode == "short")
            return formatDateTimeShort(props.date)
        if (props.mode == "long")
            return formatDateTimeLong(props.date)
        else
            return formatDateTime(props.date)
    })
    
</script>
<style>
    
</style>
<template>
    {{dateFormatted}}
</template>