import { DigiLeanNgScope } from "@common/model/angularModel"

let currentDate = new Date()
let intervalId = 0

let $scope: DigiLeanNgScope

export function startMidnightTimer(scope: DigiLeanNgScope) {
    $scope = scope
    if (intervalId)
        return
    intervalId = window.setInterval(checkMidnight, 300000)
    // debug once
    // setTimeout(() => {
    //     $scope.publish("Midnight", new Date())
    // }, 10000)
}

function checkMidnight() {
    const dateNow = new Date()

    const dayNow = dateNow.getDay()
    const dayCurrent = currentDate.getDay()

    if (dayCurrent !== dayNow) {
        console.log("Midnight event fired, Time =", dateNow)
        $scope.publish("Midnight", dateNow)
    }

    currentDate = dateNow
}