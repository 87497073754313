import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as dashboardService from "@common/services/dashboardService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as translationService from "@common/services/translationService"
import * as employeeService from "@common/services/employeeService"
import * as pdfService from "@common/services/pdfService"
import { getColStyle } from "@common/services/settingsService"

angular
    .module("DigiLean")
    .directive('smartActionListApp', ['$uibModal', '$state', '$translate', 'boardTaskService', 'smartActionListService', 'orderByFilter', 'excelService', 'modalService', 'debounce','isTeamsMode',
        function ($uibModal, $state, $translate, boardTaskService, smartActionListService, orderByFilter, excelService, modalService, debounce, isTeamsMode) {
            return {
                templateUrl: 'smartActionListApp.html',
                restrict: 'E',
                transclude: true,
                scope: {
                    'isAdminMode': '<',
                    'assetId': '<',
                    'settings': '<',
                    'settingsChangeHandler': '&?',
                    'context': '@',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }

                    scope.postIts = dashboardService.getThemes()
                    
                    scope.smartActionList = null;
                    scope.showAddButton = true;
                    scope.editable = true;
                    scope.columns = [];
                    scope.tasks = [];
                    scope.accessOptions = {};
                    scope.useResizing = true;
                    scope.showArchive = false;
                    scope.taskAdded = null;
                    scope.taskDeleted = null;
                    scope.isStandAlone = false; // Indicate if this app is running outside flexiboard.
                    scope.settingsSortColumn = null;

                    scope.showFileGenerators = true;
                    if (isTeamsMode) {
                        scope.showFileGenerators = false;
                    }

                    // Favorite
                    scope.isStar = false;
                    scope.quickAccess = [];

                    var boardId = null;
                    scope.viewMode = "List";

                    scope.zoomOptions = {
                        zoomLevels: [
                            0.75, 1, 1.5, 2
                        ],
                        zoomMax: 2,
                        zoomMin: 0.75
                    }
                    scope.zoom = 1
                    function setFontStyle() {
                        scope.fontStyle = {
                            "font-size": scope.zoom + "rem"
                        }
                    }
                    setFontStyle()
                    scope.zoomChangedHandler = function (zoom) {
                        scope.zoom = zoom
                        setFontStyle()
                        scope.updateSettings()
                    }

                    // Resolve 
                    if (scope.context && scope.context == "standAlone") {
                        scope.isStandAlone = true;
                        scope.useResizing = false;
                    } else {
                        scope.isStandAlone = false;
                    }

                    scope.$watch("isAdminMode", function (isAdminMode) {
                        if (isAdminMode) {
                            showActionListSelectOptions();
                        }
                    })
                    // Admin mode
                    scope.toggleAdminMode = function (isAdminMode) {
                        scope.isAdminMode = isAdminMode;
                    }

                    scope.$watch("viewMode", function (viewMode) {
                        // View Mode 
                    })

                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            if (settings.title) {
                                scope.title = settings.title;
                            }

                            if (settings.boardId) {
                                boardId = settings.boardId;

                                boardService.canEdit(settings.boardId).then(function (result) {
                                    scope.editable = result;
                                })
                                loadsmartActionList(settings.boardId);
                                getAllUsers();
                            } else {
                                showActionListSelectOptions();
                            }
                            if (settings.showAddButton === false) {
                                scope.showAddButton = settings.showAddButton;
                            }

                            if (settings.timeBoardOptions) {
                                scope.timeBoardOptions = settings.timeBoardOptions;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            if (settings.viewMode) {
                                scope.viewMode = settings.viewMode;
                            }
                            if (settings.settingsSortColumn) {
                                scope.settingsSortColumn = settings.settingsSortColumn;
                            }
                            if (settings.zoom) {
                                scope.zoom = settings.zoom
                                setFontStyle()
                            }
                        } else {

                            showActionListSelectOptions();

                        }
                    });

                    // Kanban options
                    scope.kanbanEnabled = true;
                    if (scope.kanbanEnabled) {
                        // Debug, remove in prod
                        // scope.viewMode = "Kanban";
                    }

                    // Timeboard options
                    scope.timeboardEnabled = true;
                    scope.timeBoardModel = null;
                    scope.timeBoardOptions = {
                        showTeamMembers: false,
                        showHeader: false,
                        useActionList: true,
                        showArchive: scope.showArchive
                    }
                    scope.optionsChangeHandler = function (options) {
                        scope.timeBoardOptions.viewModeSelection = options.viewModeSelection;
                        scope.timeBoardOptions.taskLevelDetails = options.taskDetailLevel;
                        scope.updateSettings();
                    }

                    function refreshTimeBoardModel() {
                        if (scope.timeboardEnabled) {
                            setTimeBoardModel();
                        }
                    }

                    function setKanbanModel() {
                        var currentboard = scope.smartActionList.board;
                        var currentcolumns = scope.smartActionList.columns;
                        scope.smartActionList = {
                            board: currentboard,
                            columns: currentcolumns,
                            tasks: scope.tasks
                        }
                    }

                    function setTimeBoardModel() {
                        scope.timeBoardModel = {
                            board: {
                                id: scope.boardId,
                                name: '',
                                assetId: 0
                            },
                            actionList: scope.smartActionList,
                            users: scope.users
                        }
                    }

                    /* Task Styling */
                    
                    scope.borderColor = "#337ab7";  // borderColor
                    scope.fillColor = "white";

                    scope.saveAsTemplate = function () {
                        var board = {
                            id: scope.boardId,
                            name: scope.title,
                            boardType: 'SmartActionList'
                        }
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newboardtemplate.html',
                            controller: 'newboardtemplateController',
                            resolve: {
                                board: function () {
                                    return board;
                                }
                            }
                        })
                    }

                    scope.toggleViewMode = function (viewMode) {
                        scope.viewMode = viewMode;
                        if (scope.isAdminMode) {
                            scope.updateSettings();
                        }
                    }

                    scope.exportToExcel = function () {
                        excelService().exportSmartActionList(scope.boardId);
                    }

                    scope.exportToPDF = function () {
                        pdfService.createSmartActionListReport(scope.boardId);
                    }

                    // load users
                    function getAllUsers() {
                        boardService.getUserListForBoard(scope.boardId).then(function (data) {
                            scope.users = data;
                        });

                    }


                    // Sorting
                    scope.sortColumnIndex = null;
                    scope.isAscedning = true;
                    scope.sortColumn = null;
                    scope.orderBy = function (column) {
                        if (scope.sortColumn && scope.sortColumn != column) {
                            scope.sortColumn.sort = "none";
                        }
                        
                        if (scope.settingsSortColumn && scope.settingsSortColumn.id && scope.settingsSortColumn.id != column.id) {
                        //if (scope.settingsSortColumn && scope.settingsSortColumn.attributeType && scope.settingsSortColumn.attributeType != column.attributeType) {
                            const foundColumn = scope.columns.find(c => c.id == scope.settingsSortColumn.id)
                            if (foundColumn) {
                                foundColumn.sort = "none"; //reset saved sortorder, but not changing settings 
                            }
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        scope.sortColumn = column;
                        scope.sortColumnIndex = scope.columns.indexOf(column);
                        if (column.sort == "none") {
                            scope.tasks = orderByFilter(scope.tasks, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            scope.tasks = orderByFilter(scope.tasks, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            scope.tasks = orderByFilter(scope.tasks, getSortValue, true);
                        }
                        if(scope.isAdminMode) {
                            if(column.sort == "desc" || column.sort == "asc"){
                                scope.settingsSortColumn = {
                                    id: column.id,
                                    attributeType: column.attributeType,
                                    sort: column.sort,
                                };
                            } else {
                                scope.settingsSortColumn = null;
                            }
                            scope.updateSettings();
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (task) {
                        if (task.cellModels) {
                            var cell = task.cellModels[scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return task.title;
                    }


                    // Options for selecting or creating a new list.
                    scope.hasBoard = false;
                    scope.existingBoards = [];
                    var newActionListCreated = false;
                    function showActionListSelectOptions() {                     
                        // Should ask user if want to create or link
                        if (scope.hasBoard || !scope.isAdminMode || scope.isStandAlone) return;
                        scope.hasBoard = false;
                        // Get possible actionlist to select from.
                        if (scope.existingBoards.length > 0) return;
                        boardService.getMemberBoardsOfType("SmartactionList").then(function (boards) {
                            scope.existingBoards = boards;
                            if (newActionListCreated) return;
                            if (!scope.existingBoards || scope.existingBoards.length==0){
                                scope.createNewActionList();
                            }
                        });
                    }
                    scope.createNewActionList = function () {
                        newActionListCreated = true;                 
                        scope.templates = [];
                        var templateTypes = ["SmartActionList"];
                        boardService.getTemplates(templateTypes).then(function (templates) {
                            if (templates && templates.length > 0) {
                                scope.templates = templates;
                                var standardList = {
                                    id: -1,
                                    name: $translate.instant('COMMON_STANDARD'),
                                    description: $translate.instant('DASHBOARD_SMARTACTIONLIST_CREATE_DESCRIPTION')
                                }
                                //var standardTemplate = {template:standardList};
                                scope.templates.unshift(standardList);
                            } else {
                                scope.hasBoard = true;
                                createTable();
                            }
                        });
                    }

                    scope.createFromTemplate = function (template) {
                        scope.hasBoard = true;
                        if (template.id < 0) { //standard 
                            createTable();
                        } else { // one of the saved templates
                            var options = {
                                title: template.name,
                                description: template.description,
                                assetId: scope.assetId,
                                isPublic: false
                            };
                            smartActionListService().createSmartActionListFromTemplate(template.id, options).then(function (smartActionList) {
                                scope.isLoading = false;
                                scope.boardId = smartActionList.board.id;
                                scope.smartActionList = smartActionList;
                                scope.title = smartActionList.board.name;
                                boardService.getUserListForBoard(scope.boardId).then(function (data) {
                                    scope.users = data;
                                    setupTable();
                                    scope.updateSettings();
                                    // Trigger time view
                                    setTimeBoardModel();
                                });
                            });
                        }
                    }

                    scope.linkExistingActionList = function (board) {
                        if (!board) return;
                        scope.hasBoard = true;
                        scope.boardId = board.id;
                        scope.title = board.name;
                        loadsmartActionList(board.id);
                        scope.updateSettings();
                    }

                    scope.subscribe("TaskUpdated", function (task) {
                        var isPartOfTasks = scope.tasks.filter(t => t.id == task.id)
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            current.color = task.color;
                            current.siblingId = task.siblingId;
                            setTaskStyle(current);
                        }
                    });

                    scope.subscribe('TaskCloned', function (clone) {
                        if (clone.boardId === scope.boardId) {
                            calculateCells(clone, scope.columns);
                            scope.tasks.push(clone);
                            if (scope.timeboardEnabled) {
                                scope.taskAdded = clone;
                            }
                        }
                    });

                    scope.subscribe('TaskArchived', function (archivedtaskid) {
                        var isPartOfTasks = scope.tasks.filter(t => t.id == archivedtaskid);
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            var index = scope.tasks.indexOf(current);
                            scope.tasks.splice(index, 1);
                            scope.taskDeleted = current;
                        }
                    });

                    scope.subscribe('TaskMovedFromArchive', function (unarchivedtask) {
                        var isPartOfTasks = scope.tasks.filter(t => t.id == unarchivedtask.id);
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            var index = scope.tasks.indexOf(current);
                            scope.tasks.splice(index, 1);
                        }
                    });

                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        var isPartOfTasks = scope.tasks.filter(t => t.id == task.id)
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            var index = scope.tasks.indexOf(current);
                            scope.tasks.splice(index, 1);
                        } else if( task.boardId === scope.boardId ) {
                            calculateCells(task, scope.columns);
                            scope.tasks.push(task);
                            if (scope.timeboardEnabled) {
                                scope.taskAdded = task;
                            }
                        }
                    });

                    scope.subscribe('TaskChangedBoard', function (change) {
                        if(change.toBoardId === null || change.toBoardId === 0){
                            removeTaskFromBoard(change.taskId);
                        } else if (change.fromBoardId === scope.boardId && change.toBoardId !== scope.boardId) {
                            removeTaskFromBoard(change.taskId)
                        } else if (change.toBoardId === scope.boardId && change.fromBoardId !== scope.boardId) {
                            var isPartOfTasks = scope.tasks.filter(t => t.id == change.taskId);
                            if (isPartOfTasks.length == 0) {
                                boardTaskService().get(change.taskId).then(function (task) {
                                    calculateCells(task, scope.columns);
                                    scope.tasks.push(task);
                                    if (scope.timeboardEnabled) {
                                        scope.taskAdded = task;
                                    }
                                })         
                            }
                        }
                    });
                    
                    function removeTaskFromBoard(taskId){
                        var isPartOfTasks = scope.tasks.filter(t => t.id == taskId);
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            var index = scope.tasks.indexOf(current);
                            scope.tasks.splice(index, 1);
                        }
                    }

                    scope.$watch("isAdminMode", function () {

                    });
                    scope.changeTheme = function (theme) {
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    function loadsmartActionList(boardId) {
                        scope.hasBoard = true;
                        scope.boardId = boardId;
                        scope.isLoading = true;
                        smartActionListService().get(boardId).then(function (smartActionList) {
                            scope.smartActionList = smartActionList;
                            setupTable();
                            setTimeout(function () {
                                scope.isLoading = false;
                                resizeDebounced();
                                setColumnSorting();
                            }, 500);

                            // Trigger time view
                            setTimeBoardModel();
                            checkFavorite();
                            
                        })

                    }

                    scope.changeDateColor = function (col) {

                        if (col.settings.changeColor) {
                            if (!col.settings.changeColorXDaysBefore) {
                                col.settings.changeColorXDaysBefore = 1;
                            }
                            var payload = {
                                columnId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            scope.publish("SmartDateColorCellUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        } else {
                            col.settings.changeColor = false;
                            var payload = {
                                columnId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            scope.publish("SmartDateColorCellUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        }
                    }

                    scope.updateChangeColorXDaysBefore = function (col) {
                        col.settings.changeColor = true;
                        var payload = {
                            columnId: col.id,
                            changeColor: col.settings.changeColor,
                            changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                        }
                        scope.publish("SmartDateColorCellUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })

                    }


                    var getArchivedTasks = function () {
                        smartActionListService().getArchivedTasks(boardId).then(function (archivedtasks) {
                            scope.tasks = archivedtasks;

                            if (scope.tasks) {
                                for (var i = 0; i < scope.tasks.length; i++) {
                                    var task = scope.tasks[i];
                                    calculateCells(task, scope.columns);
                                    scope.publish("SmartActionListArchiveChanged", boardId);
                                }
                            }
                            setKanbanModel();
                            setTimeBoardModel();
                        })

                    }

                    scope.toggleArchive = function () {
                        scope.showArchive = !scope.showArchive;
                        if (scope.showArchive) {
                            getArchivedTasks()
                        } else {
                            loadsmartActionList(scope.boardId);
                        }
                        scope.timeBoardOptions = {
                            showTeamMembers: false,
                            showHeader: false,
                            useActionList: true,
                            showArchive: scope.showArchive
                        }
                    }

                    function setupTable() {
                        scope.columns = scope.smartActionList.columns;
                        scope.tasks = scope.smartActionList.tasks;
                        if (scope.tasks) {
                            for (var i = 0; i < scope.tasks.length; i++) {
                                var task = scope.tasks[i];
                                setTaskStyle(task);
                                calculateCells(task, scope.columns)
                            }
                        }
                    }

                    function setColumnSorting(){
                        if(!scope.columns) return;
                        if(scope.settingsSortColumn){
                            const foundColumn = scope.columns.find(c => c.id == scope.settingsSortColumn.id);
                            if (foundColumn) {
                                scope.sortColumnIndex = scope.columns.indexOf(foundColumn);
                                foundColumn.sort = scope.settingsSortColumn.sort;
                                if (foundColumn.sort == "asc") {
                                    scope.tasks = orderByFilter(scope.tasks, getSortValue, false);
                                }
                                if (foundColumn.sort == "desc") {
                                    scope.tasks = orderByFilter(scope.tasks, getSortValue, true);
                                }
                            }
                        }
                    }

                    function setTaskStyle(task) {
                        // Try to match fillColor
                        var predefinedPostIt = scope.postIts.find(p => p.fillColor == task.color)
                        if (predefinedPostIt) {
                            task.borderColor = predefinedPostIt.background
                        } else {
                            // Try to match background color
                            var backgroundPostIt = scope.postIts.find(p => p.background == task.color)
                            if (backgroundPostIt) {
                                task.borderColor = backgroundPostIt.background;
                            } else {
                                task.borderColor = scope.borderColor
                            }
                        }
                    }

                    function createTable() {
                        scope.isLoading = true;
                        var savetemplate = {
                            board: {
                                name: $translate.instant('DASHBOARD_SMARTACTIONLIST'),
                                assetId: scope.assetId,
                                boardType: "SmartActionList",
                                settings: JSON.stringify({
                                    IsWeeklyBoard: false
                                })
                            },
                            columns: [
                                {
                                    name: $translate.instant("COMMON_TITLE"),
                                    sortOrder: 0,
                                    attributeType: "title"
                                },
                                {
                                    name: $translate.instant("COMMON_RESPONSIBLE"),
                                    sortOrder: 1,
                                    attributeType: "responsible"
                                },
                                {
                                    name: $translate.instant("PROJECT_START_DATE"),
                                    sortOrder: 2,
                                    attributeType: "startdate"
                                },
                                {
                                    name: $translate.instant("COMMON_DUE_DATE"),
                                    sortOrder: 3,
                                    attributeType: "duedate"
                                },
                                {
                                    name: $translate.instant("COMMON_STATUS"),
                                    sortOrder: 4,
                                    attributeType: "status"
                                }],
                            tasks: [
                                {
                                    sortOrder: 0,
                                    title: $translate.instant("BOARD_EXAMPLE_TASK_1")
                                },
                                {
                                    sortOrder: 1,
                                    title: $translate.instant("BOARD_EXAMPLE_TASK_2")
                                }
                            ],
                            cells: [
                            ]
                        };
                        smartActionListService().createsmartActionList(savetemplate).then(function (smartActionList) {
                            scope.isLoading = false;
                            scope.boardId = smartActionList.board.id;
                            scope.smartActionList = smartActionList;
                            scope.title = smartActionList.board.name;
                            scope.updateSettings();
                            boardService.getUserListForBoard(scope.boardId).then(function (data) {
                                scope.users = data;
                                setupTable();
                               
                                // Trigger time view
                                setTimeBoardModel();
                            });

                        });
                    }

                    scope.updateBoardTitle = function () {
                        if (!scope.isStandAlone) {
                            scope.updateSettings();
                        }

                        var boardName = {
                            boardId: scope.boardId,
                            name: scope.title
                        }

                        boardService.updateName(scope.boardId, boardName).then(function (smartActionList) {
                        });
                    }

                    scope.updateSettings = function () {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                title: scope.title,
                                boardId: scope.boardId,
                                showAddButton: scope.showAddButton,
                                timeBoardOptions: scope.timeBoardOptions,
                                theme: scope.theme,
                                viewMode: scope.viewMode,
                                settingsSortColumn: scope.settingsSortColumn,
                                zoom: scope.zoom
                            }
                            if (!scope.isStandAlone) {
                                // Sync widget settings
                                scope.settingsChangeHandler({ settings: componentSettings })
                            }
                            // Save as board settings
                            boardService.updateSettings(scope.boardId, componentSettings)
                        }
                    }

                    scope.toggleButton = function () {
                        scope.updateSettings();
                    }

                    scope.$on("widget-resized", function (event, args) {
                        resizeDebounced();
                    });

                    function resize() {
                        if (!scope.useResizing) return;
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 60;
                        var container = $(elem).find(".project-board-container");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                        // Yearlyboard
                        var smartKanban = $(elem).find(".smart-kanban");
                        if (smartKanban.length === 0) return;
                        headerHeight = headerHeight + 50;
                        $(smartKanban).css("height", (height - headerHeight));
                        // Yearlyboard
                        var yearlyBoardcontainer = $(elem).find(".yearly-board-content-container");
                        if (yearlyBoardcontainer.length === 0) return;
                        headerHeight = headerHeight + 100;
                        $(yearlyBoardcontainer).css("height", (height - headerHeight));
                    }

                    const resizeDebounced = debounce(resize, 100, false)
                    var calculateCells = function (task, columns) {
                        task.cellModels = [];
                        for (let index = 0; index < columns.length; index++) {
                            const column = columns[index];
                            var cellModel = {
                                task: task,
                                column: column,
                                cell: null
                            }
                            var hasCell = task.cells.filter(c => c.smartTableColumnId == column.id);
                            if (hasCell.length > 0) {
                                var cell = hasCell[0];
                                if (cell.value) {
                                    cell.value = JSON.parse(cell.value);
                                }
                                cellModel.cell = cell;
                            } else {
                                // Create default cell
                                cellModel.cell = {
                                    id: 0,
                                    boardTaskId: task.id,
                                    smartTableColumnId: column.id,
                                    value: null
                                }
                            }
                            
                            task.cellModels.push(cellModel)
                        }
                    }
                    var addColumnTotasks = function (column) {
                        for (let index = 0; index < scope.tasks.length; index++) {
                            var task = scope.tasks[index];
                            var cellModel = {
                                task: task,
                                column: column,
                                cell: null
                            }
                            // Create default attribute
                            cellModel.cell = {
                                id: 0,
                                boardTaskId: task.id,
                                smartTableColumnId: column.id,
                                value: null
                            }
                            task.cellModels.push(cellModel)
                        }
                    }

                    scope.columnNameUpdated = function (column) {
                        smartActionListService().updateColumn(column).then(function (attribute) {
                        })
                    }
                    scope.predefinedIcons = function (col) {
                        if (!col.settings) {
                            col.settings = {};
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'predefinedIcons.html',
                            controller: 'predefinedIcons',
                            resolve: {
                                labels: function () {
                                    return col.settings.predefinedIcons;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (!col.settings) {
                                col.settings = {};
                            }
                            col.settings.predefinedIcons = result;
                            var payload = {
                                type: 'smartactionlist',
                                columnId: col.id,
                                predefinedIcons: col.settings.predefinedIcons
                            }
                            scope.publish("SmartIconCellSelectorUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                                refreshTimeBoardModel();
                            })

                        });
                    }
                    scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                if (!col.settings) {
                                    col.settings = {};
                                }
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    columnId: col.id,
                                    predefinedLabels: col.settings.predefinedLabels,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                scope.publish("SmartActionListTextCellLabelUpdated", payload);
                                smartActionListService().updateColumn(col).then(function (attribute) {
                                    refreshTimeBoardModel();
                                })

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                columnId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            scope.publish("SmartActionListTextCellLabelUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                                refreshTimeBoardModel();
                            })
                        }
                    }

                    

                    function createNewTask(taskSortOrder){
                        var task = {
                            id: 0,
                            boardId: scope.boardId,
                            title: "",
                            text: "",
                            status: 'blank',
                            tags: "",
                            color:"#e6e9ed",
                            sortOrder: taskSortOrder
                        }

                        var options = {
                            users: scope.users,
                            showDueDate: true
                        }

                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            if(taskSortOrder === 0){
                                var has = scope.tasks.filter(t => t.id == result.id);
                                if (has.length === 0) {
                                    scope.task.unshift(result);
                                }
                                saveTaskSortOrder();
                            }
                        });
                    }

                    scope.newTask = function () {
                        createNewTask(scope.columns.length);
                    }

                    scope.newTaskFirstInList = function () {
                        createNewTask(0);
                    }

                    scope.deleteBoard = function () {
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.ADMIN_DELETED_BOARD,
                                headerText: translations.COMMON_DELETE + ' ' + scope.title + '?',
                                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardService.deleteBoard(boardId).then(function (result) {
                                    if(!(result && result.status == 400)){
                                        $state.go('flexiboards');
                                        scope.publish("SendBoardDeleted", boardId);
                                    }
                                });
                            });

                        });
                    }
                    scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        } else {
                            var headertext = translationService.getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var column = {
                                boardId: scope.boardId,
                                name: headertext,
                                attributeType: type,
                                sortOrder: scope.columns.length
                            }
                            smartActionListService().addColumn(column).then(function (savedAttribute) {

                            })

                        }
                    }
                    scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                            var payload = {
                                columnId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            scope.publish("SmartActionListNumberCellAutomaticTimeframeUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {

                            })

                        });

                    }
                    scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }
                    scope.delete = function (task) {
                        boardTaskService().deleteTask(task.id).then(function (task) {
                        });
                    }

                    scope.deleteColumn = function (column) {
                        smartActionListService().deleteColumn(column.id);
                    }

                    scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.projectAttributes.length; i++) {
                            var value = colDef.projectAttributes[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    function addNumberAutomaticColumn(type) {

                        var dataSource = null;
                        var filters = null;
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return false;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                            var timePeriod = {
                                timeframe: "all",
                                period: timeService.getTimePeriod("all")
                            }

                            var unit = null;
                            if (result.dataSource.valueElement) {
                                unit = result.dataSource.valueElement.unit;
                            }
                            var cellSettings = JSON.stringify({
                                dataSource: result.dataSource,
                                timePeriod: timePeriod,
                                filters: result.filters,
                                aggregateType: "SUM",
                                unit: unit,
                                unitPlacement: "right"
                            })
                            var column = {
                                boardId: scope.boardId,
                                name: result.dataSource.title,
                                attributeType: type,
                                sortOrder: scope.columns.length - 1,
                                settings: cellSettings,
                            }
                            smartActionListService().addColumn(column).then(function (savedAttribute) {

                            })

                        });
                    };

                    scope.updateAggregateType = function (col) {
                        var payload = {
                            columnId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        scope.publish("SmartActionListNumberCellAggregateTypeUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnit = function (col) {
                        var payload = {
                            columnId: col.id,
                            unit: col.settings.unit
                        }
                        scope.publish("SmartActionListNumberCellUnitUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnitPlacement = function (col) {
                        var payload = {
                            columnId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        scope.publish("SmartActionListNumberCellUnitPlacementUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };

                    // TODO: FIX THIS Bakend/Frontend
                    scope.updateDecimals = function (col) {
                        var payload = {
                            decimals: col.settings.decimals,
                            smartActionListColumnId: col.id
                        }
                        // Use this event in order to reuse the project elements components
                        scope.publish("SmartActionListNumberCellDecimalUpdated", payload);


                        smartActionListService().updateColumn(col).then(function (attribute) {

                        })

                    };

                    scope.edit = function () {
                        var options = {
                            targetType : "Board",
                            targetId : scope.boardId
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardSettings.html',
                            controller: 'boardSettingsController',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            scope.title = result.name;
                            getAllUsers();
                            if (!scope.isStandAlone) {
                                scope.updateSettings();
                            }
                        }, function () {
                
                        });
                        
                    }

                    scope.cellChangeHandler = function (cell) {
                        if (cell.value) {
                            cell.value = JSON.stringify(cell.value);
                        }

                        smartActionListService().updateCell(cell).then(function (savedCell) {
                            // Parse value again
                            cell.id = savedCell.id;
                            if (savedCell.value) {
                                cell.value = JSON.parse(savedCell.value);
                            }
                            // We need to notify yearlyboard
                            //if (scope.timeboardEnabled) {
                                scope.cellUpdated = cell;
                            //}

                        });
                    }

                    scope.subscribe("TaskDeleted", function (taskId) {
                        var isPartOfTasks = scope.tasks.filter(t => t.id == taskId);
                        if (isPartOfTasks.length > 0) {
                            var current = isPartOfTasks[0];
                            var index = scope.tasks.indexOf(current);
                            scope.tasks.splice(index, 1);
                            scope.taskDeleted = current;
                        }
                    });

                    scope.subscribe("SmartActionListColumnUpdated", function (updatedColumn) {
                        if (updatedColumn.boardId === scope.boardId) {
                            var hasColumn = scope.columns.filter(c => c.id == updatedColumn.id);
                            if (hasColumn.length > 0) {
                                var column = hasColumn[0];
                                if (column.name !== updatedColumn.name) {
                                    column.name = updatedColumn.name;
                                }
                                if (updatedColumn.settings) {
                                    column.settings = JSON.parse(updatedColumn.settings);
                                }
                            }
                        }
                    });
                    scope.subscribe("SmartActionListColumnAdded", function (column) {
                        if (column.boardId === scope.boardId) {
                            var hasColumn = scope.columns.filter(c => c.id == column.id);
                            if (hasColumn.length === 0) {
                                addColumnTotasks(column);
                                scope.columns.push(column);
                            }
                        }
                    });
                    scope.subscribe("SmartActionListColumnDeleted", function (column) {
                        if (column.boardId === scope.boardId) {
                            loadsmartActionList(scope.boardId);
                        }
                    });
                    scope.subscribe("SmartActionListtaskDeleted", function (task) {
                        var isPartOfBoard = scope.tasks.filter(t => t.id == task.id);
                        if (isPartOfBoard && isPartOfBoard.length === 1) {
                            var tabletask = isPartOfBoard[0];
                            tabletask.cssState = "animated slideOutRight"; // Add animation
                            var index = scope.tasks.indexOf(tabletask);
                            if (index > -1) {
                                scope.tasks.splice(index, 1);
                            }

                        }
                    });

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        const task = scope.tasks.find(t => t.id == cell.boardTaskId);
                        if (task) {
                            const cellModel = task.cells.find(c => c.smartTableColumnId == cell.smartTableColumnId);
                            if (cellModel) {
                                cellModel.value = cell.value;
                                // We need to notify yearlyboard 
                                /* Currently yearly board subscribe the event SmartActionListCellUpdated and do not use this logic since it was not reliable
                                if (scope.timeboardEnabled) {
                                    scope.cellUpdated = cell;
                                }*/
                            }

                        }
                    });
                    

                    scope.subscribe("NewTask", function (task) {
                        if (task.boardId === scope.boardId) {
                            const hasTask = scope.tasks.find(t => t.id == task.id);
                            if (!hasTask) {
                                calculateCells(task, scope.columns);
                                if(task.sortOrder === 0){
                                    scope.tasks.unshift(task);
                                } else {
                                    scope.tasks.push(task);
                                }         
                                if (scope.timeboardEnabled) {
                                    scope.taskAdded = task;
                                }
                            }
                        }
                    });


                    scope.subscribe("SmartActionListSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.boardId) {
                            loadsmartActionList(scope.boardId);
                        }
                    });
                    scope.subscribe("SmartActionListRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.boardId) {
                            for (var i = 0; i < scope.tasks.length; i++) {
                                var task = scope.tasks[i];
                                var updatedSortOrder = payLoad.sortOrders.filter(s => s.boardTaskId == task.id );
                                if (updatedSortOrder.length > 0) {
                                    task.sortOrder = updatedSortOrder[0].sortOrder;
                                }
                            }
                            scope.tasks = orderByFilter(scope.tasks, 'sortOrder');
                        }
                    });
         
                    // Drag and drop of columns events
                    scope.$on('column-header-bag.drop-model', function (e, el) {
                        if(!scope.editable) return;
                        var columnOrder = scope.columns;
                        var sortOrder = [];
                        for (var i = 0; i < scope.columns.length; i++) {
                            var column = scope.columns[i];
                            var columnOrder = {
                                smartActionListColumnId: column.id,
                                sortOrder: i
                            }
                            sortOrder.push(columnOrder)
                        }
                        smartActionListService().updateColumnSortOrder(scope.boardId, sortOrder).then(function () {

                        });

                    });
                    scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });

                    scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');

                    });
                    scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });

                    scope.columnWidthChangeHandler = function(col){
                        if(col){
                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        }
                    }

                    function saveTaskSortOrder(){
                        if(!scope.editable) return;
                        var taskOrder = scope.tasks;
                        var sortOrder = [];
                        for (var i = 0; i < scope.tasks.length; i++) {
                            var task = scope.tasks[i];
                            var taskOrder = {
                                boardTaskId: task.id,
                                sortOrder: i
                            }
                            sortOrder.push(taskOrder)
                        }
                        smartActionListService().updateTaskSortOrder(scope.boardId, sortOrder).then(function () {

                        });
                    }
                    // Drag and drop of tasks events
                    scope.$on('task-header-bag.drop-model', function (e, el) {
                        saveTaskSortOrder();
                    });

                    scope.addToFavorites = function () {
                        scope.isStar = true
                        const board = {
                            id: scope.boardId,
                            name: scope.title,
                            boardType: 'SmartActionList'
                        }
                        scope.quickAccess.push(board);
                        saveQuickAccess()
                    }
                    scope.removeFromFavorites = function () {
                        scope.isStar = false
                        const isFavorite = scope.quickAccess.find(q => q.id == scope.boardId)
                        if (isFavorite) {
                            const currentIndex = scope.quickAccess.indexOf(isFavorite)
                            scope.quickAccess.splice(currentIndex, 1)
                            saveQuickAccess()
                        }
                    }

                    scope.getColStyle = getColStyle
                    function saveQuickAccess() {
                        employeeService.updateUserPreference("FlexiboardFavoriteBoards", scope.quickAccess).then(function (settings) {
                            scope.publish("FlexiboardFavoriteBoardsChanged", scope.quickAccess)
                        })
                    }

                    function checkFavorite(){
                        employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                            if (settings && settings.value) {
                                angular.forEach(settings.value, function (board) {
                                    if (board) {
                                        if(board.id == scope.boardId){
                                            scope.isStar = true;
                                        }
                                        scope.quickAccess.push(board);
                                    }
                                });
                            }
                        });
                    }

                }
            }
        }]);

