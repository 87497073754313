<script setup lang="ts">
    import { PropType, ref, watch } from "vue"
    import { FileInfo } from "@api"
    import { Theme } from "@common/model/types"
    import { getFileUrl } from "@common/services/documentService"
    import { translateLabel } from "@common/services/language/DigiLeanLang"
    import { PdfZoomOptions } from "@common/components/viewers/pdfViewer"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    import { settingsPdfApp } from "./pdfDisplayer"

    const vCustomEventsEmitter = new CustomEventsEmitter()

    let popupShow = ref(false)
    const togglePopup = () => {
        popupShow.value = !popupShow.value
    }

    const props = defineProps({
        settings: { type: Object as PropType<settingsPdfApp>, required: true },
        adminmode: { type: Boolean, required: false, default: false }
    })
    const internal = ref<settingsPdfApp>({
        title: "",
        pdfFileName: "",
        pdfFileInfoId: "",
        theme: { background: "var(--digilean-primary-text)", color: "inherit", fillColor: "var(--digilean-secondary-background)" }
    })
    const pdfSasUrl = ref("")
    const setPdfSasUrl = async () => {
        const { pdfFileInfoId, pdfFileName } = internal.value
        const sasUrl = await getFileUrl(pdfFileName, pdfFileInfoId)
        pdfSasUrl.value = sasUrl
    }

    let placeholderLbl = ref("")
    translateLabel("COMMON_EDIT_TITLE").then(label => placeholderLbl.value = label)

    watch(() => props.settings, (s) => {
        if (!s) return
        internal.value = {
            title: s.title ?? "",
            pdfFileName: s.pdfFileName ?? "",
            pdfFileInfoId: s.pdfFileInfoId ?? ""
        }
        if (s.theme)
            internal.value.theme = s.theme
        if (s.pdfZoomOption)
            internal.value.pdfZoomOption = s.pdfZoomOption
        if (s.pdfFileInfoId)
            setPdfSasUrl()
    })

    const updateTitle = (e: Event) => {
        const title = (e.target as HTMLInputElement).value
        internal.value.title = title
    }
    const fileUploaded = (e: CustomEvent) => {
        const files = e.detail as FileInfo[]
        const file = files[0]
        internal.value.pdfFileName = file.fileName!
        internal.value.pdfFileInfoId = file.id!
        setPdfSasUrl()
        popupShow.value = false
        updateSettings()
    }
    const zoomOptionChanged = (e: CustomEvent) => {
        const option = e.detail as PdfZoomOptions
        internal.value.pdfZoomOption = option
        updateSettings()
    }
    const updateTheme = (e: CustomEvent) => {
        const theme = e.detail as Theme
        internal.value.theme = theme
        popupShow.value = false
        updateSettings()
    }
    const updateSettings = () => {
        if (internal.value)
            vCustomEventsEmitter.emit("settings-changed", { settings: internal.value })
    }
</script>
<style>
    #wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .header {
        display: flex;
        flex-direction: column;
    }
    .title {
        padding: 14px 15px 7px;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
    }

    digilean-input {
        width: 100px;
    }

    h5 {
        font-size: 1.4rem;
        margin: 0 0 7px;
        padding: 0;
        font-weight: 600;
    }

    digilean-popup {
        margin-top: 0.5rem;
        --digilean-popup-width: 305px;
    }

    .menu {
        background: var(--digilean-secondary-background);
    }

    pdf-viewer {
        flex: 1 1 auto;
    }

    .well {
        background: var(--digilean-primary-background);
        margin: 1px;
        padding: 19px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
    }

    hr {
        margin: 3rem 0;
        border: 0;
        height: 0;
        border-bottom: 1px solid #e5e5e5
    }

    input {
        font-weight: 600;
        flex: 0 1 189px;
        width: 189px;
    }
</style>
<template>
    <link href="/assets/global.css" rel="stylesheet">
    <div v-custom-events-emitter id="wrapper">
        <div class="header" :style="{background: internal.theme?.fillColor, color: internal.theme?.background}">
            
            <div v-if="!adminmode" class="title">
                <h5>{{internal.title}}</h5>
            </div>

            <div v-if="adminmode" class="title">
                <input type="text" class="form-control" @blur="updateSettings()" @change="updateTitle"
                    .value="internal.title" :placeholder="placeholderLbl" />

                <digilean-popup .show="popupShow">
                    <fa-icon icon="fa fa-wrench" slot="button" @click="togglePopup"></fa-icon>
                    <div class="menu" slot="content">
                        <div class="well">
                            <file-uploader @files-uploaded="fileUploaded" accept=".pdf">
                            </file-uploader>
                        </div>
                        <hr>
                        <theme-selector @theme-selected="updateTheme"></theme-selector>
                    </div>
                </digilean-popup>
            </div>
        </div>
        
        <pdf-viewer v-if="pdfSasUrl" .url="pdfSasUrl" .zoomoption="internal.pdfZoomOption"
            @zoom-option-changed="zoomOptionChanged">
        </pdf-viewer>
    </div>
</template>
