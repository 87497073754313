import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .controller("projectDetailsController", ['$scope', '$state', '$stateParams', '$filter', '$uibModal', '$translate', 'authService','projectService','navigationService',
        function ($scope, $state, $stateParams, $filter, $uibModal, $translate, authService, projectService, navigationService) {

            $scope.project = null;
            $scope.customers = null;
            $scope.owner = {};
            $scope.selectedStatusOption = {};

            $scope.projectId = parseInt($stateParams.id);
            $scope.canEdit = false;
            $scope.titlehaserror = false;
            $scope.participants = [];

            $scope.isStar = false;
            $scope.quickAccess = [];

            $scope.imageId = null;
            $scope.editImage = false;
            $scope.imageUrl = null;

            $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
            $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
            
            $scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
                $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");  
            });
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            // if page is refreshed we need to refresh
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            });
            if($scope.isAdmin){
                $scope.canEdit = true;
            }

            $scope.settingProjectCategory =
                {
                    groupColumn: "problemCategoryId",
                    stackingColumn: "status",
                    filters: [{
                        sourceColumn: "projectId",
                        operator: "InList",
                        items: [$scope.projectId]
                    }]
                }
            get();

            $scope.editProject = function () {
                $state.go('selectedProject', { id: $scope.projectId }, { reload: false });
            }

            


            $scope.setImageViewMode = function () {
                $scope.editImage = false;
            };
            $scope.setImageEditMode = function () {
                $scope.editImage = true;
            };

            projectService().getUsers($scope.projectId).then(function (projectUsers) {
                if (projectUsers) {
                    $scope.users = projectUsers;

                }
            });
            function get() {
                projectService().hasAccess($scope.projectId).then(function (hasAccess) {
                    if (hasAccess) {
                        projectService().getProjectInfo($scope.projectId).then(function (project) {
                            $scope.project = project;
        
                            if(!$scope.canEdit){
                                var userId = authService.getCurrentUser().userId;
                                if($scope.project.ownerUserId == userId){
                                    //project owner may edit
                                    $scope.canEdit = true;
                                }
                            }
                                
                            $scope.imageId = $scope.project.imageId;
                            $scope.imageUrl = $scope.project.imagePreviewUrl;
                            if (!$scope.imageUrl) {
                                $scope.editImage = true;
                            }
        
                            $scope.statusOptions = projectService().getStatus();
                            $scope.selectedStatusOption = $filter('filter')($scope.statusOptions, { dbCode: $scope.project.status }, true)[0];
        
                            setProjectCustomer();
                            checkFavorite();
                        });
                    } else {
                        swal({
                            title: $translate.instant("COMMON_NO_ACCESS"),
                            text: $translate.instant("COMMON_REQUIRED_GROUP_MEMBERSHIP"),
                            icon: "warning",
                            buttons: { ok: { text: $translate.instant('COMMON_OK'), className: "sa-digilean-button" } },
                            dangerMode: true,
                            closeOnConfirm: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        });
                    }
                });           
            }

            function setProjectCustomer() {
                if ($scope.project && $scope.project.projectCustomer && $scope.customers) {
                    var matching = $filter('filter')($scope.customers, { id: $scope.project.projectCustomerId }, true);
                    if (matching.length > 0) {
                        $scope.selectedCustomer = matching[0];

                    }
                }

            }

            $scope.editMilestones = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'projectMilestoneListForm.html',
                    controller: 'projectMilestoneListModalController',
                    resolve: {
                        projectId: function () {
                            return $scope.projectId;
                        }
                    }
                });
            }
    
            
            

            projectService().getProjectCustomers().then(function (customers) {
                $scope.customers = customers;
                setProjectCustomer();
            });

            $scope.addToFavorites = function () {
                $scope.isStar = true;
                var project = {
                    id: $scope.projectId,
                    name: $scope.project.name
                };
                $scope.quickAccess.push(project);
                saveQuickAccess();
            }
            $scope.removeFromFavorites = function () {
                $scope.isStar = false;
                var isFavorite = $filter('filter')($scope.quickAccess, { id: $scope.projectId })
                if (isFavorite.length > 0) {
                    var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                    $scope.quickAccess.splice(currentIndex, 1);
                    saveQuickAccess();
                }
            }
            function saveQuickAccess() {
                employeeService.updateUserPreference("ProjectFavorites", $scope.quickAccess).then(function (settings) {
                    $scope.publish("ProjectFavoritesChanged", $scope.quickAccess);
                });
            }

            function checkFavorite(){
                employeeService.getUserPreference("ProjectFavorites").then(function (settings) {
                    if (settings && settings.value) {
                        angular.forEach(settings.value, function (project) {
                            if (project) {
                                if(project.id == $scope.projectId){
                                    $scope.isStar = true;
                                }
                                $scope.quickAccess.push(project);
                            }
                        });
                    }
                });
            }
        }]);
