import * as employeeService from "@common/services/employeeService"

var DigiLean = angular.module("DigiLean");
DigiLean.controller('dailyfeedActivitySelector', ['$scope', '$filter', '$translate', '$uibModalInstance', 'validEvents',
    function ($scope, $filter, $translate, $uibModalInstance, validEvents) {
        $scope.validEvents = validEvents;

        function init(){
            resetAllEvents();
            if($scope.validEvents){
                for(var e=0; e < $scope.allEvents.length; e++){
                    var matchingActivity = $filter('filter')($scope.validEvents, $scope.allEvents[e].id, true);
                    if (matchingActivity.length > 0) {
                        $scope.allEvents[e].include = true;
                    } else {
                        $scope.allEvents[e].include = false;
                    }
                }
            }
        }
        init();

        // REMEMBER: to update dailyfeedController.allEvents if modified
        function resetAllEvents(){
            $scope.allEvents = [ 
                {id: "BoardCreation",
                include: true,
                module: "Board",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_BOARD')},
                {id: "BoardDeleted",
                include: true,
                module: "Board",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_BOARD')},
                {id: "TaskComment",
                include: true,
                module: "Board",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_COMMENTED_ON_TASK')},
                {id: "NewTask",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_TASK')},
                {id: "DeleteTask",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_TASK')},
                {id: "TaskAssigned",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_ASSIGNED_TASK')},
                {id: "TaskUpdated",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_UPDATED_TASK')},
                {id: "TaskStatusUpdated",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_COMPLETED_TASK')},
                {id: "TaskCloned",
                module: "Board",
                include: true,
                translation: $translate.instant('BOARD_CLONED_TASK')},
                {id: "TaskRepeated",
                module: "Board",
                include: true,
                translation: $translate.instant('BOARD_REPEATED_TASK')},
                {id: "TaskMoved",
                module: "Board",
                include: true,
                translation: $translate.instant('COMMON_MOVED_TASK')},
                {id: "TaskMovedToActionList",
                module: "Board",
                include: true,
                translation: $translate.instant('COMMON_MOVED_TASK_TO_ACTIONLIST')},
                {id: "TaskMovedFromActionListToBoard",
                module: "Board",
                include: true,
                translation: $translate.instant('COMMON_MOVED_TASK_FROM_ACTIONLIST_TO_BOARD')},
                {id: "TaskCreatedToActionList",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_ACTIONLIST_TASK')},
                {id: "TaskAttachmentAdded",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_ADDED_ATTACHMENT_ON_TASK')},
                {id: "TaskAttachmentDeleted",
                module: "Board",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_ATTACHMENT_ON_TASK')},
                {id: "TaskDeviationCreated",
                module: "Board",
                include: true,
                translation: $translate.instant('BOARD_CREATED_DEVIATION_TASK')},

                {id: "NewImprovement",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_NEW_SUGGESTION')},
                {id: "ImprovementDeleted",
                include: true,
                module: "Improvement",
                translation: $translate.instant('IMPROVEMENT_IMPROVEMENT_DELETED')},
                {id: "NewAttachment",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_ADDED_ATTACHMENT_ON_SUGGESTION')},
                {id: "DeleteAttachment",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_ATTACHMENT_ON_SUGGESTION')},
                {id: "UpdatedStatusSuggestion",
                include: true,
                module: "Improvement",
                translation: $translate.instant('COMMON_UPDATED_STATUS')},
                {id: "UpdatedCategorySuggestion",
                include: true,
                module: "Improvement",
                translation: $translate.instant('COMMON_UPDATED_CATEGORY')},
                {id: "UpdatedPrioritySuggestion",
                include: true,
                module: "Improvement",
                translation: $translate.instant('IMPROVEMENT_CHANGED_PRIORITY')},
                {id: "ImprovementDueDateChanged",
                include: true,
                module: "Improvement",
                translation: $translate.instant('COMMON_UPDATED_DUE_DATE')},
                {id: "UpdatedResponsibleSuggestion",
                include: true,
                module: "Improvement",
                translation: $translate.instant('COMMON_CHANGED_RESPONSIBLE')},
                {id: "ImprovementComment",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_COMMENTED_ON_SUGGESTION')},
                {id: "ImprovementLikeAdded",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_LIKED_SUGGESTION')},
                {id: "UpdatedSuggestion",
                include: true,
                module: "Improvement",
                translation: $translate.instant('DASHBOARD_DAILY_FEED_UPDATED_SUGGESTION')},
                
                {id: "NewDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_NEW_DEVIATION')},
                {id: "DeviationDeleted",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DEVIATION_DEVIATION_DELETED')},
                {id: "UpdatedResponsibleDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('COMMON_CHANGED_RESPONSIBLE')},
                {id: "UpdatedDueDateDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('COMMON_UPDATED_DUE_DATE')},
                {id: "UpdatedProblemReasonDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('ADMIN_MESSAGE_CAUSE_UPDATED')},
                {id: "DeviationAttachmentAdded",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_ADDED_ATTACHMENT_ON_DEVIATION')},
                {id: "DeviationAttachmentDeleted",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_ATTACHMENT_FROM_DEVIATION')},
                {id: "UpdatedStatusDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('COMMON_UPDATED_STATUS')},
                {id: "UpdatedSeverityDeviation",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DEVIATION_CHANGED_SEVERITY')},
                {id: "DeviationUpdated",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_UPDATED_DEVIATION')},
                {id: "DeviationComment",
                module: "Deviation",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_COMMENTED_ON_DEVIATION')},
          
                {id: "A3Created",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_A3')},
                {id: "A3Updated",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_UPDATED_A3')},            
                {id: "ImprovementActivatedToA3",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_A3_FROM_IMPROVEMENT')},
                {id: "DeviationActivatedToA3",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_A3_FROM_DEVIATION')},
                {id: "A3VersionCreated",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_CREATED_A3_VERSION')},
                {id: "A3StepUpdated",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_UPDATED_A3_STEP')},
                {id: "A3Deleted",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_DELETED_A3')},
                {id: "A3StepCommentedOn",
                module: "A3",
                include: true,
                translation: $translate.instant('DASHBOARD_DAILY_FEED_COMMENTED_ON_A3')},
            
            ];
        }
            

        $scope.isSupportedEvent  = function(activity) {
            for (var i = 0; i < $scope.validEvents.length; i++) {
                if (activity === $scope.validEvents[i]) return true;   
            }
            return false;
        };

        $scope.showAllActivities = function () {
            resetAllEvents();
            $scope.validEvents = null;
        };

        $scope.toggleActivity = function (activity) {
            if($scope.validEvents){ //allready filtered events
                var matchingActivity = $filter('filter')($scope.validEvents, activity, true);
                if (matchingActivity.length > 0) {
                    var i = $scope.validEvents.indexOf(matchingActivity[0]);
                    $scope.validEvents.splice(i, 1);
                } else {
                    $scope.validEvents.push(activity);
                }
            } else { //first filtered event
                $scope.validEvents = [];
                for(var e=0; e < $scope.allEvents.length; e++){
                    if($scope.allEvents[e].include){
                        $scope.validEvents.push($scope.allEvents[e].id); 
                    }
                }     
            }
        };
        
        $scope.ok = function () {
            employeeService.getUserSettings().then(function (userSettings) {
                userSettings.feed = $scope.validEvents;
                employeeService.updateUserSettings(userSettings).then(function () {
                    $scope.publish("UserSettingsChanged", userSettings);
                });
            });
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);