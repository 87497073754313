
import angular from "angular"
import { NgComponent } from "@common/model/angularModel"
import { getUserProfile } from "@common/stores/userStore"
import * as helpSearchService from "./helpSearchService"
import {SearchResult, Article} from "./SearchResult"
import config from "@common/config"

const DigiLean = angular.module('DigiLean')
DigiLean.component('helpSearch', {
    templateUrl: "helpSearch.html",
    controller: ['$sce', 'learningPublicService', function(this:NgComponent,$sce, learningPublicService) {
        let $ctrl = this as NgComponent

        $ctrl.isLoading = false
        $ctrl.showArticle = false
        $ctrl.userName = getUserProfile().firstName
        $ctrl.showBackBtn = false
        $ctrl.showResult = true;
        $ctrl.articles = null;
        $ctrl.articlePageId = null;
        $ctrl.articleUrl = ""
        $ctrl.showClearBtn = false
        $ctrl.inputValue = '';
        $ctrl.learningMaterials = []

        let searchEl: HTMLInputElement
        let resultEl: HTMLDivElement
        let popoverEl: HTMLDivElement
        let footerEl: HTMLDivElement

        $ctrl.$onInit = async function() {
            resultEl = document.querySelector("#search-result")!    
            popoverEl = document.querySelector("#help-search-popover")!
            footerEl = document.querySelector("#article-footer")!
            searchEl = document.querySelector("digilean-input#search-input")!
            $ctrl.learningMaterials = await learningPublicService.getPublishedMaterials()
        }

        $ctrl.search = async function() {
            console.log("search")
            $ctrl.isLoading = true
            const searchPhrase = searchEl.value           
            $ctrl.response = null;
            $ctrl.showClearBtn = true
            try {
                let response = await helpSearchService.postQuestion(searchPhrase) as SearchResult
                $ctrl.response = response
            }
            catch(error)
            {
                $ctrl.answer = "Error fetching data"
            }
            finally{
                $ctrl.isLoading = false
                $ctrl.showResult = true
                resultEl.innerHTML = $ctrl.response.summary + "<br><p style=\"text-align: center;\">The summary is generated by AI.</p><br><br<br>Relevant links to DigiLEANS learning material: </p>"

            }
        }

        $ctrl.backBtnClicked = function() {
            $ctrl.showBackBtn = false
            $ctrl.showArticle = false
            $ctrl.showResult = true
        }

        $ctrl.clearBtnClicked = function() {
            $ctrl.showClearBtn = false
            $ctrl.showResult = false
            searchEl.value = ""
        }

        $ctrl.closePopover = function()
        {
            popoverEl.hidePopover();
        }
        
        $ctrl.viewArticle = async function (article:Article) {
                console.log(article)
                $ctrl.articlePageId = article.pageId
                $ctrl.showArticle = true
                $ctrl.showResult = false
                $ctrl.showBackBtn = true
                let url = `${config.websiteUrl}/#/learning/external/${article.learningMaterialId}?pageid=0`
                let foundArticle = $ctrl.learningMaterials.find(l => l.id === article.learningMaterialId)
                if(foundArticle)
                {
                    footerEl.innerHTML = `Link to this article's full learning material: <br><a href="${url}" target="_blank">${foundArticle.title}</a>`
                }
            }
    }]
})

