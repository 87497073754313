import angular from "angular"
import {html} from "@common/services/template/templateLiteral"
import '@common/shared/dates/datePicker.css'
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module("DigiLean")
DigiLean.component("datePickerSlot", 
{
    transclude: true,
    bindings: {
        'isDisabled': '<',
        'dateValue': '<',
    },
    controller: ['$element', function(this: NgComponent, $element) {
        let $ctrl = this
        
        $ctrl.dateOptions = {
            startingDay: 1 //ISO week
        }

        $ctrl.open = function($event) {
            $ctrl.state.opened = true
            $event.stopPropagation()
        }

        $ctrl.state = { opened: false }

        $ctrl.dateUpdated = function() {
            dispatchDateChangedEvent()
        }
        function dispatchDateChangedEvent() {
            const options = {
                detail: $ctrl.dateValue,
                bubbles: true,
                composed: true
            }
            $element[0].dispatchEvent(new CustomEvent("date-changed", options))
        }
    }],
    template: html`
        <div
            class="digilean-datepicker-slot"
            aria-hidden="true"
            ng-disabled="$ctrl.isDisabled"
            is-open="$ctrl.state.opened"
            
            uib-datepicker-popup
            datepicker-append-to-body="true"
            datepicker-options="$ctrl.dateOptions"

            ng-model="$ctrl.dateValue"
            ng-model-options="{updateOn: 'blur'}" 

            ng-click="$ctrl.open($event)"
            ng-change="$ctrl.dateUpdated()"
            
            current-text="{{ 'COMMON_TODAY' | translate }}"
            clear-text="{{ 'COMMON_CLEAR' | translate }}"
            close-text="{{ 'COMMON_CLOSE' | translate }}"
            ng-transclude
        >
        </div>
    `
})