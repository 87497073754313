import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "div-spinner"
}
const _hoisted_2 = [".spin"]
const _hoisted_3 = [".log"]

import {ref, onMounted} from "vue"
    import { JobRun, LogMessage } from "@api";
    import { PropType } from "vue"
    import { refreshRunLog } from "@common/components/monitoring/integrations/loggMonitor"
    import { getLastErrors } from "@common/services/data/monitoringService"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'MonitorJobLog',
  props: {
        run: { type: Object as PropType<JobRun> },
        connectorid: { type: String },
        days: { type: Number }
    },
  setup(__props) {


    // CE monitor-job-log
    const props = __props

    const logs = ref<LogMessage[] | null>(null)
    let loading = ref(false)

    const getLogs = async () => {
        loading.value = true
        if (props.run) {
            const logsloaded = await refreshRunLog(props.run)
            logs.value = logsloaded
        } else if (props.connectorid) {
            const errors = await getLastErrors(props.connectorid, props.days)
            logs.value = errors
        }
        loading.value = false
    }
    onMounted(() => {
        getLogs()
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, [
      _createElementVNode("translate-label", { label: "ADMIN_DATA_JOB_SHOWLOGS" }),
      _createTextVNode(": ")
    ], -1 /* HOISTED */)),
    (_unref(loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("spinner-icon", { ".spin": _unref(loading) }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logs.value, (log) => {
      return (_openBlock(), _createElementBlock("logmessage-viewer", { ".log": log }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3))
    }), 256 /* UNKEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}
}

})