import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"

angular
    .module('DigiLean')
    .directive("webViewer", ['$sce',
        function ($sce) {
            return {
                templateUrl: 'webviewer.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {

                    console.log("webviewer link")
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (scope.settings) {
                            scope.title = settings.title;
                            scope.url = settings.url;
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                        }
                        
                    });

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }
                    
                    scope.updateSettings = function () {
                        // fix url
                        var url = scope.url;
                        url = url.replace(/&amp;/g, '&');
                        scope.url = url;
                        var componentSettings = {
                            title: scope.title,
                            url: scope.url,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }
                    
                    //scope.$on("widget-resized", function (event, args) {
                        //resizeFrame()
                    //})

                }
            }
        }]);
