import { PDFDocumentLoadingTask, PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist"
import { DocumentInitParameters, RenderParameters, TypedArray } from "pdfjs-dist/types/src/display/api"
import { GlobalWorkerOptionsType } from "pdfjs-dist/types/src/display/worker_options"

const workerSrc = "/assets/pdf.worker.min.js"
const cmapsSrc = "/cmaps/"

interface IPdfLib {
    getDocument: (src: string | URL | TypedArray | ArrayBuffer | DocumentInitParameters) => PDFDocumentLoadingTask
    GlobalWorkerOptions: GlobalWorkerOptionsType
}

let loadingPromise: Promise<IPdfLib> | null

const loadLib = async () => {
    if (!loadingPromise)
        loadingPromise = import("pdfjs-dist")
    
    const pdfLib = await loadingPromise
    pdfLib.GlobalWorkerOptions.workerSrc = workerSrc
    return pdfLib
}

const readPdf = async (url: string) => {
    const pdf = await loadLib()
    
    const loadingTask = pdf!.getDocument({
        url,
        cMapUrl: cmapsSrc,
        cMapPacked: true,
        enableXfa: true
    })

    const pdfDocument = await loadingTask.promise
    return pdfDocument
}

type PdfOrientation = "portrait" | "landscape"
interface ContainerSize {
    width: number
    height: number
}

export enum PdfZoomOptions {
    pageFit = "pageFit",
    pageWidth = "pageWidth",
    pageHeight = "pageHeight"
}

export class DigiPdfViewer {
    private currentPdfDocument: PDFDocumentProxy | null = null

    private url: string
    private canvas: HTMLCanvasElement
    private context: CanvasRenderingContext2D

    private alreadyLoading = false
    private currentZoomOption = PdfZoomOptions.pageFit
    private currentSize: ContainerSize = {width: 0, height: 0}

    orientation: PdfOrientation = "portrait"
    numberOfPages = 1

    constructor(url: string, canvas: HTMLCanvasElement) {
        this.url = url
        this.canvas = canvas
        this.context = canvas.getContext("2d") as CanvasRenderingContext2D
    }
    
    private currentPage: PDFPageProxy | null = null

    async renderPage(pageNo: number, containerSize: ContainerSize, zoomOption = PdfZoomOptions.pageFit) {
        if (!this.currentPdfDocument)
            this.currentPdfDocument = await readPdf(this.url)

        if (this.alreadyLoading)
            return // abort
        this.alreadyLoading = true

        this.numberOfPages = this.currentPdfDocument.numPages

        this.currentPage = await this.currentPdfDocument.getPage(pageNo)

        let scale = this.getNewScale(containerSize, zoomOption)
            
        const pageViewport = this.currentPage.getViewport({ scale })
        
        this.canvas.width = pageViewport.width
        this.canvas.height = pageViewport.height

        const renderContext: RenderParameters = {
            canvasContext: this.context,
            viewport: pageViewport
        }
        this.currentPage.render(renderContext)
        this.alreadyLoading = false
    }

    resizeCurrentPage(containerSize: ContainerSize, zoomOption = PdfZoomOptions.pageFit) {
        if (!this.currentPage) {
            return
        }
        
        if (this.alreadyLoading) {
            return // abort
        }
        
        if (this.currentSize.width === containerSize.width && this.currentSize.height === containerSize.height && this.currentZoomOption === zoomOption) {
            return
        }
        this.alreadyLoading = true
        
        let scale = this.getNewScale(containerSize, zoomOption)
        
        const pageViewport = this.currentPage.getViewport({ scale })

        this.canvas.width = pageViewport.width
        this.canvas.height = pageViewport.height

        const renderContext: RenderParameters = {
            canvasContext: this.context,
            viewport: pageViewport
        }
        this.currentPage.render(renderContext)
        this.alreadyLoading = false
    }

    static scrollWidth = 17
    getNewScale(containerSize: ContainerSize, zoomOption = PdfZoomOptions.pageFit) {
        if (!this.currentPage)
            return 1

        this.currentSize = containerSize
        this.currentZoomOption = zoomOption
        let pageViewport = this.currentPage.getViewport({ scale: 1 })
        
        let scaleHeight = (containerSize.height - DigiPdfViewer.scrollWidth) / pageViewport.height
        let scaleWidth = (containerSize.width - DigiPdfViewer.scrollWidth) / pageViewport.width

        if (zoomOption == PdfZoomOptions.pageFit)
            return Math.min(scaleHeight, scaleWidth)
        if (zoomOption == PdfZoomOptions.pageHeight)
            return scaleHeight
        else if (zoomOption == PdfZoomOptions.pageWidth)
            return scaleWidth
        
        return this.orientation == "landscape" ? scaleWidth : scaleHeight
    }
    /// always run this when done
    dispose() {
        if (this.currentPage)
            this.currentPage.cleanup()
        if (this.currentPdfDocument)
            this.currentPdfDocument.destroy()
    }
}