import { getFileType } from "@common/services/filesService"

angular
    .module('DigiLean')
    .directive("taskAttachment",
        function ($) {
            return {
                templateUrl: 'taskattachment.html',
                restrict: 'E',
                scope: {
                    'file': '<',
                },
                link: function (scope, elem, attrs) {
                    
                    console.log("taskAttachments really in use?")
                    scope.$watch('file', function (file) {
                        // apply
                        if (!file) return;
                        if (file.validFileUrl) return;
                        var fileType = getFileType(file.fileExtension)
                        if (fileType) {
                            file.fileType = fileType.type
                        } 
                    });
                }
            }
        });
