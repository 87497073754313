angular
    .module('DigiLean').directive("learningPage", ['$filter', '$translate', 'learningService', 'learningPublicService', 'debounce',
        function ($filter, $translate, learningService, learningPublicService, debounce) {
            return {
                templateUrl: 'learningPage.html',
                restrict: 'E',
                scope: {
                    'pageId': '<',
                    'isEditMode': '<',
                    'isExternal': '<',
                    'hideFooter': '<'
                },
                link: function (scope, elem, attrs) {
                    
                    scope.pageTitle = {
                        isEditMode : false
                    }


                    scope.sectionsSortableOption = {
                        handle: ".drag-handle",
                        placeholder: "section-sortable-placeholder",
                        stop: sortOrderChanged
                    };


                    scope.subscribe('LearningItemTitleChanged', function (titleChanged) {
                        if (!scope.page) return;
                        if (scope.page.id == titleChanged.itemId) {
                            scope.page.title = titleChanged.title;
                        }
                    });


                    scope.$watch("pageId", function (pageId) {
                        if (!scope.pageId) return;
                        loadPage(pageId);
                    });
                    scope.title = "";

                    scope.$watch("isEditMode", function (isEditMode) {
                        if (!scope.isEditMode) {
                            setAllSectionsEditMode(false);
                            scope.pageTitle.isEditMode = false;
                        };
                    });

    
                    scope.pageTitleChanged = function (page) {
                        scope.title = page.title;
                        learningService().updateItemTitle(page.id, page.title).then(function (itemTitle) {

                        });
                    }

                    scope.setSectionViewMode = function (section) {
                        section.isEditMode = false;
                    }
                    scope.setSectionEditMode = function (section) {
                        if(section.sectionType == "image"){
                            for (var index = 0; index < scope.page.sections.length; index++) {
                                var tempsection = scope.page.sections[index];
                                if(tempsection.sectionType == "image"){
                                    tempsection.isEditMode = false;
                                }
                            }
                        }
                        section.isEditMode = true;
                    }


                    scope.removeSection = function (section) {

                        var index = scope.page.sections.indexOf(section);
                        scope.page.sections.splice(index, 1);
                        learningService().deleteSection(section.id).then(function () {

                        });
                    }
                    scope.addSection = function (sectionType) {
                        var section = {
                            learningItemId: scope.pageId,
                            sectionType: sectionType,
                        };
                        learningService().createSection(section).then(function (savedSection) {
                            savedSection.isEditMode = true;
                            scope.page.sections.push(savedSection);
                            updatePageSectionSortOrder(scope.page.sections);
                        })
                    }

                    function loadPage(pageId) {
                        learningPublicService.getPage(scope.isExternal, pageId).then(function (page) {
                            scope.page = page;
                            scope.title = page.title;
                            setAllSectionsEditMode(false);
                            // If only one section which is text than set it to edit mode directly
                            if (scope.isEditMode) {
                                if (scope.page.sections.length == 1 && scope.page.sections[0].sectionType == "text") {
                                    scope.page.sections[0].isEditMode = true;
                                }
                            } 
                            
                            window.scrollTo(0, 0);
                        });
                    }

                    function setAllSectionsEditMode(isEditMode) {
                        if (!scope.page) return;
                        for (let index = 0; index < scope.page.sections.length; index++) {
                            const section = scope.page.sections[index];
                            section.isEditMode = isEditMode;
                        }
                    }

                    function sortOrderChanged(e, ui) {

                        var sections = ui.item.sortable.droptargetModel;
                        updatePageSectionSortOrder(sections);
                    }

                    function updatePageSectionSortOrder(sections) {
                        var pageSectionSortOrder = {
                            pageId: scope.pageId,
                            sortOrders: []
                        }
                        var index = 0;
                        sections.forEach(section => {
                            pageSectionSortOrder.sortOrders.push({
                                id: section.id,
                                sortOrder: index
                            });
                            index += 1;
                        });
                        learningService().updatePageSectionSortOrder(pageSectionSortOrder);
                    }
                }

            }
        }]);
