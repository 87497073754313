import * as boardService from "@common/services/boardService"
import { getUserProfile } from "@common/stores/userStore"
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('newBoardTaskController', ['$scope', '$filter', '$uibModalInstance', '$translate','boardTaskService', 'task', 'options', 'taskDrawingService',
    function ($scope, $filter, $uibModalInstance, $translate, boardTaskService, task, options, taskDrawingService) {
        $scope.task = task;
        $scope.subTasks = [];
        $scope.uploadedFiles = [];
        $scope.users = [];

        $scope.isProcessing = false;
        $scope.showDueDate = false;
        $scope.boardoptions = {
            mandatory: true
        }
        

        $scope.background = "var(--digilean-secondary-background)"; //"#ffffff";
        $scope.customColorBackground = "#a9a9a9";

         
        $scope.selectUser = function (user) {

            $scope.task.responsibleImageUrl = user.profileImageUrl;
            $scope.task.responsibleUserId = user.userId;
            $scope.task.responsibleUser = user.fullName;
            $scope.task.responsibleDisplayName = user.displayName;
            if(user.userId == ''){
                $scope.task.user = null;    
            } else {
                $scope.task.user = user;
            }
        }

        
        if (task.boardDate) {
            // Make sure boardDate is date object 
            task.boardDate = moment(task.boardDate).toDate();
        }
        
       if (options) {
            $scope.options = options;
            if ($scope.options == null) return;
            if ($scope.options.showDueDate) {
                $scope.showDueDate = true;
            }
            if ($scope.options.users) {
                $scope.users = $scope.options.users;
            }
            if ($scope.options.postIts) {
                $scope.postIts = $scope.options.postIts;
                setPostitTooltip();
            }
            if ($scope.options.canChangeBoard) {
                $scope.canChangeBoard = true;
                getBoards();
            }
            if ($scope.options.selectedUser) {
                $scope.selectUser($scope.options.selectedUser);
            } 
            
            if(!$scope.postIts || $scope.postIts.length == 0){
                $scope.postIts = taskDrawingService().getPostIts();
            }
            if (task.color) {
                $scope.background = task.color;
                $scope.taskBorderStyle = {
                    "border-bottom" : borderStyle +  $scope.background
                }
                var predefinedPostIt = $filter('filter')($scope.postIts, { background: $scope.background });
                if(predefinedPostIt.length > 0){
                   setTimeout(function () {
                    $scope.selectColor(predefinedPostIt[0]);
                    }, 10);
                } else //Custom color 
                {
                    $scope.selectedPostIt = {title:"custom", background: $scope.customColorBackground};
                    //$scope.customColorBackground  = task.color;
                }
            }
        };
        
        $scope.emptyUser = {
            id: '',
            userName: null,
            profileImageUrl: null,
            fullName: null,
            displayName: null
        }
        
        $scope.descriptionChanged = function (content) {
            $scope.task.text = content;
        }
        
        // colors
        var borderStyle = "6px solid";
        $scope.taskBorderStyle = {
            "border-bottom" : borderStyle +  "#ffffff"
        }
        $scope.selectColor = function (postIt) {
            $scope.selectedPostIt = postIt;
            $scope.background = postIt.background;
            $scope.taskBorderStyle = {
                "border-bottom" : borderStyle +  $scope.background
            }
            if(postIt.tags){
                setTimeout(function () {
                    $("#taskTags").tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $('#tagsSelected')
                    });
                    var tagArray = postIt.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                }, 10);
            }
        }

        function setPostitTooltip(){
            $scope.postIts.forEach(color => {
                if(color.tags && color.tags.length >0){
                    color.tooltip = getTagsDescription(color);
                } else {
                    color.tooltip = "";
                }
            }); 
        }
        function getTagsDescription(postIt){
            var tooltip = "";
            if(postIt.tags){
                for (var i = 0; i < postIt.tags.length; i++) {
                    if(i>0){
                        tooltip = tooltip.concat(', ');
                    }
                    tooltip = tooltip.concat(postIt.tags[i]);
                }
            }
            return tooltip;
        }
        $scope.getUserProfile = function () {
            const currentUser = getUserProfile()
            $scope.selectedEmployee = currentUser;
            $scope.selectUser(currentUser);
        }
        
        function getBoards(){
            if($scope.boards) return;
            boardService.getMemberBoards().then(function (data) {
                $scope.boards = data;
                var personalBoard = {
                    name: $translate.instant('BOARD_PERSONAL_BOARD'),
                    id: null,
                    boardType: 'PERSONAL'
                }
                $scope.boards.unshift(personalBoard);
                $scope.task.board = personalBoard;
                if(!$scope.options.selectedUser){ // If user is not preselected, select current user
                    $scope.getUserProfile();
                }     
            });
        }
        $scope.selectCustomColor = function (color) {
            $scope.selectedPostIt = {title:"custom", background: color};
            $scope.background = color;
            $scope.taskBorderStyle = {
                "border-bottom" : borderStyle +  $scope.background
            }
        }

        $scope.boardDateChanged = function($event) {
            const date = $event.originalEvent.detail
            $scope.task.boardDate = date
        }


        // Init tags and focus (without this the textfield is 2px)
        setTimeout(function () {
            $("#taskTags").tagit({
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
            var tagArray = $scope.task.tags.split(",");
            for (var i = 0; i < tagArray.length; i++) {
                $("#taskTags").tagit('createTag', tagArray[i]);
            }
        }, 50);

        $scope.ok = function () {
            $scope.isProcessing = true;

            if($scope.task.boardDate)
            {
                var utcDate = moment($scope.task.boardDate)
                .hour(12)
                .minute(0)
                .second(0)
                .millisecond(0)
                .utc()
                .format();
                $scope.task.boardDate = utcDate
            }


            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all
                if(tagsArray[i]){ 
                    tags.push({
                        Id: 0,
                        Tag: tagsArray[i],
                        BoardTaskId: 0
                    });
                }
            }
            if($scope.task.selectedRow){
                $scope.task.rowCategoryId = $scope.task.selectedRow.id;
            }
            if($scope.task.selectedColumn){
                $scope.task.columnCategoryId = $scope.task.selectedColumn.id;
            }
            $scope.task.tags = tags;
            $scope.task.subTasks = $scope.subTasks;
            $scope.task.color = $scope.background;
            $scope.task.files = $scope.uploadedFiles;
            if (!$scope.task.boardId){
                $scope.task.boardId = null;
                $scope.task.board = null; 
            } 
            boardTaskService().addBoardTask($scope.task).then(function (createdTask) {
                $scope.isProcessing = false;
                $uibModalInstance.close(createdTask);
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);