import * as customerService from "@common/services/customers/customerService"
import * as documentService from "@common/services/documentService"

var DigiLean = angular.module('DigiLean')
DigiLean.controller('organizationAdminController', ['$scope','$stateParams', '$filter', '$translate', 'authService',
    function ($scope, $stateParams, $filter, $translate, authService) {

        $scope.isKaizenAdmin = authService.hasRole('KaizenAdmin')
        $scope.fileUrl = "";
        $scope.tabs = [
            {
                name: "billing",
                isActive: false,
                title: $translate.instant("ADMIN_BILLING_PLAN")
            },
            {
                name: "settings",
                isActive: false,
                title: $translate.instant("COMMON_SETTINGS")
            },{
                name: "logo",
                isActive: false,
                title: $translate.instant("COMMON_LOGO")
            },{
                name: "mesages",
                isActive: false,
                title: $translate.instant("COMMON_MESSAGES")
            },
            {
                name: "mail",
                isActive: false,
                title: $translate.instant("ADMIN_SEND_MAIL")
            }
            
            
            
        ];


        if ($scope.isKaizenAdmin){
            $scope.tabs.push({
                name: "push",
                isActive: false,
                title: $translate.instant("COMMON_PUSH_NOTIFICATIONS")
            });
        }

        
        $scope.selectedTab = null;
        $scope.selectedTabName = "";
        function getCurrentLogo() {
            customerService.getCurrent().then(function (customer) {
                var logo = customer.logo;
                if (logo) {
                    documentService.getFileUrl(logo.fileName).then(function(fileUrl) {
                        $scope.fileUrl = fileUrl;
                    });
                }
            });
        }

        getCurrentLogo();

        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            $scope.fileUrl = "";
            customerService.updateLogo(file.id).then(function () {
                getCurrentLogo();
            });
        };        

        $scope.selectTab = function (tab) {
            if ($scope.selectedTab) {
                $scope.selectedTab.isActive = false;
            }
            tab.isActive = true;
            $scope.selectedTab = tab;
            $scope.selectedTabName = tab.name;
        };

        // Select tab based on input or default first.
        if ($stateParams.tab) {
            var tab = $stateParams.tab;
            var hasTab = $filter('filter')($scope.tabs, { name: tab });
            if (hasTab.length === 1) {
                $scope.selectTab(hasTab[0]);
            } else {
                $scope.selectTab($scope.tabs[0]);
            }

        } else {
            $scope.selectTab($scope.tabs[0]);
        }
    }]);