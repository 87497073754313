import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"

@customElement('digilean-json-editor')
export class DigileanJsonEditor extends LitElement {
    
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: var(--digilean-primary-text);
        }

        * {
            box-sizing: border-box;
        }

        label {
            color: inherit;
            font-size: 0.8rem;
        }
        label.error {
            color: var(--digilean-warning);
        }
        label.valid {
            color: var(--digilean-success);
        }
        textarea {
            flex: 1 1 auto;
            background-color: var(--digilean-secondary-background);
            background-image: none;
            border: none;
            outline: 1px solid var(--digilean-input-border);
            border-radius: 1px;
            color: inherit;
            display: block;
            padding: 6px 12px;
            transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        }
        textarea:focus {
            outline-color: var(--digilean-blue-sharp);
        }
    `

    _jsonStringFormatted = ""
    
    @state()
    private _errorMsg = ""

    @property({attribute: true})
    set value(val: string | object) {
        if (typeof val === "object")
            val = JSON.stringify(val)
        this.validate(val)
    }
    
    get value() {
        if (!this._jsonStringFormatted)
            return ""
        const json = JSON.parse(this._jsonStringFormatted)
        const jsonStringFlat = JSON.stringify(json)
        return jsonStringFlat
    }

    @property({attribute: true})
    name = ""

    setJson(val: string) {
        this.validate(val)
    }

    sendEvent(name: string) {
        const evt = new Event(name, { bubbles: false, composed: true })
        this.dispatchEvent(evt)
    }
    validate(val: string) {
        let oldVal = this._errorMsg
        this._errorMsg = ""
        try {
            const json = JSON.parse(val)
            this._jsonStringFormatted = JSON.stringify(json, null, 2)
            this.sendEvent("change")
        }
        catch (error) {
            if (error instanceof Error) 
                this._errorMsg = error.message
            else
                this._errorMsg = String(error)
        }
        finally {
            this.requestUpdate("_errorMsg", oldVal)
        }
    }
    render() {
        const error = !!this._errorMsg
        
        return html`
            ${error ? html`
                <label class="error">${this._errorMsg}</label>
            ` : html`
                <label class="valid">Valid</label>
            `}
            
            <textarea id="${this.name}" 
                name="${this.name}" 
                cols="100"
                rows="20"
                @input=${(e) => this.setJson(e.target.value)}>${this._jsonStringFormatted}</textarea>
        `
    }
}
