
import * as timeService from "@common/services/timeService"
import * as employeeService from "@common/services/employeeService"

var DigiLean = angular.module("DigiLean");
DigiLean.controller('dailyFeedController', ['$rootScope', '$scope', '$uibModal', '$filter', '$translate', 'activityService', 'navigationService', 'deviationService', 'suggestionService',
    function ($rootScope, $scope, $uibModal, $filter, $translate, activityService, navigationService, deviationService, suggestionService) {
        $scope.isLoading = true;
        $scope.feed = [];
        $scope.items = []; //used by paging
        var translateOptions = {};
        $scope.translationData = {
            noOfFeeds: $scope.feed.length
        };

        // REMEMBER: to update dailyfeedActivitySelector.allEvents if modified
        var allEvents = ["BoardCreation",
            "BoardDeleted",
            "TaskComment",
            "TaskCloned",
            "TaskUpdated",
            "TaskRepeated",
            "NewTask",
            "DeleteTask",
            "TaskAssigned",
            "TaskStatusUpdated",
            "TaskDeviationCreated",
            "UpdatedResponsibleDeviation",
            "UpdatedDueDateDeviation",
            "UpdatedProblemReasonDeviation",
            "TaskMoved",
            "TaskMovedToActionList",
            "TaskCreatedToActionList",
            "TaskMovedFromActionListToBoard",
            "TaskAttachmentAdded",
            "TaskAttachmentDeleted",
            "NewSuggestion",
            "ImprovementDeleted",
            "NewImprovement",
            "NewAttachment",
            "DeleteAttachment",
            "DeviationAttachmentAdded",
            "DeviationAttachmentDeleted",
            "DeviationDeleted",
            "UpdatedStatusSuggestion",
            "UpdatedCategorySuggestion",
            "UpdatedPrioritySuggestion",
            "UpdatedResponsibleSuggestion",
            "UpdatedSuggestion",
            "ImprovementLikeAdded",
            "ImprovementComment",
            "ImprovementActivatedToA3",
            "ImprovementDueDateChanged",
            "A3Created",
            "A3Updated",
            "A3VersionCreated",
            "A3StepUpdated",
            "A3Deleted",
            "A3StepCommentedOn",
            "NewDeviation",
            "DeviationActivatedToA3",
            "UpdatedStatusDeviation",
            "UpdatedSeverityDeviation",
            "DeviationUpdated",
            "DeviationComment"];
        $scope.validEvents = allEvents;
        // DEFAULT TRANSLATION
        $translate(['COMMON_TIMEELAPSED_MONTH', 'COMMON_TIMEELAPSED_DAYS', 'COMMON_TIMEELAPSED_HOURS', 'COMMON_TIMEELAPSED_MINUTES', 'COMMON_TIMEELAPSED_SECONDS', 'COMMON_TIMEELAPSED_NOW']).then(function (translations) {
            translateOptions = {
                month: translations.COMMON_TIMEELAPSED_MONTH,
                days: translations.COMMON_TIMEELAPSED_DAYS,
                hours: translations.COMMON_TIMEELAPSED_HOURS,
                mins: translations.COMMON_TIMEELAPSED_MINUTES,
                seconds: translations.COMMON_TIMEELAPSED_SECONDS,
                now: translations.COMMON_TIMEELAPSED_NOW
            }
            // Now we can get data
            getSettings();

        });

        function getSettings() {
            employeeService.getUserSettings().then(function (userSettings) {
                if (userSettings) {
                    if (!userSettings.feed) {
                        $scope.validEvents = allEvents;
                    } else {
                        $scope.validEvents = userSettings.feed;
                    }
                } else {
                    $scope.validEvents = allEvents;
                }
                subscribeToEvents();
                getLatestActivity();
            });
        }

        var translateAndRecalculateTimeElapsed = function () {
            // Recalculate time elapsed
            $scope.feed.forEach(function (activity) {
                activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
            });
        }

        // When language has changed.
        $rootScope.$on('$translateChangeSuccess', function () {
            translateOptions = {
                month: $translate.instant('COMMON_TIMEELAPSED_MONTH'),
                days: $translate.instant('COMMON_TIMEELAPSED_DAYS'),
                hours: $translate.instant('COMMON_TIMEELAPSED_HOURS'),
                mins: $translate.instant('COMMON_TIMEELAPSED_MINUTES'),
                seconds: $translate.instant('COMMON_TIMEELAPSED_SECONDS'),
                now: $translate.instant('COMMON_TIMEELAPSED_NOW')
            }
            translateAndRecalculateTimeElapsed();
        });


        // Subscribe to Events
        function subscribeToEvents() {
            $scope.subscribe('ActivityOccured', function (activity) {
                // Handle activity and add to list. Need some filter for what is relevant.
                if (activity.assetId && !navigationService().hasAccessToAsset(activity.assetId)) {
                    return;
                }
                if (!$scope.isSupportedEvent(activity)) return;
                if (alreadyInList(activity)) return;
                if (isNotRelevant(activity)) {
                    return;
                }
                activity.cssState = "animated slideInDown";
                $scope.feed.unshift(activity);
                // Recalculate time elapsed
                $scope.feed.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                    if (activity.payLoad && typeof activity.payLoad === "string") {
                        activity.payLoad = JSON.parse(activity.payLoad);
                    }
                });
                // $scope.$apply();
                $scope.translationData.noOfFeeds = $scope.feed.length;
            });
        }

        $scope.subscribe('UserSettingsChanged', function (settings) {
            if (settings) {
                if (!settings.feed) {
                    $scope.validEvents = allEvents;
                } else {
                    $scope.validEvents = settings.feed;
                }
                // Now we can refresh data
                subscribeToEvents();
                getLatestActivity();
            }
        });


        // Valid event activities for user
        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            $scope.profile = profile;
            if (!profile.settings || !profile.settings.feed) {
                $scope.validEvents = allEvents;
            } else {
                $scope.validEvents = profile.settings.feed;
            }
            // Now we can get data
            subscribeToEvents();
            getLatestActivity();
        });


        $scope.openActivitySelector = function () {

            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'dailyfeedActivitySelector.html',
                controller: 'dailyfeedActivitySelector',
                resolve: {
                    validEvents: function () {
                        return $scope.validEvents;
                    }
                }
            });
        };
 
        function getLatestActivity() {
            // Handle activity and add to list. Need some filter for what is relevant.
            activityService().getDailyFeed($scope.validEvents).then(function (log) {
                $scope.feed = [];
                log.forEach(function (activity) {
                    if (!isNotRelevant(activity)) {
                        activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                        if (activity.payLoad && typeof activity.payLoad === "string") {
                            activity.payLoad = JSON.parse(activity.payLoad);
                        }
                        $scope.feed.push(activity);
                    }
                });
                $scope.isLoading = false;
                
                $scope.translationData.noOfFeeds = $scope.feed.length;
            });
        }

        $scope.isSupportedEvent = function (activity) {
            for (var i = 0; i < $scope.validEvents.length; i++) {
                if (activity.action === $scope.validEvents[i]) return true;
            }
            return false;
        };

        var isNotRelevant = function (activity) {
            // Private task
            if (!activity.assetId && activity.activity === "BoardTask") return true;
            return false;
        }
        var alreadyInList = function (activity) {
            // Private task
            var inList = $filter('filter')($scope.feed, { id: activity.id });
            return inList.length > 0;
        }

        $scope.openDeviation = function (id) {
            deviationService().get(id).then(function (deviation) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullDeviation.html',
                    controller: 'fullDeviationController',
                    windowClass: 'full-screen',
                    resolve: {
                        deviation: function () {
                            return deviation;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
            });
        };

        $scope.openImprovementSuggestion = function (id) {
            suggestionService().get(id).then(function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
            });
        };

        $scope.addSuggestionLike = function (id) {
            suggestionService().addLike(id).then(function (like) {

            });
        };

    }]);