import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = [".icon"]

import { computed } from "vue"

    const classBase =  "fa fa-redo fa-lg"
    
export default /*@__PURE__*/_defineComponent({
  __name: 'spinnerIcon',
  props: {
        spin: {
            type: Boolean,
            required: true,
            default: false
        }
    },
  setup(__props) {

    const props = __props

    const icon = computed(() => {
        if (props.spin)
            return `${classBase} fa-spin`
        return classBase
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("link", {
      href: "/assets/fontawesome.all.min.css",
      rel: "stylesheet"
    }, null, -1 /* HOISTED */)),
    _createElementVNode("fa-icon", { ".icon": icon.value }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})