import * as customerService from "@common/services/customers/customerService"
import * as dashboardService from "@common/services/dashboardService"
import * as staticFileService from "@common/shared/staticFileService"
import * as displayService from "@common/services/displayService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .controller("dashboardDesignerController", ['$scope', '$state', '$translate', '$filter', '$stateParams', '$uibModal', 'authService', 'modalService', 'debounce',
        function ($scope, $state, $translate, $filter, $stateParams, $uibModal, authService, modalService, debounce) {
            $scope.id = parseInt($stateParams.id);
            
            $scope.isInPresentationMode = false;
            var rotatePageTimer;
            var isRotatingPage = false;
            $scope.rotateIntervals = [
                {
                    title: $translate.instant("DASHBOARD_ROTATION_5_SECONDS"),
                    interval: 5000
                },
                {
                    title: $translate.instant("DASHBOARD_ROTATION_15_SECONDS"),
                    interval: 15000
                },
                {
                    title: $translate.instant("DASHBOARD_ROTATION_30_SECONDS"),
                    interval: 30000
                },
                {
                    title: $translate.instant("DASHBOARD_ROTATION_1_Minute"),
                    interval: 60000
                },
                {
                    title: $translate.instant("DASHBOARD_ROTATION_5_Minute"),
                    interval: 300000
                },
                {
                    title: $translate.instant("DASHBOARD_ROTATION_10_Minute"),
                    interval: 600000
                }
            ];

            $scope.selectedInterval = $scope.rotateIntervals[2]; // 30 sec default
            // Pick up settings from address bar
            if ($stateParams.interval) {
                var interval = parseInt($stateParams.interval);
                var selectedInterval = $filter("filter")($scope.rotateIntervals, { interval: interval });
                if (selectedInterval.length > 0) {
                    $scope.selectedInterval = selectedInterval[0];
                }
            }
            if ($stateParams.mode) {
                if ($stateParams.mode == "info") {
                    setTimeout(function() {
                        startPresentationView();
                    }, 100);
                    
                } else {
                    stopPresentationView();
                }
            } else {
                stopPresentationView();
            }
            
            $scope.isAdmin = authService.hasRole("Admin");
            $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
                
            });

            customerService.getCurrent().then(function (customer) {
                $scope.logoUrl = customer.logoUrl;
            });

            $scope.subscribe('DashboardUpdated', function (dashboard) {
                if ($scope.currentDashboard.id === dashboard.id) {
                    loadDashboard(dashboard, $scope.currentPage);
                    $scope.$apply();
                }
            });

            $scope.gridsterOptions = {  //margins: [20, 10],   (top, right, bottom, and left).
                margins: [20, 20],
                columns: 40,
                maxRows: 200,
                sparse: true, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
                swapping: false, // allow widgets of same size to swap place
                resizable: {
                    enabled: true,
                    handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
                    resize: widgetResizing, // optional callback fired when item is resized,
                    stop: widgetResized // optional callback fired when item is finished resizing
                },
                draggable: {
                    enabled: true, // whether dragging items is supported
                    handle: '.widget-drag-handle', // optional selector for drag handle
                    stop: function (event, $element, widget) {
                        $scope.save();
                    }
                }
            };
            $scope.isClosed = true; 
            $scope.isAdminMode = false;
            $scope.gridsterOptions.draggable.enabled = false;
            $scope.gridsterOptions.resizable.enabled = false;

            $scope.options = {
                timePeriod: {
                    timeframe: "none",
                    timeframes: ['all', 'last12mth', 'year', '30', 'year', 'month', 'previousmonth', 'thisandnextweek', 'isoweek', 'day', 'custom', 'none']
                }
            };

            $scope.currentIndex = null;


            $scope.periodChangedHandler = function (timePeriod) {
                $scope.options.timePeriod = timePeriod;

                if ($scope.isAdminMode) {
                    commitChanges();
                }
            };

            $scope.selectPage = function (page) {
                page.widgets.forEach(function (widget) {
                    if (widget.settings && typeof widget.settings === "string") {
                        widget.settings = JSON.parse(widget.settings);
                        console.log("parsing settings");
                        //widget.isAdminMode = $scope.isAdminMode;
                    }
                });
                $scope.currentPage = page;
            }


            $scope.giveWidgetFocus = function (widget) {
                var index = $scope.currentPage.widgets.indexOf(widget);
                $scope.currentPage.widgets.splice(index, 1);
                $scope.currentPage.widgets.push(widget);

            };


            
            $scope.changeRotateInterval = function (interval) {
                $scope.selectedInterval = interval;
                if (isRotatingPage) {
                    stopRotationOfPage();
                    startRotationOfPage();
                }
            }
            $scope.togglePresentationView = function () {
                $scope.isInPresentationMode = !$scope.isInPresentationMode;
                if ($scope.isInPresentationMode) {
                    startPresentationView();
                } else {
                    stopPresentationView();
                }
            }

            function startPresentationView() {
                $scope.isInPresentationMode = true;
                $("#page-wrapper").css("margin-left", 0);
                $("#page-navigation-menu").hide();
                $("#page-topnavbar").hide();
                $("#page-footer").hide();
                displayService.showFullScreen();
                // 
                $scope.publish("PresentationViewStarted");
                // rotate page
                startRotationOfPage();
            }

            function stopPresentationView() {
                $scope.isInPresentationMode = false;
                stopRotationOfPage();
                $("#page-wrapper").css("margin-left", '');
                $("#page-navigation-menu").show();
                $("#page-topnavbar").show();
                $("#page-footer").show();
                $scope.publish("PresentationViewStopped");
                displayService.hideFullScreen();
            }

            function setFocus() {
                //Set focus on dashboard
                var dashboard = document.querySelector("#dashboard")
                dashboard.focus();
            }

            function stopRotationOfPage() {
                if (rotatePageTimer) {
                    clearInterval(rotatePageTimer);
                }
                isRotatingPage = false;
            }
            function startRotationOfPage() {
                setFocus();
                rotatePageTimer = setInterval(function () {
                    if (!$scope.presentationPaused) {
                        var pages = $scope.currentDashboard.pages;
                        $scope.currentIndex = pages.indexOf($scope.currentPage);
                        $scope.currentIndex += 1;

                        $scope.selectPage(pages[Math.abs($scope.currentIndex % pages.length)]);
                    }
                }, $scope.selectedInterval.interval);
                isRotatingPage = true;
            }

            $scope.next = function () {
                var numberOfPages = $scope.currentDashboard.pages.length;
                $scope.currentIndex++;

                $scope.selectPage($scope.currentDashboard.pages[$scope.currentIndex % numberOfPages]);
            };

            $scope.previous = function () {
                var numberOfPages = $scope.currentDashboard.pages.length;
                $scope.currentIndex = $scope.currentIndex > 0 ? $scope.currentIndex - 1 : 0;
                $scope.selectPage($scope.currentDashboard.pages[$scope.currentIndex % numberOfPages]);
            };

            $scope.keyPress = function (keyEvent) {
                if (isRotatingPage) {
                    if (keyEvent.key == "ArrowRight") {
                        $scope.next();
                        keyEvent.preventDefault();
                    }

                    if (keyEvent.key == "ArrowLeft") {
                        $scope.previous();
                        keyEvent.preventDefault();
                    }

                    if (keyEvent.key == " " && isRotatingPage) {
                        $scope.presentationPaused = !$scope.presentationPaused;
                        keyEvent.preventDefault();
                    }
                }
            }

            $scope.delete = function () {
                $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_DASHBOARD_CONFIRMATION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.COMMON_DELETE,
                        headerText: translations.COMMON_DELETE + ' ' + $scope.currentDashboard.title + '?',
                        bodyText: translations.ADMIN_MESSAGE_DELETE_DASHBOARD_CONFIRMATION
                    };

                    modalService.showModal({}, modalOptions).then(function (result) {
                        dashboardService.deleteDashboard($scope.currentDashboard.id).then(function (data) {
                            $state.go('myspace');
                            $translate('ADMIN_MESSAGE_DASHBOARD_DELETED').then(function (msg) {
                                toastr.info(msg);
                            }, function (translationId) {
                                $scope.headline = translationId;
                            });
                        });
                    });
                });
            }

            $scope.remove = function (widget) {
                
                dashboardService.deleteWidget(widget.id).then(function (affected) {
                    if (affected === 1) {
                        var index = $scope.currentPage.widgets.indexOf(widget);
                        $scope.currentPage.widgets.splice(index, 1);
                    }
                    // All boards component also needs to be deleted? Esp smartactionlist
                    if (widget.component == 'smartactionlist') {
                        
                        var boardId = widget.settings.boardId;
                        boardService.deleteBoard(boardId)
                    }
                    
                });
            }
            $scope.changeApp = function (widget) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'appstore.html',
                    controller: 'appstoreController',
                    windowClass: 'newdeviation-modal-window',
                    resolve: {
                        //task: function () {
                        //    return task;
                        //}
                    }
                });

                modalInstance.result.then(function (result) {
                    widget.component = result.component;
                    widget.header = result.name;
                });
            };

            function loadDashboard(dashboard, preferredPage) {
                // $scope.currentDashboard = null;
                $scope.currentPage = null;
                // Parse settings on widget
                for (let i = 0; i<dashboard.pages.length; i++) {
                    let page = dashboard.pages[i];
                    for (let y = 0; y<page.widgets.length; y++) {
                        let widget = page.widgets[y];
                        if (widget.settings && typeof widget.settings === "string") {
                            widget.settings = JSON.parse(widget.settings);
                           // widget.settings.originalTimePeriod = widget.settings.timePeriod;
                        } else {
                            // try old settings
                            if ($scope.currentDashboard && $scope.currentDashboard.pages) {
                                const oldPage = $scope.currentDashboard.pages[i];
                                if (oldPage && oldPage.widgets) {
                                    const oldWidget = oldPage.widgets[y];
                                    if (oldWidget) {
                                        widget.settings = oldWidget.settings;
                                        continue;
                                    }
                                }
                                widget.settings = null;
                            }
                        }
                    }

                    if (page.settings) {
                        $scope.options = JSON.parse(page.settings);
                    }
                }
                
                $scope.currentDashboard = dashboard;
                if (preferredPage) {
                    var currentPage = $filter("filter")($scope.currentDashboard.pages, { id: preferredPage.id });
                    if (currentPage.length > 0) {
                        $scope.currentPage = currentPage[0];
                    } else {
                        $scope.currentPage = $scope.currentDashboard.pages[0];
                    }
                } else {
                    $scope.currentPage = $scope.currentDashboard.pages[0];
                }
            }
            function getDashboard() {
                dashboardService.get($scope.id).then(function (dashboard) {
                    loadDashboard(dashboard);
                });
            }
            getDashboard();

            
            $scope.updateTitle = function () {
                dashboardService.updateTitle($scope.currentDashboard.id,$scope.currentDashboard.title).then(function (saved) {
                });
            };

            $scope.save = function () {
                console.log("dashboard save called");
                $scope.commitChangesDebounced(); // Make sure we throttle, since so many events can call save.
            };

            var commitChanges = function () {
                console.log("changes commited");
                $scope.currentPage.settings = JSON.stringify($scope.options);
                $scope.currentDashboard.pages.forEach(function (page) {
                    page.widgets.forEach(function (widget) {
                        if (widget.settings && typeof widget.settings !== "string") {
                            widget.settings = JSON.stringify(widget.settings);
                        }
                    });
                });

                dashboardService.saveDashboard($scope.currentDashboard).then(function (savedDashboard) {
                    $scope.publish("SendDashboardUpdated", savedDashboard);
                });

                return true;
            };
            // Debounce function so that save is called to often.
            $scope.commitChangesDebounced = debounce(commitChanges, 2000, false);

            $scope.viewMode = function () {
                $scope.isAdminMode = false;
                $scope.isClosed = true;
                $scope.gridsterOptions.draggable.enabled = false;
                $scope.gridsterOptions.resizable.enabled = false;
                $("#pagelist").sortable("disable"); // Turn off sortable on pages
                var widgets = $(".widget");
                angular.forEach(widgets, function (widget, key) {
                    var gridsterContainerHeight = $(widget).closest(".gridster-item").height();
                    $(widget).height(gridsterContainerHeight);
                });
            };

            $scope.adminMode = function () {
                $scope.isAdminMode = true;
                $scope.isClosed = false;
                if ($scope.isInPresentationMode) {
                    $scope.togglePresentationView();
                    stopRotationOfPage();
                }
                $scope.gridsterOptions.draggable.enabled = true;
                $scope.gridsterOptions.resizable.enabled = true;
                // Adjust height of widgets
                resizeWidgetsHosts();
                addSortableOnPageList();
            };

            function addSortableOnPageList() {
                // add sortable
                setTimeout(function () {
                    //
                    // ^^^ this is required otherwise re-enabling sortable will not work!

                    $("#pagelist").sortable({
                        disabled: false,
                        update: pagesOrderChanged
                    });
                    $("#pagelist").disableSelection();
                }, 500);
            }

            var pagesOrderChanged = function (ui, sender) {
                var pageList = $("#pagelist li");
                angular.forEach(pageList, function (page, key) {
                    var pageId = $(page).data("pageid");
                    if (pageId) {
                        var currentPage = $filter("filter")($scope.currentDashboard.pages, { id: pageId })[0];
                        currentPage.sortOrder = key;
                        console.log(key + " pageid: " + pageId);
                    }
                });
                $scope.save();
            };

            var resizeWidgetsHosts = function () {
                var widgets = $(".widget");
                angular.forEach(widgets, function (widget, key) {
                    resizeWidgetHost(widget);
                });
            };

            $scope.addPage = function () {
                var page = {
                    dashboardId: $scope.currentDashboard.id,
                    title: "new page",
                    widgets: []
                };
                dashboardService.addPage(page).then(function (page) {
                    $scope.currentDashboard.pages.push(page);
                    $scope.currentPage = page;
                    toastr.success("Page added");
                    $scope.publish("SendDashboardUpdated", $scope.currentDashboard);
                });
            };

            $scope.removePage = function (page) {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Delete page',
                    headerText: 'Delete ' + page.title + '?',
                    bodyText: 'Are you sure you want to delete this page?'
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    dashboardService.deletePage(page.id).then(function (affected) {
                        $scope.publish("SendDashboardUpdated", $scope.currentDashboard);
                        // Reload dashboard after delete
                        getDashboard();
                    });
                });

            };
            $scope.cloneApp = function (widget) {
                dashboardService.copyWidget(widget.id).then(function (savedWidget) {
                    if (savedWidget.settings && typeof savedWidget.settings === "string") {
                        savedWidget.settings = JSON.parse(savedWidget.settings);
                    } else {
                        savedWidget.settings = null;
                    }
                    if ($scope.currentPage.widgets.length === 0) {
                        $scope.currentPage.widgets.push(savedWidget);
                    } else {
                        $scope.currentPage.widgets.unshift(savedWidget);
                    }
                    setTimeout(resizeWidgetsHosts, 200);
                    $scope.publish("SendDashboardUpdated", $scope.currentDashboard);
                });
            };

            var customized = "KPI";
            $scope.popularApps = [
                {
                    name: $translate.instant("DASHBOARD_SMARTACTIONLIST"),
                    component: "smartactionlist",
                    category: $translate.instant("COMMON_BOARD"),
                    imageUrl: staticFileService.image("smartactionlist.jpg"),
                    description:  $translate.instant("DASHBOARD_SMARTACTIONLIST_DESCRIPTION"),
                    defaultXSize: 30,
                    defaultYSize: 10
                }, 
                {
                    name: $translate.instant("DASHBOARD_SINGLE_VALUE"),
                    component: "singlevalue",
                    category: "KPI",
                    imageUrl: staticFileService.image("singlevalue.jpg"),
                    description: $translate.instant("DASHBOARD_SINGLE_VALUE_DESC"),
                    defaultXSize: 9,
                    defaultYSize: 4
                },
                {
                    name: $translate.instant("DASHBOARD_SINGLE_VALUE_TARGET"),
                    component: "singlevaluetarget",
                    category: "KPI",
                    imageUrl: staticFileService.image("singlevaluetarget.jpg"),
                    description: $translate.instant("DASHBOARD_SINGLE_VALUE_TARGET_DESC"),
                    defaultXSize: 9,
                    defaultYSize: 4
                },
                {
                    name: $translate.instant("DASHBOARD_TARGET_GRAPH"),
                    component: "targetgraph",
                    category: "KPI",
                    imageUrl: staticFileService.image("targetgraph.jpg"),
                    description: $translate.instant("DASHBOARD_TARGET_GRAPH_DESC"),
                    defaultXSize: 14,
                    defaultYSize: 14
                },
                {
                    name: $translate.instant("DASHBOARD_PIE_CHART"),
                    component: "piechart",
                    category: customized,
                    imageUrl: staticFileService.image('piechart.jpg'),
                    description: $translate.instant("DASHBOARD_PIE_CHART_DESC"),
                    defaultXSize: 12,
                    defaultYSize: 12
                },
                {
                    name: $translate.instant("DASHBOARD_COMPARE_CHART"),
                    component: "comparechart",
                    category: customized,
                    imageUrl: staticFileService.image("comparechart.jpg"),
                    description: $translate.instant("DASHBOARD_COMPARE_CHART_DESC"),
                    defaultXSize: 14,
                    defaultYSize: 14
                },
                {
                    name: $translate.instant("DASHBOARD_MULTI_CHART"),
                    component: "multichart",
                    category: customized,
                    imageUrl: staticFileService.image("multichart.jpg"),
                    description: $translate.instant("DASHBOARD_MULTI_CHART_DESC"),
                    defaultXSize: 14,
                    defaultYSize: 14
                },
            ];

            $scope.addAppToDashboard = function (app) {
                var sizeX = 12;
                var sizeY = 12;

                if (app.defaultXSize) {
                    sizeX = app.defaultXSize;
                }
                if (app.defaultYSize) {
                    sizeY = app.defaultYSize;
                }
                // get max row
                var row = 0;
                if ($scope.currentPage.widgets.length > 0) {
                    var maxWidget = Math.max.apply(Math, $scope.currentPage.widgets.map(function (widget) { return widget.row + widget.sizeX; }));
                    row = maxWidget;
                }

                var widget = {
                    header: app.name,
                    component: app.component,
                    row: 0,
                    col: 0,
                    sizeX: sizeX,
                    sizeY: sizeY,
                    dashboardPageId: $scope.currentPage.id
                };

                dashboardService.addWidget(widget).then(function (savedWidget) {
                    $scope.currentPage.widgets.push(savedWidget);
                    setTimeout(resizeWidgetsHosts, 200);
                    $scope.publish("SendDashboardUpdated", $scope.currentDashboard);
                });
            };

            $scope.addApp = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'appstore.html',
                    controller: 'appstoreController',
                    windowClass: 'newdeviation-modal-window',
                });

                modalInstance.result.then(function (app) {
                    $scope.addAppToDashboard(app);
                });
            };

            $(window).on('resize.fullscreenchange', function () {
                console.log("dashboard: resize.fullscreenchange")
                setTimeout(function () {
                    $scope.$broadcast("widget-resized", {});
                }, 200);
            });

            $scope.$on('$destroy', function () {
                $(window).off('resize.fullscreenchange');
            });

            var resizeWidgetHost = function (widget) {
                var gridsterContainerHeight = $(widget).closest(".gridster-item").height();

                $(widget).height(gridsterContainerHeight);
            };

            function widgetResizing(event, $element, widget) {
                $scope.widgetResizeDebounced(event, $element, widget);
            }

            function adjustSize(event, $element, widget) {
                var gridsterContainerHeight = $($element).height();
                var widgetElement = $element.find(".widget");
                $(widgetElement).height(gridsterContainerHeight);

                $scope.$broadcast("widget-resized", {});
            }
            $scope.widgetResizeDebounced = debounce(adjustSize, 200, false);

            function widgetResized(event, $element, widget) {
                $scope.save();
            }
        }]);