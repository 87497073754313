import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = { class: "menu-item" }
const _hoisted_4 = { class: "menu-item" }
const _hoisted_5 = [".value"]
const _hoisted_6 = {
  key: 0,
  class: "div-spinner"
}
const _hoisted_7 = [".spin"]

import { onMounted, onUnmounted, PropType, ref, watch } from "vue"
    import { debounce } from "lodash"
    import { DigiPdfViewer, PdfZoomOptions } from "@common/components/viewers/pdfViewer"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    interface zoomSelectOption {
        option: PdfZoomOptions
        label: string
    }
    
export default /*@__PURE__*/_defineComponent({
  __name: 'pdfViewer',
  props: {
        url: {
            type: String,
            required: true
        },
        zoomoption: {
            type: Object as PropType<PdfZoomOptions>,
            required: true,
            default: PdfZoomOptions.pageFit
        }
    },
  setup(__props) {

    const vCustomEventsEmitter = new CustomEventsEmitter()

    const props = __props

    const viewOptions: zoomSelectOption[] = []

    for (const opt in PdfZoomOptions) {
        const option = PdfZoomOptions[opt]
        viewOptions.push({option, label: option.replace("page", "")})
    }
    const viewOptionSelected = ref<zoomSelectOption>(viewOptions[0])
    const viewOptionChanged = () => {
        vCustomEventsEmitter.emit("zoom-option-changed", viewOptionSelected.value.option)
        debouncePdfResize()
    }

    let canvasWrapperEl = ref<HTMLDivElement | null>(null)

    const checkZoomOptionProp = () => {
        if (props.zoomoption) {
            const option = viewOptions.find(o => o.option == props.zoomoption)
            if (option) {
                viewOptionSelected.value = option
                debouncePdfResize()
            }
        }
    }

    watch(() => props.url, (url) => {
        if (url)
            debounceLoadPdf()
    })
    watch(() => props.zoomoption, (opt) => {
        if (opt)
            checkZoomOptionProp()
    })
    let canvasEl = ref<HTMLCanvasElement | null>(null)
    
    let currentPageNo = ref(1)

    let totalNumOfPages = ref(1)

    let loading = ref(true)

    let pdfViewer: DigiPdfViewer | null = null

    const loadPdf = async () => {
        if (!props.url || props.url.startsWith("{")) {
            // console.log("no pdf url yet")
            return
        }
        if (!canvasEl.value) {
            // console.error("no canvas to render in")
            return
        }
        console.log("Load new pdf", props.url)
        const canvas = canvasEl.value!
        currentPageNo.value = 1
        if (pdfViewer)
            pdfViewer.dispose()
        pdfViewer = new DigiPdfViewer(props.url, canvas)
        await renderPage()
    }

    const renderPage = async () => {
        if (!canvasWrapperEl.value) {
            // console.error("no container to render in")
            return
        }
        const wrapper = canvasWrapperEl.value!
        const wrapperRectangle = wrapper.getBoundingClientRect()
        
        if (pdfViewer) {
            await pdfViewer.renderPage(currentPageNo.value, wrapperRectangle, viewOptionSelected.value.option)
            totalNumOfPages.value = pdfViewer.numberOfPages
        }
        loading.value = false
    }
    const resizePage = () => {
        if (!canvasWrapperEl.value) {
            // console.error("no container to render in")
            return
        }
        const wrapper = canvasWrapperEl.value!
        const wrapperRectangle = wrapper.getBoundingClientRect() // more accurate
        //const containerSize = { width: wrapper.clientWidth, height: wrapper.clientHeight}

        if (pdfViewer)
            pdfViewer.resizeCurrentPage(wrapperRectangle, viewOptionSelected.value.option)
        loading.value = false
    }
    
    const nextPage = () => {
        if (currentPageNo.value == totalNumOfPages.value)
            return
        currentPageNo.value += 1
        renderPage()
    }
    const prevPage = () => {
        if (currentPageNo.value == 1)
            return
        currentPageNo.value -= 1
        renderPage()
    }

    // resize observer debounced
    let threshold = 20
    let previousWrapperBoxSize: DOMRect | null = null
    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            if (entry.target.nodeName == "DIV" && entry.target.classList.contains("canvas-wrapper") && entry.contentBoxSize) {
                const wrapper = entry.target as HTMLDivElement
                const contentBoxSize = wrapper.getBoundingClientRect()
                if (previousWrapperBoxSize) {
                    const diffWidth = previousWrapperBoxSize.width - contentBoxSize.width
                    const diffHeight = previousWrapperBoxSize.height - contentBoxSize.height
                    // console.log(`diff = ${diffWidth}, ${diffHeight}`)
                    let passThreshold = Math.abs(diffWidth) > threshold || Math.abs(diffHeight) > threshold
                    if (!passThreshold)
                        continue
                }
                debouncePdfResize()
                previousWrapperBoxSize = contentBoxSize
            }
        }
    })
    
    const debounceLoadPdf = debounce(loadPdf, 1000)
    const debouncePdfResizeActual = debounce(resizePage, 200)
    const debouncePdfResize = () => {
        if (!pdfViewer)
            return // dont queue resize before initial load
        loading.value = true
        debouncePdfResizeActual()
    }
    onMounted(() => {
        if (canvasWrapperEl.value)
            resizeObserver.observe(canvasWrapperEl.value)
        checkZoomOptionProp()
        debounceLoadPdf()
    })
    onUnmounted(() => {
        if (canvasWrapperEl.value)
            resizeObserver.unobserve(canvasWrapperEl.value)
        if (pdfViewer)
            pdfViewer.dispose()
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createElementVNode("link", {
      href: "/assets/global.css",
      rel: "stylesheet"
    }, null, -1 /* HOISTED */)),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, [
            _createElementVNode("span", null, _toDisplayString(_unref(currentPageNo)), 1 /* TEXT */),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "separator" }, "/", -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString(_unref(totalNumOfPages)), 1 /* TEXT */)
          ]),
          _createElementVNode("digilean-button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (prevPage()))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("fa-icon", { icon: "fa fa-angle-left" }, null, -1 /* HOISTED */)
          ])),
          _createElementVNode("digilean-button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage()))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("fa-icon", { icon: "fa fa-angle-right" }, null, -1 /* HOISTED */)
          ]))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            id: "viewOptions",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((viewOptionSelected).value = $event)),
            onChange: viewOptionChanged
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(viewOptions, (option) => {
              return _createElementVNode("option", { ".value": option }, _toDisplayString(option.label), 41 /* TEXT, PROPS, NEED_HYDRATION */, _hoisted_5)
            }), 64 /* STABLE_FRAGMENT */))
          ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
            [_vModelSelect, viewOptionSelected.value]
          ])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "menu-item" }, null, -1 /* HOISTED */))
      ]),
      _createElementVNode("div", {
        class: "canvas-wrapper",
        ref_key: "canvasWrapperEl",
        ref: canvasWrapperEl
      }, [
        (_unref(loading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("spinner-icon", {
                ".spin": _unref(loading),
                "ng-click": "$ctrl.reload()"
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_7)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("canvas", {
          ref_key: "canvasEl",
          ref: canvasEl
        }, null, 512 /* NEED_PATCH */)
      ], 512 /* NEED_PATCH */)
    ])), [
      [_unref(vCustomEventsEmitter)]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
}

})