import { A3TableColumn, SmartTableColumn } from "@api";

export function parseColumnSettings(columns: A3TableColumn[] | SmartTableColumn[]) {
    if (!columns || columns.length === 0)
        return
    for (let i = 0; i < columns.length; i++) {
        const column = columns[i]
        if (column.settings && typeof (column.settings) === "string") {
            column.settings = JSON.parse(column.settings)
        }
    }
}
// A3TableColumn | SmartTableColumn
export function getColStyle(col: any) {
    let styles = {}
    if (!col || !col.settings)
        return styles

    if (col.settings.widthSetting == "MANUAL" && col.settings.width) {
        const widthPx = col.settings.width + "px"
        styles = {
            "width": widthPx,
            "min-width": widthPx, 
            "max-width": widthPx
        }   
    }
    return styles
}