import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import { forEach } from "angular";

angular
    .module('DigiLean')
    .directive("organizationBillingAdmin", ['$translate', '$filter', 'customerAccountService', '$uibModal',
        function ($translate, $filter, customerAccountService, $uibModal) {
            return {
                templateUrl: 'organizationBillingAdmin.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.currency = "USD";
                    scope.totalFee = 0;
                    scope.paymentdetails = null;
                    scope.validPaymentDetails = true;
                    scope.hasReadAndAcceptedTerms = false;
                    scope.missingReadAndAcceptedTerms = false;
                    var firstRegistration = false;

                    scope.updatedSubscription = false;
                    var newStartDate = null;

                    scope.translationData = {
                        due: scope.due
                    };

                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {

                        getSubscription();
                    });
         

                    function setTrialDateIfTrial(){
                        if(scope.account.isTrial && scope.account.endDate != null){//In trial and has not ordered earlier
                            scope.account.endDate = null;
                            var trialPeriod = customerAccountService().getTrialPeriod();
                            var newStartDate = moment(scope.account.startDate).add(trialPeriod,'days');
                            scope.account.startDate = newStartDate.toDate();
                        }
                    }
                    function setNewStartDate(){
                        if(newStartDate){
                            scope.account.startDate = newStartDate.toDate();
                        }
                    }
                    scope.countryChanged = function (updated) {
                        scope.paymentdetails.country = updated.countryCode;
                        scope.validatePaymentDetails();
                    }

                    scope.readAndAccept = function (hasReadAndAcceptedTerms) {
                        scope.missingReadAndAcceptedTerms = !hasReadAndAcceptedTerms;
                        scope.hasReadAndAcceptedTerms = hasReadAndAcceptedTerms;
                    }
                    scope.updateAccount = function () {
                        if(scope.account.isTrial && scope.account.endDate != null){//In trial and has not ordered earlier
                            if(!scope.hasReadAndAcceptedTerms){
                                scope.missingReadAndAcceptedTerms = true;
                                return;
                            }
                        }
                        setTrialDateIfTrial();
                        // trengs dette? Det er nå ikke mulig å gå fra årlig abonnement til månedlig...   
                        setNewStartDate();
                        customerAccountService().updateCustomerAccount(scope.account).then(function (result) {
                            $translate('ADMIN_CONFIRM_ORDER').then(function (msg) {
                                toastr.success(msg);
                                scope.updatedSubscription = false;
                                scope.account = result;
                            })
                        })
                    }


                    scope.openPlanDialog = function () {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'customerAccountForm.html',
                            controller: 'customerAccountModalController',
                            resolve: {
                                account: scope.account,
                                updated: scope.updatedSubscription
                            }
                        });
                        modalInstance.result.then(function (updated) {
                            if(updated){
                                scope.updatedSubscription = updated;
                                
                                presentPredictedFee();
                            }
                        }, function () {
                        });
                    }

                    scope.updatePaymentDetails = function () {
                        firstRegistration = false;
                        if (scope.validatePaymentDetails()) {
                            customerAccountService().updatePaymentDetails(scope.paymentdetails).then(function (result) {
                                $translate('ADMIN_PAYMENT_DETAILS_UPDATED').then(function (msg) {
                                    toastr.success(msg);
                                })
                            })
                        }
                    }

                    scope.validatePaymentDetails = function () {
                        
                        scope.validPaymentDetails = true;
                        if(!firstRegistration){ 
                        if (!scope.paymentdetails.companyName) {
                            scope.notValidCompanyName = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidCompanyName = false;
                        }
                        if (!scope.paymentdetails.email) {
                            scope.notValidEmail = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidEmail = false;
                        }
                        if (!scope.paymentdetails.address) {
                            scope.notValidAddress = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidAddress = false;
                        }
                        if (!scope.paymentdetails.zip) {
                            scope.notValidZip = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidZip = false;
                            scope.paymentdetails.zip = scope.paymentdetails.zip.substring(0,4); //truncate to 4 digits due to bug in uniconomy
                        }
                        if (!scope.paymentdetails.city) {
                            scope.notValidCity = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidCity = false;
                        }
                        if (!scope.paymentdetails.country) {
                            scope.notValidCountry = true;
                            scope.validPaymentDetails = false;
                        } else {
                            scope.notValidCountry = false;
                        }
                    }
                        return scope.validPaymentDetails;
                    }

                    
                    scope.removeRegNumberWhiteSpace = function () {
                        if (scope.paymentdetails.regnumber) {
                            scope.paymentdetails.regnumber = scope.paymentdetails.regnumber.replace(/\s/g, '');
                        }
                    }


                    scope.openCancelSubscriptionDialog = function () {
                        var endDate = calculateSubscriptionEndDate();
                        var desc = $translate.instant("ADMIN_BILLING_CANCEL_SUBSCRIPTION") + " " + endDate.format("MMM DD, YYYY"); 
                        swal({
                            title: $translate.instant("ADMIN_CANCEL_SUBSCRIPTION"),
                            text: desc,
                            icon: "error",
                            buttons: [$translate.instant("ADMIN_BACK_TO_SETTINGS"),
                                $translate.instant("ADMIN_CANCEL_SUBSCRIPTION")],
                            dangerMode: true,
                            closeOnConfirm: false
                        }).then((value) => {
                            switch (value) {
                                case true: // Yes to cancel
                                    customerAccountService().cancelSubscription(endDate).then(function (result) {
                                        scope.account = result;
                                    })

                                    break;
                                default:
                            }
                        });
                    }

                    function calculateSubscriptionEndDate() {
                        if(!scope.due){
                            calculateDue();
                        }
                        var lastPayedDate = moment().add(scope.due - 1, 'days');
                        if (scope.account.billing == 'YEARLY') {
                            var daysThisMonth = moment().daysInMonth();
                            if (scope.due <= daysThisMonth) { // subscription is not terminated more than a month before renewal => subscription extended a year and then terminated
                                return lastPayedDate.add(1, 'year');
                            } else {
                                return lastPayedDate;
                            }
                        } else if (scope.account.billing == 'MONTHLY') {
                            var startNextPayDate = moment().add(scope.due, 'days');
                            var newEndDate = startNextPayDate.add(1, 'months');
                            return newEndDate;
                        } else { //FREE
                            return lastPayedDate;
                        }
                    }

                    scope.openReactivateDialog = function () {
                        swal({
                            title: $translate.instant('ADMIN_WELCOME_BACK'),
                            text: "",
                            icon: "warning",
                            buttons: { reactivate: { text: $translate.instant('ADMIN_REACTIVATE_NOW'), className: "sa-digilean-button" } },
                            dangerMode: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        })
                            .then((reactivate) => {
                                if (reactivate) {
                                    swal($translate.instant('ADMIN_ACCOUNT_REACTIVATED'), {
                                        icon: "success",
                                        buttons: {
                                            confirm: {
                                                text: "OK",
                                                value: true,
                                                visible: true,
                                                className: "sa-digilean-button",
                                                closeModal: true,
                                            }
                                        }

                                    }).then((ok) => {
                                        customerAccountService().reactivateAccount().then(function (result) {
                                            scope.account.endDate = null;
                                        })
                                    })
                                }
                            });
                    }

                    scope.openFrequencyDialog = function () {
                        swal({
                            title: $translate.instant('ADMIN_CHANGE_FREQUENCY'),
                            text: $translate.instant('ADMIN_CHANGE_FREQUENCY_DESC'),
                            icon: "",
                            buttons: {
                                nochange: {
                                    text: $translate.instant('COMMON_CANCEL'),
                                    className: "digilean-label-historical",
                                    closeModal: true,
                                    value: false
                                },
                                monthly: {
                                    text: $translate.instant('COMMON_MONTHLY'),
                                    className: "sa-digilean-button",
                                    closeModal: true,
                                    value: "MONTHLY"
                                },
                                yearly: {
                                    text: $translate.instant('DASHBOARD_ANNUAL'),
                                    className: "sa-digilean-button",
                                    closeModal: true,
                                    value: "YEARLY"
                                },

                            },
                            dangerMode: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        }).then((value) => {
                            switch (value) {

                                case "MONTHLY":
                                    if (scope.account.billing != 'MONTHLY') {
                                        var endDateCurrentSubscription = calculateSubscriptionEndDate();
                                        newStartDate = endDateCurrentSubscription.add(1, 'days');
                                        //TODO should this information also be available in mail notification?
                                        scope.descNewSubscription = $translate.instant('ADMIN_BILLING_MONTHLY_INVOICE_VALID_FROM') + endDateCurrentSubscription.format("MMM DD, YYYY"); 
                                        scope.account.billing = 'MONTHLY';
                                        
                                        presentPredictedFee();
                                        
                                        scope.updatedSubscription = true;
                                    }
                                    break;

                                case "YEARLY":
                                    if (scope.account.billing != 'YEARLY') {
                                        var endDateCurrentSubscription = calculateSubscriptionEndDate();

                                        newStartDate = moment().add(scope.due, 'days');
                                        scope.account.billing = 'YEARLY';
                                        scope.descNewSubscription = null;
                                        presentPredictedFee();
                                        scope.updatedSubscription = true;
                                    }
                                    break;

                                default:
                            }
                        });
                    }
                    
                    function getSubscription() {
                        customerAccountService().getCustomerAccount().then(function (account) {
                            scope.account = account;
                            if (scope.account.billing != "FREE") {
                                getPaymentDetails().then(function() {
                                    calculateFee()
                                    if (scope.account.isTrial) {
                                        calculateTrialDue()
                                    } else {
                                        calculateDue()
                                    }
                                })
                            }
                        })
                    }
                    getSubscription();

                    function getPaymentDetails() {
                        return customerAccountService().getPaymentDetails().then(function (details) {
                            if (details) {
                                scope.paymentdetails = details;
                                if (details.billingCurrency) {
                                    scope.currency = details.billingCurrency;
                                }
                            } else {
                                scope.paymentdetails = {
                                    companyName: "",
                                    email: "",
                                    address: "",
                                    regnumber: "",
                                    contactFirstName: "",
                                    contactLastName: "",
                                    zip: "",
                                    city: "",
                                    country: null,
                                    other: ""
                                }
                                firstRegistration = true;
                            }
                            
                            customerService.getCountryList().then(function(countries){
                                scope.countries = countries;
                                if (scope.paymentdetails.country) {
                                    var match = $filter('filter')(scope.countries , { countryCode: scope.paymentdetails.country }, true);
                                    if (match.length > 0) {
                                        scope.paymentdetails.countryinfo = match[0];
                                    }
                                }
                            })
                            return scope.paymentdetails
                        })
                    }

                    function presentPredictedFee(){
                        customerAccountService().getRecurringInvoiceFromInputData(scope.account).then(function (invoice) {
                            scope.invoice = invoice;
                            scope.totalFee = scope.invoice.sumExTax;
                        })
                    }


                    function calculateFee() {
                        customerAccountService().getRecurringInvoice().then(function (invoice) {
                            scope.invoice = invoice;
                            scope.totalFee = scope.invoice.sumExTax;
                        })
                    }

                    function calculateTrialDue() {
                        var trialPeriodInDays = customerAccountService().getTrialPeriod();
                        var daysInTrial = timeService.getDurationInDays(scope.account.startDate, moment());
                        if(daysInTrial < 0){ //HAve already ordered and therefore startDate has been updated to beginning of pay period
                            calculateDue();
                        } else {             
                            if (!daysInTrial){ // start date not set
                                var daysLeftInTrial = timeService.getDurationInDays(moment(),scope.account.endDate);
                                if(daysLeftInTrial < 0){
                                    scope.due = 0;
                                } else {
                                    scope.due = daysLeftInTrial;
                                }
                            } else {
                                scope.due = trialPeriodInDays - daysInTrial;
                            }
                            scope.translationData = {
                                due: scope.due
                            };
                        }
                    }

                    function calculateDue() {
                        var startDate = moment(scope.account.startDate);
                        if (scope.account.billing == 'YEARLY') {

                            var dayOfYear = startDate.dayOfYear();
                            var currentDayOfYear = moment().dayOfYear();
                            var diff = dayOfYear - currentDayOfYear;
                            if (diff >= 0) {
                                scope.due = diff;
                            } else {                             
                                var totalDayInThisYear = moment().endOf("year").dayOfYear();
                                scope.due = totalDayInThisYear - currentDayOfYear + dayOfYear;
                            }
                        } else {
                            var dayOfMonth = startDate.get('date');
                            var currentDayOfMonth = moment().get('date');
                            var diff = dayOfMonth - currentDayOfMonth;
                            if (diff >= 0) {
                                scope.due = diff;
                            } else {
                                var totalDayInThisMonth = moment().daysInMonth();
                                scope.due = totalDayInThisMonth - currentDayOfMonth + dayOfMonth;
                            }
                        }
                        scope.translationData = {
                            due: scope.due
                        };
                    }
                }
            }
        }]);
