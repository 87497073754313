angular.module("DigiLean").factory('a3Service', ['$rootScope', '$http', '$translate','webApiUrl',
    function ($rootScope, $http, $translate, webApiUrl) {
        function a3Service() {
            var resource = "/a3";
            var resourceUrl = webApiUrl + resource;

            return {

                canEdit: function (id) {
                    resourceUrl = resourceUrl + "/canedit/" + id;
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },

                updateA3Status: function (id, status) {
                    var a3Status =  {
                        a3Id: id,
                        status: status
                    }
                    resourceUrl = resourceUrl + "/" + id + "/updateStatus";
                    return $http.put(resourceUrl, a3Status).then(function (result) {
                        return result.data;
                    });
                },

                get: function (id) {
                    resourceUrl = resourceUrl + "/" + id;
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },

                getA3s: function () {
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },
                
                getA3MiniInfo: function (){ // id and title
                    var url = resourceUrl + "/GetA3MiniInfo";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },

                getA3forUser: function () { // A3 where current user is owner
                    var url = resourceUrl + "/getA3forUser";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getA3ByStatus: function (status) {
                    var url = resourceUrl + "/getbystatus/" + status;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getPreviewImageUrl: function (id) {
                    resourceUrl = resourceUrl + "/GetPreviewImageUrl/" + id;                    
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },

                deleteA3s: function (a3Ids) {
                    var url = resourceUrl + "/DeleteA3Items";
                    return $http.post(url, a3Ids).then(function (result) {
                        return result.data;
                    });
                },

                addA3: function (a3) {
                    return $http.post(resourceUrl, a3).then(function (result) {
                        return result.data;
                    });
                },
                
                setPrivateStatus: function (a3Id, status) {
                    resourceUrl = resourceUrl +"/" + a3Id +  "/setPrivateStatus";
                    return $http.put(resourceUrl, status).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectInfo: function (a3Id, data) {
                    resourceUrl = resourceUrl +"/" + a3Id +  "/UpdateProjectInfo";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },
                updateOwner: function (data) {
                    resourceUrl = resourceUrl + "/UpdateOwner/";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },
                updateA3Title: function (data) {
                    if (!data || !data.title) return;
                    resourceUrl = resourceUrl + "/UpdateA3Title/";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },
                updateA3Goal: function (data) {
                    resourceUrl = resourceUrl + "/UpdateA3Goal/";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },

                getMilestonesForA3Project: function (a3ProjectId) {
                    var url = resourceUrl + "/getMilestonesForA3Project/" + a3ProjectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                deleteA3Milestone: function (milestoneId) {
                    var url = resourceUrl + "/deleteA3Milestone/" + milestoneId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
    
                createA3Milestone: function (milestone) {
                    var url = resourceUrl + "/createA3Milestone/";
                    return $http.post(url,milestone).then(function (result) {
                        return result.data;
                    });
                },
    
                updateA3Milestone: function (milestone) {
                    var url = resourceUrl + "/updateA3Milestone/";
                    return $http.put(url,milestone).then(function (result) {
                        return result.data;
                    });
                },
                getA3ProjectInfo: function (id) {
                    var url = resourceUrl + "/getA3ProjectInfo/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProjectInfoForA3s: function () {
                    var url = resourceUrl + "/getProjectInfoForA3s/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getInitiativeMonthlyRealized: function (timePeriod) {
                    var url = resourceUrl + "/GetInitiativeMonthlyRealized/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    }); 
                },

                createA3FromTemplate: function (a3) {
                    var url = resourceUrl + "/CreateA3FromTemplate";
                    return $http.post(url, a3).then(function (result) {
                        return result.data;
                    });
                },

                createA3FromDeviation: function (deviationId) {
                    var url = resourceUrl + "/CreateA3FromDeviation/" + deviationId;
                    return $http.post(url).then(function (result) {
                        return result.data;
                    });
                },

                getDefaultLayout: function (){
                    var url = resourceUrl + "/defaultlayout";
                    return $http.get(url).then(function(result){
                        return result.data;
                    })
                },

                saveLayout: function (a3Versionid, layout) {
                    var url = resourceUrl + "/saveLayout/" + a3Versionid;
                    return $http.put(url, layout).then(function (result) {
                        return result.data;
                    });
                },

                getA3sForAssetWithActionItems: function (assetId) {
                    var url = resourceUrl + "/getA3sForAssetWithActionItems/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getUserlistForA3: function (a3Id) {
                    var url = resourceUrl + "/getUserListForA3/" + a3Id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getVersion: function (versionId) {
                    var url = resourceUrl + "/getversion/" + versionId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateParticipants: function (a3Id, a3ParticipantGroup) {
                    var url = resourceUrl + "/" + a3Id + "/updateParticipants";
                    return $http.put(url, a3ParticipantGroup).then(function (result) {
                        return result.data;
                    });
                },
                saveA3Metadata: function (a3) {
                    var url = resourceUrl + "/" + a3.id;
                    return $http.put(url, a3).then(function (result) {
                        return result.data;
                    });
                },
                saveStep: function (step) {
                    var url = resourceUrl + "/savestep/" + step.id;
                    return $http.put(url, step).then(function (result) {
                        return result.data;
                    });
                },

                approveStep: function (stepid, status) {
                    var url = resourceUrl + "/approvestep/" + stepid;
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },

                getActionList: function (stepid) {
                    var url = resourceUrl + "/GetActionList/" + stepid;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                assignActionListItemUser: function (stepItemId, user) {
                    var url = resourceUrl + "/assignActionListItemUser/" + stepItemId;
                    return $http.put(url, user).then(function (result) {
                        return result.data;
                    });
                },

                deleteActionListItem: function (itemId) {
                    var url = resourceUrl + "/DeleteActionListItem/" + itemId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                saveItemDescription: function (itemId, desc) {
                    var url = resourceUrl + "/saveItemDescription/" + itemId;
                    return $http.put(url, desc).then(function (result) {
                        return result.data;
                    });
                },

                updateStatus: function (status) {
                    var url = resourceUrl + "/updateStatus/" + status.taskId;
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },

                // DEPRECATED refactor to one dateupdate method for all actionlist task
                updateItemActionDate: function (actionDate) {
                    var url = resourceUrl + "/updateItemActionDate/" + actionDate.taskId;
                    return $http.put(url, actionDate).then(function (result) {
                        return result.data;
                    });
                },

                createActionItem: function (a3id, stepid) {
                    var url = resourceUrl + "/" + a3id + "/CreateActionItem/" + stepid;
                    return $http.post(url).then(function (result) {
                        return result.data;
                    });
                },

                getCommentsOnStep: function (stepid) {
                    var url = resourceUrl + "/getCommentsOnStep/" + stepid;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                addCommentToStep: function (a3id, stepid, comment) {
                    var url = resourceUrl + "/" + a3id + "/AddCommentToStep/" + stepid;
                    return $http.post(url, comment).then(function (result) {
                        return result.data;
                    });
                },
    
                getStrategyGoals: function(a3Id) {
                    var url = resourceUrl + "/GetStrategyGoals/" + a3Id;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addStrategyGoalToA3: function (a3Id, strategyGoalId) {
                    var url = resourceUrl + "/" + a3Id + "/addStrategyGoalToA3";
                    return $http.post(url, strategyGoalId).then(function (result) {
                        return result.data;
                    });
                },
                removeStrategyGoalFromA3: function (a3Id, strategyGoalId) {
                    var url = resourceUrl + "/" + a3Id + "/removeStrategyGoalFromA3/" + strategyGoalId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getLinks: function(a3Id) {
                    var url = resourceUrl + "/getLinks/" + a3Id;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addLink: function (a3Id, link) {
                    var url = resourceUrl + "/" + a3Id + "/addLink";
                    return $http.post(url, link).then(function (result) {
                        return result.data;
                    });
                },
                deleteLink: function (a3Id, linkId) {
                    var url = resourceUrl + "/" + a3Id + "/deleteLink/" + linkId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getAttachments: function(a3Id) {
                    var url = resourceUrl + "/getAttachments/" + a3Id;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addAttachment: function (a3Id, fileInfo) {
                    var url = resourceUrl + "/" + a3Id + "/addAttachment";
                    return $http.post(url, fileInfo).then(function (result) {
                        return result.data;
                    });
                },

                deleteAttachment: function (a3Id, attachmentId) {
                    var url = resourceUrl + "/" + a3Id + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getCategories: function () {
                    var url = resourceUrl + "/getCategories";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createCategory: function (category) {
                    var url = webApiUrl + "/a3admin/addCategory";
                    return $http.post(url, category).then(function (result) {
                        return result.data;
                    });
                },
                updateCategory: function (category) {
                    var url = webApiUrl + "/a3admin/updateCategory";
                    return $http.put(url, category).then(function (result) {
                        return result.data;
                    });
                },
                deleteCategory: function (id) {
                    var url = webApiUrl + "/a3admin/deleteCategory/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getImprovementStatus:function(a3status){
                    switch (a3status) {
                        case 'New':
                            return "Suggested";
                        case 'Plan':
                            return "Planned";
                        case 'Do':
                            return "InProgress";
                        case 'Check':
                            return "Implemented";
                        case 'Act':
                            return "Evaluated";
                        case 'Closed':
                            return "Evaluated";
                        default:
                            return null;
                    }
                },
                getStatus: function () {
                    return [
                        {   sortorder: 1,
                            statusDB: "New",
                            translated: $translate.instant('COMMON_NEW'),
                            cssClass: "new",
                            pie: {
                                data: [0, 0, 0, 0],
                                options: {
                                    radius: 18,
                                    fill: ["#f3f3f4"],
                                }
                            }
                        },
                        {   sortorder: 2,
                            statusDB: "Plan",
                            translated: $translate.instant('A3_PLAN'),
                            cssClass: "plan",
                            pie: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 18,
                                    fill: ["#5cb85c", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
                                }
                            }
                        },
                        {   sortorder: 3,
                            statusDB: "Do",
                            translated: $translate.instant('COMMON_DO'),
                            cssClass: "do",
                            pie: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 18,
                                    fill: ["#5cb85c", "#5cb85c", "#f3f3f4", "#f3f3f4"],
                                }
                            } 
                        },
                        {   sortorder: 4,
                            statusDB: "Check",
                            translated: $translate.instant('COMMON_CHECK'),
                            cssClass: "check",
                            pie: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 18,
                                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#f3f3f4"],
                                }
                            }
                        },
                        {
                            sortorder: 5,
                            statusDB: "Act",
                            translated: $translate.instant('COMMON_ACT'),
                            cssClass: "act",
                            pie: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 18,
                                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
                                }
                            }
                        },
                        {
                            sortorder: 6,
                            statusDB: "Closed",
                            translated: $translate.instant('DEVIATION_CLOSED'),
                        }
                    ];
                },
            }
        }
        return a3Service;
    }]);
