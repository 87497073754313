import * as profileService from "@common/services/user/profileService"
import * as customerService from "@common/services/customers/customerService"
import { UserProfileImageInfo, UserShortInfo } from "@api"
import { userFullProfile } from "@common/stores/userStore"

export interface UserShortInfoWithImage extends UserShortInfo {
    profileImage?: boolean
    profileImageUrl?: string | null
}
type ThumbnailSizes = "ExtraSmall" | "Small" |"Medium" |"Large"

let profileImageUsers: UserProfileImageInfo[] = []
userFullProfile.subscribe(async (u) => {
    if (u.user)
        profileImageUsers = await profileService.getUsersWithProfileImage() // cache
})

export async function getAllUsersIncludeProfileImage() {
    profileImageUsers = await profileService.getUsersWithProfileImage()
    const allusers:UserShortInfoWithImage[] = await customerService.getAllUsers()

    return getProfileImagesForUsers(allusers)
}

export async function getProfileImagesForUsers(users: UserShortInfoWithImage[]) {
    for (let i = 0; i < users.length; i++) {
        let user = users[i]
        let imageUrl = await getProfileImageUrl(user.id, "ExtraSmall")
        if (imageUrl) {
            user.profileImage = true
            user.profileImageUrl = imageUrl
        } else {
            user.profileImage = false
        }
    }
    return users
}

export async function getProfileImageUrl(userId: string, imageSize: ThumbnailSizes) {
    profileImageUsers = await profileService.getUsersWithProfileImage()
    const user = profileImageUsers.find(p => p.userId == userId)
    if (user)
        return formatProfileImageUrl(user.storageUrl, imageSize, user.fileName)
    
    return null;
}

function formatProfileImageUrl(storageUrl: string, size: ThumbnailSizes, fileName: string) {
    if (!storageUrl.endsWith("/"))
        storageUrl = `${storageUrl}/`

    return `${storageUrl}${size}-${fileName}`
}