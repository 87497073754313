import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { class: "table-row-group" }
const _hoisted_3 = { class: "table-row" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "table-cell job-run-cell" }
const _hoisted_6 = [".date"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = [".state", ".result", ".date"]
const _hoisted_9 = [".date"]
const _hoisted_10 = [".jobrun"]
const _hoisted_11 = { class: "table-row" }
const _hoisted_12 = { class: "table-cell" }
const _hoisted_13 = ["onClick", "title"]
const _hoisted_14 = { class: "table-cell" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = [".state", ".result", ".date"]
const _hoisted_17 = [".jobrun"]

import { PropType, ref, onMounted, watch } from "vue"
    import { ConnectorMonitor, JobRun, JobPart, JobMonitor, LogMessage, LogMessageType } from "@api"
    import dialog from "@common/components/digilean/DigileanDialog"
    import { translateLabel } from "@common/services/language/DigiLeanLang"
    import * as monitoringService from "@common/services/data/monitoringService"
    import { systemHub } from "@common/services/systemHub"
    import { UpdateRunByLog } from "@common/components/monitoring/integrations/loggMonitor"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectorMonitorTable',
  props: {
        data: {
            type: Object as PropType<ConnectorMonitor>,
            required: true
        },
        numruns: {
            type: Number,
            default: 7
        }
    },
  setup(__props) {

    const props = __props

    let connectorId = ""
    watch(() => props.data, (data) => {
        if (!data) return
        updateJobRuns()
    })
    let jobs = ref<JobMonitor[]>([])

    async function updateJobRuns() {
        if (!props.data?.jobsSetup)
            return

        jobs.value = []
        for (let i = 0; i < props.data.jobsSetup.length; i++) {
            const job = props.data.jobsSetup[i]
            await getRuns(job.id)
        }
        
    }

    let getRuns = async (jobId: number) => {
        if (!props.data?.jobsSetup)
            return
        let job = props.data.jobsSetup.find(j => j.id == jobId)
        if (!job)
            return
        try {
            connectorId = job.connectorId!
            const runs = await monitoringService.getJobRuns(job.connectorId!, job.id)
            if (runs)
                jobs.value.push(runs)
        }
        catch (e) {}
    }

    let getPartJobs = (jobId: number) => {
        const job = props.data?.jobsSetup?.find(j => j.id == jobId)
        if (job && job.jobParts)
            return job.jobParts
        return []
    }
    let getname = (jobId: number, jobPartId: number) => {
        if (!props.data)
            return ""
        const job = props.data.jobsSetup?.find(j => j.id == jobId)
        if (!job) return ""
        if (jobPartId) {
            const jobPart = job.jobParts?.find(p => p.id == jobPartId)
            if (jobPart)
                return jobPart.name
        }
        return job.name
    }

    let openLog = async (jobId: number, jobRun: JobRun) => {
        let name = getname(jobId, jobRun.jobPartId!)
        const id = `joblog${jobId}${jobRun ? jobRun.jobPartId : ""}`
        let el = document.createElement("monitor-job-log")
        el.id = id
        el.run = jobRun

        const closeLbl = await translateLabel("COMMON_CLOSE")
        dialog.openElement({
            title: `<i>${name}</i>, <translate-label label="ADMIN_MONITOR_RUN_STARTED"></translate-label>: 
                    <datetime-viewer date="${jobRun.started}"></datetime-viewer>`,
            hideOkBtn: true,
            cancelBtnText: closeLbl
        }, el)
    }
    let showConfig = async (jobPart: JobPart) => {
        let jsonString = jobPart.config ? jobPart.config : ""
        if (jsonString) {
            const json = JSON.parse(jsonString)
            jsonString = JSON.stringify(json, null, 4)
        }
        const closeLbl = await translateLabel("COMMON_CLOSE")
        const configLbl = await translateLabel("COMMON_CONFIGURATION")
        const template = `<textarea cols="100" rows="20">${jsonString}</textarea>`
        dialog.openHtml({
            title: configLbl,
            hideOkBtn: true,
            cancelBtnText: closeLbl
        }, template)
    }

    async function updateNextRunTime(log: LogMessage) {
        if (log.jobId && (log.type == LogMessageType.JobStarted || log.type == LogMessageType.JobFinished) && !log.jobPartId) {
            let nextRun = await monitoringService.getNextRun(log.jobId).catch(err => {
                console.log("could not get next run")
            })
            if (nextRun) {
                let job = jobs.value.find(j => j.id == log.jobId)
                if (job) {
                    /// @ts-ignore
                    job.nextTime = nextRun
                }
            }
        }
    }
    function handleLog(log: LogMessage) {
        if (log.connectorId ==  connectorId && (log.type == LogMessageType.JobStarted || 
                                                log.type == LogMessageType.JobFinished ||
                                                log.type == LogMessageType.JobFailed))
        {
            if (log.jobId && log.runId)
                updateRunFromLog(log)
            // else
            //     loadData()
        }
    }
    async function updateRunFromLog(log: LogMessage) {
        const updatedData = UpdateRunByLog(jobs.value, props.numruns, log)
        if (updatedData) {
            // $ctrl.data = updatedData
            updateNextRunTime(log)
        }
    }
    function setupEvents() {
        systemHub.on("NewLogMessage", handleLog)
    }
    onMounted(() => {
        updateJobRuns()
        setupEvents()
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"table-header-group\"><div class=\"table-row\"><div class=\"table-cell\"><translate-label label=\"COMMON_JOBTYPE\"></translate-label></div><div class=\"table-cell\"><!-- &lt;h2&gt; --><translate-label label=\"COMMON_NEXT_RUN\"></translate-label><!-- &lt;/h2&gt; --></div></div></div>", 1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(jobs), (job) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            class: "table-cell",
            href: '#/joblogs/'+job.id
          }, _toDisplayString(job.name), 9 /* TEXT, PROPS */, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("datetime-viewer", {
              ".date": job.nextTime
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(job.runs, (run) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "table-cell job-run-cell",
              onClick: ()=>_unref(openLog)(job.id, run)
            }, [
              _createElementVNode("jobrun-state", {
                ".state": run.state,
                ".result": run.result,
                ".date": run.started
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8),
              _cache[0] || (_cache[0] = _createTextVNode("  ")),
              _createElementVNode("datetime-viewer", {
                ".date": run.started
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_9),
              _cache[1] || (_cache[1] = _createTextVNode("  ")),
              _createElementVNode("jobrun-duration", { ".jobrun": run }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10)
            ], 8 /* PROPS */, _hoisted_7))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "table-cell" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getPartJobs)(job.id), (jobPart) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["part-name partjob-cell", { disabled: !jobPart.enabled }]),
                onClick: ()=>_unref(showConfig)(jobPart),
                title: jobPart.enabled ? 'enabled, click for config' : 'jobpart disabled'
              }, _toDisplayString(jobPart.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_13))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(job.runs, (run) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(run.jobParts, (jobPartRun) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "partjob-cell",
                  onClick: ()=>_unref(openLog)(job.id, jobPartRun)
                }, [
                  _createElementVNode("jobrun-state", {
                    ".state": jobPartRun.state,
                    ".result": jobPartRun.result,
                    ".date": jobPartRun.started
                  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_16),
                  _cache[2] || (_cache[2] = _createTextVNode("  ")),
                  _createElementVNode("jobrun-duration", { ".jobrun": jobPartRun }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_17)
                ], 8 /* PROPS */, _hoisted_15))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}
}

})