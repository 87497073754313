import angular from "angular"
import { NgComponent } from "@common/model/angularModel"
import { UserShortInfo } from "@api"

const DigiLean = angular.module('DigiLean')
DigiLean.component('taskUserSelector', {
    templateUrl: "taskUserSelector.html",
    bindings: {
        'users': '<',
        'canEdit': '<',
        'showClearUser': '<'
    },
    controller: ['$element', function(this: NgComponent, $element) {
        let $ctrl = this
        

        // $ctrl.$onInit = function() {
        // }
        $ctrl.selectUser = function(user: UserShortInfo) {
            dispatchCustomEvent("task-user-selected", user)
        }

        $ctrl.clearUser = function() {
            dispatchCustomEvent("task-clear-user", {})
        }

        function dispatchCustomEvent(name: string, detail: any) {
            const options = { detail, bubbles: false, composed: true }
            $element[0].dispatchEvent(new CustomEvent(name, options))
        }
    }]
})