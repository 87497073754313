import { loadUserProfile } from "@common/services/user/userService"
import * as profileService from "@common/services/user/profileService"
import * as employeeService from "@common/services/employeeService"

angular
.module('DigiLean')
.directive('profileInfoSettings', ['$uibModal','languageAdminService',
    function ($uibModal,languageAdminService) {
        return {
            templateUrl: 'profileInfoSettings.html',
            restrict: 'E',
            scope: {

            },
            link: function (scope, elem, attrs) {
                scope.firstName = "";
                scope.lastName = "";
                scope.email = "";
                scope.preferredLanguage = "";
                scope.selectedLanguage = null;

                scope.languages = languageAdminService().getSupportedLanguages();

                function setSelectedLanguage(){
                    const selected = scope.languages.find(l => l.languageCode == scope.preferredLanguage)
                    if (selected) {
                        scope.selectedLanguage = selected
                    }
                }

                async function getMyProfile () {
                    const profile = await profileService.getMyProfile()
                    scope.profile = profile;
                    scope.firstName = profile.firstName;
                    scope.lastName = profile.lastName;
                    scope.email = profile.email;
                    scope.preferredLanguage = profile.preferredLanguage;
                    setSelectedLanguage()
                    scope.$apply()
                }
                getMyProfile();
                scope.updateProfilePhoto = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'myprofilephoto.html',
                        controller: 'myprofilephotoController',
                        resolve: {
                        }
                    });
                    modalInstance.result.then(function () {
                        getMyProfile();
                    }, function () {
                    });
                }
                scope.changeLanguage = function (language) {
                    if(!language)
                        return
                    
                    const code = language.languageCode
                    employeeService.changeLanguage(code).then(function () {
                        // this loads all the language stuff
                        loadUserProfile()
                    })
                    scope.preferredLanguage = code
                    setSelectedLanguage()
                    var payload = {
                        locale: scope.preferredLanguage
                    }
                    
                    scope.publish("UserLanguageUpdated", payload)
                }

                scope.updateProfile = function() {
                    var profileInfo = {
                        firstName: scope.firstName,
                        lastName: scope.lastName,
                        email: scope.email
                    };
                    profileService.updateProfileInfo(profileInfo)
                }
            }
        }
    }]);
