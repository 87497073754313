﻿import { getUserProfile } from "@common/stores/userStore"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("personalcalendar", ['$rootScope', 'boardTaskService', '$translate', '$uibModal',
        function ($rootScope, boardTaskService, $translate, $uibModal) {
            return {
                templateUrl: 'personalcalendar.html',
                restrict: 'E',
                scope: {
                },
                link: function (scope, elem, attrs) {

                    scope.lang = "en"
                    const up = getUserProfile()
                    if (up.preferredLanguage)
                        scope.lang = up.preferredLanguage

                    setLanguage(scope.lang)
                    setCalendar()

                    function setLanguage(language) {
                        if (language === "no")
                            language = "nb"
                        scope.lang = language
                        $(elem).find('.calendar').fullCalendar('option', 'locale', language)
                    }

                    scope.$on('$translateChangeSuccess', function (val) {
                        setLanguage($translate.use())
                    })

                    function setCalendar() {
                        $(elem).find('.calendar').fullCalendar({
                            events: getTasks,
                            header: {
                                left: 'prev,next today',
                                center: 'title',
                                right: ''
                                //right: 'month,agendaWeek,agendaDay'
                            },
                            weekNumbers: true,
                            eventLimit: true,
                            editable: true,
                            eventDurationEditable: false,
                            firstDay: 1, // Setting monday as first day of week
                            locale: scope.lang,
                            eventClick: function (calEvent, jsEvent, view) {
                                boardTaskService().get(calEvent.id).then(function (task) {
                                    var modalInstance = $uibModal.open({ backdrop: 'static',
                                        animation: true,
                                        templateUrl: 'boardTask.html',
                                        controller: 'boardTaskController',
                                        resolve: {
                                            task: function () {
                                                return task;
                                            },
                                            activeTab: function () {
                                                return "Info";
                                            },
                                            options: function () {
                                                var options = {
                                                    archiveAvailable: false
                                                }
                                                return options;
                                            }
                                        }
                                    });
                                    modalInstance.result.then(function (result) {
                                        refreshCalendar();
                                    });
                                });
                            },dayClick: function (date, jsEvent, view) {
                                var task = {
                                    id: 0,
                                    boardId: 0,
                                    title: "",
                                    text: "",
                                    boardDate: date,
                                    status: 'blank',
                                    tags: ""
                                }
                                customerService.getAllUsers().then(function (users) {
                                    scope.users = users;
                                });
                                var modalInstance = $uibModal.open({ backdrop: 'static',
                                    animation: true,
                                    templateUrl: 'newBoardTask.html',
                                    controller: 'newBoardTaskController',
                                    resolve: {
                                        task: function () {
                                            return task;
                                        },
                                        options: function () {
                                            var options = {
                                                canChangeBoard: true,
                                                showDueDate: true,
                                                users: scope.users
                                            }
                                            return options;
                                        }
                                    }
                                });
                                modalInstance.result.then(function (result) {
                                    refreshCalendar();
                                });
                                
                            },
                            eventDrop: function (event, delta, revertFunc) {
                                var start = event.start;
                                boardTaskService().updateDate(event.id, start).then(function (taskMoved) {
                                    $rootScope.publish("SendTaskMoved", taskMoved);
                                });
                            }
                        });
                    }

                    function refreshCalendar() {
                        $(elem).find('.calendar').fullCalendar('refetchEvents');
                    }


                    function getTasks(start, end, timezone, callback) {
                        var timeperiod = {
                            from: start,
                            to: end
                        }
                        boardTaskService().getMyTasks(timeperiod).then(function (tasks) {
                            var events = [];
                            angular.forEach(tasks, function (task, key) {
                                events.push({
                                    id: task.id,
                                    start: moment(task.boardDate).utc().toDate(),
                                    title: task.title,
                                    className: task.status,
                                    allDay: true
                                });
                            });
                            return callback(events);
                        });

                    }
                }
            }
        }]);
