import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { Job, JobPart } from "@api"
import dialog from "@common/components/digilean/DigileanDialog"
import { EditJobPartDialog } from "./editJobpartDialog"

@customElement('jobparts-admin')
export class JobpartsAdmin extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        .display {
            color: var(--digilean-primary-text);
            font-size: 0.9rem;
        }
    `
    
    @property({attribute: false})
    job: Job | undefined

    async openConfigEdit(jobPart: JobPart) {
        try {
            const dialogEl = document.createElement("edit-jobpart-dialog") as EditJobPartDialog
            dialogEl.jobPart = jobPart
            const res = await dialog.openElement<JobPart>({
                title: "COMMON_CONFIGURATION",
                okBtnText: "COMMON_SAVE",
                booleanResult: false
            }, dialogEl)

            jobPart.config = res.config
            jobPart.name = res.name
            jobPart.source = res.source
            jobPart.jobPartType = res.jobPartType
            this.requestUpdate()

            this.emitUpdate(jobPart)
        }
        catch (err) {
            if (err != "cancel")
                console.error(err)
        }
    }
    toggleCheck(jobpart: JobPart) {
        jobpart.enabled = !jobpart.enabled
    }
    render() {
        if (!this.job)
            return html`<p>loading</p>`

        return html`
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"><translate-label label="COMMON_ENABLED"></translate-label></th>
                        <th scope="col"><translate-label label="COMMON_NAME"></translate-label></th>
                        <th scope="col"><translate-label label="COMMON_SOURCE"></translate-label></th>
                        <th scope="col">Job Part Type</th>
                        <th scope="col"><translate-label label="COMMON_CONFIGURATION"></translate-label></th>
                    </tr>
                </thead>
                <tbody>
                    ${this.job.jobParts?.map(jobPart => {
                        return html`
                            <tr>
                            <td></td>
                            <td>
                                <input type="checkbox" .checked=${jobPart.enabled!}
                                    @click=${() => this.toggleCheck(jobPart)}
                                />
                            </td>
                            <td>${jobPart.name}</td>
                            <td>${jobPart.source}</td>
                            <td>${jobPart.jobPartType}</td>
                            <td>
                                <span>${jobPart.config}</span>
                                <digilean-button @click=${() => this.openConfigEdit(jobPart)}>edit</digilean-button>
                            </td>
                        </tr>
                        `
                    })}
                    
                </tbody>
            </table>
        `
    }

    emitUpdate(jobpart: JobPart) {
        const options = {
            detail: jobpart,
            bubbles: false,
            composed: true
        }
        this.dispatchEvent(new CustomEvent("jobpart-update", options))
    }
}
