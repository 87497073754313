import { getAllUnitsCached } from "@common/services/businessUnitService"
angular
    .module('DigiLean')
    .controller("editDeviationTypePermissionController", ['$scope','$translate', 'deviationService', '$filter','$uibModalInstance', 'deviationTypeId', 
    function ($scope, $translate,deviationService, $filter, $uibModalInstance, deviationTypeId) {


        $scope.deviationTypeId = deviationTypeId;
        $scope.deviationType = {};

        $scope.selectedPermission = "all";
        $scope.selectedBusinessUnits = [];
        $scope.businessUnits = [];
        $scope.businessUnitsEnabled = false;


        getAllUnitsCached().then(units => {
            if (units.length > 0)
                $scope.businessUnitsEnabled = true
            $scope.businessUnits.push(...units)

            deviationService().getPermissionsForType($scope.deviationTypeId).then(function(permissions){
                $scope.selectedPermission = "all";
                $scope.selectedBusinessUnits = [];
                if($scope.businessUnits && permissions && permissions.length > 0){
                    $scope.selectedPermission = "businessunit";
                    $scope.selectedBusinessUnits = permissions;
                    //Loop through all business units to make sure previous values is not cached
                    for (var i = 0; i < $scope.businessUnits.length; i++) {        
                        $scope.businessUnits[i].include = false;                  
                    }
                    for (var i = 0; i < $scope.selectedBusinessUnits.length; i++) {  
                        var unit = $filter('filter')($scope.businessUnits, { id: $scope.selectedBusinessUnits[i].businessUnitId });
                        if(unit && unit.length > 0){
                            unit[0].include = true;
                        }                     
                    }
                } else {
                    for (var i = 0; i < $scope.businessUnits.length; i++) {        
                        $scope.businessUnits[i].include = true;   
                        $scope.includeAll = true;                  
                    }
                }
            });

        })


        $scope.deselectAll = function (include) {
            for (var k = 0; k < $scope.businessUnits.length; k++) {
                $scope.businessUnits[k].include = include;                     
            }
        };
        
        $scope.modifyPermissions = function (include) {
            $scope.includeAll = false;
            var selected = $filter('filter')($scope.businessUnits, { include: true });
            if(selected.length === $scope.businessUnits.length){
                $scope.includeAll = true;
            }
        };

        $scope.save = function () {
            var specificSelectedBusinessUnits = $filter('filter')($scope.businessUnits, { include: true });
            if($scope.selectedPermission === "all" || specificSelectedBusinessUnits.length === $scope.businessUnits.length){
                specificSelectedBusinessUnits = [];
            }

            var deviationTypeSelectedBusinessUnits = {DeviationTypeId : $scope.deviationTypeId, BusinessUnits: specificSelectedBusinessUnits}

            deviationService().updateDeviationTypePermissions($scope.deviationTypeId,deviationTypeSelectedBusinessUnits).then(function(result){
                $uibModalInstance.close()
            });          
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
