import backendHttp from "./backendHttp"
// import { systemHub } from "@common/services/systemHub"

interface CachedPromise {
    promise: Promise<any>
    created: Date
    cacheTimeMinutes: number
    expire: Date
}
let cache = new Map<string, CachedPromise>()

// 1. not cache failed requests
// 2. invalidating after time
// 3. invalidate on events

export function cachedGetRequest<T>(url: string, cacheTimeMinutes: number = 60, invalidateEvents: string[] = []): Promise<T> {

    if (!cache.has(url)) {
        createCache<T>(url, cacheTimeMinutes)
    }
    
    const cachedPromise = cache.get(url)!
    const expired = checkIfExpired(cachedPromise)
    if (expired) {
        console.log(`Cache for ${url} expired after ${cachedPromise.cacheTimeMinutes}`)
        createCache<T>(url, cacheTimeMinutes)
    }

    return cache.get(url)!.promise as Promise<T>
}

function createCache<T>(url: string, cacheTimeMinutes: number) {
    const promise = backendHttp.get<T>(url)

    promise.catch(() => {
        cache.delete(url) // cache removed because failed request
    })

    const created = new Date()
    const expire = new Date()
    expire.setMinutes(expire.getMinutes() + cacheTimeMinutes)
    cache.set(url, {created, promise, cacheTimeMinutes, expire }) // will overwrite previous cached promise
}

function checkIfExpired(cachedPromise: CachedPromise) {
    if (cachedPromise.cacheTimeMinutes == 0)
        return false
    const now = new Date().getTime()
    const expire = cachedPromise.expire.getTime()
    return now > expire
}