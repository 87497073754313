<script setup lang="ts">
    import { onMounted, onUnmounted, PropType, ref, watch } from "vue"
    import { debounce } from "lodash"
    import { DigiPdfViewer, PdfZoomOptions } from "@common/components/viewers/pdfViewer"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    const vCustomEventsEmitter = new CustomEventsEmitter()

    const props = defineProps({
        url: {
            type: String,
            required: true
        },
        zoomoption: {
            type: Object as PropType<PdfZoomOptions>,
            required: true,
            default: PdfZoomOptions.pageFit
        }
    })

    interface zoomSelectOption {
        option: PdfZoomOptions
        label: string
    }
    const viewOptions: zoomSelectOption[] = []

    for (const opt in PdfZoomOptions) {
        const option = PdfZoomOptions[opt]
        viewOptions.push({option, label: option.replace("page", "")})
    }
    const viewOptionSelected = ref<zoomSelectOption>(viewOptions[0])
    const viewOptionChanged = () => {
        vCustomEventsEmitter.emit("zoom-option-changed", viewOptionSelected.value.option)
        debouncePdfResize()
    }

    let canvasWrapperEl = ref<HTMLDivElement | null>(null)

    const checkZoomOptionProp = () => {
        if (props.zoomoption) {
            const option = viewOptions.find(o => o.option == props.zoomoption)
            if (option) {
                viewOptionSelected.value = option
                debouncePdfResize()
            }
        }
    }

    watch(() => props.url, (url) => {
        if (url)
            debounceLoadPdf()
    })
    watch(() => props.zoomoption, (opt) => {
        if (opt)
            checkZoomOptionProp()
    })
    let canvasEl = ref<HTMLCanvasElement | null>(null)
    
    let currentPageNo = ref(1)

    let totalNumOfPages = ref(1)

    let loading = ref(true)

    let pdfViewer: DigiPdfViewer | null = null

    const loadPdf = async () => {
        if (!props.url || props.url.startsWith("{")) {
            // console.log("no pdf url yet")
            return
        }
        if (!canvasEl.value) {
            // console.error("no canvas to render in")
            return
        }
        console.log("Load new pdf", props.url)
        const canvas = canvasEl.value!
        currentPageNo.value = 1
        if (pdfViewer)
            pdfViewer.dispose()
        pdfViewer = new DigiPdfViewer(props.url, canvas)
        await renderPage()
    }

    const renderPage = async () => {
        if (!canvasWrapperEl.value) {
            // console.error("no container to render in")
            return
        }
        const wrapper = canvasWrapperEl.value!
        const wrapperRectangle = wrapper.getBoundingClientRect()
        
        if (pdfViewer) {
            await pdfViewer.renderPage(currentPageNo.value, wrapperRectangle, viewOptionSelected.value.option)
            totalNumOfPages.value = pdfViewer.numberOfPages
        }
        loading.value = false
    }
    const resizePage = () => {
        if (!canvasWrapperEl.value) {
            // console.error("no container to render in")
            return
        }
        const wrapper = canvasWrapperEl.value!
        const wrapperRectangle = wrapper.getBoundingClientRect() // more accurate
        //const containerSize = { width: wrapper.clientWidth, height: wrapper.clientHeight}

        if (pdfViewer)
            pdfViewer.resizeCurrentPage(wrapperRectangle, viewOptionSelected.value.option)
        loading.value = false
    }
    
    const nextPage = () => {
        if (currentPageNo.value == totalNumOfPages.value)
            return
        currentPageNo.value += 1
        renderPage()
    }
    const prevPage = () => {
        if (currentPageNo.value == 1)
            return
        currentPageNo.value -= 1
        renderPage()
    }

    // resize observer debounced
    let threshold = 20
    let previousWrapperBoxSize: DOMRect | null = null
    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            if (entry.target.nodeName == "DIV" && entry.target.classList.contains("canvas-wrapper") && entry.contentBoxSize) {
                const wrapper = entry.target as HTMLDivElement
                const contentBoxSize = wrapper.getBoundingClientRect()
                if (previousWrapperBoxSize) {
                    const diffWidth = previousWrapperBoxSize.width - contentBoxSize.width
                    const diffHeight = previousWrapperBoxSize.height - contentBoxSize.height
                    // console.log(`diff = ${diffWidth}, ${diffHeight}`)
                    let passThreshold = Math.abs(diffWidth) > threshold || Math.abs(diffHeight) > threshold
                    if (!passThreshold)
                        continue
                }
                debouncePdfResize()
                previousWrapperBoxSize = contentBoxSize
            }
        }
    })
    
    const debounceLoadPdf = debounce(loadPdf, 1000)
    const debouncePdfResizeActual = debounce(resizePage, 200)
    const debouncePdfResize = () => {
        if (!pdfViewer)
            return // dont queue resize before initial load
        loading.value = true
        debouncePdfResizeActual()
    }
    onMounted(() => {
        if (canvasWrapperEl.value)
            resizeObserver.observe(canvasWrapperEl.value)
        checkZoomOptionProp()
        debounceLoadPdf()
    })
    onUnmounted(() => {
        if (canvasWrapperEl.value)
            resizeObserver.unobserve(canvasWrapperEl.value)
        if (pdfViewer)
            pdfViewer.dispose()
    })
</script>
<style>
    .wrapper {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        overflow: auto;
    }
    .canvas-wrapper {
        box-sizing: border-box;
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0;
    }
    
    .menu {
        padding: 0.4rem 1.4rem;
        display: inline-flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 0.5rem;
    }
    .menu-item {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
    .separator {
        margin: 0 0.3rem;
    }
    .div-spinner {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: rgba(100,100,100,0.2);;
        display: inline-flex;
        box-sizing: border-box;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }
</style>
<template>
    <link href="/assets/global.css" rel="stylesheet">
    <div v-custom-events-emitter class="wrapper">
        <div class="menu">
            <div class="menu-item">
                <span>
                    <span>{{ currentPageNo }}</span>
                    <span class="separator">/</span>
                    <span>{{ totalNumOfPages }}</span>
                </span>
                <digilean-button @click="prevPage()"> 
                    <fa-icon icon="fa fa-angle-left"></fa-icon>
                </digilean-button>
                <digilean-button @click="nextPage()">
                    <fa-icon icon="fa fa-angle-right"></fa-icon>
                </digilean-button>
            </div>
            <div class="menu-item">
                <select class="form-control" id="viewOptions" v-model="viewOptionSelected" @change="viewOptionChanged">
                    <option v-for="option in viewOptions" 
                        .value="option">{{ option.label }}

                    </option>
                </select>
            </div>
            <div class="menu-item"></div>
        </div>
        <div class="canvas-wrapper" ref="canvasWrapperEl">
            <div class="div-spinner" v-if="loading">
                <spinner-icon .spin="loading" ng-click="$ctrl.reload()"></spinner-icon>
            </div>
            <canvas ref="canvasEl"></canvas>
        </div>
    </div>
</template>
