﻿import { getUserProfile } from "@common/stores/userStore"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("learningPageComments", ['learningPublicService',
        function (learningPublicService) {
            return {
                templateUrl: 'learningPageComments.html',
                restrict: 'E',
                scope: {
                    'pageId': '<',
                    'materialId': '<'
                },
                link: function (scope) {
                    scope.showAddComment = false
                    scope.isLoadingComments = true

                    scope.currentUser = getUserProfile()
                    scope.showAddComment = true
                    scope.isLoadingComments = false
               
                    scope.text = ""
                    scope.total = 0
                    
                    scope.$watch('pageId', function (pageId) {
                        // apply
                        if (!pageId) return
                        learningPublicService.getPageComments(scope.materialId, pageId).then(function(pageComments){
                            scope.comments = pageComments.comments
                            scope.total = pageComments.total
                        })
                    
                    })
                    scope.showAddCommentVisible = function() {
                        scope.showAddComment = true
                    }
                    scope.subscribe('PageCommentAdded', function (comment) {
                        if (scope.pageId === comment.learningItemId) {
                            scope.total += 1
                        }
                    })
                    scope.subscribe('PageCommentDeleted', function (comment) {
                        if (scope.pageId === comment.learningItemId) {
                            scope.total -= 1
                        }
                    })
                
                    scope.addComment = function(comment) {
                        return scope.saveComment(comment).then(function(savedComment){
                            scope.showAddComment = false
                            scope.comments.push(savedComment)
                        })
                        
                    }
                    scope.deleteComment = function(comment) {
                        return learningPublicService.deletePageComment(scope.materialId, comment.id)
                    }
                    scope.saveComment = function(comment) {
                        comment.learningItemId = scope.pageId
                        return learningPublicService.addPageComment(scope.materialId, scope.pageId, comment)
                    }
                    employeeService.getList().then(function (data) {
                        scope.employees = data
                    })
                }
            }
        }])
