﻿/*
@param {object} settings - task
@param {object} settings - assetId
@param {object} settings - severity
@param {object} settings - deviationTypeId
@param {object} settings - incidentDate

TODO: candidate for typescript
*/

import { getUserProfile } from "@common/stores/userStore"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"

angular.module("DigiLean").controller('newDeviationController', ['$scope', '$filter', '$translate', '$uibModalInstance', 'projectService', 'navigationService', 'areaService', 'deviationService', 'authService', 'settings', 'dataListService',
    function ($scope, $filter, $translate, $uibModalInstance, projectService, navigationService, areaService, deviationService, authService, settings, dataListService) {
        $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("DeviationAdmin");
        $scope.configuration = {};
        $scope.deviation = {
            evaluationText: "",
            deviationTypeId: settings.deviationTypeId
        };
        $scope.selectedCategories = [];
        $scope.selectedArea = null;
        $scope.selectedConsequence = {};

        $scope.customfields = [];
        $scope.selectedDataListItem = null;

        $scope.steps = [];
        $scope.currentStep = {};
        $scope.settings = settings;
        $scope.users = [];
        $scope.uploadedFiles = [];

        $scope.mytime = null;
        $scope.hstep = 1;
        $scope.mstep = 5;
        $scope.options = {
            hstep: [1, 2, 3],
            mstep: [1, 5, 10, 15, 25, 30]
        };

        var severityColors = ["#c0c0c0", "#1872ab", "#FFCE54", "#DA4453"];
        $scope.severityLowDescription = "";
        $scope.severityMediumDescription = "";
        $scope.severityHighDescription = "";
        $scope.severityNotSpecifiedDescription = "";
        
        

        $scope.showTime = false;
        $scope.showArea = false;
        $scope.showProject = false;
        $scope.hasProject = navigationService().hasModule("PROJECT");
        $scope.project = null;
        $scope.projects = [];
        // if page is refreshed we need to refresh the access to modules
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasProject = navigationService().hasModule("PROJECT");
            loadProjects();
        });

        $scope.$watchCollection('selectedCategories', function (newCol, oldCol, scope) {
            if (!$scope.categories) return
            if ($scope.categories.length == 1 && newCol.length == 1) return
            if (newCol.length != oldCol.length) {
                $scope.validate();
            }
        });

        var deviationTypeId = $scope.settings.deviationTypeId;
        deviationService().getDeviationTypeConfiguration(deviationTypeId).then(function (configuration) {
            $scope.configuration = configuration;
            checkFields($scope.configuration.fields);
            $scope.categories = $scope.configuration.categories;
            setSingelCategory();
            $scope.consequences = $scope.configuration.consequences;
            $scope.problemReasons = $scope.configuration.causes;
            $scope.severities = $scope.configuration.severities;
            setSeverityDescription();
            if($scope.deviation){
                $scope.changeSeverity($scope.deviation.severity);
            }
        });

        function setSingelCategory(){
            if($scope.categories && $scope.categories.length == 1){
                $scope.selectedCategories.push($scope.categories[0]);
            }
        }

        function setSeverityDescription(){
            if($scope.severities){
                for(var i=0; i< $scope.severities.length; i++){
                    if($scope.severities[i].severity == 0){
                        $scope.severityNotSpecifiedDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 1){
                        $scope.severityLowDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 2){
                        $scope.severityMediumDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 3){
                        $scope.severityHighDescription = $scope.severities[i].customLanguageLabel.label;
                    }
                }    
            }
        }
        
        $scope.descriptionChanged = function (content) {
            $scope.deviation.text = content;
        }

        function checkFields(validFields) {
            if (validFields) {
                for (var f = 0; f < validFields.length; f++) {
                    switch (validFields[f].type) {
                        case "PROJECT":
                            loadProjects();
                            break;
                        case "AREA":
                            loadAreas();
                            break;
                        case "DEVIATION_INCIDENT_TIME":
                            $scope.showTime = true;
                            break;
                        case "list":
                            setCustomList(validFields[f]);
                            
                            break;
                        default:
                            $scope.customfields.push(validFields[f]);
                    }
                }
            }
        }

        function setCustomList(field){
            var listOptions = {includeDeletedItems:false};
            dataListService().getItems(field.dataListId, listOptions).then(function (list) {
                field.datalistelements = list;
                $scope.customfields.splice(field.sortOrder, 0, field);
            });
        }

        function loadProjects() {
            if(!$scope.projects || $scope.projects.length == 0){
                projectService().getOngoingProjects().then(function (projects) {
                    $scope.projects = projects;
                    $scope.projectmodel = { projectId: $scope.deviation.projectId,
                                            projects: $scope.projects}
                    $scope.showProject = true;
                });
            }
        }

        $scope.projectChangeHandler = function (projectinfo) {
            if (projectinfo) {
                $scope.deviation.projectId = projectinfo.project.id;
            } else {
                $scope.deviation.projectId = null;
            }                       
        }

        function loadAreas() {
            areaService().getList().then(function (areas) {
                $scope.areas = areas;
                $scope.showArea = true;
            });
        }

        $scope.incidentDateChanged = function($event) {
            const date = $event.originalEvent.detail
            $scope.deviation.incidentDate = date
        }

        $scope.changeSeverity = function (severity) {
            $scope.deviation.severity = severity;
            $scope.background = severityColors[$scope.deviation.severity];
            $scope.severityDescription = "";
            if($scope.severities){
                var selectedSeverity = $filter('filter')($scope.severities, { severity: severity }, true)[0];
                if(selectedSeverity){
                    $scope.severityDescription = selectedSeverity.customLanguageLabel.label;
                }         
            }
        };

        function reset() {
            $scope.datetimepickerOpen = function () {
                $scope.datetimePicker.opened = true;
            }
            $scope.datetimePicker = {
                opened: false
            };

            $scope.deviation.incidentDate = moment().toDate();
            if($scope.settings.incidentDate){
                $scope.deviation.incidentDate = $scope.settings.incidentDate;
            }
            $scope.deviation.incidentDate.setHours(0);
            $scope.deviation.incidentDate.setMinutes(0);


            const currentUser = getUserProfile()
            $scope.users.push(currentUser);
            $scope.user = currentUser;
            $scope.user.fullName = $scope.user.firstName + " " + $scope.user.lastName;
            $scope.deviation.createdByUserId = $scope.user.userId;
            $scope.deviation.createdByUser = $scope.user.fullName;

            $scope.currentStep = $scope.steps[0];
            if ($scope.settings.severity) {
                $scope.changeSeverity($scope.settings.severity);
            } else {
                $scope.changeSeverity(0);
            }

            if ($scope.settings.task) {
                $scope.task = $scope.settings.task;
                $scope.deviation.boardTaskId = $scope.task.taskId;
                $scope.deviation.title = $scope.settings.task.title;
                $scope.deviation.text = $scope.settings.task.text; //TODO: is this needed?
            }
        }
        reset();

        $scope.setTime = function (time) {
            $scope.deviation.incidentDate.setHours(time.getHours());
            $scope.deviation.incidentDate.setMinutes(time.getMinutes());
        };

        $scope.areaChanged = function (area) {
            $scope.deviation.areaId = area.id;
        };

        $scope.changeRegisteredBy = function (user) {
            $scope.user = user;
            if (user && user.userId) {
                $scope.deviation.createdByUserId = $scope.user.userId;
                $scope.deviation.createdByUser = $scope.user.fullName;
            } else {
                $scope.deviation.createdByUserId = null;
                $scope.deviation.createdByUser = null;
            }          
        };

        assetService.getAllAssets().then(function (assets) {
            $scope.assets = assets;
            for (var k = 0; k < $scope.assets.length; k++) {
                $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
            }

            if ($scope.settings.assetId) {
                $scope.deviation.assetId = $scope.settings.assetId;
                $scope.deviation.followUpAssetId = $scope.settings.assetId;
            }
            if($scope.isAdmin){
                $scope.assetmodel = { assetId: $scope.settings.assetId,
                    assets: $scope.assets};
            } else {
                assetService.getList().then(function (myAssets) {
                    $scope.assetmodel = { assetId: $scope.settings.assetId,
                        assets: myAssets};
                });
            }     
            $scope.followupassetmodel = { assetId: $scope.settings.assetId,
                assets: $scope.assets};
        });


        $scope.steps.push({ id: 'STEP_1', title: $translate.instant("COMMON_DESCRIPTION") });
        $scope.steps.push({ id: 'STEP_2', title: $translate.instant("COMMON_CATEGORIES") });
        $scope.steps.push({ id: 'STEP_3', title: $translate.instant("DEVIATION_CONSEQUENCE") });
        $scope.steps.push({ id: 'STEP_4', title: $translate.instant("DEVIATION_CAUSE") });
        $scope.steps.push({ id: 'STEP_5', title: $translate.instant("DEVIATION_HANDLING") });
        $scope.currentStep = $scope.steps[0];


        $scope.modifyReason = function (reason) {
            if ($scope.deviation.problemReasons) {
                var i = $scope.deviation.problemReasons.indexOf(reason.id);
                if (i == -1) { //reason is not allready selected
                    $scope.deviation.problemReasons.push(reason.id);
                } else {
                    $scope.deviation.problemReasons.splice(i);
                }
            } else {
                $scope.deviation.problemReasons = [];
                $scope.deviation.problemReasons.push(reason.id);
            }
        }

        $scope.modifyConsequence = function (consequence) {
            if ($scope.deviation.consequences) {
                var match = $filter('filter')($scope.deviation.consequences, { lossTypeId: consequence.id }, true);
                if (match.length === 1) {
                    if (consequence.checked) {
                        match[0].amount = consequence.amount;
                    } else {
                        consequence.amount = null;
                        var index = $scope.deviation.consequences.indexOf(match[0]);
                        $scope.deviation.consequences.splice(index);
                    }
                } else { //consequences is not allready selected
                    let deviationConsequence = {
                        lossTypeId: consequence.id,
                        amount: consequence.amount,
                        unit: consequence.unit
                    };
                    $scope.deviation.consequences.push(deviationConsequence);
                }
            } else {
                $scope.deviation.consequences = [];
                let deviationConsequence = {
                    lossTypeId: consequence.id,
                    amount: consequence.amount,
                    unit: consequence.unit
                };
                $scope.deviation.consequences.push(deviationConsequence);
            }
        };

        $scope.setConsequence = function (consequence) {
            if (consequence.amount) {
                consequence.checked = true;
                
                var valueString = consequence.amount.toString();
                var valuereplacecomma = valueString.replace(/,/g, '.');
                consequence.amount = valuereplacecomma;
                if (consequence.amount == '' || Number(consequence.amount)) {
                    consequence.notvalidnumber = false;
                    $scope.modifyConsequence(consequence);
                } else {
                    consequence.notvalidnumber = true;
                }   
            } else {
                consequence.notvalidnumber = false;
            }
        };

        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        };

        $scope.assetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                $scope.deviation.assetId = assetinfo.asset.id;
                customerService.getUsersForAsset(assetinfo.asset.id).then(function (assetUsers) {
                    $scope.users = assetUsers;
                    angular.forEach($scope.users, function (user, key) {
                        user.fullName = user.firstName + " " + user.lastName;
                    });
                    if ($scope.user && $scope.user.userId) {
                        var match = $filter('filter')($scope.users, { userId: $scope.user.userId });
                        if (match.length > 0) {
                            $scope.user = match[0];
                        }
                    }
                });
            }       
        }

        $scope.followUpAssetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                $scope.deviation.followUpAssetId = assetinfo.asset.id;
            }       
        }

        // Attachments
        $scope.showAttachments = false;
        $scope.toggleShowAttachments = function (show) {
            $scope.showAttachments = show;
        };

        // Handling
        $scope.isResolved = false;
        $scope.resolveDeviation = function () {
            $scope.isResolved = !$scope.isResolved;
            if ($scope.isResolved) {
                $scope.deviation.status = 20;
            } else {
                $scope.deviation.status = 0;
                $scope.deviation.evaluationText = "";
            }
        };

        $scope.nextStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if ((currentIndex + 1) < $scope.steps.length) {
                $scope.selectStep($scope.steps[currentIndex + 1]);
            }
        };
        $scope.previousStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if (currentIndex > 0) {
                $scope.selectStep($scope.steps[currentIndex - 1]);
            }
        };

        $scope.isValid = true;
        $scope.validationMessages = [];
        $scope.validate = function () {
            // Rules
            $scope.validationMessages = [];
            // Has categories
            if ($scope.selectedCategories.length == 0) {
                $scope.validationMessages.push($translate.instant("DEVIATION_VALIDATION_CATEGORIES_REQUIRED"));
            }

            //Check mandatory custom fields
            if(mandatoryFieldsMissing()){
                $scope.validationMessages.push($translate.instant("ADMIN_DATA_FIELD_MISSING"));
            }
            // If deviation closed, we need an evaluation test
            if ($scope.isResolved && $scope.deviation.evaluationText.length == 0) {
                // evaluationText
                // statusDisplayText: translations.DEVIATION_RESOLVED,
                // statusDatabase: 20
                $scope.validationMessages.push($translate.instant("DEVIATION_VALIDATION_EVALUATION_TEXT_REQUIRED"));
            }

            if ($scope.validationMessages.length > 0) {
                $scope.isValid = false;
            } else {
                $scope.isValid = true;
            }
            return $scope.isValid;
        };

        $scope.checkIfValidate = function(){
            // check if error message should be updated and the form is valid
            if(!$scope.isValid){ //the form has been previous validated as invalid
                $scope.validate();
            }
        }

        function mandatoryFieldsMissing(){
            for(let index = 0; index < $scope.customfields.length; index++){
                if($scope.customfields[index].isMandatory){
                    if($scope.customfields[index].type == 'list'){
                        if(!($scope.customfields[index].selectedDataListItem && $scope.customfields[index].selectedDataListItem.id)){
                           return true;
                        }
                    } else if($scope.customfields[index].type == 'number'){
                        if(isNaN($scope.customfields[index].value)){
                            return true;
                         }
                    } else if($scope.customfields[index].type == 'text'){
                        if(!$scope.customfields[index].value){
                            return true;
                         }
                    }
                }

            }
            return false;
        }

        function saveCustomFieldsValues(deviationId){
            if(deviationId){
                var deviationtypefieldvalues = [];
                for(let index = 0; index < $scope.customfields.length; index++){
                    if($scope.customfields[index].type == 'list'){
                        if($scope.customfields[index].selectedDataListItem && $scope.customfields[index].selectedDataListItem.id){
                            var valueData = {
                                DeviationId: deviationId,
                                DeviationTypeFieldId: $scope.customfields[index].id,
                                Value:  $scope.customfields[index].selectedDataListItem.id
                            }
                            deviationtypefieldvalues.push(valueData);
                        }
                    } else {
                        var valueData = {
                            DeviationId: deviationId,
                            DeviationTypeFieldId: $scope.customfields[index].id,
                            Value: $scope.customfields[index].value
                        }
                        deviationtypefieldvalues.push(valueData);
                    }
                }
                if(deviationtypefieldvalues.length > 0 ){
                    deviationService().addCustomFields(deviationId, deviationtypefieldvalues).then(function (result) {
                    });
                }

            }
        }

        $scope.isSaving = false;
        $scope.addDeviation = function () {
            if (!$scope.validate()) return;

            $scope.isSaving = true;
            $scope.deviation.files = $scope.uploadedFiles;
            $scope.deviation.categories = [];
            for (let index = 0; index < $scope.selectedCategories.length; index++) {
                const element = $scope.selectedCategories[index];
                $scope.deviation.categories.push({
                    problemCategoryId: element.id
                });
            }
            deviationService().addDeviation($scope.deviation).then(function (createdDeviation) {
                saveCustomFieldsValues(createdDeviation.id);
                if ($scope.settings.task) {
                    $scope.publish("SendTaskDeviationCreated", $scope.settings.task);
                }
                $scope.isSaving = false;
                $uibModalInstance.close(createdDeviation.id);
            });
        };

        $scope.cancel = function () {
            $scope.isSaving = false;
            $uibModalInstance.dismiss('cancel');
        };

        $scope.deviation.isAnonymous = false;
    }]);
