import moment from "moment"
import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as profileImageService from "@common/services/user/profileImageService"

angular
    .module('DigiLean')
    .directive("goalKanban", ['$uibModal', '$filter', '$translate', 'strategyService', 'boardTaskService',
        function ($uibModal, $filter, $translate, strategyService, boardTaskService) {
            return {
                templateUrl: 'goalKanban.html',
                restrict: 'E',
                scope: {
                    'strategyGoalId': '=',
                    'assetId': '=',
                },
                link: function (scope, elem, attrs) {
                    // scope.columns = [];
                    scope.tasks = [];
                    var timeperiodAll = timeService.getTimePeriod('all');

                    function resetColumn(){
                        scope.columns = [
                            {
                                title: $translate.instant("DASHBOARD_CURRENT_MONTH"),
                                timeframe: "month",
                                showAddButton: true,
                                tasks:[]
                                //columnProvider: statusColumnProvider,
                                //groupModel: null
                            },
                            {
                                title: $translate.instant("DASHBOARD_QUATER"),
                                timeframe: "quarter",
                                showAddButton: true,
                                tasks:[]
                            },
                            {
                                title: $translate.instant("DASHBOARD_THIS_YEAR"),
                                timeframe: "thisyear",
                                showAddButton: true,
                                tasks:[]
                            },
                            {
                                title: $translate.instant("STRATEGY_WITHIN_DUE_DATE"),
                                timeframe: "all",
                                showAddButton: true,
                                tasks:[]
                            },
                        ];
                        setTimePeriod();
                    }
                    resetColumn();
                    
                    scope.$watch('strategyGoalId', function (strategyGoalId) {
                        if (!scope.strategyGoalId) return;
                        if(scope.assetId){      
                            loadTeamGoals();
                        } else {
                            getAllUsers();
                            scope.strategyGoals = [{id:scope.strategyGoalId}];
                            var timeperiod = timeService.getTimePeriod(scope.columns[0].timeframe);
                            getTasks(timeperiod);
                        }
                       
                        
                    });

                    scope.$watch('assetId', function () {
                        if (!scope.strategyGoalId) return;
                        if(scope.assetId){
                            getAllUsers();
                            loadTeamGoals();
                        } else {
                            scope.strategyGoals = [{id:scope.strategyGoalId}];
                            var timeperiod = timeService.getTimePeriod(scope.columns[0].timeframe);
                            getTasks(timeperiod);
                        }
                    });

                    function loadTeamGoals() {
                        if (scope.strategyGoalId == null) return;
                        if (scope.assetId == null) return;
                        strategyService().getTeamGoals(scope.strategyGoalId, scope.assetId).then(function(goals){
                            
                            scope.strategyGoals = goals;
                            resetColumn();
                            var timeperiod = timeService.getTimePeriod(scope.columns[0].timeframe);
                            if(!scope.users || scope.users.length == 0){
                                getAllUsers();
                            }
                            getTasks(timeperiod);

                        });
                    }

                        // Get all users until we decide to restrict who can be assign a task
                    async function getAllUsers() {
                        scope.users = await profileImageService.getAllUsersIncludeProfileImage()
                    }

                    function setTimePeriod(){
                        var endOfYear = timeService.getTimePeriod('year').to;
                        for(var i=0; i<scope.columns.length; i++){
                            if(!scope.columns[i].timeperiod){
                                var timeperiod = timeService.getTimePeriod(scope.columns[i].timeframe);
                                scope.columns[i].timeperiod = timeperiod;
                                if(i>0){
                                    scope.columns[i].timeperiod.from = moment(scope.columns[i-1].timeperiod.to).add(1, 'days');
                                }
                                var endDate = moment(scope.columns[i].timeperiod.to);
                                if(i == 0 && endDate.isSame(endOfYear)){
                                    scope.columns[1].title = $translate.instant("DASHBOARD_NEXT_QUATER");
                                    scope.columns[1].timeframe = "nextquarter";
                                    scope.columns[2].title = $translate.instant("DASHBOARD_NEXT_YEAR");
                                    scope.columns[2].timeframe = "nextyear";
                                }
                                if(i == 1 && endDate.isSame(endOfYear)){
                                    scope.columns[2].title = $translate.instant("DASHBOARD_NEXT_YEAR");
                                    scope.columns[2].timeframe = "nextyear";
                                }
                            }
                        }
                    }
                    

                    

                    function getTasks(timeperiod){
                        resetColumn();
                        strategyService().getTasksForStrategyGoals(scope.strategyGoals, timeperiod).then(function (tasks) {
                            
                            for(var i=0; i<tasks.length; i++){
                                initTask(tasks[i]);
                                placeTaskInColumn(tasks[i]);
                            }    
                        });
                    }

                    scope.subscribe('TaskDateMoved', function (moveSettings) {
                        scope.columns.forEach(function (column) {
                            var exists = $filter('filter')(column.tasks, { id: moveSettings.taskId });
                            if (exists.length > 0) { 
                                var index = column.tasks.indexOf(exists[0]);
                                if(index >= 0){
                                    column.tasks.splice(index, 1);
                                    exists[0].boardDate = moveSettings.boardDate;
                                    placeTaskInColumn(exists[0]);
                                }
                            }                     
                        });
                    });

                    scope.$watch('users', function (users) {
                        if (!users) return;
                        scope.users = users;
                    });

                    function placeTaskInColumn(task){
                        if(!task.boardDate){
                            scope.columns[3].tasks.push(task);
                        } else {
                            for(var i=0; i<scope.columns.length; i++){
                                if(task.boardDate <= scope.columns[i].timeperiod.to){
                                    var duration = moment.duration(moment().diff(moment(task.boardDate)));
                                    var daysoverdue = duration.asDays();
                                    if(i == 0 && daysoverdue > 1 && task.status != 'OK'){
                                        task.overdue = true;
                                    } else {
                                        task.overdue = false;
                                    }
                                    scope.columns[i].tasks.push(task);
                                    i = scope.columns.length;
                                }
                            }
                        }
                    }

                    function placeTaskFirstInColumn(task){
                        if(!task.boardDate){
                            scope.columns[3].tasks.push(task);
                        } else {
                            for(var i=0; i<scope.columns.length; i++){
                                if(task.boardDate <= scope.columns[i].timeperiod.to){
                                    scope.columns[i].tasks.unshift(task);
                                    i = scope.columns.length;
                                }
                            }
                        }
                    }
                    scope.columnOptions = {
                        connectWith: ".task-list",
                        receive: function (e, ui) {
                            var target = ui.item.sortable.droptarget[0];
                            var columnIndex = $(target).attr("data-column-index");
                            var task = ui.item.sortable.model;
                            // Find column
                            var column = scope.columns[columnIndex];

                            task.animate("bounce");
                            if(!column.timeperiod){
                                var timeperiod = timeService.getTimePeriod(column.timeframe);
                                column.timeperiod = timeperiod;
                            }
                            var newDate = column.timeperiod.from; //moment(); 
                            if(moment(newDate).isBefore(moment())){
                                newDate = moment().startOf('day');
                            }
                            boardTaskService().updateDate(task.id, newDate).then(function (result) {
                            });
                        }
                    };

                    var initTask = function (task) {
                        /*task.model = {
                            task: task,
                            boardTaskId: task.id
                        }*/
                        task.animate = function (animation, timeOut) {
                            if (!timeOut) {
                                timeOut = 1000;
                            }
                            task.animateClass = "animated " + animation;
                            setTimeout(function () {
                                task.animateClass = "";
                            }, timeOut);
                        }
                        task.animate("slideInDown", 3000);
                    };

                                
                    scope.subscribe('NewTask', function (task) {
                            if (!task) return;
                            if (task.goals){
                                for(var g=0; g<task.goals.length; g++){
                                    var relevantGoal = $filter('filter')(scope.strategyGoals, { id: task.goals[g].strategyGoalId });
                                    if (relevantGoal.length > 0) {
                                        initTask(task);
                                        placeTaskFirstInColumn(task);
                                    }
                                }
                            }
                        });
                        scope.subscribe('TaskDeleted', function (task) {
                            if (!task) return;
                            //if (task.goals){
                                //for(var g=0; g<task.goals.length; g++){
                                    //if(task.goals[g].strategyGoalId == scope.strategyGoalId) {
                                            // use time so animation shows before removing task from list.
                                        setTimeout(function () {
                                            scope.columns.forEach(function (column) {
                                                var exists = $filter('filter')(column.tasks, { id: task });
                                                if (exists.length > 0) { 
                                                    var index = column.tasks.indexOf(exists[0]);
                                                        initTask(exists[0]); // init task so we can animate
                                                        // animate removal
                                                        exists[0].animate("fadeOut", 2000);
                                                        column.tasks.splice(index, 1);
                                                }                     
                                            });
                                        }, 1500);
                                    //}
                                //}
                            //}
                        });

                    
                    function openBoardTask(column, goalId){
                        var status = "blank";
                        var options = {
                            users: scope.users, 
                            showDueDate: true           
                        }
                        if(!column.timeperiod){
                            var timeperiod = timeService.getTimePeriod(column.timeframe);
                            column.timeperiod = timeperiod;
                        }

                        var newDate = column.timeperiod.from; 
                        if(moment(newDate).isBefore(moment())){
                            newDate = moment().startOf('day');
                        }
                        var task = {
                            id: 0,
                            boardId: null,
                            title: "",
                            text: "",
                            status: status,
                            tags: "",
                            boardDate: moment(newDate).toDate(),
                            goals: [{Id:0,
                                BoardTaskId: 0,
                                StrategyGoalId:goalId}]
                        }



                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                        });

                    }

                    scope.createTask = function (column) {

                        if(scope.strategyGoals.length == 1){
                            openBoardTask(column, scope.strategyGoals[0].id);
                        } else {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'goalSelector.html',
                            controller: 'goalSelectorController',
                            resolve: {
                                goals: function () {
                                    return scope.strategyGoals;
                                }
                            }
                        });
    
                        modalInstance.result.then(function (result) {
                            openBoardTask(column, result);
                        });
                        }                        
                    }


                }

            }
        }]);