import { isEqual, omitBy, isUndefined, transform, omit, isObject, keyBy } from "lodash"

export function fixTransparentText(settings) {
    if (!settings || !settings.theme || !settings.theme.background) return
    if (settings.theme.background == "transparent") {
        settings.theme.background = "var(--digilean-primary-text)"
        settings.theme.fillColor = "var(--digilean-secondary-background)"
    }
}

export function isObjectEqual(object1, object2) {

    if (!object1 || !object2) 
      return isEqual(object1, object2);
  
    const typeOf1 = typeof object1
    const typeOf2 = typeof object2

    if (typeOf1 !== 'object' || typeOf2 !== 'object')
        return isEqual(object1, object2)

    const copy1 = omitBy(object1, isUndefined)
    const copy2 = omitBy(object2, isUndefined)
    const timePeriod1 = object1.timePeriod;
    const timePeriod2 = object2.timePeriod;

    if (timePeriod1 && timePeriod2) {
      const timeframe1 = timePeriod1.timeframe;
      const timeframe2 = timePeriod2.timeframe;

      // if both timeframes are custom, compare the from and to dates
      if (timeframe1 === 'custom' && timeframe2 === 'custom') {
        return isEqual(copy1, copy2);
      }

      // if both timeframes are customStartDate, compare the from date only                                                  
      if (timeframe1 === 'customStartDate' && timeframe2 === 'customStartDate') {
        const removedToCopy1 = deepOmit(copy1, ['to']);
        const removedToCopy2 = deepOmit(copy2, ['to']);
        return isEqual(removedToCopy1, removedToCopy2);
      }

      // if both timeframes are customEndDate, compare the to date only
      if (timeframe1 === 'customEndDate' && timeframe2 === 'customEndDate') {
        const removedFromCopy1 = deepOmit(copy1, ['from']);
        const removedFromCopy2 = deepOmit(copy2, ['from']);
        return isEqual(removedFromCopy1, removedFromCopy2);
      }
    }

    const removedFromToCopy1 = deepOmit(copy1, ['from', 'to']);
    const removedFromToCopy2 = deepOmit(copy2, ['from', 'to']);
    return isEqual(removedFromToCopy1, removedFromToCopy2);
}

export function deepOmit(obj, keysToOmit) {
    var keysToOmitIndex =  keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit] ); // create an index object of the keys that should be omitted
  
    function omitFromObject(obj) { // the inner function which will be called recursivley
      return transform(obj, function(result, value, key) { // transform to a new object
        if (key in keysToOmitIndex) { // if the key is in the index skip it
          return;
        }
  
        result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
      })
    }
    
    return omitFromObject(obj); // return the inner function result
  }