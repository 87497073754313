﻿import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import * as employeeService from "@common/services/employeeService"

angular
.module('DigiLean')
.directive("assetMessage", ['$filter', '$translate', 'modalService',
        function ($filter, $translate, modalService) {
            return {
                templateUrl: 'assetMessage.html',
                restrict: 'E',
                scope: {
                    'assetid': '=',
                    'startdate': '=',
                    'enddate': '='
                },
                controller: ['$scope', '$uibModal', function($scope, $uibModal) {
                  
                    $scope.hasComments = function(msg) {
                        return msg.replies && Array.isArray(msg.replies) && msg.replies.length > 0;
                    };
                    $scope.openReplies = function(msg) {
                        $uibModal.open({ backdrop: 'static',
                            component: 'messageRepliesDialog',
                            resolve: {
                                msg: function () {
                                    return msg;
                                }
                            }
                        });
                    };
                    $scope.subscribe("MessageReplySent", function(r) {
                        for(var i=0; i<$scope.messages.length; i++) {
                            var msg = $scope.messages[i];
                            if (r.parentId == msg.id) {
                                timeService.addTimeElapsedForMsg(r);
                                if(!msg.replies){
                                    msg.replies = [];
                                }
                                msg.replies.unshift(r);
                            }
                        }
                    });
                }],
                link: function (scope, element, attrs) {
                    scope.messages = [];

                    scope.messageText = "";
                    scope.showNewMessage = false;
                    scope.isLoading = true;
                    scope.isMember = false;

                    scope.$watch('assetid', function(assetId) {
                        if (assetId) {
                            employeeService.getMembership(scope.assetid).then(function(userAsset) {
                                if (userAsset && (userAsset.assetRole == "Member" || userAsset.assetRole == "Owner")) {
                                    scope.isMember = true;
                                }
                            })
                        }
                    });

                    scope.showAddMessage = function() {
                        scope.showNewMessage = true;
                    };

                    scope.toggleMessageEdit = function(message) {
                        message.edit = !message.edit;
                    };

                    scope.delete = function (message) {
                        var modalOptions = {
                            closeButtonText: $translate.instant("COMMON_CANCEL"),
                            actionButtonText: $translate.instant("COMMON_DELETE"),
                            headerText: $translate.instant("COMMON_DELETE") + ": "+ message.title + "?",
                            bodyText: $translate.instant("COMMON_DELETE_ITEM_DESCRIPTION"),
                        };

                        modalService.showModal({}, modalOptions).then(function (result) {
                            customerService.deleteMessage(message.id).then(function () {

                            });
                        });

                    };
                    
                    scope.updateMessage = function(message) {
                        message.edit = false;
                        customerService.updateMessage(message).then(function () {
                            
                        });
                    };

                    scope.addMessage = function() {
                        assetService.getById(scope.assetid).then(function (asset) {
                            
                            var msgtitle = $translate.instant('BOARD_POSITIVE_MESSAGE') + " - " + asset.name;
                            var time = new Date();
                            if (scope.enddate && moment(time).isBefore(scope.startdate) ||
                            moment(time).isAfter(scope.enddate)){
                                time = scope.enddate;
                            }
                            var newmessage = {
                                title: msgtitle,
                                text: scope.messageText,
                                assetId: scope.assetid,
                                sendTime: time
                            }
                            scope.showNewMessage = false;
                            scope.isLoading = true;
                            customerService.sendBoardMessage(newmessage).then(function(message){
                                scope.messageText = "";
                                scope.isLoading = false;
                                //scope.messages.unshift(message);
                            });
                        });
                        
                    };
                    scope.$watch('assetid', function (assetid) {
                        if (!assetid) return;
                        var timePeriod = 
                        {
                            from:scope.startdate,
                            to:scope.enddate
                        }
                        if(!scope.startdate || !scope.enddate){
                            timePeriod = timeService.getTimePeriod('30');
                        }
                        
                        customerService.getMessagesByAsset(scope.assetid, timePeriod).then(function (result) {
                            scope.messages = result;
                            scope.isLoading = false;
                            scope.messages.forEach(message => {
                                message.replies = [];
                                customerService.getReplies(message.id).then(function(replies) {
                                    message.replies = replies;
                                });
                                // replace linebreaks
                                message.text = message.text.replace(/\r\n|\r|\n/g, "<br />");
                                message.edit = false;
                            }); 
                        });
                    });

                    

                    scope.subscribe("MessageSent", function (message) {
                        if (message.assetId === scope.assetid) {
                            message.edit = false;
                            scope.messages.unshift(message);        
                        }
                    });

                    scope.subscribe('MessageChanged', function (message) {

                        var msg = $filter('filter')(scope.messages, { id: message.id });
                        if (msg.length > 0) {
                            scope.$apply(function () {
                                var index = scope.messages.indexOf(msg[0]);
                                if (index > -1) {
                                    message.edit = false;
                                    scope.messages[index] = message;
                                }
                            });
                        }
                    });
                    
                    scope.subscribe('MessageDeleted', function (message) {

                        var msg = $filter('filter')(scope.messages, { id: message.id });
                        if (msg.length > 0) {
                            scope.$apply(function () {
                                var index = scope.messages.indexOf(msg[0]);
                                if (index > -1) {
                                    scope.messages.splice(index, 1);
                                }
                            });
                        }
                    });

                }
            };
        }]);
