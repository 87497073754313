import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from "vue"
    import { getBusinessUnitUsers } from "@common/services/businessUnitService"
    import { User } from "@api"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'businessUnitMembers',
  props: {
        id: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: false
        }
    },
  setup(__props) {

    const props = __props

    let users = ref<User[]>([])

    let getUsers = async () => {
        if (!props.id)
            return
        
        let us = await getBusinessUnitUsers(props.id)
        users.value = us
    }
    onMounted(() => {
        getUsers()
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(__props.name), 1 /* TEXT */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(users), (user) => {
      return (_openBlock(), _createElementBlock("p", null, _toDisplayString(user.firstName) + " " + _toDisplayString(user.lastName), 1 /* TEXT */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}
}

})