import * as assetService from "@common/services/assetService"

//
// task-list-filter component
// 
angular
    .module('DigiLean')
    .component("taskListFilter", {
        templateUrl: 'taskListFilter.html',
        bindings: {
            filterInitial: '<',
            onFilterChanged: '&',
            showStatusFilter: '<'
        },
        controller: ['$scope', '$uibModal', '$translate', 'boardTaskService', 'authService', 'debounce', 'navigationService', 'strategyService',
            function ($scope, $uibModal, $translate, boardTaskService, authService, debounce, navigationService, strategyService) {
                let $ctrl = this

                $ctrl.query = ""
                $ctrl.isSearchWordActive = false
                $ctrl.likesFilterActive = false
                $ctrl.myResponsibleFilterActive = false
                $ctrl.overdueFilterActive = false
                $ctrl.assets = []
                $ctrl.projects = []
                $ctrl.hasStrategyModule = false

                //
                $ctrl.isMoreTagsAvailable = false;
                $ctrl.tagsVisible = []
                $ctrl.tags = []

                $ctrl.statuses = [
                    { id: 'blank', name: $translate.instant("COMMON_NEW"), filteredAndInResultList: false },
                    { id: 'OK', name: $translate.instant("COMMON_OK"), filteredAndInResultList: false },
                    { id: 'unacceptable', name: $translate.instant("COMMON_REJECTED"), filteredAndInResultList: false }
                ]

                $ctrl.priorityOptions = [
                    {
                        priorityDB: 1,
                        translated: $translate.instant('task_LOW_EFFORT_HIGH_EFFECT'),
                        cssClass: "highEffectLowEffort",
                        filteredAndInResultList: false
                    }, {
                        priorityDB: 2,
                        translated: $translate.instant('task_HIGH_EFFORT_HIGH_EFFECT'),
                        cssClass: "highEffectHighEffort",
                        filteredAndInResultList: false
                    }, {
                        priorityDB: 3,
                        translated: $translate.instant('task_LOW_EFFORT_LOW_EFFECT'),
                        cssClass: "lowEffectlowEffort",
                        filteredAndInResultList: false
                    }, {
                        priorityDB: 4,
                        translated: $translate.instant('task_HIGH_EFFORT_LOW_EFFECT'),
                        cssClass: "lowEffectHighEffort",
                        filteredAndInResultList: false
                    }, {
                        priorityDB: 0,
                        translated: $translate.instant('task_NOT_PRIORITIZED'),
                        cssClass: "btn-default",
                        filteredAndInResultList: false
                    }
                ]

                function resetListFilter(list) {
                    list.forEach(item => {
                        item.filteredAndInResultList = false
                    })
                }


                $ctrl.toggleOverdueFilter = function () {
                    $ctrl.overdueFilterActive = !$ctrl.overdueFilterActive
                    filterDebounce()
                }

                $ctrl.filterPriority = function (priority) {
                    priority.filteredAndInResultList = !priority.filteredAndInResultList
                    filterDebounce()
                }


                $ctrl.resetFilter = function () {
                    $ctrl.query = ""
                    resetListFilter($ctrl.statuses)
                    resetListFilter($ctrl.assets)
                    resetListFilter($ctrl.projects)
                    resetListFilter($ctrl.goals)
                    resetListFilter($ctrl.priorityOptions)
                    $ctrl.overdueFilterActive = false
                    $ctrl.myResponsibleFilterActive = false
                    $ctrl.overdueFilterActive = false
                    $ctrl.filter = []
                    if ($ctrl.onFilterChanged) {
                        $ctrl.onFilterChanged({ filter: [] })
                    }
                    angular.forEach($ctrl.tags, function (tag) {
                        tag.filteredAndInResultList = false;
                    });
                }

                $ctrl.toggleMyResponsibleFilter = function () {
                    $ctrl.myResponsibleFilterActive = !$ctrl.myResponsibleFilterActive
                    filterDebounce()
                }

                $ctrl.showAllTags = function () {
                    $ctrl.tagsVisible = $ctrl.tags;
                    $ctrl.isMoreTagsAvailable = false;
                }

                function getAllData() {
                    let promises = []
                    promises.push(assetService.getAllAssets().then(assets => {
                        $ctrl.assets = _.sortBy(assets, function (i) { return i.name.toLowerCase() })
                        return true
                    }))

                    promises.push(boardTaskService().getTagCount().then(tags => {
                        $ctrl.tags = tags;
                        if (tags.length > 20) {
                            $ctrl.isMoreTagsAvailable = true;
                            $ctrl.tagsVisible = tags.slice(0, 19);
                        } else {
                            $ctrl.tagsVisible = tags;
                        }
                        return true
                    }))

                    promises.push(strategyService().getBreakthroughObjectives().then(function (result) {
                        $ctrl.goals = result
                        return true
                    }))

                    Promise.all(promises).then(() => {
                        addInitialFilter()
                    })
                }
                getAllData()

                $ctrl.search = function () {
                    if ($ctrl.query) {
                        $ctrl.isSearchWordActive = true

                    } else {
                        $ctrl.isSearchWordActive = false
                    }
                    filterDebounce()
                }


                $ctrl.filterStatus = function (status) {
                    status.filteredAndInResultList = !status.filteredAndInResultList
                    filterDebounce()
                }
                $ctrl.filterAsset = function (asset) {
                    asset.filteredAndInResultList = !asset.filteredAndInResultList
                    filterDebounce()
                }

                $ctrl.filterTag = function (tag) {
                    tag.filteredAndInResultList = !tag.filteredAndInResultList
                    filterDebounce()
                }
                $ctrl.filterGoal = function (goal) {
                    goal.filteredAndInResultList = !goal.filteredAndInResultList
                    filterDebounce()
                }

                const filterDebounce = debounce(setFilter, 500, false)
                function setFilter() {

                    let filter = []
                    addToFilterIfActive(filter, $ctrl.priorityOptions, "priorityStatus", "priorityDB")
                    addToFilterIfActive(filter, $ctrl.statuses, "status", "id")
                    addToFilterIfActive(filter, $ctrl.assets, "Board.assetId", "id")
                    addToFilterIfActive(filter, $ctrl.projects, "S.projectId", "id")
                    addToFilterIfActive(filter, $ctrl.tags, "Tag.tag", "tag")
                    addToFilterIfActive(filter, $ctrl.goals, "Goals.StrategyGoalId", "id")
                    if ($ctrl.myResponsibleFilterActive) {
                        var userid = authService.getCurrentUser().userId
                        var itemFilter = {
                            sourceColumn: "Task.responsibleUserId",
                            operator: "InList",
                            items: [userid]
                        }
                        filter.push(itemFilter)
                    }
                    if ($ctrl.overdueFilterActive) {
                        let itemFilter = {
                            sourceColumn: "boardDate",
                            operator: "LessThanDate",
                            items: [moment().subtract(1, 'days')]
                        }
                        let notImplementedFilter = {
                            sourceColumn: "status",
                            operator: "InList",
                            items: ['blank', 'unacceptable']
                        }
                        filter.push(itemFilter)
                        filter.push(notImplementedFilter)
                    }
                    if ($ctrl.isSearchWordActive && $ctrl.query) {
                        // CHeck for ID filter
                        if (isNumeric($ctrl.query)) {
                            // Check if suggestion is ID and show.

                            boardTaskService().get($ctrl.query).then(function (task) {
                                if (task) {
                                    $uibModal.open({
                                        backdrop: 'static',
                                        animation: true,
                                        templateUrl: 'boardTask.html',
                                        controller: 'boardTaskController',
                                        resolve: {
                                            task: function () {
                                                return task;
                                            },
                                            activeTab: function () {
                                                return "Info";
                                            },
                                            options: function () {
                                                return {

                                                }
                                            }
                                        }
                                    });
                                }
                            })
                        }
                        let itemFilter = {
                            sourceColumn: "title",
                            operator: "Contains",
                            items: [$ctrl.query]
                        }
                        filter.push(itemFilter)
                    }
                    $ctrl.filter = filter
                    if ($ctrl.onFilterChanged) {
                        $ctrl.onFilterChanged({ filter })
                    }
                }

                function isNumeric(num) {
                    return !isNaN(num)
                }

                function addToFilterIfActive(filter, list, sourceColumn, property) {
                    var itemToShow = list.filter(l => l.filteredAndInResultList)
                    if (itemToShow.length > 0) {

                        let itemFilter = {
                            sourceColumn: sourceColumn,
                            operator: "InList",
                            items: []
                        }
                        itemToShow.forEach(item => {
                            itemFilter.items.push(item[property])
                        })
                        filter.push(itemFilter)
                    }
                }

                function addInitialFilter() {
                    let filter = $ctrl.filter
                    if (Array.isArray(filter) && filter.length > 0) {
                        updateGuiFromFilter(filter, $ctrl.priorityOptions, "priorityStatus", "priorityDB")
                        updateGuiFromFilter(filter, $ctrl.statuses, "status", "id")
                        updateGuiFromFilter(filter, $ctrl.assets, "Board.assetId", "id")
                        updateGuiFromFilter(filter, $ctrl.projects, "S.projectId", "id")
                        updateGuiFromFilter(filter, $ctrl.tag, "Tag.tag", "tag")
                        updateGuiFromFilter(filter, $ctrl.goals, "Goals.StrategyGoalId", "id")
                        $ctrl.myResponsibleFilterActive = updateGuiFromFilterSpecialCase(filter, "responsibleUserId")
                        $ctrl.overdueFilterActive = updateGuiFromFilterSpecialCase(filter, "dueDate")
                    }
                    if ($ctrl.onFilterChanged) {
                        $ctrl.onFilterChanged({ filter })
                    }
                }
                function updateGuiFromFilter(filter, list, sourceColumn, property) {
                    const appl = filter.find(f => f.sourceColumn == sourceColumn)
                    if (appl) {
                        const itemsint = stringtoint(appl.items)
                        list.forEach(l => {
                            const propVal = l[property]
                            if (appl.items.includes(propVal) || itemsint.includes(propVal))
                                l.filteredAndInResultList = true
                        })
                    }
                }
                function stringtoint(listofstr) {
                    try {
                        if (!listofstr)
                            return []
                        return listofstr.map(str => parseInt(str))
                    }
                    catch (e) { return [] }
                }
                function updateGuiFromFilterSpecialCase(filter, sourceColumn) {
                    const appl = filter.find(f => f.sourceColumn == sourceColumn)
                    return !!appl
                }

                function getHasModule() { $ctrl.hasStrategyModule = navigationService().hasModule("STRATEGY") }
                $ctrl.$postLink = () => { getHasModule() }
                $scope.subscribe("UserAuthenticatedAndReady", () => { getHasModule() })

                $ctrl.$onChanges = function (changes) {
                    if (changes.filterInitial) {
                        if (changes.filterInitial.currentValue) {
                            $ctrl.filter = changes.filterInitial.currentValue
                            addInitialFilter()
                        }
                    }
                }

            }
        ]
    })
