import { AssetBusinessUnit, AzureAdDigiLeanGroup, CustomerSubscriptionType, GroupType } from "@api"
import { DigiLeanNgScope } from "@common/model/angularModel"
import angular from "angular"
import * as assetService from "@common/services/assetService"
import toastr from "toastr"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import dialog from "@common/components/digilean/DigileanDialog"
import { handleThrownErrorMsg } from "@common/services/error/aspNetErrorHandling"
import { AssetChangeInfo } from "@common/model/types"
import { Subscription } from "rxjs"
import swal from "sweetalert"

interface IRegistrationAsset extends AssetBusinessUnit {
    isNewAsset: boolean
}

interface IParentAssetModel {
    assetId?: number | null
    assets?: AssetBusinessUnit[]
}
interface IAssetEditController extends DigiLeanNgScope {
    asset: IRegistrationAsset
    otherAssetsList: AssetBusinessUnit[]
    parentAssetModel: IParentAssetModel
    subscription: CustomerSubscriptionType
    selectedLanguage: any
    languages: any[]

    openAssetParticipantsForEdit: (assetId: number) => void
    clearAzureGroup: () => void
    azureGroupLookup: () => void
    parentChangeHandler: (assetInfo: AssetChangeInfo) => void
    messages: string[]
    msgIsError: boolean
    save: () => void
}

let subUserProfile: Subscription | null = null

const DigiLean = angular.module('DigiLean')
DigiLean.directive("assetEdit", ['languageAdminService', 'modalService', '$uibModal',
    function (languageAdminService, modalService, $uibModal) {
        return {
            templateUrl: 'assetEdit.html',
            restrict: 'E',
            scope: {
                'asset': '=',
            },
            controller: ['$scope', '$element', 'authService',
            function($scope: IAssetEditController, $element: JQuery, authService) {
                $scope.messages = []
                $scope.msgIsError = false
                $scope.otherAssetsList = []
                $scope.selectedLanguage = null
                $scope.languages = languageAdminService().getSupportedLanguages()
                $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
        

                $scope.parentAssetModel = { assetId: 0 }

                $scope.$watch("asset", function() {
                    if ($scope.asset) {
                        $scope.parentAssetModel = { assetId: $scope.asset.parentId, assets: $scope.otherAssetsList }
                        resetMsg()
                        if ($scope.asset.language) {
                            $scope.selectedLanguage = $scope.languages.find(l => l.languageCode == $scope.asset.language)
                        }
                        loadAssets()
                    }
                    
                })

                $scope.displayAssetType = function(asset: AssetBusinessUnit) {
                    if (asset.assetType == GroupType.BUSINESSUNIT)
                        return translateLabelInstant("ADMIN_ASSETTYPE_BUSINESS_UNIT")
                    return translateLabelInstant("COMMON_ASSET")
                }

                const loadAssets = async function() {
                    try {
                      let availableAssets;
                      if ($scope.isBusinessunitAdmin) {
                        availableAssets = await assetService.getAssetsForBusinessUnit();
                      } else {
                        availableAssets = await assetService.getAllAssets();
                      }
                  
                      if ($scope.asset && $scope.asset.id) {
                        $scope.otherAssetsList = availableAssets.filter(a => a.id !== $scope.asset.id);
                      } else {
                        $scope.otherAssetsList = availableAssets;
                      }
                  
                      $scope.parentAssetModel = { assetId: $scope.asset.parentId, assets: availableAssets };
                    } catch (error) {
                      console.error('Error loading assets:', error);
                    }
                };

                function onAssetChanges(detail: any) {
                    loadAssets()
                    const options = {detail, bubbles: true, composed: true}
                    $element[0].dispatchEvent(new CustomEvent("assets-changed", options))
                }
                
                function resetMsg() {
                    $scope.messages = []
                    $scope.msgIsError = false
                }

                function resetAsset() {
                    $scope.asset = {
                        isNewAsset: true,
                        id: 0,
                        name: "",
                        description: "",
                        parentId: null,
                    }
                }
                function handleExceptions(error: Error, fallbackErrorLabel?: string) {
                    console.error(error)
                    const errors = handleThrownErrorMsg(error)
                    $scope.msgIsError = true
                    if (errors.length > 0)
                        $scope.messages = errors
                    else if (fallbackErrorLabel)
                        $scope.messages = [fallbackErrorLabel]
                    else
                        $scope.messages = ["COMMON_ERROR_MSG"]
                }
                function setInfoMessage(label: string) {
                    $scope.msgIsError = false
                    $scope.messages = [label]
                }

                $scope.save = function () {
                    resetMsg()
                    if ($scope.parentAssetModel) {
                        $scope.asset.parentId = $scope.parentAssetModel.assetId
                        if ($scope.asset.parentId)
                            $scope.asset.assetType = GroupType.GENERAL //This also prevent businessunitAdmin to create businessunit
                    }
                    
                    if ($scope.selectedLanguage) {
                        $scope.asset.language = $scope.selectedLanguage.languageCode
                    }
                    if ($scope.asset.isNewAsset) {
                        assetService.add($scope.asset).then(function (newAsset) {
                            $scope.asset = newAsset as IRegistrationAsset
                            onAssetChanges(newAsset.id)
                            resetAsset()
                            setInfoMessage("ADMIN_MESSAGE_ASSET_ADDED")
                        }, handleExceptions)
                    } else {
                        assetService.update($scope.asset).then(function (updatedAsset) {
                            $scope.asset = updatedAsset as IRegistrationAsset
                            onAssetChanges(updatedAsset.id)
                            setInfoMessage("ADMIN_MESSAGE_ASSET_MODIFIED")
                        }, handleExceptions)
                    }
                }

                function getBoardText (boards, idTranslated) {
                    var result = "";
                    for(var s=0; s< boards.length; s++){
                        result = result + boards[s].name + " (" + idTranslated + ":" + boards[s].id + ")";
                        if(s != boards.length-1){
                            result = result + ",  \n";
                        }
                    }
                    return result;
                }

                function getTitleText (items, idTranslated) {
                    var result = "";
                    for(var s=0; s< items.length; s++){
                        result = result + items[s].title + " (" + idTranslated + ":" + items[s].id + ")";
                        if(s != items.length-1){
                            result = result + ", \n";
                        }
                    }
                    return result;
                }

                function getA3TitleText (items, idTranslated) {
                    var result = "";
                    for(var s=0; s< items.length; s++){
                        result = result + items[s].title + " (" + idTranslated + ":" + items[s].a3Id + ")";
                        if(s != items.length-1){
                            result = result + ",  \n";
                        }
                    }
                    return result;
                }
                function deleteAsset(asset) {
                    assetService.remove(asset.id).then(function () {  
                        onAssetChanges(asset.id)
                        resetAsset()
                        $scope.parentAsset = undefined
                        const msg = translateLabelInstant("ADMIN_MESSAGE_ASSET_DELETED")
                        toastr.info(msg) 
                        
                    }, function (err) {
                        handleExceptions(err, "ADMIN_ERROR_MESSAGE_DELETE_ASSET")
                    })
                }
                $scope.delete = function (asset) {
                    resetMsg()
                    assetService.getAssetAssociatedData(asset.id).then(function (data) {
                        var noOfDashboards = data?.dashboards?.length;
                        var noOfPortfolios = data?.boards?.filter(b => b.boardType == 'Project').length;
                        var noOfTeamBoards = data?.boards?.filter(b => b.boardType != 'Project').length;
                        var noOfProjects = data?.projects?.length;
                        var noOfA3s = data?.a3s?.length;
                         
                        var buttons;
                        if (noOfDashboards && noOfDashboards > 0){
                            buttons = {dashboards: {
                                text:  noOfDashboards+ " " + translateLabelInstant('COMMON_DASHBOARDS'),
                                className: "digilean-label-historical",
                                closeModal: false,
                                value: "DASHBOARDS"
                            }};
                        }  
                        if (noOfTeamBoards && noOfTeamBoards > 0){
                            buttons = buttons ?? {};
                            buttons.boards = {
                                text:  noOfTeamBoards + " " + translateLabelInstant('COMMON_BOARDS'),
                                className: "digilean-label-historical",
                                closeModal: false,
                                value: "BOARDS"
                            };
                        }
                        if (noOfPortfolios && noOfPortfolios > 0){
                            buttons = buttons ?? {};
                            buttons.projectportfolios = {
                                text:  noOfPortfolios + " " + translateLabelInstant('PROJECT_PROJECT_PORTFOLIO'),
                                className: "digilean-label-historical",
                                closeModal: false,
                                value: "PROJECTPORTFOLIOS"
                            };
                        }
                        if (noOfProjects && noOfProjects > 0){
                            buttons = buttons ?? {};
                            buttons.projects = {
                                text:  noOfProjects + " " + translateLabelInstant('COMMON_PROJECTS'),
                                className: "digilean-label-historical",
                                closeModal: false,
                                value: "PROJECTS"
                            };
                        }
                        if (noOfA3s && noOfA3s > 0){
                            buttons = buttons ?? {};
                            buttons.a3s = {
                                text:  noOfA3s + " A3",
                                className: "digilean-label-historical",
                                closeModal: false,
                                value: "A3S"
                            };
                        }
                        if (buttons){
                            buttons.delete = {
                                text: translateLabelInstant('COMMON_DELETE_ALL'),
                                className: "sa-digilean-button-danger",
                                value: "delete",
                                closeModal: true,
                            };
                            openWarningPopup(buttons, data, asset);
                        } else {
                            deleteAsset(asset);
                        }
                    });
                }

                function openWarningPopup(buttons, data, asset){
                    var idTranslated = translateLabelInstant('COMMON_ID')
                    swal({
                        title: translateLabelInstant('ADMIN_MESSAGE_DELETE_ASSET_CONFIRMATION'),
                        icon: "warning",
                        buttons: buttons,
                        dangerMode: true,
                        closeOnClickOutside: true,
                        closeOnEsc: true
                    }).then((value) => {
                        switch (value) {

                            case "BOARDS":
                                var taskboards = data.boards.filter(b => b.boardType != 'Project')
                                swal({
                                    title: translateLabelInstant('COMMON_BOARDS'),
                                    text: getBoardText(taskboards, idTranslated),
                                    closeOnClickOutside: true,
                                    closeOnEsc: true
                                }).then((result) => {
                                    // Reopen the first popup
                                    openWarningPopup(buttons, data, asset);
                                });
                                break;

                            case "DASHBOARDS":
                                swal({
                                    title: translateLabelInstant('COMMON_DASHBOARDS'),
                                    text: getTitleText(data.dashboards, idTranslated),
                                    closeOnClickOutside: true,
                                    closeOnEsc: true
                                }).then((result) => {
                                    // Reopen the first popup
                                    openWarningPopup(buttons, data, asset);
                                });
                                break;
                            case "PROJECTPORTFOLIOS":
                                var portfolios = data.boards.filter(b => b.boardType == 'Project')
                                swal({
                                    title: translateLabelInstant('PROJECT_PROJECT_PORTFOLIO'),
                                    text: getBoardText(portfolios, idTranslated),
                                    closeOnClickOutside: true,
                                    closeOnEsc: true
                                }).then((result) => {
                                    // Reopen the first popup
                                    openWarningPopup(buttons, data, asset);
                                });
                                break;
                            case "PROJECTS":
                                swal({
                                    title: translateLabelInstant('COMMON_PROJECTS'),
                                    text: getTitleText(data.projects, idTranslated),
                                    closeOnClickOutside: true,
                                    closeOnEsc: true
                                }).then((result) => {
                                    // Reopen the first popup
                                    openWarningPopup(buttons, data, asset);
                                });
                                break;
                            case "A3S":
                                swal({
                                    title: 'A3',
                                    text: getA3TitleText(data.a3s, idTranslated),
                                    closeOnClickOutside: true,
                                    closeOnEsc: true
                                }).then((result) => {
                                    // Reopen the first popup
                                    openWarningPopup(buttons, data, asset);
                                });
                                break;
                            case "delete":
                                deleteAsset(asset);
                                break;
                            default:
                        }
                    });
                }

                $scope.azureGroupLookup = async function() {
                    let group: AzureAdDigiLeanGroup = {}
                    try {
                        group = await dialog.openForm({
                            title: "COMMON_AZURE_AD",
                            hideOkBtn: true,
                            booleanResult: false
                        }, "<group-azure-lookup></<group-azure-lookup>", group)
                        
                        if (group && group.id) {
                            $scope.asset.externalId = group.id
                            $scope.asset.externalName = group.displayName
                        }
                    }
                    catch(err) {
                        if (err != "cancel")
                            console.log(err)
                    }
                }

                $scope.clearAzureGroup = function() {
                    $scope.asset.externalId = ""
                    $scope.asset.externalName = ""
                }

                $scope.parentChangeHandler = function (assetinfo) {
                    if (assetinfo) {
                        const newParent = assetinfo.asset
                        $scope.parentAssetModel.assetId = newParent.id
                        if ($scope.parentAssetModel.assetId)
                            $scope.asset.assetType = GroupType.GENERAL
                    }
                }

                $scope.openAssetParticipantsForEdit = function (assetId: number) {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: $scope.animationsEnabled,
                        templateUrl: 'assetparticipantsform.html',
                        controller: 'assetParticipantsController',
                        windowClass: 'assetparticipants-modal-window',
                        resolve: {
                            assetId: function () {
                                return assetId
                            },
                            boardId: function() {
                                return null
                            }
                        }
                    })
                    modalInstance.result.then(function (asset) {
                        loadAssets()
                    }, function () {})
                }
            }]
        }
    }
])