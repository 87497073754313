import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { getUserFullProfile } from "@common/stores/userStore"
import * as employeeService from "@common/services/employeeService"

angular.module("DigiLean").controller('teamSpaceController', ['$scope', '$translate',
    function ($scope, $translate) {
        $scope.teams = [];
        var userSettings = {};
        $scope.selectedTeam = {};

        $scope.isStar = false;
        $scope.quickAccess = [];

        function init(){
            assetService.getList().then(function (assets) {
                $scope.teams = assets;
                setSelectedTeam();
                checkFavorite();
            });
        }
        init();

        $scope.teamChanged = function(team) {
            if(team){
                loadTeamMembers(team);

                if(userSettings){
                    userSettings.teamspace = team.id;
                    employeeService.updateUserSettings(userSettings).then(function () {
                        $scope.publish("UserSettingsChanged", userSettings);
                    });
                }
            }
        };

        function loadTeamMembers(team) {
            if (!team) return;
            if (!team.id) return;
            customerService.getUsersForAsset(team.id).then(function(teamMembers) {
                $scope.selectedTeam.members = teamMembers;
            });
        }
        

        function setSelectedTeam(){
            const profile = getUserFullProfile()
                
            if (profile.settings){
                userSettings = profile.settings;
                if (userSettings.teamspace) {
                    const selected = $scope.teams.find(t => t.id == userSettings.teamspace);
                    if (selected){
                        $scope.selectedTeam = selected
                    }   
                }
            }
            if (!$scope.selectedTeam.id && $scope.teams.length > 0){
                $scope.selectedTeam = $scope.teams[0];
            }
            loadTeamMembers($scope.selectedTeam);
        }
        $scope.addToFavorites = function () {
            $scope.isStar = true;
            var board = {
                id: "TEAMSPACE",
                name: $translate.instant("COMMON_TEAM_SPACE"),
                boardType: "TEAMSPACE"
            };
            $scope.quickAccess.push(board);
            saveQuickAccess();
        }
        $scope.removeFromFavorites = function () {
            $scope.isStar = false;
            const isFavorite = $scope.quickAccess.find(q => q.id == "TEAMSPACE")
            if (isFavorite) {
                var currentIndex = $scope.quickAccess.indexOf(isFavorite);
                $scope.quickAccess.splice(currentIndex, 1);
                saveQuickAccess();
            }
        }
        function saveQuickAccess() {
            employeeService.updateUserPreference("FlexiboardFavoriteBoards", $scope.quickAccess).then(function (settings) {
                $scope.publish("FlexiboardFavoriteBoardsChanged", $scope.quickAccess);
            });
        }

        function checkFavorite(){
            employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                if (settings && settings.value) {
                    angular.forEach(settings.value, function (board) {
                        if (board) {
                            if(board.id == "TEAMSPACE"){
                                $scope.isStar = true;
                            }
                            $scope.quickAccess.push(board);
                        }
                    });
                }
            });
        }

    

    }]);

