﻿import * as documentService from "@common/services/documentService"
import { getFileType } from "@common/services/filesService"

angular
    .module('DigiLean')
    .directive("fileinfo", ['$filter', '$uibModal',
        function ($filter, $uibModal) {
            return {
                templateUrl: 'fileinfo.html',
                restrict: 'E',
                scope: {
                    'file': '<',
                },
                link: function (scope, elem, attrs) {

                    scope.fileClone = {}
                    scope.$watch('file', async function (file) {
                        // apply
                        if (!file)
                            return

                        const fileType = getFileType(file.fileExtension)
                        if (fileType) {
                            file.fileType = fileType.type
                        }

                        scope.fileClone = structuredClone(file)
                        const fileUrl = await documentService.getFileUrl(file.fileName, file.id)
                        scope.fileClone.fileUrl = fileUrl
                        scope.fileClone.validFileUrl = fileUrl
                    })
                }
            }
        }])
