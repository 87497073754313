﻿import * as profileImageService from "@common/services/user/profileImageService"

angular.module("DigiLean").service('taskDrawingService', ['$translate', '$compile', 
    function ($translate, $compile) {
        function taskDrawingService() {
            return {
                
                getTaskCardTheme: function (task, isKamishibai) {
                    var theme = {
                        statusClass: task.status,
                        portletStyle: ""
                    };

                    if(isKamishibai){
                        if (task.color && task.color !== "#ffffff") {
                            theme.statusClass = ""; // We are not going to apply style when empty
                            var postIt = taskDrawingService().getPostItFromColor(task.color);
                            if (task.status == "OK") {
                                theme.portletStyle = "background:#8cc152 " + ";border-bottom-color: "+ postIt.background + "; border-style: solid; border-width: 0px 0px 3px 0px;";
                            }
                            else  {
                                theme.portletStyle = "background:#ed5565 " + ";border-bottom-color: "+ postIt.background + "; border-style: solid; border-width: 0px 0px 3px 0px;";
                            }

                        }
                        else {
                            if (task.status == "OK") {
                                theme.portletStyle = "background:#8cc152;border-bottom-color: #8cc152; border-style: solid; border-width: 0px 0px 3px 0px;";
                             }
                             else {
                                theme.portletStyle = "background:" + "#ed5565 " + ";border-color: #ed5565; border-style: solid; border-width: 3px 3px 3px 3px;";
                            }
                        }


                    }else if (task.type === "WeeklyBoardTask") {
                        var postIt = taskDrawingService().getPostItFromColor(task.color);
                        // theme.portletStyle = "style='background:" + postIt.fillColor + ";border-color: " + postIt.background + "; border-style: solid; border-width: 0px 0px 3px 0px;'";
                        theme.portletStyle = "border-color: " + postIt.background + "; border-style: solid; border-width: 0px 0px 3px 0px;";



                        
                    } else { // Kanban / Flexi board
                        if (task.color && task.color !== "#ffffff") {
                            theme.statusClass = ""; // We are not going to apply style when empty
                            var postIt = taskDrawingService().getPostItFromColor(task.color);
                            if (task.status == "blank") {
                                theme.portletStyle = "background:" + postIt.fillColor + ";border-color: " + postIt.background + "; border-style: solid; border-width: 0px 0px 3px 0px;";
                           } else if (task.status == "OK") {
                                theme.portletStyle = "background:" + postIt.fillColor + ";border-color: #8cc152; border-style: solid; border-width: 3px 3px 3px 3px;";
                                }
                            else if (task.status == "unacceptable") {
                                theme.portletStyle = "background:" + postIt.fillColor + ";border-color: #e9573f; border-style: solid; border-width: 3px 3px 3px 3px;";
                           }

                        }
                        else {
                            if (task.status == "OK") {
                                theme.portletStyle = "border-color: #8cc152; border-style: solid; border-width: 3px 3px 3px 3px;";
                                //theme.portletStyle = "style='border-color: #01c298; border-style: solid; border-width: 3px 3px 3px 3px;'";
                             }
                             else if (task.status == "unacceptable") {
                                theme.portletStyle = "border-color: #e9573f; border-style: solid; border-width: 3px 3px 3px 3px;";
                           }
                        }
                    }

                    return theme;
                },

                updateStatus(boardId, taskId, status, isKamishibai) {

                    var portlet = $("div .portlet[data-id='" + taskId + "']").first();
                    var task = this.getTaskFromDOM(portlet, boardId);
                    if (task.status == status) return;
                    
                    task.status = status;
                    var theme = this.getTaskCardTheme(task, isKamishibai)

                    $(portlet).addClass(status);
                    
                    if(isKamishibai){
                        $(portlet).attr("style", theme.portletStyle);
                        if(status !== 'blank'){
                            $(portlet).addClass("animated flipInY");
                        }

                        // Add status class / color to header
                        var portletHeader = $(portlet).find(".portlet-header");
                        $(portletHeader).removeClass();
                        $(portletHeader).addClass("portlet-header " + status);
                        
                        // remove animation afterwards
                        setTimeout(function () {
                            $(portlet).removeClass("animated flipInY");
                        }, 1000);
                    //} else if (task.type == "KanbanTask" && task.color && task.color != "#ffffff" && task.color != "#FFFFFF") {
                    } else if (task.type == "KanbanTask") {
                        $(portlet).attr("style", theme.portletStyle);
                           // Add status class / color to header
                           var portletHeader = $(portlet).find(".portlet-header");
                           $(portletHeader).removeClass();
                           $(portletHeader).addClass("portlet-header " + status);
                    } else {
                        
                        // Add status class / color to header
                        var portletHeader = $(portlet).find(".portlet-header");
                        $(portletHeader).removeClass();
                        $(portletHeader).addClass("portlet-header " + status);
                    }

                    // Set status icon
                    var container = $(portlet).find(".status");
                    var currentStatus = $(container).attr("data-status");
                    var currentIconClass = taskDrawingService().getIconClass(currentStatus, isKamishibai);
                    var iconClass = taskDrawingService().getIconClass(status, isKamishibai);

                    $(container).find('i').toggleClass(currentIconClass + ' ' + iconClass);
                    $(container).attr('data-status', status);
                },

                updateAttachments: function (scope, taskId, files) {
                    var task = $("div .portlet[data-id='" + taskId + "']").first();
                    var portletHeader = $(task).find(".boardtask-attachments-container").first();
                    // var el = $compile( "<fileinfo></fileinfo>" )( $scope );
                    var internalScope = scope.$new(true);
                    internalScope.files = files;
                    var el = $compile("<task-attachments files='files'></task-attachments>")(internalScope);
                    portletHeader.append(el);

                },

                updateSubTask: function (scope, task) {
                    var taskDom = $("div .portlet[data-id='" + task.id + "']").first();
                    var portletHeader = $(taskDom).find(".subtask-status-container").first();
                    var internalScope = scope.$new(true);
                    internalScope.task = task;
                    var el = $compile("<subtask-status task='task'></subtask-status>")(internalScope);
                    portletHeader.append(el);
                },

                addUserList: function (users, updateResponsibleCallBack, idSelectorElement) {
                    $translate('BOARD_CLEAR_RESPONSIBLE').then(function (translation) {
                        var translatedClearResponsible = translation;
                        var userList = "<li data-responsibleuserid='' data-responsibleuser=''  data-displayname=''><a>" + translatedClearResponsible + "</a></li>";
                        angular.forEach(users, function (user, key) {
                            userList += "<li data-responsibleuserid='" + user.id + "' data-responsibleuser='" + user.fullName + "' data-displayname='" + user.displayName + "'><a>" + user.firstName + " " + user.lastName + " (" + user.displayName + ") </a></li>";
                        });

                        var ul = angular.element(".responsiblecontainer ul");
                        ul.dropdown();
                        if (ul.length > 0) {
                            $(ul).empty();
                            $(ul).append(userList);

                            $('.responsiblecontainer li').off();
                            $('.responsiblecontainer li').click(function (e) {
                                if (idSelectorElement) {
                                    var task = $(this).closest(idSelectorElement);
                                    var taskId = $(task).data("id");
                                } else {
                                    var task = $(this).closest("div .portlet");
                                    var taskId = $(task).data("id");
                                }

                                var username = $(this).data("responsibleuser");
                                var userId = $(this).data("responsibleuserid");
                                var userDisplayName = $(this).data("displayname");

                                var responsible = $(task).attr("data-responsibleuser");
                                var responsibleUserId = $(task).attr("data-responsibleuserid");

                                var options = {
                                    taskId: taskId,
                                    user: {
                                        taskId: taskId,
                                        userName: username,
                                        userId: userId,
                                        userDisplayName: userDisplayName,
                                        previousUserName: responsible,
                                        previousUserId: responsibleUserId
                                    }
                                }
                                updateResponsibleCallBack(options);
                            });
                        }
                    });
                },
                getPostIts: function () {
                    return [
                        {
                            title: "default",
                            fillColor: "#ffffff",
                            background: "#E6E9ED"
                        },
                        {
                            title: "four", // orange
                            // fillColor: "#FFCE54",
                            fillColor: "#FFF6DF",
                            background: "#FCBB42"
                        },
                        {
                            title: "three", // green
                            // fillColor: "#A0D468",
                            fillColor: "#EDF7E3",
                            background: "#8CC152"
                        },
                        {
                            title: "two", //blue
                            // fillColor: "#4FC1E9",
                            fillColor: "#DFF3FB",
                            background: "#3BAFDA"
                        },
                        {
                            title: "one", // red
                            // fillColor: "#FC6E51",
                            fillColor: "#FEE4DF",
                            background: "#E9573F"
                        },
                        {
                            title: "five", // pink
                            // fillColor: "#EC87C0",
                            fillColor: "#FBE9F3",
                            background: "#D770AD"
                        }

                    ];
                },
                getPostItFromColor: function (color) {
                    var postIts = this.getPostIts();
                    for (let postIt of postIts) {

                        // expected output: 1
                        if (postIt.background == color) {
                            return postIt;

                        }
                    }

                    return {
                        background: color,
                        fillColor: "#FFFFFF"
                    };
                },
                getTextDescription: function (task) {
                    if (!task || !task.text) return "";

                    var lessText = $translate.instant('COMMON_LESS');
                    var moreText = $translate.instant('COMMON_MORE');

                    var description =
                        "<div class='descriptiontextcontainer'>" +
                        "<div class='description'>" +
                        task.text +
                        "</div>" +
                        "<a class='more-button' data-less='" + lessText + "' data-more='" + moreText + "'>" + moreText + "</a>" +
                        "</div>"

                    return description;

                },

                getActionContainer: function (isWeeklyBoard) {
                    if (isWeeklyBoard) {
                        var actionContainer = "<div class='actioncontainer'>" +
                            "<div class='dropdown'>" +
                            "<i class='dropdown-toggle fas fa-ellipsis-v' data-toggle='dropdown'></i>" +
                            "<ul class='dropdown-menu'>" +
                            "<li class='clone-task'><a><span class='fa fa-clone'></span> " + $translate.instant("BOARD_CLONE") + " </a></li>" +
                            "<li class='copy-task'><a><span class='fa fa-copy'></span> " + $translate.instant("COMMON_COPY") + "</a></li>" +
                            "<li class='repeat-task'><a><span class='fas fa-redo-alt'></span> " + $translate.instant("COMMON_REPEAT") + "</a></li>" +
                            "<li class='move-to-actionlist-task'><a><span class='fa fa-list-ul'></span> " + $translate.instant("BOARD_MOVE_TO_ACTIONLIST") + "</a></li>" +
                            "<li class='move-to-board-task'><a><span class='fas fa-desktop''></span> " + $translate.instant("BOARD_MOVE") + "</a></li>" +
                            "<li class='divider'></li>" +
                            "<li class='delete-task' ><a><span class='fa fa-trash'></span> " + $translate.instant("COMMON_DELETE") + "</a></li>" +
                            "</ul>" +
                            "</div>" +
                            "</div>";
                    } else {
                        var actionContainer = "<div class='actioncontainer'>" +
                            "<div class='dropdown'>" +
                            "<i class='dropdown-toggle fas fa-ellipsis-v' data-toggle='dropdown'></i>" +
                            "<ul class='dropdown-menu'>" +
                            "<li class='clone-task'><a><span class='fa fa-clone'></span> " + $translate.instant("BOARD_CLONE") + " </a></li>" +
                            "<li class='copy-task'><a><span class='fa fa-copy'></span> " + $translate.instant("COMMON_COPY") + "</a></li>" +
                            "<li class='move-to-actionlist-task'><a><span class='fa fa-list-ul'></span> " + $translate.instant("BOARD_MOVE_TO_ACTIONLIST") + "</a></li>" +
                            "<li class='move-to-archive-task'><a><span class='fa fa-archive'></span> " + $translate.instant("BOARD_MOVE_TO_ARCHIVE") + "</a></li>" +
                            "<li class='move-from-archive-task' style='display:none;'><a><span class='fa fa-archive'></span> " + $translate.instant("BOARD_MOVE_FROM_ARCHIVE") + "</a></li>" +
                            "<li class='move-to-board-task'><a><span class='fas fa-desktop''></span> " + $translate.instant("BOARD_MOVE") + "</a></li>" +
                            "<li class='divider'></li>" +
                            "<li class='delete-task' ><a><span class='fa fa-trash'></span> " + $translate.instant("COMMON_DELETE") + "</a></li>" +
                            "</ul>" +
                            "</div>" +
                            "</div>";
                    }

                    return actionContainer;

                },

                drawTimeElement: function (startTime, endTime) {

                    var timeElement = "";
                    if (startTime && endTime) {
                        var start = moment(startTime).utc();
                        var end = moment(endTime).utc()
                        var duration = moment.duration(end.diff(start));
                        var days = duration.asDays();
                        if (days >= 1) return days + " d"; // needs translation

                        timeElement += moment(startTime).utc().format("HH:mm");
                        timeElement += "-" + moment(endTime).utc().format("HH:mm");

                    }
                    return timeElement;

                },

                drawResponsibleContainer: function (task) {
                    var responsibleUser = "";
                    var responsibleUserId = "";
                    var responsibleDisplayName = "";
                    var profileImageUrl = "";
                    if (task.responsibleUserId) {
                        responsibleUser = task.responsibleUser;
                        responsibleUserId = task.responsibleUserId;
                        responsibleDisplayName = task.responsibleDisplayName;
                        if (!responsibleDisplayName) {
                            responsibleDisplayName = responsibleUser;
                        }
                    }
                    var profileImage = "<i class='fa fa-user'></i>";
                    // if (profileImageUrl) {
                    //     profileImage = "<img class='img-circle' src='" + profileImageUrl + "' />";
                    // }
                    var responsibleContainer = "<div class='responsiblecontainer'>" +
                        "<div class='dropdown'>" +
                        "<div class='responsibleuser dropdown-toggle'  data-toggle='dropdown'> " +
                        profileImage +
                        "<span class='responsibleusername' data-responsibleuserid='" + responsibleUserId + "' data-responsibleuser='" + responsibleUser + "' data-displayname='" + responsibleDisplayName + "'>" + responsibleDisplayName + "</span></div>" +
                        "<ul class='dropdown-menu userlist pull-right'></ul>" +
                        "</div>" +
                        "</div>";
                    return responsibleContainer;
                },

                drawResponsibleForCategory: function (categoryAssigned) {
                    var hideButton = "<div class='hide-button' data-categoryid=" + categoryAssigned.categoryId + ">x</div>";
                    var userinfo = "<div class='userinfo'><i class='fa fa-user'></i> <span class='category-displayname'>" + categoryAssigned.userDisplayName + "</span> " + hideButton + "</div>";

                    if (!categoryAssigned.userId || categoryAssigned.userId === "") {
                        userinfo = "<div class='userinfo' style='visibility:hidden;'></div>";
                    } else {
                        profileImageService.getProfileImageUrl(categoryAssigned.userId, "ExtraSmall").then(profileImageUrl => {
                            if (profileImageUrl) {
                                // userinfo = "<div class='userinfo'><img class='img-circle' src='" + profileImageUrl + "' /><span class='category-displayname'>" + categoryAssigned.userDisplayName + "</span> " + hideButton + "</div>";
                                userinfo = "<div class='userinfo'><img class='img-circle' src='" + profileImageUrl + "' title='" + categoryAssigned.userDisplayName + "' />" + hideButton + "</div>";
                            }
                        })
                    }

                    var responsibleForCategory = "<div class='responsible-for-category' data-responsibleuserid='" + categoryAssigned.userId + "' data-responsibleuser='" + categoryAssigned.userName +
                        "' data-responsibledisplayname='" + categoryAssigned.userDisplayName + "'>" +
                        "<div class='responsibleuser'>"
                        + userinfo +
                        "</div></div>";

                    return responsibleForCategory;
                },

                updateResponsibleForCategory: function (container, categoryAssigned) {
                    var hideButton = "<div class='hide-button' data-categoryid=" + categoryAssigned.categoryId + ">x</div>";
                    $(container).attr("data-responsibleuserid", categoryAssigned.userId);
                    $(container).attr("data-responsibleuser", categoryAssigned.userName);
                    $(container).attr("data-responsibledisplayname", categoryAssigned.userDisplayName);
                    var userinfo = "<div class='userinfo'><i class='fa fa-user'></i> <span class='category-displayname'>" + categoryAssigned.userDisplayName + "</span>" + hideButton + "</div>";
                    if (!categoryAssigned.userId || categoryAssigned.userId === "") {
                        userinfo = "<div class='userinfo' style='visibility:hidden;></div>";
                    } else {
                        profileImageService.getProfileImageUrl(categoryAssigned.userId, "ExtraSmall").then(profileImageUrl => {
                            if (profileImageUrl) {
                                userinfo = "<div class='userinfo'><img class='img-circle' src='" + profileImageUrl + "' /><span class='category-displayname'>" + categoryAssigned.userDisplayName + "</span> " + hideButton + "</div>";
                            }
                        })
                        
                    }
                    var userDisplay = $(container).find(".userinfo").first();
                    $(userDisplay).replaceWith(userinfo);
                },

                drawCommentsContainer: function (count, animation, showAsUnread) {
                    var animationClass = "";
                    if (animation) {
                        animationClass = "animated " + animation;
                    }
                    var unreadCssClass = "";
                    if (showAsUnread) { unreadCssClass = " primary-foreground" }
                    var commentsContainer = "<div class='commentscontainer " + animationClass + unreadCssClass + "' data-commentcount=" + count + ">";
                    if (count > 0) {
                        commentsContainer += "<i class='fa fa-comments'></i>" +
                            "<span>" + count + "</span>";

                    }
                    commentsContainer += "</div>";
                    return commentsContainer;
                },


                drawAttachmentContainer: function (count, animation, showAsUnread) {
                    var animationClass = "";
                    if (animation) {
                        animationClass = "animated " + animation;
                    }
                    var unreadCssClass = "";
                    if (showAsUnread) { unreadCssClass = " primary-foreground" }
                    var attachmentcontainer = "<div class='attachmentcontainer " + animationClass + unreadCssClass + "' data-commentcount=" + count + ">";
                    if (count > 0) {
                        attachmentcontainer += "<i class='fa fa-paperclip'></i>" +
                            "<span>" + count + "</span>";

                    }
                    attachmentcontainer += "</div>";
                    return attachmentcontainer;
                },

                updateImage: function (taskId, fileResults) {
                    var task = $("div .portlet[data-id='" + taskId + "'] .image-container").first();
                    task.find("img").remove();
                    for (let index = 0; index < fileResults.length; index++) {
                        const fileResult = fileResults[index];
                        if (fileResult.hasPreview) {
                            task.append("<img src='" + fileResult.previewUrl + "' style='max-width: 100%; max-height: 160px; margin-bottom:8px; margin-top:8px;' />");
                        }
                    }
                },

                updateCommentCount: function (count, taskcontainer, animation) {
                    var commentscontainer = $(taskcontainer).find(".commentscontainer").first();
                    $(commentscontainer).replaceWith(this.drawCommentsContainer(count, animation, true));
                },

                updateAttachmentCount: function (count, taskcontainer, animation) {
                    var commentscontainer = $(taskcontainer).find(".attachmentcontainer").first();
                    $(commentscontainer).replaceWith(this.drawAttachmentContainer(count, animation, true));
                },

                getTaskFromDOM: function (portlet, boardId) {
                    var taskTitle = $(portlet).find(".tasktitle").text();
                    var taskText = $(portlet).find(".description").text();
                    var activeTask = portlet;
                    var id = activeTask.data("id");
                    var tags = activeTask.data("tags");
                    var toContainer = $(portlet).closest("td");
                    var date = toContainer.data("date");
                    var responsibleUser = activeTask.attr("data-responsibleuser");
                    var responsibleDisplayName = activeTask.attr("data-displayname");
                    var responsibleUserId = activeTask.attr("data-responsibleuserid");
                    var type = activeTask.attr("data-type");
                    var status = activeTask.find(".status").attr("data-status");
                    var repeatParentId = activeTask.data("repeatparentid");
                    var siblingId = activeTask.data("siblingid");
                    var commentCount = $(activeTask).find(".commentscontainer").data("commentcount");
                    var color = activeTask.attr("data-color");
                    var task = {
                        id: id,
                        boardId: boardId,
                        type: type,
                        title: taskTitle,
                        text: taskText,
                        status: status,
                        tags: tags,
                        boardDate: date,
                        responsibleuserid: responsibleUserId,
                        responsibleuser: responsibleUser,
                        responsibleDisplayName: responsibleDisplayName,
                        repeatParentId: repeatParentId,
                        siblingId: siblingId,
                        commentCount: commentCount,
                        color: color
                    }
                    return task;
                },

                getDeviationAction: function (access) {
                    if (access.hasDeviation) {
                        return "<li class='divider'></li>" +
                            "<li class='add-deviation' ><a><span class='fa fa-exclamation-triangle'></span> Add deviation</a></li>";
                    }
                    return "";
                },


                readjustTaskWidth: function () {
                    var tableWidth = $("#boardContainer .ibox").width();
                    var columnsOpen = $("th.column-expand:visible").length;
                    var cellMaxWidth = Math.round((tableWidth - 60) / columnsOpen);
                    // Adjust cells so the table scales according to table.
                    $(".boardcell").css("max-width", cellMaxWidth + "px");
                    $(".boardcell").css("min-width", (cellMaxWidth - 60) + "px");
                },



                drawTags: function (tags, infoContainer) {
                    var drawTags = true;
                    if (!tags || tags.length === 0 || tags[0].tag === "") {
                        drawTags = false;
                    }
                    if (drawTags === false && infoContainer === "") return "";

                    var container = $("<div class='tag-container'></div>");
                    var ul = $('<ul class=list-inline></ul>');
                    if(drawTags){
                        for (var i = 0; i < tags.length; i++) {
                            var li = "<li><small class='label'>" + tags[i].tag + "</small></li>";
                            ul.append(li);
                        }
                    }
                    container.append(infoContainer);
                    container.append(ul);

                    return container.wrap('<p/>').parent().html();
                },
                getIconClass: function (status, isKamishibai) {
                    var statusClasses = ['blank', 'OK', 'unacceptable'];
                    var iconClasses = ['far fa-square', 'fa fa-check-square', 'fas fa-times-circle'];
                    if(isKamishibai){
                        iconClasses = ['far fa-square kamishibai-icon', 'fas fa-check-square kamishibai-icon', 'fas fa-times-circle kamishibai-icon'];
                        //iconClasses = ['far fa-square', 'far fa-check-square', 'far fa-times-circle'];
                    } 
                    var index = $.inArray(status, statusClasses);
                    return iconClasses[index];

                },

                getNextStatus: function (currentStatus, isKamishibai) {
                    var statusClasses = ['blank', 'OK', 'unacceptable'];
                    var index = $.inArray(currentStatus, statusClasses);
                    var nextIndex = index + 1;
                    if (nextIndex === statusClasses.length) {
                        nextIndex = 0;
                    }

                    var statusText = statusClasses[nextIndex];
                    return {
                        status: statusText,
                        iconClass: this.getIconClass(statusText,isKamishibai)
                    }
                }
            }
        }
        return taskDrawingService;
    }]);

