/*
This directive allows us to pass a function in on an enter key to do what we want.
// Prevent double invoke: https://stackoverflow.com/questions/35627896/angularjs-ngenter-function-is-invoked-twice
*/

angular
    .module('DigiLean')
    .directive('ngEnter', function () {
        return function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter)
                    })
    
                    event.preventDefault()
                }
            })
        }
    })