<script setup lang="ts">
    import { computed } from "vue"
    import { authUser } from "@common/stores/userStore"
    import { observableComposable } from "@common/directives/rxstate"
    import { parseToken, parseExpire } from "@common/services/tokenService"

    const authUserObs = observableComposable(authUser)
    const tokenParsed = computed(() => {
        if (authUserObs.value && authUserObs.value.token) {
            var token = parseToken(authUserObs.value.token)
            return JSON.stringify(token, null, 4)
        }
    })

    const expired = computed(() => {
        if (authUserObs.value && authUserObs.value.token) {
            var token = parseToken(authUserObs.value.token)
            var exp = parseExpire(token.exp)
            return exp
        }
        return {
            expiredDate: "",
            parsedDiff: ""
        }
    })

</script>

<template>
    <textarea cols="60" rows="30" :value="tokenParsed"></textarea><br>
    <p>Expire date: <datetime-viewer .date="expired.expiredDate"></datetime-viewer></p>
    <p>Expire In:   {{expired.parsedDiff}}</p>
</template>
