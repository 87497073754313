import { getFileType } from "@common/services/filesService"

angular
    .module('DigiLean')
    .directive("taskAttachments", ['$filter', '$uibModal',
        function ($filter, $uibModal) {
            return {
                templateUrl: 'taskattachments.html',
                restrict: 'E',
                scope: {
                    'files': '<',
                },
                link: function (scope, elem, attrs) {
                    

                    scope.filesToShow = [];
                    scope.$watch('files', function (files) {
                        // apply
                        
                        scope.filesToShow.splice(0, scope.filesToShow.length);
                        if (!files) return;
                        for (let i = 0; i < files.length; i++) {
                            var file = files[i]
                            var fileType = getFileType(file.fileExtension)
                            if (fileType) {
                                file.fileType = fileType.type
                            }                            
                            // We are only going to show files that are not image, because image will be rendered as preview
                            if (fileType.length > 0 && fileType[0].type != "image") {
                                scope.filesToShow.push(file)
                            }
                        }
                   
                    });
                }
            }
        }]);
