import angular from "angular"
import { authUser } from "@common/stores/userStore"
import { DigiLeanAuthUser, DigiLeanRole, RoleArray } from "@common/model/types"
import digiLeanOidc from "@common/services/auth/digileanOidc"

angular.module('DigiLean').factory('authService', ['moduleCache', '$rootScope',
    function (moduleCache, $rootScope) {
        
        let currentUser: DigiLeanAuthUser = {loggedIn: false, roles: new RoleArray()}
        authUser.subscribe(u => {
            currentUser = u
            if (currentUser.loggedIn) {
                setTimeout(() => {
                    try {
                        $rootScope.publish("userLoggedIn", u)
                    }
                    catch (e) {}
                }, 500)
            }
        })

        digiLeanOidc.events.addUserSignedOut(() => {
            logOut() // this ensures user logs out properly when another tab signs out. We don't want it for Teams, so place it here instead of in the common digileanOidc file.
        })

        function login() {
            return digiLeanOidc.login()
        }

        function logOut() {
            digiLeanOidc.signOut()
            moduleCache.removeAll()
        }

        function getAccessToken() {
            return currentUser.token
        }

        return {
            isCurrentUser: function(userId: string) {
                return currentUser.userId === userId
            },
            getCurrentUser: () => {
                return currentUser
            },
            login,
            renew: digiLeanOidc.silentRenew,
            logOut,
            getAccessToken,
            hasRole: (role: DigiLeanRole) => {
                if (!currentUser.roles)
                    return false
                const hasRole = currentUser.roles.hasRole(role)
                return hasRole
            }
        }
    }])

