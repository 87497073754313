﻿
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import { t } from "@common/thirdparty/orgchart/orgchart";
var DigiLean = angular.module('DigiLean'); 
DigiLean.controller('manualKpiIdentifierAdminController', ['$scope','$translate','qualityService','modalService',
    function ($scope, $translate, qualityService, modalService) {

        $scope.visualizationTypes = {
            list: [],
            filteredList: []
        };

        $scope.$watch('query', function (query) {
            if ($scope.query) {
                $scope.searchQuery= angular.copy($scope.query);
            }
        });

        qualityService().getActiveVisualizationTypes().then(function (result) {
            $scope.visualizationTypes.list = result;
            $scope.visualizationTypes.filteredList = result;
        });


        $scope.delete = function (visualizationType) {
            swal({
                title: $translate.instant('COMMON_DELETE_ITEM_DESCRIPTION'),
                text: $translate.instant('ADMIN_DATA_DELETE_VALUES'),
                icon: "warning",
                buttons: { cancelaction: { 
                                text: translateLabelInstant('COMMON_CANCEL'), 
                                className: "digilean-label-historical",
                                closeModal: true,
                                value: false},
                           deletepermanent: { 
                                text: $translate.instant('ADMIN_PERMANENT_DELETE'), 
                                className: "sa-digilean-button-danger ",
                                value: "DELETEPERMANENT",
                                closeModal: true}
                           },
                dangerMode: true,
                closeOnClickOutside: true,
                closeOnEsc: true
            })
            .then((value) => {
                if (value === "DELETEPERMANENT") {
                    qualityService().deleteVisualizationType(visualizationType).then(function () {
                        var index = $scope.visualizationTypes.list.indexOf(visualizationType);
                        if (index >= 0 && index < $scope.visualizationTypes.list.length) {
                            $scope.visualizationTypes.list.splice(index, 1);
                        }
                        var indexFiltered = $scope.visualizationTypes.filteredList.indexOf(visualizationType);
                        if (indexFiltered >= 0 && indexFiltered < $scope.visualizationTypes.filteredList.length) {
                            $scope.visualizationTypes.filteredList.splice(indexFiltered, 1);
                        }
                        toastr.info($translate.instant('COMMON_DELETED'));
                    });
                }
            });
        }
    }]);