<script setup lang="ts">
    // date-viewer element
    import {watch, ref, onMounted, computed} from "vue"
    import { formatDateOnly, formatDateTime } from "@common/services/datetime/dateTimeFormattingLocale"

    const props = defineProps({
        date: {
            type: String,
            required: true
        }
    })

    const dateFormatted = computed(() => {
        return formatDateOnly(props.date)
    })

    // just to show title/tooltip, accessing shadow root and root element in vue
    // could be useful in other situations
    let dateRef = ref<HTMLSpanElement | null>(null)
    let shadowRoot: ShadowRoot | null = null
    let $element: Element | null = null
    watch(() => props.date, (d) => {
        let formattedDateTime = formatDateTime(d)
        if ($element)
            $element.setAttribute("title", formattedDateTime)
    })
    onMounted(() => {
        shadowRoot = dateRef.value?.getRootNode() as ShadowRoot
        if (shadowRoot)
            $element = shadowRoot.host
    })
    
</script>
<style>
    
</style>
<template>
    <span ref="dateRef">{{dateFormatted}}</span>
</template>