angular
    .module('DigiLean')
    .directive("dLetter", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var dLetterDirective = {
                name: 'dLetter',
                templateUrl: 'dLetter.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<',
                    'userAccess': '<'
                }
            };

            dLetterDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        scope.visualizationType = "D";
                        //scope.setVisualizationType("D");
                   
                    }
                };
            };

            return dLetterDirective;
        }]);
