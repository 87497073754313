import { translateLabelInstant } from "@common/services/language/DigiLeanLang"

export function getTranslatedSmartColumnName (type: string): string {
    switch (type) {
        case "projectName":
            return translateLabelInstant("PROJECT_NAME");
        case "projectNumber":
            return translateLabelInstant("PROJECT_NUMBER");
        case "projectStatus":
            return translateLabelInstant("COMMON_STATUS");
        case "customer":
            return translateLabelInstant("PROJECT_CUSTOMER");
        case "owner":
            return translateLabelInstant("COMMON_RESPONSIBLE");
        case "asset":
            return translateLabelInstant("COMMON_ASSET");
        case "participants":
            return translateLabelInstant("COMMON_PARTICIPANTS");
        case "startdate":
            return translateLabelInstant("PROJECT_START_DATE");
        case "nextmilestone":
            return translateLabelInstant("PROJECT_NEXT_MILESTONE");
        case "last-modified-date":
            return translateLabelInstant("COMMON_LAST_CHANGED");
        case "duedate":
            return translateLabelInstant("COMMON_DUE_DATE");
        case "enddate":
            return translateLabelInstant("COMMON_END_DATE");
        case "text":
            return translateLabelInstant("COMMON_TEXT");
        case "date":
            return translateLabelInstant("COMMON_DATE");
        case "number":
            return translateLabelInstant("ADMIN_DATA_DATASOURCE_NUMBER");
        case "rowno":
            return translateLabelInstant("COMMON_NUMBER_SHORT");
        case "linktohub":
            return translateLabelInstant("PROJECT_HUB");
        case "contactperson":
            return translateLabelInstant("ADMIN_DATA_CONTACT");
        case "tasks":
            return translateLabelInstant("COMMON_TASKS");
        case "subtasks":
            return translateLabelInstant("COMMON_SUBTASKS");
        case "numberAutomatic":
            return translateLabelInstant("COMMON_AUTOMATIC");
        case "progress":
            return translateLabelInstant("PROJECT_PROGRESS");
        case "progresstasks":
            return translateLabelInstant("COMMON_TASKS_PROGRESS");
        case "progresssubtasks":
            return translateLabelInstant("COMMON_SUBTASKS_PROGRESS");
        case "target":
            return translateLabelInstant("ADMIN_DATA_DATASOURCE_TARGETS");
        case "responsible":
            return translateLabelInstant("COMMON_RESPONSIBLE");
        case "risk":
            return translateLabelInstant("PROJECT_RISK");
        case "rating":
            return translateLabelInstant("COMMON_RATING");
        case "check":
            return translateLabelInstant("COMMON_CHECKPOINT");
        case "linktoa3":
            return translateLabelInstant("IMPROVEMENT_TO_A3");
        case "a3Goal":
            return translateLabelInstant("COMMON_GOAL");
        case "subGoals":
            return translateLabelInstant("STRATEGY_SUB_GOALS");
        case "projectdescription":
            return translateLabelInstant("COMMON_DESCRIPTION");
        case "taskdescription":
            return translateLabelInstant("COMMON_DESCRIPTION");
        case "name": //smarttable
            return translateLabelInstant("COMMON_DESCRIPTION");
        case "icon":
            return translateLabelInstant("BOARD_ICON");
        default:
            return "";
    }
}
