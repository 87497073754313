import "@common/components/users/userBadge.css"
//import { NgComponent } from "@common/model/angularModel"
//import { UserShortInfoWithImage, getProfileImageUrl } from "@common/services/user/profileImageService"
import angular from "angular"

// interface UserBadgeCtrl extends NgComponent {
//     user: UserShortInfoWithImage
// }

const digiLean = angular.module('DigiLean')
digiLean.component("userBadge", {
    templateUrl: 'userBadge.html',
    bindings: {
        user: '<',
        showFullName: '<',
        big: '<'
    },
    controller: function() {
        // TODO: We should use this component instead of populating user lists with images everywhere

        // const $ctrl = this as UserBadgeCtrl

        // async function checkAndGetProfileImage() {
        //     if ($ctrl.user && !$ctrl.user.profileImage)
        //         $ctrl.user.profileImageUrl = await getProfileImageUrl($ctrl.user.userId!, "ExtraSmall")
        // }
        // $ctrl.$onInit = function() {
        //     checkAndGetProfileImage()
        // }
    }
})