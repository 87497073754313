var DigiLean = angular.module('DigiLean');  
DigiLean.controller('customerfeedbackController', ['$scope', '$filter', 'suggestionService', 'authService', '$uibModal',
function ($scope, $filter, suggestionService, authService, $uibModal) {
    
    // This is only for Kaizen so return if not in role.
    if (!authService.hasRole("KaizenAdmin")) return;

    $scope.suggestions = [];
    $scope.allSuggestions = [];
    $scope.suggestionsToBeHandled = [];
 
    $scope.subscribe('ImprovementChangedStatus', function (status) { 
        var improvement = $filter('filter')($scope.suggestions, { id: status.suggestionId }, true);
        if (improvement.length > 0) {
            improvement[0].changed = true;
            improvement[0].status = status.status;
        }     
    });

    $scope.changeStatus = function (suggestion) {
        var modalInstance = $uibModal.open({ backdrop: 'static',
            animation: true,
            templateUrl: 'changestatus.html',
            controller: 'changestatusController',
            resolve: {
                suggestion: function () {
                    return suggestion;
                }
            }
        });
    };


    $scope.subscribe("SuggestionCommentedOn", function (suggestionComment) {
        // Check if comment is related to one of users
        var improvement = $filter('filter')($scope.suggestions, { id: suggestionComment.improvementSuggestionId }, true);
        if (improvement.length > 0) {
            improvement[0].changed = true;
            improvement[0].comments.push(suggestionComment);
            var index = $scope.suggestions.indexOf(improvement[0]);
            if (index > -1) {
                $scope.suggestions.splice(index, 1);
                $scope.suggestions.unshift(improvement[0]);
            }
            
        }
    });


    // Get suggestions
    var getSuggestions = function () {
        suggestionService().getCustomerSuggestions().then(function (suggestions) {
            suggestions.forEach(function (suggestion) {
                suggestion.activeComment = "";
                suggestion.changed = false;
                if(suggestion.status === "Suggested" || suggestion.status === "Open" || suggestion.status === "Planned" || suggestion.status === "InProgress"){
                    $scope.suggestionsToBeHandled.push(suggestion);
                }
            });
            $scope.suggestions = $scope.suggestionsToBeHandled;
            $scope.allSuggestions = suggestions;
        });
    }
    getSuggestions();



    $scope.showSuggestionsToBeHandled = function () {
        $scope.suggestions = $scope.suggestionsToBeHandled;
    }

    $scope.showAllSuggestions = function () {
        $scope.suggestions = $scope.allSuggestions;
    }
    

    $scope.showComments = function (suggestion) {
        suggestion.showComments = !suggestion.showComments;
    }

    $scope.addComment = function (suggestion) {
        if (typeof suggestion.comments === 'undefined') {
            suggestion.comments = [];
        }

        var currentComment = {
            suggestionId: suggestion.id,
            comment: suggestion.activeComment
        };
        suggestionService().addComment(currentComment).then(function (savedComment) {
            suggestion.comments.push(savedComment);
            return false;
        });
        suggestion.activeComment = "";

    }

}]);