import lottie from "lottie-web/build/player/lottie_light"
angular
    .module('DigiLean')
    .directive('personalTaskStatus', ['$rootScope', 'boardTaskService', 'taskDrawingService', 'suggestionService', 'deviationService', 'debounce',
        function ($rootScope, boardTaskService, taskDrawingService, suggestionService, deviationService, debounce) {
            return {
                templateUrl: 'personalTaskStatus.html',
                restrict: 'E',
                scope: {
                    "task": "<"
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.$watch('task', function (task) {
                        if (!task) return;
                        checkCompletedStatus();
                    });
                    scope.$on('$destroy', function () {
                        if (completeAnimation) {
                            completeAnimation.destroy(completeAnimationName);
                        }
                    });
                    var statusChangeDebounced = debounce(commitStatusChanged, 1000, false);
                    var improvementStatusChangeDebounced = debounce(commitImprovementStatusChanged, 1000, false);
                    var deviationStatusChangeDebounced = debounce(commitDeviationStatusChanged, 1000, false);

                    scope.changeStatus = function (task) {
                        switch (task.entityType) {
                            case "Improvement":
                                scope.changeImprovementStatus(task);
                                break;
                            case "Deviation":
                                scope.changeDeviationStatus(task);
                                break;
                            default:
                                scope.changeTaskStatus(task);
                                break;
                        }
                    }

                    scope.changeTaskStatus = function (task) {
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        if (task.status == "OK") {
                            showCompleteAnimation();
                        }
                        var statusOptions = {
                            taskId: task.id,
                            status: task.status
                        };
                        statusChangeDebounced(task, statusOptions);
                    }
                    scope.changeImprovementStatus = function (task) {
                        task.status = suggestionService().getNextStatus(task.status);
                        if (task.status == "Implemented") {
                            showCompleteAnimation();
                        }
                        var changedStatus =
                        {
                            suggestionId: task.id,
                            status: task.status
                        }
                        improvementStatusChangeDebounced(changedStatus);
                    }

                    scope.changeDeviationStatus = function (task) {
                        task.status = deviationService().getNextStatus(task.status);
                        if (task.status == "20") {
                            showCompleteAnimation();
                        }
                        var changedStatus =
                        {
                            deviationId: task.id,
                            status: task.status
                        }

                        deviationStatusChangeDebounced(changedStatus);
                    }

                    function checkCompletedStatus() {
                        switch (scope.task.entityType) {
                            case "Improvement":
                                if (scope.task.status == "Implemented") {
                                    setTimeout(function() {
                                        showCompleteAnimation();
                                    }, 100);
                                } else {
                                    if (completeAnimation) {
                                        completeAnimation.stop(completeAnimationName);
                                    }
                                }
                                return;
                            case "Deviation":
                                if (scope.task.status == "20") {
                                    setTimeout(function() {
                                        showCompleteAnimation();
                                    }, 100);
                                } else {
                                    if (completeAnimation) {
                                        completeAnimation.stop(completeAnimationName);
                                    }
                                }
                                return;
                            default:
                                if (scope.task.status == "OK") {
                                    setTimeout(function() {
                                        showCompleteAnimation();
                                    }, 100);
                                } else {
                                    if (completeAnimation) {
                                        completeAnimation.stop(completeAnimationName);
                                    }
                                }
                                return;
                        }

                    }

                    var completeAnimation;
                    var completeAnimationName = "ok-animation";
                    function showCompleteAnimation() {
                        if (completeAnimation) {
                            completeAnimation.play();
                            return;
                        }
                        var okContainer = $(elem).find(".ok-animation")[0]; // the dom element that will contain the animation
                        completeAnimation = lottie.loadAnimation({
                            container: okContainer,
                            renderer: 'svg',
                            name: completeAnimationName,
                            loop: false,
                            autoplay: true,
                            path: 'https://assets3.lottiefiles.com/packages/lf20_sbJCHH.json' // the path to the animation json
                        });

                        okContainer.addEventListener('click', function () {
                            completeAnimation.stop(completeAnimationName);
                            scope.changeStatus(scope.task);
                        });
                    }

                    function commitStatusChanged(task, statusOptions) {
                        boardTaskService().updateStatus(task.id, statusOptions).then(function (result) {

                        });
                    }

                    function commitImprovementStatusChanged(statusChanged) {
                        suggestionService().updateStatus(statusChanged).then(function (result) {
                        });
                    }

                    function commitDeviationStatusChanged(statusChanged) {
                        deviationService().updateStatus(statusChanged).then(function (result) {

                        });
                    }

                    $rootScope.subscribe('TaskStatusUpdated', function (status) {
                        updateTaskStatus(status.taskId, status.status, "BoardTask");
                    });
                    $rootScope.subscribe('ImprovementChangedStatus', function (status) {
                        updateTaskStatus(status.suggestionId, status.status, "Improvement");
                    });
                    $rootScope.subscribe('DeviationChangedStatus', function (status) {
                        updateTaskStatus(status.deviationId, status.status, "Deviation");
                    });

                    function updateTaskStatus(id, status, entityType) {
                        if (!entityType) entityType = "BoardTask";
                        if (scope.task.id != id) return;
                        if (scope.task.entityType != entityType) return;

                        scope.task.status = status;
                        checkCompletedStatus();

                    }
                }
            }
        }]);
