import { NgComponent } from "@common/model/angularModel";
import { UserMention } from "@common/model/types";
import angular from "angular"
import FroalaEditor from "froala-editor"
import Tribute, { TributeOptions } from "tributejs"
import * as profileImageService from "@common/services/user/profileImageService"

angular
    .module('DigiLean')
    .component("commentEditor", {
        template: '<div>@</div>',
        bindings: {
            text: '<',
            allUsers: '<',
            onUpdate: '&'
        },
        controller: ['$element', '$translate',
            function (this: NgComponent, $element: JQuery, $translate) {
                let $ctrl = this
                let froalaEditor: FroalaEditor

                let tributeConfig: TributeOptions<UserMention> = {
                    lookup: 'fullName',
                    fillAttr: 'fullName',
                    menuItemTemplate: function (item) {
                        if (item.original.imageUrl)
                            return '<img class="img-circle mention-profile-image" src="' + item.original.imageUrl + '"><span class="profile-name">' + item.string + '</span>'
                        return '<span class="mention-profile-image-initials">' + item.original.initials + '</span><span class="profile-name">' + item.string + '</span>'
                    },
                    selectTemplate: function (item) {
                        return (
                            '<span contenteditable="false" class="mention" data-userid="' + item.original.id + '"><a>@' +
                            item.original.fullName +
                            "</a></span>"
                        )
                    },
                    values: []
                };

                let tribute = new Tribute(tributeConfig)

                var froalaOptions = {
                    key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
                    attribution: false,
                    toolbarButtons: ["bold", "italic", "underline"],
                    pastePlain: false,
                    imagePaste: false,
                    linkAlwaysBlank: true,
                    toolbarSticky: true,
                    toolbarInline: true,
                    placeholderText: $translate.instant('COMMON_ENTER_TEXT_MENTION'),
                    quickInsertTags: [],
                    events: {
                        initialized: function(this: FroalaEditor) {
                            const editor = this;
                            editor.html.set("")
                            
                            tribute.attach(editor.el)
                    
                            editor.events.on('keydown', function(e: any) {
                                if (e.which == FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
                                    return false
                                }
                            }, true)

                            // tribute events
                            editor.el.addEventListener("tribute-replaced", function (e: any) {
                                console.log(e.detail.item.original)
                            })
                        },
                        'contentChanged': function () {
                            const val = froalaEditor.html.get()
                            const mentions = getMentions()
                            $ctrl.onUpdate({val, mentions})
                        },
                        'paste.after': function (this: FroalaEditor) {
                            const editor = this
                            const contents = editor.el.innerHTML
                            $ctrl.text = contents
                        }
                    }
                }
                
                function getMentions() {
                    var mentionsElements = froalaEditor.el.querySelectorAll("span.mention")
                    let mentions: string[] = []
                    if (mentionsElements.length > 0) {
                        for (let mention of mentionsElements) {
                            var id = mention.dataset.userid as string
                            if (id)
                                mentions.push(id)
                        }
                    }
                    return mentions
                }

                $ctrl.$onInit = function() {
                    froalaEditor = new FroalaEditor($element[0], froalaOptions)
                }

                $ctrl.$onChanges = async function (changes) {
                    if (changes.text) {
                        if (!changes.text.currentValue && froalaEditor && froalaEditor.html)
                            froalaEditor.html.set("")
                    }
                    
                    if (changes.allUsers) {

                        if (changes.allUsers.currentValue) {
                            var mentionUsers: UserMention[] = []
                            for (var i = 0; i < changes.allUsers.currentValue.length; i++) {
                                const user = changes.allUsers.currentValue[i]
                                if (!user || !user.fullName)
                                    continue;
                                let um: UserMention = {
                                    fullName: user.fullName,
                                    id: user.id ? user.id : user.userId,
                                    initials: user.initials,
                                    imageUrl: ""
                                }
                                if (user.profileImage && user.profileImage.fileUrl) {
                                    um.imageUrl = user.profileImage.fileUrl
                                } else {
                                    var imageUrl = await profileImageService.getProfileImageUrl(um.id, "ExtraSmall")
                                    if (imageUrl) 
                                        um.imageUrl = imageUrl
                                }
                                mentionUsers.push(um)
                            }
                            tribute.append(0, mentionUsers)
                        }
                    }
                }

                $ctrl.$onDestroy = function() {
                    froalaEditor.destroy()
                }
            }]
    })