import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  for: "file-input"
}
const _hoisted_3 = {
  key: 0,
  class: "error"
}
const _hoisted_4 = [".label"]
const _hoisted_5 = ["accept"]

import {onMounted, ref, onUnmounted} from "vue"
    import { uploadFileBlobStorage } from "@common/services/documentService"
    import { CustomEventsEmitter } from "@common/directives/vueCustomEventsEmitter"
    
export default /*@__PURE__*/_defineComponent({
  __name: 'fileUploader',
  props: {
        accept: { type: String, required: false, default: "*" }
    },
  setup(__props) {

    const vCustomEventsEmitter = new CustomEventsEmitter()
    
    const wrapperEl = ref<HTMLDivElement | null>(null)
    const inputEl = ref<HTMLInputElement | null>(null)

    const props = __props

    let uploading = ref(false)
    let isDraggingOver = ref(false)
    let errorMsg = ref("")

    let inputField: HTMLInputElement | null = null

    const onChangeInput = (e: Event) => {
        inputField = e.target as HTMLInputElement
        if (inputField.files && inputField.files[0]) {
            uploadFile(inputField.files[0])
        }
    }
    const uploadFile = async (file: File) => {
        uploading.value = true
        if (inputField)
            inputField.disabled = true
        
        const filesUploaded = await uploadFileBlobStorage(file)
        if (filesUploaded)
            vCustomEventsEmitter.emit("files-uploaded", filesUploaded)
        
        if (inputField) {
            inputField.disabled = false
            inputField.value = ""   
        }
        uploading.value = false
    }

    const getAndValidateFileDragging = (ev: DragEvent) => {
        errorMsg.value = ""
        if (ev.dataTransfer?.items && ev.dataTransfer?.items.length > 0) {
            console.log("ev.dataTransfer.items", ev.dataTransfer.items)
            if (ev.dataTransfer.items.length > 1) {
                errorMsg.value = "FILEUPLOADER_ONLY_ONE_FILE"
                return
            }
            const item = ev.dataTransfer.items[0]
            if (item.kind == "file") {
                const file = item.getAsFile()
                return file
            }
        } else if (ev.dataTransfer?.files && ev.dataTransfer?.files.length > 0) {
            console.log("ev.dataTransfer.files", ev.dataTransfer.files)
            if (ev.dataTransfer?.files.length > 1) {
                errorMsg.value = "FILEUPLOADER_ONLY_ONE_FILE"
                return
            }
            const file = ev.dataTransfer.files[0]
            return file
        }
        return null
    }
    const onDrop = (ev: DragEvent) => {
        ev.preventDefault()
        const file = getAndValidateFileDragging(ev)
        if (file)
            uploadFile(file)
        isDraggingOver.value = false
    }
    const onDragEnter = (e: DragEvent) => {
        console.log("onDragEnter")
        e.preventDefault()
        isDraggingOver.value = true
        getAndValidateFileDragging(e)
    }
    const onDragOver = (e: DragEvent) => {
        e.preventDefault()
        isDraggingOver.value = true
    }
    const onDragLeave = (e: DragEvent) => {
        e.preventDefault()
        isDraggingOver.value = false
    }

    onMounted(() => {
        console.log("accept", props.accept)
        if (inputEl.value)
            inputEl.value.addEventListener("change", onChangeInput)
        if (wrapperEl.value) {
            wrapperEl.value.addEventListener("drop", onDrop);
            wrapperEl.value.addEventListener("dragenter", onDragEnter);
            wrapperEl.value.addEventListener("dragover", onDragOver);
            wrapperEl.value.addEventListener("dragleave", onDragLeave);
        }
    })
    onUnmounted(() => {
        if (inputEl.value)
            inputEl.value.removeEventListener("change", onChangeInput)
        if (wrapperEl.value) {
            wrapperEl.value.removeEventListener("drop", onDrop);
            wrapperEl.value.removeEventListener("dragenter", onDragEnter);
            wrapperEl.value.removeEventListener("dragover", onDragOver);
            wrapperEl.value.removeEventListener("dragleave", onDragLeave);
        }
    })

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "wrapper",
    class: _normalizeClass({draggingover: _unref(isDraggingOver)}),
    ref_key: "wrapperEl",
    ref: wrapperEl
  }, [
    (_unref(uploading))
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Uploading ...", -1 /* HOISTED */),
          _createElementVNode("spinner-icon", { spin: "true" }, null, -1 /* HOISTED */)
        ])))
      : (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("translate-label", { label: "COMMON_DROPZONE" }, null, -1 /* HOISTED */)),
          (_unref(errorMsg))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                _createElementVNode("translate-label", { ".label": _unref(errorMsg) }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
              ]))
            : _createCommentVNode("v-if", true)
        ])),
    _createElementVNode("input", {
      type: "file",
      ref_key: "inputEl",
      ref: inputEl,
      accept: __props.accept,
      id: "file-input",
      class: "noshow"
    }, null, 8 /* PROPS */, _hoisted_5)
  ], 2 /* CLASS */)), [
    [_unref(vCustomEventsEmitter)]
  ])
}
}

})