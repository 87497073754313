import lottie from "lottie-web/build/player/lottie_light"
angular
    .module('DigiLean')
    .directive("taskStatus", ['$rootScope', 'boardTaskService','taskDrawingService','debounce',
        function ($rootScope, boardTaskService,taskDrawingService, debounce) {
            return {
                templateUrl: 'taskStatus.html',
                restrict: 'E',
                scope: {
                    "taskId": "<",
                    "currentStatus": "<",
                },
                link: function (scope, elem, attrs) {

                    scope.$watch('currentStatus', function (status) {
                        if (!status) return;
                        scope.status = status;
                        checkCompletedStatus();
                    });
                    scope.$on('$destroy', function () {
                        if (completeAnimation) {
                            completeAnimation.destroy(completeAnimationName);
                        }
                    });
                    var statusChangeDebounced = debounce(commitStatusChanged, 1000, false);
                    scope.changeTaskStatus = function () {
                        scope.status = taskDrawingService().getNextStatus(scope.status).status;
                        if (scope.status == "OK") {
                            showCompleteAnimation();
                        }
                        var statusOptions = {
                            taskId: scope.taskId,
                            status: scope.status
                        };
                        statusChangeDebounced(statusOptions);
                    }

                    function checkCompletedStatus(){
                        if (scope.status == "OK") {
                            setTimeout(function() {
                                showCompleteAnimation();
                            }, 100);
                        } else {
                            if (completeAnimation) {
                                completeAnimation.stop(completeAnimationName);
                            }
                        }
                    }

                    function commitStatusChanged(statusOptions) {
                        boardTaskService().updateStatus(scope.taskId, statusOptions).then(function (result) {

                        });
                    }

                    $rootScope.subscribe('TaskStatusUpdated', function (status) {
                        updateTaskStatus(status.taskId, status.status);
                    });

                    function updateTaskStatus(id, status) {
                        if (scope.taskId != id) return;
                        scope.status = status;
                        checkCompletedStatus();
                    }

                    var completeAnimation;
                    var completeAnimationName = "ok-animation";
                    function showCompleteAnimation() {
                        if (completeAnimation) {
                            completeAnimation.play();
                            return;
                        }
                        var okContainer = $(elem).find(".ok-animation")[0]; // the dom element that will contain the animation
                        completeAnimation = lottie.loadAnimation({
                            container: okContainer,
                            renderer: 'svg',
                            name: completeAnimationName,
                            loop: false,
                            autoplay: true,
                            path: 'https://assets3.lottiefiles.com/packages/lf20_sbJCHH.json' // the path to the animation json
                        });

                        okContainer.addEventListener('click', function () {
                            completeAnimation.stop(completeAnimationName);
                            scope.changeTaskStatus();
                        });
                    }
                }
            }
        }]);