import { systemHub } from "@common/services/systemHub"
import { DigiLeanNgScope } from "@common/model/angularModel"
import * as systemHubService from "@common/services/systemHubService"
import { A3StepContentChanged } from "@common/model/backendTypes"

export function connectSystemHubEvents($scope: DigiLeanNgScope) {
    systemHub.on("TrialRegistration", function(trial) {
        console.log("TrialRegistration")
        console.log(trial)
    })
    systemHub.on('TestMessage', function (test) {
        $scope.publish("TestMessage", test);
    });
    systemHub.on('Notification', function (notification) {
        $scope.publish("Notification", notification);
    });
    systemHub.on('NotificationSent', function (notification) {
        $scope.publish("NotificationSent", notification);
    });
    systemHub.on('NotificationArchived', function (notification) {
        $scope.publish("NotificationArchived", notification);
    });
    systemHub.on('NotificationSeen', function (notification) {
        $scope.publish("NotificationSeen", notification);
    });
    systemHub.on('MarkedAllNotificationsAsRead', function (user) {
        $scope.publish("MarkedAllNotificationsAsRead", user);
    });
    systemHub.on('OnlineUsersStatus', function (status) {
        $scope.publish("OnlineUsersStatus", status);
    });
    systemHub.on('UsersConnectedChanged', function (users) {
        $scope.publish("UsersConnectedChanged", users);
    });
    systemHub.on('CustomerUsersConnectedChanged', function (customerEvent) {
        $scope.publish("CustomerUsersConnectedChanged", customerEvent);
    });
    systemHub.on('UsersHavingAssetAccessChanged', function (users) {
        $scope.publish("UsersHavingAssetAccessChanged", users);
    });
    systemHub.on('AllUsersOnline', function (usersOnline) {
        $scope.publish("AllUsersOnline", usersOnline);
    });
    // ThreeC
    systemHub.on('ThreeCItemCreated', function (data) {
        $scope.publish("ThreeCItemCreated", data);
    });
    systemHub.on('ThreeCItemConcernUpdated', function (data) {
        $scope.publish("ThreeCItemConcernUpdated", data);
    });
    systemHub.on('ThreeCLogAssetUpdated', function (data) {
        $scope.publish("ThreeCLogAssetUpdated", data);
    });
    systemHub.on('ThreeCItemStatusUpdated', function (data) {
        $scope.publish("ThreeCItemStatusUpdated", data);
    });
    /// Dashboard events
    ///
    systemHub.on('DashboardCreated', function (dashboard) {
        $scope.publish("DashboardCreated", dashboard);
    });
    systemHub.on('DashboardUpdated', function (dashboard) {
        $scope.publish("DashboardUpdated", dashboard);
    });
    systemHub.on('DashboardDeleted', function (dashboard) {
        $scope.publish("DashboardDeleted", dashboard);
    });
    systemHub.on('DashboardWidgetSettingsChanged', function (data) {
        $scope.publish("DashboardWidgetSettingsChanged", data);
    });

    systemHub.on('DashboardPageAdded', function(page) {
        $scope.publish("DashboardPageAdded", page);
    });
    systemHub.on('DashboardPageDeleted', function(page) {
        $scope.publish("DashboardPageDeleted", page);
    });
    systemHub.on('DashboardWidgetAdded', function(widget) {
        $scope.publish("DashboardWidgetAdded", widget);
    });
    systemHub.on('DashboardWidgetRemoved', function(widget) {
        $scope.publish("DashboardWidgetRemoved", widget);
    });
    systemHub.on('DashboardWidgetChanged', function(widget) {
        $scope.publish("DashboardWidgetChanged", widget);
    })
    /// Dashboard events end
    ///
    systemHub.on('DataValueAdded', function (value) {
        $scope.publish("DataValueAdded", value);
    });
    systemHub.on('DataValueDeleted', function (value) {
        $scope.publish("DataValueDeleted", value);
    });
    systemHub.on('DataValueUpdated', function (value) {
        $scope.publish("DataValueUpdated", value);
    });
    systemHub.on('DataListSortOrderChanged', function (value) {
        $scope.publish("DataListSortOrderChanged", value);
    });
    systemHub.on('DataListCreated', function (value) {
        $scope.publish("DataListCreated", value);
    });
    systemHub.on('DataListItemAdded', function (value) {
        $scope.publish("DataListItemAdded", value);
    });
    systemHub.on('DataListItemDeleted', function (value) {
        $scope.publish("DataListItemDeleted", value);
    });
    systemHub.on('DataListItemUpdated', function (value) {
        $scope.publish("DataListItemUpdated", value);
    });
    systemHub.on('DataListUpdated', function (value) {
        $scope.publish("DataListUpdated", value);
    });
    systemHub.on('DataListDeleted', function (value) {
        $scope.publish("DataListDeleted", value);
    });
    systemHub.on('DataSourceUpdated', function (value) {
        $scope.publish("DataSourceUpdated", value);
    });
    systemHub.on('DataSourceUpdated', function (value) {
        $scope.publish("DataSourceUpdated", value);
    });
    systemHub.on('DataSourceCreated', function (value) {
        $scope.publish("DataSourceCreated", value);
    });
    systemHub.on('DataSourceDeleted', function (value) {
        $scope.publish("DataSourceDeleted", value);
    });
    systemHub.on('DateStatusChanged', function (attribute) {
        $scope.publish("DateStatusChanged", attribute);
    });
    systemHub.on('ConnectorCreated', function (value) {
        $scope.publish("ConnectorCreated", value);
    });
    systemHub.on('ConnectorUpdated', function (value) {
        $scope.publish("ConnectorUpdated", value);
    });
    systemHub.on('ConnectorDeleted', function (value) {
        $scope.publish("ConnectorDeleted", value);
    });
    systemHub.on('JobCreated', function (value) {
        $scope.publish("JobCreated", value);
    });
    systemHub.on('JobUpdated', function (value) {
        $scope.publish("JobUpdated", value);
    });
    systemHub.on('JobDeleted', function (value) {
        $scope.publish("JobDeleted", value);
    });
    systemHub.on('NewVersion', function (version) {
        $scope.publish("NewVersion", version);
    });
    systemHub.on('ImprovementLikeAdded', function (like) {
        $scope.publish("ImprovementLikeAdded", like);
    });
    systemHub.on('ImprovementLikeRemoved', function (like) {
        $scope.publish("ImprovementLikeRemoved", like);
    });
    systemHub.on('SuggestionCommentedOn', function (comment) {
        $scope.publish("SuggestionCommentedOn", comment);
    });
    systemHub.on('SuggestionAttachmentAdded', function (attachment) {
        $scope.publish("SuggestionAttachmentAdded", attachment);
    });
    systemHub.on('SuggestionAttachmentDeleted', function (attachment) {
        $scope.publish("SuggestionAttachmentDeleted", attachment);
    });
    systemHub.on('NewFeedBackToDigiLean', function (suggestion) {
        $scope.publish("NewFeedBackToDigiLean", suggestion);
    });
    systemHub.on('NewImprovement', function (improvement) {
        $scope.publish("NewImprovement", improvement);
    });
    systemHub.on('ImprovementUpdated', function (improvement) {
        $scope.publish("ImprovementUpdated", improvement);
    });
    systemHub.on('ImprovementTitleChanged', function (improvement) {
        $scope.publish("ImprovementTitleChanged", improvement);
    });
    systemHub.on('ImprovementDeleted', function (improvement) {
        $scope.publish("ImprovementDeleted", improvement);
    });
    systemHub.on('ImprovementChangedStatus', function (status) {
        $scope.publish("ImprovementChangedStatus", status);
    });
    systemHub.on('ImprovementPriorityChanged', function (priority) {
        $scope.publish("ImprovementPriorityChanged", priority);
    });
    systemHub.on('ImprovementResponsibleChanged', function (data) {
        $scope.publish("ImprovementResponsibleChanged", data);
    });
    systemHub.on('ImprovementDueDateChanged', function (data) {
        $scope.publish("ImprovementDueDateChanged", data);
    });
    systemHub.on('ImprovementTextChanged', function (data) {
        $scope.publish("ImprovementTextChanged", data);
    });
    systemHub.on('A3LinkAdded', function (link) {
        $scope.publish("A3LinkAdded", link);
    });
    systemHub.on('A3LinkDeleted', function (link) {
        $scope.publish("A3LinkDeleted", link);
    });    
    systemHub.on('A3AttachmentAdded', function (attachment) {
        $scope.publish("A3AttachmentAdded", attachment);
    });
    systemHub.on('A3AttachmentDeleted', function (attachment) {
        $scope.publish("A3AttachmentDeleted", attachment);
    });
    systemHub.on('A3AttachmentCountChanged', function (attachment) {
        $scope.publish("A3AttachmentCountChanged", attachment);
    });
    systemHub.on('A3Created', function (a3) {
        $scope.publish("A3Created", a3);
    });
    systemHub.on('A3Updated', function (a3) {
        $scope.publish("A3Updated", a3);
    });
    systemHub.on('A3OwnerChanged', function (a3) {
        $scope.publish("A3OwnerChanged", a3);
    });
    systemHub.on('A3StrategyGoalAdded', function (a3) {
        $scope.publish("A3StrategyGoalAdded", a3);
    });
    systemHub.on('A3StrategyGoalDeleted', function (a3) {
        $scope.publish("A3StrategyGoalDeleted", a3);
    });
    systemHub.on('A3Deleted', function (id) {
        $scope.publish("A3Deleted", id);
    });
    systemHub.on('A3TemplateCreated', function (A3Template) {
        $scope.publish("A3TemplateCreated", A3Template);
    });
    systemHub.on('A3TemplateUpdated', function (A3Template) {
        $scope.publish("A3TemplateUpdated", A3Template);
    });
    systemHub.on('A3TemplateDeleted', function (id) {
        $scope.publish("A3TemplateDeleted", id);
    });
    systemHub.on('A3sDeleted', function (ids) {
        $scope.publish("A3sDeleted", ids);
    });
    systemHub.on('A3TitleChanged', function (title) {
        $scope.publish("A3TitleChanged", title);
    });
    systemHub.on('A3GoalChanged', function (goal) {
        $scope.publish("A3GoalChanged", goal);
    });
    systemHub.on('A3VersionCreated', function (a3) {
        $scope.publish("A3VersionCreated", a3);
    });
    systemHub.on('A3ProjectInfoUpdated', function (info) {
        $scope.publish("A3ProjectInfoUpdated", info);
    });
    systemHub.on('A3ProjectMilestoneDeleted', function (milestone) {
        $scope.publish("A3ProjectMilestoneDeleted", milestone);
    });
    systemHub.on('A3ProjectMilestoneCreated', function (milestone) {
        $scope.publish("A3ProjectMilestoneCreated", milestone);
    });
    systemHub.on('A3ProjectMilestoneUpdated', function (milestone) {
        $scope.publish("A3ProjectMilestoneUpdated", milestone);
    });
    systemHub.on('A3StatusChanged', function (data) {
        $scope.publish("A3StatusChanged", data);
    });
    systemHub.on('A3StepUpdated', function (step) {
        $scope.publish("A3StepUpdated", step);
    });
    systemHub.on('A3StepContentChanged', function (data: A3StepContentChanged) {
        $scope.publish("A3StepContentChanged", data)
    });
    systemHub.on('A3StepApprovedEvent', function (step) {
        $scope.publish("A3StepApprovedEvent", step);
    });
    systemHub.on('A3StepCommentedOn', function (step) {
        $scope.publish("A3StepCommentedOn", step);
    });
    systemHub.on('A3ParticipantGroupUpdated', function (group) {
        $scope.publish("A3ParticipantGroupUpdated", group);
    });
    systemHub.on('A3TemplateDownloaded', function (data) {
        $scope.publish("A3TemplateDownloaded", data);
    });
    systemHub.on('DeviationResponsibleChanged', function (data) {
        $scope.publish("DeviationResponsibleChanged", data);
    });
    systemHub.on('DeviationDueDateChanged', function (data) {
        $scope.publish("DeviationDueDateChanged", data);
    });
    systemHub.on('DeviationCreated', function (data) {
        $scope.publish("DeviationCreated", data);
    });
    systemHub.on('DeviationUpdated', function (data) {
        $scope.publish("DeviationUpdated", data);
    });
    systemHub.on('DeviationDeleted', function (data) {
        $scope.publish("DeviationDeleted", data);
    });
    systemHub.on('DeviationChangedStatus', function (data) {
        $scope.publish("DeviationChangedStatus", data);
    });
    systemHub.on('DeviationSeverityChanged', function (data) {
        $scope.publish("DeviationSeverityChanged", data);
    });
    systemHub.on('DeviationCommentedOn', function (comment) {
        $scope.publish("DeviationCommentedOn", comment);
    });
    systemHub.on('DeviationAttachmentAdded', function (attachment) {
        $scope.publish("DeviationAttachmentAdded", attachment);
    });
    systemHub.on('DeviationAttachmentDeleted', function (attachment) {
        $scope.publish("DeviationAttachmentDeleted", attachment);
    });
    systemHub.on('DeviationAttachmentCountChanged', function (attachment) {
        $scope.publish("DeviationAttachmentCountChanged", attachment);
    });
    systemHub.on('DeviationTitleChanged', function (data) {
        $scope.publish("DeviationTitleChanged", data);
    });
    systemHub.on('DeviationConsequencesChanged', function (data) {
        $scope.publish("DeviationConsequencesChanged", data);
    });
    systemHub.on('DeviationArchived', function (data) {
        $scope.publish("DeviationArchived", data);
    });
    systemHub.on('DeviationUnArchived', function (data) {
        $scope.publish("DeviationUnArchived", data);
    });
    systemHub.on('ActivityOccured', function (activity) {
        $scope.publish("ActivityOccured", activity);
    });
    systemHub.on('SystemActivityOccured', function (activity) {
        $scope.publish("SystemActivityOccured", activity);
    });
    systemHub.on('BoardTemplateDeleted', function (template) {
        $scope.publish("BoardTemplateDeleted", template);
    });
    systemHub.on('NewTask', function (task) {
        $scope.publish("NewTask", task);
    });
    systemHub.on('CreateActionListItem', function (task) {
        $scope.publish("CreateActionListItem", task);
    });
    systemHub.on('DeleteActionListItem', function (task) {
        $scope.publish("DeleteActionListItem", task);
    });
    systemHub.on('UpdateActionListItem', function (task) {
        $scope.publish("UpdateActionListItem", task);
    });
    systemHub.on('ActionItemMovedToTask', function (task) {
        $scope.publish("ActionItemMovedToTask", task);
    });
    systemHub.on('TaskUpdated', function (task) {
        $scope.publish("TaskUpdated", task);
    });
    systemHub.on('TaskStatusUpdated', function (status) {
        $scope.publish("TaskStatusUpdated", status);
    });
    systemHub.on('TaskDeviationCreated', function (task) {
        $scope.publish("TaskDeviationCreated", task);
    });
    systemHub.on('TaskCloned', function (clone) {
        $scope.publish("TaskCloned", clone);
    });
    systemHub.on('TaskDeleted', function (id) {
        $scope.publish("TaskDeleted", id);
    });
    systemHub.on('TaskMoved', function (moveSettings) {
        $scope.publish("TaskMoved", moveSettings);
    });
    systemHub.on('TaskAssigned', function (taskAssigned) {
        $scope.publish("TaskAssigned", taskAssigned);
    });
    systemHub.on('TaskDurationUpdated', function (durationUpdated) {
        $scope.publish("TaskDurationUpdated", durationUpdated);
    });
    systemHub.on('TaskMovedRow', function (durationUpdated) {
        $scope.publish("TaskMovedRow", durationUpdated);
    });
    systemHub.on('TaskMovedToActionList', function (task) {
        $scope.publish("TaskMovedToActionList", task);
    });
    systemHub.on('TaskStartDateUpdated', function (task) {
        $scope.publish("TaskStartDateUpdated", task);
    });
    systemHub.on('BoardDeleted', function (id) {
        $scope.publish("BoardDeleted", id);
    });
    systemHub.on('BoardCreated', function (board) {
        $scope.publish("BoardCreated", board);
    });
    systemHub.on('TasksDeleted', function (taskIds) {
        $scope.publish("TasksDeleted", taskIds);
    });
    systemHub.on('TaskRepeated', function (data) {
        $scope.publish("TaskRepeated", data);
    });
    systemHub.on('BoardCategoryAssigned', function (data) {
        $scope.publish("BoardCategoryAssigned", data);
    });
    systemHub.on('BoardNameUpdated', function (data) {
        $scope.publish("BoardNameUpdated", data);
    });
    systemHub.on('BoardSettingUpdated', function (data) {
        $scope.publish("BoardSettingUpdated", data);
    });
    systemHub.on('DashboardSettingsUpdated', function (data) {
        $scope.publish("DashboardSettingsUpdated", data);
    });
    systemHub.on('DashboardTitleUpdated', function (data) {
        $scope.publish("DashboardTitleUpdated", data);
    });
    systemHub.on('DeviationActionItemAdded', function (data) {
        $scope.publish("DeviationActionItemAdded", data);
    });
    systemHub.on('TaskDateUpdated', function (data) {
        $scope.publish("TaskDateUpdated", data);
    });
    systemHub.on('TaskDescriptionUpdated', function (data) {
        $scope.publish("TaskDescriptionUpdated", data);
    });
    systemHub.on('TaskChangedBoard', function (data) {
        $scope.publish("TaskChangedBoard", data);
    });
    systemHub.on('TaskCommentAdded', function (data) {
        $scope.publish("TaskCommentAdded", data);
    });
    systemHub.on('TaskCommentDeleted', function (data) {
        $scope.publish("TaskCommentDeleted", data);
    });
    systemHub.on('TaskComment', function (data) {
        $scope.publish("TaskCommentAdded", data);
    });
    systemHub.on('TaskAttachmentAdded', function (data) {
        $scope.publish("TaskAttachmentAdded", data);
    });
    systemHub.on('TaskAttachmentDeleted', function (data) {
        $scope.publish("TaskAttachmentDeleted", data);
    });
    systemHub.on('TaskAttachmentCountChanged', function (data) {
        $scope.publish("TaskAttachmentCountChanged", data);
    });
    systemHub.on('TaskDateMoved', function (data) {
        $scope.publish("TaskDateMoved", data);
    });
    systemHub.on('TaskArchived', function (data) {
        $scope.publish("TaskArchived", data);
    });
    systemHub.on('TaskTitleChanged', function (data) {
        $scope.publish("TaskTitleChanged", data);
    });
    systemHub.on('TaskMovedFromArchive', function (data) {
        $scope.publish("TaskMovedFromArchive", data);
    });
    systemHub.on('SubTaskCountChanged', function (data) {
        $scope.publish("SubTaskCountChanged", data);
    });
    systemHub.on('NewSubTask', function (data) {
        $scope.publish("NewSubTask", data);
    });
    systemHub.on('MessageReceived', function (data) {
        $scope.publish("MessageReceived", data);
    });
    systemHub.on('ProjectMessageAdded', function (data) {
        $scope.publish("ProjectMessageAdded", data);
    });
    systemHub.on('ProjectMessageEdited', function (data) {
        $scope.publish("ProjectMessageEdited", data);
    });
    systemHub.on('ProjectMessageDeleted', function (data) {
        $scope.publish("ProjectMessageDeleted", data);
    });
    systemHub.on('MessageSent', function (data) {
        $scope.publish("MessageSent", data);
    });
    systemHub.on('MessageChanged', function (data) {
        $scope.publish("MessageChanged", data);
    });
    systemHub.on('MessageDeleted', function (data) {
        $scope.publish("MessageDeleted", data);
    });
    systemHub.on('MessageReplySent', function (data) {
        $scope.publish("MessageReplySent", data);
    });
    systemHub.on('LearningItemTitleChanged', function (data) {
        $scope.publish("LearningItemTitleChanged", data);
    });
    systemHub.on('LearningMaterialDeleted', function (data) {
        $scope.publish("LearningMaterialDeleted", data);
    });
    systemHub.on('LearningItemDeleted', function (data) {
        $scope.publish("LearningItemDeleted", data);
    });
    systemHub.on('LearningItemSectionUpdated', function (data) {
        $scope.publish("LearningItemSectionUpdated", data);
    });
    
    systemHub.on('PageCommentAdded', function (data) {
        $scope.publish("PageCommentAdded", data);
    });
    systemHub.on('PageCommentDeleted', function (data) {
        $scope.publish("PageCommentDeleted", data);
    });
    systemHub.on('HeartbeatReceived', function (heartbeat) {
        $scope.publish("HeartbeatReceived", heartbeat);
    });
    systemHub.on('JobErrorOccurred', function (jobId) {
        $scope.publish("JobErrorOccurred", jobId);
    });
    systemHub.on('ConnectorStarted', function (connectorId) {
        $scope.publish("ConnectorStarted", connectorId);
    });
    systemHub.on('ConnectorStopped', function (connectorId) {
        $scope.publish("ConnectorStopped", connectorId);
    });
    systemHub.on('JobExecuted', function (job) {
        $scope.publish("JobExecuted", job);
    });
    systemHub.on('ProfileImageUpdated', function (data) {
        $scope.publish("ProfileImageUpdated", data);
    });
    
    systemHub.on('A3TableColumnAdded', function (data) {
        if (data.settings && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        $scope.publish("A3TableColumnAdded", data);
    });
    systemHub.on('A3TableColumnDeleted', function (data) {
        $scope.publish("A3TableColumnDeleted", data);
    });
    systemHub.on('A3TableColumnUpdated', function (data) {
        if (data.value && typeof data.value === "string") {
            data.value = JSON.parse(data.value);
        }
        $scope.publish("A3TableColumnUpdated", data);
    });
    systemHub.on('A3TableCellUpdated', function (data) {
        if (data.settings  && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        if (data.value && typeof data.value === "string") {
            data.value = JSON.parse(data.value);
        }
        $scope.publish("A3TableCellUpdated", data);
    });
    systemHub.on('A3TableSortOrderChanged', function (data) {
        $scope.publish("A3TableSortOrderChanged", data);
    });
    systemHub.on('A3TableRowSortOrderChanged', function (data) {
        $scope.publish("A3TableRowSortOrderChanged", data);
    });
    systemHub.on('A3TableRowDeleted', function (data) {
        $scope.publish("A3TableRowDeleted", data);
    });
    systemHub.on('A3AddedToTable', function (data) {
        $scope.publish("A3AddedToTable", data);
    });
    systemHub.on('SmartActionListTaskCreated', function (data) {
        $scope.publish("SmartActionListTaskCreated", data);
    });
    systemHub.on('SmartActionListColumnAdded', function (data) {
        if (data.settings && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        $scope.publish("SmartActionListColumnAdded", data);
    });
    systemHub.on('SmartActionListColumnDeleted', function (data) {
        $scope.publish("SmartActionListColumnDeleted", data);
    });
    systemHub.on('SmartActionListSortOrderChanged', function (data) {
        $scope.publish("SmartActionListSortOrderChanged", data);
    });
    systemHub.on('SmartActionListRowSortOrderChanged', function (data) {
        $scope.publish("SmartActionListRowSortOrderChanged", data);
    });
    systemHub.on('SmartActionListColumnDeleted', function (data) {
        $scope.publish("SmartActionListColumnDeleted", data);
    });
    systemHub.on('SmartActionListCellUpdated', function (data) {
        if (data.settings  && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        if (data.value  && typeof data.value === "string") {
            try {
                data.value = JSON.parse(data.value);
            } catch(e) {
                // Not JSON, so not let event crash
            }
            
        }
        $scope.publish("SmartActionListCellUpdated", data);
    });
    systemHub.on('SmartActionListColumnUpdated', function (data) {
        if (data.value  && typeof data.value === "string") {
            data.value = JSON.parse(data.value);
        }
        $scope.publish("SmartActionListColumnUpdated", data);
    });
    systemHub.on('SmartActionListNumberCellUpdated', function (data) {
        $scope.publish("SmartActionListNumberCellUpdated", data);
    });
    systemHub.on('SmartIconCellSelectorUpdated', function (attribute) {
        $scope.publish("SmartIconCellSelectorUpdated", attribute);
    });
    systemHub.on('SmartClonedCellChanged', function (attribute) {
        $scope.publish("SmartClonedCellChanged", attribute);
    });
    systemHub.on('SmartTableRowCreated', function (data) {
        $scope.publish("SmartTableRowCreated", data);
    });
    systemHub.on('SmartTableRowDeleted', function (data) {
        $scope.publish("SmartTableRowDeleted", data);
    });
    systemHub.on('SmartTableColumnAdded', function (data) {
        if (data.settings && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        $scope.publish("SmartTableColumnAdded", data);
    });
    systemHub.on('SmartTableColumnDeleted', function (data) {
        $scope.publish("SmartTableColumnDeleted", data);
    });
    systemHub.on('SmartTableSortOrderChanged', function (data) {
        $scope.publish("SmartTableSortOrderChanged", data);
    });
    systemHub.on('SmartTableRowSortOrderChanged', function (data) {
        $scope.publish("SmartTableRowSortOrderChanged", data);
    });
    systemHub.on('SmartTableRowNameUpdated', function (data) {
        $scope.publish("SmartTableRowNameUpdated", data);
    });
    systemHub.on('SmartTableRowStartTimeUpdated', function (data) {
        $scope.publish("SmartTableRowStartTimeUpdated", data);
    });
    systemHub.on('SmartTableRowEndTimeUpdated', function (data) {
        $scope.publish("SmartTableRowEndTimeUpdated", data);
    });
    systemHub.on('SmartTableColumnDeleted', function (data) {
        $scope.publish("SmartTableColumnDeleted", data);
    });
    systemHub.on('SmartTableRowOwnerUpdated', function (data) {
        $scope.publish("SmartTableRowOwnerUpdated", data);
    });
    systemHub.on('SmartTableCellUpdated', function (data) {
        if (data.settings  && typeof data.settings === "string") {
            data.settings = JSON.parse(data.settings);
        }
        if (data.value && typeof data.value === "string") {
            data.value = JSON.parse(data.value);
        }
        $scope.publish("SmartTableCellUpdated", data);
    });
    systemHub.on('SmartTableColumnUpdated', function (data) {
        if (data.value && typeof data.value === "string") {
            data.value = JSON.parse(data.value);
        }
        $scope.publish("SmartTableColumnUpdated", data);
    });
    systemHub.on('ProjectAddedToBoard', function (data) {
        $scope.publish("ProjectAddedToBoard", data);
    });
    systemHub.on('ProjectRemovedFromBoard', function (data) {
        $scope.publish("ProjectRemovedFromBoard", data);
    });
    systemHub.on('ProjectDeleted', function (project) {
        $scope.publish("ProjectDeleted", project);
    });
    systemHub.on('ProjectBoardAttributeAdded', function (data) {
        $scope.publish("ProjectBoardAttributeAdded", data);
    });
    systemHub.on('ProjectNumberUpdated', function (data) {
        $scope.publish("ProjectNumberUpdated", data);
    });
    systemHub.on('ProjectStatusUpdated', function (data) {
        $scope.publish("ProjectStatusUpdated", data);
    });
    systemHub.on('ProjectNameUpdated', function (data) {
        $scope.publish("ProjectNameUpdated", data);
    });
    systemHub.on('ProjectDescriptionUpdated', function (data) {
        $scope.publish("ProjectDescriptionUpdated", data);
    });
    systemHub.on('ProjectMilestoneDeleted', function (date) {
        $scope.publish("ProjectMilestoneDeleted", date);
    });
    systemHub.on('ProjectMilestoneCreated', function (date) {
        $scope.publish("ProjectMilestoneCreated", date);
    });
    systemHub.on('ProjectMilestoneUpdated', function (date) {
        $scope.publish("ProjectMilestoneUpdated", date);
    });
    systemHub.on('ProjectEstimatedEndDateUpdated', function (date) {
        $scope.publish("ProjectEstimatedEndDateUpdated", date);
    });
    systemHub.on('ProjectEndDateUpdated', function (date) {
        $scope.publish("ProjectEndDateUpdated", date);
    });
    systemHub.on('ProjectCustomerCreated', function (date) {
        $scope.publish("ProjectCustomerCreated", date);
    });
    systemHub.on('ProjectCustomerUpdated', function (date) {
        $scope.publish("ProjectCustomerUpdated", date);
    });
    systemHub.on('PersonMembershipChanged', function (data) {
        $scope.publish("PersonMembershipChanged", data);
    });
    systemHub.on('ProjectStartDateUpdated', function (date) {
        $scope.publish("ProjectStartDateUpdated", date);
    });
    systemHub.on('ProjectOwnerUpdated', function (project) {
        $scope.publish("ProjectOwnerUpdated", project);
    });
    systemHub.on('ProjectParticipantAdded', function (project) {
        $scope.publish("ProjectParticipantAdded", project);
    });
    systemHub.on('ProjectParticipantRemoved', function (project) {
        $scope.publish("ProjectParticipantRemoved", project);
    });
    systemHub.on('ProjectEstimatedStartDateUpdated', function (date) {
        $scope.publish("ProjectEstimatedStartDateUpdated", date);
    });
    systemHub.on('ProjectBoardSortOrderChanged', function (attribute) {
        $scope.publish("ProjectBoardSortOrderChanged", attribute);
    });
    systemHub.on('ProjectBoardAttributeUpdated', function (attribute) {
        $scope.publish("ProjectBoardAttributeUpdated", attribute);
    });
    systemHub.on('ProjectBoardAttributeDeleted', function (attribute) {
        $scope.publish("ProjectBoardAttributeDeleted", attribute);
    });
    systemHub.on('ProjectAttributeUpdated', function (attribute) {
        if (attribute.value && typeof attribute.value === "string") {
            attribute.value = JSON.parse(attribute.value);
        }
        $scope.publish("ProjectAttributeUpdated", attribute);
    });
    systemHub.on('StrategyGoalNameUpdated', function (goal) {
        $scope.publish("StrategyGoalNameUpdated", goal);
    });
    systemHub.on('StrategyGoalColorUpdated', function (goal) {
        $scope.publish("StrategyGoalColorUpdated", goal);
    });
    systemHub.on('StrategyGoalAssetUpdated', function (goal) {
        $scope.publish("StrategyGoalAssetUpdated", goal);
    });
    systemHub.on('StrategyGoalDescriptionUpdated', function (goal) {
        $scope.publish("StrategyGoalDescriptionUpdated", goal);
    });
    systemHub.on('StrategyGoalStartDateUpdated', function (goal) {
        $scope.publish("StrategyGoalStartDateUpdated", goal);
    });
    systemHub.on('StrategyGoalEndDateUpdated', function (goal) {
        $scope.publish("StrategyGoalEndDateUpdated", goal);
    });
    systemHub.on('StrategyGoalCreated', function (goal) {
        $scope.publish("StrategyGoalCreated", goal);
    });
    systemHub.on('StrategyGoalDeleted', function (goal) {
        $scope.publish("StrategyGoalDeleted", goal);
    });
    systemHub.on('KPIAdded', function (kpi) {
        $scope.publish("KPIAdded", kpi);
    });
    systemHub.on('KPIUpdated', function (kpi) {
        $scope.publish("KPIUpdated", kpi);
    });
    systemHub.on('KPIDeleted', function (kpi) {
        $scope.publish("KPIDeleted", kpi);
    });
    systemHub.on('KPIMonthlyValueUpdated', function (value) {
        $scope.publish("KPIMonthlyValueUpdated", value);
    });
    
    systemHub.on('NewCompetenceBall', function (competence) {
        $scope.publish("NewCompetenceBall", competence);
    });
    systemHub.on('NewLogMessage', function (log) {
        $scope.publish("NewLogMessage", log);
    });

    systemHub.on("UserLogOut", () => {
        console.log("UserLogOut")
        $scope.publish("UserLogOut", {})
    })
}

export function subscribeProxyEvents($scope: DigiLeanNgScope) {
    // System Hub
    $scope.subscribe("GetOnlineUsersStatus", function () { systemHubService.sendProxyEvent("GetOnlineUsersStatus");});
    $scope.subscribe("GetAllUsersOnline", function () { systemHubService.sendProxyEvent("GetAllUsersOnline");});
    $scope.subscribe("SendDashboardCreated", function (dashboard) { systemHubService.sendProxyEvent("SendDashboardCreated", dashboard); });
    $scope.subscribe("SendDashboardUpdated", function (dashboard) { systemHubService.sendProxyEvent("SendDashboardUpdated", dashboard); });
    $scope.subscribe("SendDataSourceCreated", function (data) { systemHubService.sendProxyEvent("SendDataSourceCreated", data); });
    $scope.subscribe("SendDataSourceUpdated", function (data) { systemHubService.sendProxyEvent("SendDataSourceUpdated", data); });
    $scope.subscribe("SendDataSourceDeleted", function (data) { systemHubService.sendProxyEvent("SendDataSourceDeleted",data); });

    $scope.subscribe("SendConnectorCreated", function (connector) { systemHubService.sendProxyEvent("SendConnectorCreated",connector); });
    $scope.subscribe("SendConnectorUpdated", function (connector) { systemHubService.sendProxyEvent("SendConnectorUpdated",connector); });
    $scope.subscribe("SendConnectorDeleted", function (connector) { systemHubService.sendProxyEvent("SendConnectorDeleted",connector); });
    $scope.subscribe("SendJobCreated", function (job) { systemHubService.sendProxyEvent("SendJobCreated",job); });
    $scope.subscribe("SendJobUpdated", function (job) { systemHubService.sendProxyEvent("SendJobUpdated",job); });
    $scope.subscribe("SendJobDeleted", function (job) { systemHubService.sendProxyEvent("SendJobDeleted",job); });
    $scope.subscribe("SendExecuteJob", function (job) { systemHubService.sendProxyEvent("SendExecuteJob",job); });
    $scope.subscribe("SendStartConnector", function (connector) { systemHubService.sendProxyEvent("SendStartConnector",connector); });
    $scope.subscribe("SendStopConnector", function (connector) {
        systemHubService.sendProxyEvent("SendStopConnector",connector);
    });
    
    // ImprovementHub hub 
    $scope.subscribe("SendImprovementUpdated", function (improvement) { systemHubService.sendProxyEvent("SendImprovementUpdated",improvement); });
    $scope.subscribe("SendSuggestionAttachmentAdded", function (id) { systemHubService.sendProxyEvent("SendSuggestionAttachmentAdded",id); });
    $scope.subscribe("SendSuggestionAttachmentDeleted", function (data) { systemHubService.sendProxyEvent("SendSuggestionAttachmentDeleted",data); });
    $scope.subscribe("SendImprovementChangedCategory", function (category) { systemHubService.sendProxyEvent("SendImprovementChangedCategory",category); });
    
    $scope.subscribe("SendA3TemplateCreated", function (data) { systemHubService.sendProxyEvent("SendA3TemplateCreated",data); });
    $scope.subscribe("SendA3TemplateUpdated", function (data) { systemHubService.sendProxyEvent("SendA3TemplateUpdated",data); });
    $scope.subscribe("SendA3TemplateDeleted", function (data) { systemHubService.sendProxyEvent("SendA3TemplateDeleted",data); });
    $scope.subscribe("SendA3Created", function (data) { systemHubService.sendProxyEvent("SendA3Created",data); });
    $scope.subscribe("SendA3Updated", function (data) { systemHubService.sendProxyEvent("SendA3Updated",data); });
    $scope.subscribe("SendA3Deleted", function (data) { systemHubService.sendProxyEvent("SendA3Deleted",data); });
    $scope.subscribe("SendA3sDeleted", function (data) { systemHubService.sendProxyEvent("SendA3sDeleted",data); });
    $scope.subscribe("SendA3VersionCreated", function (data) { systemHubService.sendProxyEvent("SendA3VersionCreated",data); });
    $scope.subscribe("SendA3StepContentChanged", function (data) { systemHubService.sendProxyEvent("SendA3StepContentChanged",data); });
    
    $scope.subscribe("SendDeviationSeverityChanged", function (data) { systemHubService.sendProxyEvent("SendDeviationSeverityChanged",data); });
    $scope.subscribe("SendDeviationChangedCategory", function (data) { systemHubService.sendProxyEvent("SendDeviationChangedCategory",data); });
    
    $scope.subscribe("SendDeviationAttachmentAdded", function (data) { systemHubService.sendProxyEvent("SendDeviationAttachmentAdded",data); });
    $scope.subscribe("SendDeviationAttachmentDeleted", function (data) { systemHubService.sendProxyEvent("SendDeviationAttachmentDeleted",data); });
    // Social hub subs
    // Subscribe to BoardHubEvents to republish them to signalr
    
    $scope.subscribe("SendActionItemMovedToTask", function (data) { systemHubService.sendProxyEvent("SendActionItemMovedToTask",data); });
    $scope.subscribe("SendTaskMoved", function (data) { systemHubService.sendProxyEvent("SendTaskMoved", data); });
    $scope.subscribe("SendTaskDurationUpdated", function (data) { systemHubService.sendProxyEvent("SendTaskDurationUpdated",data); });
    $scope.subscribe("SendTaskDeviationCreated", function (data) { systemHubService.sendProxyEvent("SendTaskDeviationCreated",data); });
    $scope.subscribe("SendTaskMovedToActionList", function (data) { systemHubService.sendProxyEvent("SendTaskMovedToActionList",data); });
    
    $scope.subscribe("SendCreateActionListItem", function (data) { systemHubService.sendProxyEvent("SendCreateActionListItem",data); });
    $scope.subscribe("SendDeleteActionListItem", function (data) { systemHubService.sendProxyEvent("SendDeleteActionListItem",data); });
    $scope.subscribe("SendUpdateActionListItem", function (data) { systemHubService.sendProxyEvent("SendUpdateActionListItem",data); });

    $scope.subscribe("SendBoardDeleted", function (data) { systemHubService.sendProxyEvent("SendBoardDeleted",data); });
    $scope.subscribe("SendBoardCreated", function (data) { systemHubService.sendProxyEvent("SendBoardCreated",data); });
    $scope.subscribe("SendTasksDeleted", function (data) { systemHubService.sendProxyEvent("SendTasksDeleted",data); });
    $scope.subscribe("SendBoardCategoryAssigned", function (data) { systemHubService.sendProxyEvent("SendBoardCategoryAssigned",data); });
    $scope.subscribe("SendTaskDateUpdated", function (data) { systemHubService.sendProxyEvent("SendTaskDateUpdated",data); });
    $scope.subscribe("SendTaskDescriptionUpdated", function (data) { systemHubService.sendProxyEvent("SendTaskDescriptionUpdated",data); });
    $scope.subscribe("SendTaskChangedBoard", function (data) { systemHubService.sendProxyEvent("SendTaskChangedBoard",data); });
    $scope.subscribe("SendTaskRepeated", function (data) { systemHubService.sendProxyEvent("SendTaskRepeated",data); });
    $scope.subscribe("SendTaskAttachmentAdded", function (data) { systemHubService.sendProxyEvent("SendTaskAttachmentAdded",data); });
    $scope.subscribe("SendTaskAttachmentDeleted", function (data) { systemHubService.sendProxyEvent("SendTaskAttachmentDeleted",data); });
}