
angular
    .module('DigiLean')
    .directive("teamKanban", ['$uibModal', '$filter', '$translate', 'statusColumnProvider', 'responsibleColumnProvider', 'deviationService', 'boardTaskService', 'suggestionService',
        function ($uibModal, $filter, $translate, statusColumnProvider, responsibleColumnProvider, deviationService, boardTaskService, suggestionService) {
            return {
                templateUrl: 'teamKanban.html',
                restrict: 'E',
                scope: {
                    'users': '<?',
                },
                link: function (scope, elem, attrs) {
                    scope.columns = [];
                    scope.columnGroupList = [
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            property: "responsible",
                            columnProvider: responsibleColumnProvider,
                            groupModel: null
                        },  /*  {
                            title: $translate.instant("COMMON_STATUS"), 
                            property: "status",
                            columnProvider: statusColumnProvider,
                            groupModel: null
                        },*/
                    ];
                    scope.selectedGrouping = scope.columnGroupList[0];

                    scope.groupingChanged = function () {
                        loadModel(scope.model, scope.selectedGrouping);
                    }

                    scope.$watch('users', function (users) {
                        if (!users) return;
                        loadTasks(users)
                    });

                    scope.toggleUser = function (column) {
                        column.hide = !column.hide;
                    }



                    function addTasks(user, alltasks, numberOfUsers) {
                        boardTaskService().getUserTasks(user.userId).then(function (data) {

                            for (var o = 0; o < data.overdue.length; o++) {
                                data.overdue[o].responsibleUserId = user.userId;
                                data.overdue[o].responsibleUser = user.fullName;
                                alltasks.push(data.overdue[o]);
                            }
                            for (var t = 0; t < data.today.length; t++) {
                                data.today[t].responsibleUserId = user.userId;
                                data.today[t].responsibleUser = user.fullName;
                                alltasks.push(data.today[t]);
                            }
                            for (var f = 0; f < data.future.length; f++) {
                                data.future[f].responsibleUserId = user.userId;
                                data.future[f].responsibleUser = user.fullName;
                                alltasks.push(data.future[f]);
                            }
                            loadedUsers += 1;
                            // When all users are loaded we can create model
                            if (loadedUsers == numberOfUsers) {
                                scope.model = { tasks: alltasks };
                                var options = {
                                    showNotAssignedColum: false
                                }
                                loadModel(scope.model, scope.selectedGrouping, options);
                            }

                        });


                    }

                    var loadedUsers = 0;
                    function loadTasks(users) {
                        loadedUsers = 0;
                        if (users) {
                            var alltasks = [];
                            for (var i = 0; i < users.length; i++) {
                                addTasks(users[i], alltasks, users.length);
                            }
                        }

                    }
                    function isMemberOfAsset(userId) {
                        if (!scope.users) return false;
                        for (var u = 0; u < scope.users.length; u++) {
                            if (scope.users[u].userId == userId) {
                                return true;
                            }
                        }
                        return false;
                    }
                    scope.subscribe('NewTask', function (task) {
                        if (!task) return;
                        if (!scope.model) return;
                        if (isMemberOfAsset(task.responsibleUserId)) {
                            initTask(task);
                            var hasColumnWithTask = false;
                            scope.columns.forEach(function (column) {
                                if (column.addTask(task)) {
                                    hasColumnWithTask = true;
                                };
                            });
                            if (!hasColumnWithTask) {
                                // Reload model to create new column
                                loadModel(scope.model, scope.selectedGrouping);
                            }
                        }
                    });

                    scope.subscribe("TaskDeleted", function (taskId) {
                        if (isTaskPartOfTaskList(taskId)) {

                            var task = getTask(taskId)
                            // animate removal
                            task.animate("fadeOut", 2000);
                            // use time so animation shows before removing task from list.
                            setTimeout(function () {
                                scope.columns.forEach(function (column) {
                                    column.removeTask(task);
                                });
                            }, 1500);

                        }
                    });

                    var getTask = function (taskId) {
                        var task = $filter('filter')(scope.model.tasks, { id: taskId });
                        if (task.length === 1) return task[0];
                        return null;
                    }

                    function isTaskPartOfTaskList(taskId) {
                        return $filter('filter')(scope.model.tasks, { id: taskId }).length > 0;
                    }

                    function updateSuggestion(task, column) {
                        if (column.property == "responsible") {
                            var responsible = column.user;

                            if (responsible) {
                                var improvementResponsible =
                                {
                                    suggestionId: task.taskTypeId,
                                    newResponsible: responsible.fullName,
                                    newResponsibleUserId: responsible.userId

                                }
                                suggestionService().updateResponsible(improvementResponsible);
                            }

                        }
                    }

                    function updateDeviation(task, column) {
                        if (column.property == "responsible") {
                            var responsible = column.user;

                            if (responsible) {
                                var deviationResponsible =
                                {
                                    deviationId: task.taskTypeId,
                                    newResponsible: responsible.fullName,
                                    newResponsibleUserId: responsible.userId

                                }
                                deviationService().updateResponsible(deviationResponsible);
                            }

                        }
                    }

                    scope.columnOptions = {
                        connectWith: ".task-list",
                        receive: function (e, ui) {
                            var target = ui.item.sortable.droptarget[0];
                            var columnIndex = $(target).attr("data-column-index");
                            var task = ui.item.sortable.model;
                            // Find column
                            var column = scope.columns[columnIndex];

                            task.animate("bounce");
                            var changeSettings = {
                                dropColumn: column,
                                taskId: task.id,
                                task: task,
                                property: column.property,
                                propertyValue: column.propertyValue
                            };

                            if (task.entityType == "Improvement") {
                                updateSuggestion(task, column);

                            } else if (task.entityType == "Deviation") {
                                updateDeviation(task, column);
                            } else {
                                column.saveChanges(changeSettings)
                            }
                        }
                    };

                    var initTask = function (task) {
                        task.model = {
                            task: task,
                            boardTaskId: task.id
                        }
                        task.animate = function (animation, timeOut) {
                            if (!timeOut) {
                                timeOut = 1000;
                            }
                            task.animateClass = "animated " + animation;
                            setTimeout(function () {
                                task.animateClass = "";
                            }, timeOut);
                        }
                        task.animate("slideInDown", 3000);
                    };

                    var loadModel = function (model, columnGrouping, options) {
                        scope.columns = [];
                        model.tasks.forEach(function (task) {
                            initTask(task);
                        });
                        if (scope.selectedGrouping.property == "responsible") {
                            scope.columns = columnGrouping.columnProvider().getColumns(model.tasks, scope.users, options);
                        } else {
                            scope.columns = columnGrouping.columnProvider().getColumns(model.tasks, scope.users, columnGrouping.groupModel);    
                        }
                        
                    }

                    scope.createTask = function (column) {
                        var status = "blank";
                        var options = {
                            canChangeBoard: true,
                            showDueDate: true,
                        }
                        // Map column 
                        if (column.property == "status") {
                            status = column.propertyValue;
                        }
                        if (column.property == "responsible") {
                            options.selectedUser = column.user;
                        }
                        var task = {
                            id: 0,
                            boardId: 0,
                            title: "",
                            text: "",
                            boardDate: moment().startOf('day'),
                            status: status,
                            tags: "",
                            user: column.user,
                            sortOrder: scope.columns.length
                        }

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });
                    }
                }

            }
        }]);