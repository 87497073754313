
// This service lives on rootscope and doesn't need to be injected to controllers. just use:
//$scope.publish('eventName', data);

//$scope.subscribe('eventName', function (data) {
//    //do somthing
//});

angular.module("DigiLean").service('PubSubService', function () {
    return { Initialize: Initialize };

    function Initialize(scope) {

        //Keep a dictionary to store the events and its subscriptions
        var publishEventMap = {};
        // Register publish events
        scope.constructor.prototype.publish = scope.constructor.prototype.publish
            || function () {
                var _thisScope = this,
                    handlers,
                    args,
                    evnt;
                //Get event and rest of the data
                args = [].slice.call(arguments);
                evnt = args.splice(0, 1);

                var handlers = publishEventMap[evnt] || [];
                //console.log("publish to handler: " + evnt + "to " + handlers.length + " handlers");
                //Loop though each handlerMap and invoke the handler
                handlers.forEach(function (handlerMap) {

                    //console.debug("publish: " + evnt + " to handler");
                    try {
                        handlerMap.handler.apply(_thisScope, args);
                    }
                    catch (err) {
                        (console.error || console.log).call(console, "Failed to publish event to handler: " + handlerMap.handler + (err.stack || err));
                    }
                });
            }

        //Register Subscribe events
        scope.constructor.prototype.subscribe = scope.constructor.prototype.subscribe
            || function (evnt, handler) {
                var _thisScope = this,
                    handlers = (publishEventMap[evnt] = publishEventMap[evnt] || []);
                
                //Just keep the scopeid for reference later for cleanup
                handlers.push({ $id: _thisScope.$id, handler: handler });
                //When scope is destroy remove the handlers that it has subscribed.  
                _thisScope.$on('$destroy', function () {
                    for (var i = 0, l = handlers.length; i < l; i++) {
                        if (handlers[i].$id === _thisScope.$id) {
                            handlers.splice(i, 1);
                            // DEBUG
                            //console.log("destroy: " + handler[i]);
                            break;
                        }
                    }
                });
            }

    }
}).run(['$rootScope', 'PubSubService',function ($rootScope, PubSubService) {
    PubSubService.Initialize($rootScope);
}]);