import './../../../../../thirdparty/fullcalendar/fullcalendar.min';
import { getUserProfile } from "@common/stores/userStore"
import * as boardService from "@common/services/boardService"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("teamcalendar", ['$filter','boardTaskService', '$translate', '$uibModal',
        function ($filter, boardTaskService, $translate, $uibModal) {
            return {
                templateUrl: 'teamcalendar.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.$watch('settings', function (settings) {
                        //scope.settings = settings;
                        checkSettings();
                    });

                    scope.isPersonalCalendar = scope.calendarType === "personal"
                    scope.lang = "en"
                    const up = getUserProfile()
                    if (up.preferredLanguage)
                        scope.lang = up.preferredLanguage
                    
                    setLanguage(scope.lang)

                    scope.boardChanged = function () {
                        getBoardMemebers(scope.selectedBoard.id);
                        refreshCalendar();
                        updateSettings()
                    }

                    scope.subscribe('TaskDeleted', function (id) {
                        refreshCalendar();
                    });

                    scope.subscribe("NewTask", function (data) {
                        if(data.boardId == scope.selectedBoard.id){
                            refreshCalendar();
                        }
                    });

                    function getBoardMemebers(boardId){
                        boardService.getUserListForBoard(boardId).then(function (data) {
                            angular.forEach(data, function (employee, key) {
                                employee.fullName = employee.firstName + " " + employee.lastName;
                            });
                            scope.users = data;
                        });
                    }

                   function checkSettings() {         
                        boardService.getAll().then(function (boards) {
                            scope.boards = boards;
                            if (scope.settings && scope.settings.boardId) {
                                scope.selectedBoard = $filter('filter')(boards, { id: scope.settings.boardId }, true)[0];
                            } else {
                                scope.selectedBoard = boards[0];
                            }
                            getBoardMemebers(scope.selectedBoard.id);
                            setCalendar();
                        });                                
                    }

                    function setLanguage(language) {
                        if (language === "no")
                            language = "nb"
                        scope.lang = language
                        $(elem).find('.calendar').fullCalendar('option', 'locale', language)
                    }

                    scope.$on('$translateChangeSuccess', function (val) {
                        setLanguage($translate.use())
                    })

                    function updateSettings () {
                        if(scope.isAdminMode){
                            var componentSettings = {
                                boardId: scope.selectedBoard.id
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                            }
                    }

                    function setCalendar() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;

                        var height = host.height() - 100;
                        $(elem).find('.calendar').fullCalendar({
                            events: getTasks,
                            header: {
                                left: 'prev,next today',
                                center: 'title',
                                right: ''
                                //right: 'month,agendaWeek,agendaDay'
                            },
                            height: height,
                            weekNumbers: true,
                            eventLimit: true,
                            editable: true,
                            eventDurationEditable: false,
                            firstDay: 1, // Setting monday as first day of week
                            locale: scope.lang,
                            eventClick: function (calEvent, jsEvent, view) {
                                boardTaskService().get(calEvent.id).then(function (task) {
                                    var modalInstance = $uibModal.open({ backdrop: 'static',
                                        animation: true,
                                        templateUrl: 'boardTask.html',
                                        controller: 'boardTaskController',
                                        resolve: {
                                            task: function () {
                                                return task;
                                            },
                                            activeTab: function () {
                                                return "Info";
                                            },
                                            options: function () {
                                                var options = {
                                                    archiveAvailable: false
                                                }
                                                return options;
                                            }
                                        }
                                    });
                                    modalInstance.result.then(function (result) {
                                        refreshCalendar();
                                    });
                                });
                            },dayClick: function (date, jsEvent, view) {
                                var task = {
                                    id: 0,
                                    boardId: scope.selectedBoard.id,
                                    title: "",
                                    text: "",
                                    boardDate: date,
                                    status: 'blank',
                                    tags: ""
                                }
                                
                                var modalInstance = $uibModal.open({ backdrop: 'static',
                                    animation: true,
                                    templateUrl: 'newBoardTask.html',
                                    controller: 'newBoardTaskController',
                                    resolve: {
                                        task: function () {
                                            return task;
                                        },
                                        options: function () {
                                            var options = {
                                                canChangeBoard: true,
                                                showDueDate: true,
                                                users: scope.users
                                            }
                                            return options;
                                        }
                                    }
                                });
                                modalInstance.result.then(function (result) {
                                    refreshCalendar();
                                });                         
                            },
                            eventDrop: function (event, delta, revertFunc) {
                                var start = event.start;
                                boardTaskService().updateDate(event.id, start).then(function (taskMoved) {
                                    
                                });
                            }
                        });
                    }

                    scope.$on("widget-resized", function (event, args) {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var height = host.height() -100;
                        $(elem).find('.calendar').fullCalendar('option', 'height', height);
                    });

                    function refreshCalendar() {
                        $(elem).find('.calendar').fullCalendar('refetchEvents');
                    }


                    function getTasks(start, end, timezone, callback) {
                        var timeperiod = {
                            from: start,
                            to: end
                        };
                        boardService.tasksInPeriod(scope.selectedBoard.id, timeperiod).then(function (tasks) {
                            var events = [];
                            var tasks = tasks;

                            angular.forEach(tasks, function (task, key) {
                                events.push({
                                    id: task.id,
                                    start: moment(task.boardDate).utc().toDate(),
                                    title: task.title,
                                    className: task.status,
                                    allDay: true
                                });
                            });
                            var actionTaskQuery = {
                                assetId: scope.assetid,
                                statuses: ['blank', 'unacceptable'],
                                timeperiod: timeperiod
                            }
                            boardService.getActionTasks(scope.selectedBoard.id).then(function (actiontasks) {
                                //tasks.push(actiontasks);
                                angular.forEach(actiontasks, function (task, key) {
                                    if(task.boardDate){ // && moment('2016-10-30').isBetween('2016-10-30', '2016-12-30', null, '()');)
                                        events.push({
                                            id: task.id,
                                            start: moment(task.boardDate).utc().toDate(),
                                            title: task.title,
                                            className: task.status,
                                            allDay: true
                                        });
                                    }
                                });
                                return callback(events);

                            });
                            
                            
                        });
                        
                    }
                }
            }
        }]);
