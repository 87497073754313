angular
    .module("DigiLean")
    .directive('a3tableApp', ['$translate', 'a3TableService', 'debounce',
        function ($translate, a3TableService, debounce) {
            return {
                templateUrl: 'a3tableApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'assetId': '<',
                    'settings': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.boardData = null;
                    scope.hasBoard = false;
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            loadBoard(settings.boardId);
                        } else if (scope.isAdminMode) {
                            createNewA3TableDebounced();
                        }
                    });
                    scope.boards = [];
                    scope.boardChanged = function (selectedBoard) {
                        loadBoard(selectedBoard.id);
                        updateSettings();
                    }

                    var currentBoardId = 0;
                    var loadBoard = function (boardId) {
                        if (currentBoardId == boardId)
                            return;
                        currentBoardId = boardId;
                        if (boardId == 0) {
                            scope.hasBoard = false;
                            return;    
                        }
                        scope.isLoading = true;
                        scope.hasBoard = true;
                        a3TableService().get(boardId).then(function (board) {
                            scope.boardData = board;
                        })
                    }

                    function createNewA3Table(){
                        var template = {
                            board: {
                                boardType: "A3",
                                name: $translate.instant("A3_TABLE"),
                                description: "",
                                isPublic: true,
                                assetId: scope.assetId,
                                settings: JSON.stringify({
                                    IsWeeklyBoard: false
                                })
                            },
                            columns: a3TableService().getStandardA3Columns(),
                            a3s: [],
                            cells: []
                        
                        };
                        if (scope.hasBoard) {
                            console.log("A3 board detected, don't create new")
                            return
                        }
                        console.log("%c New A3 board will now be created!", "background: #F00; color: #FFF");
                        a3TableService().createA3Table(template).then(function (savedModel) {
                            scope.selectedBoard = savedModel.board;
                            scope.boardData = savedModel;
                            updateSettings();    
                        });
                    
                    }
                    const createNewA3TableDebounced = debounce(createNewA3Table, 500, false)


                    function updateSettings() {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                boardId: scope.selectedBoard.id,
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                }
            }
        }]);

