import * as pdfService from "@common/services/pdfService"

angular
    .module('DigiLean')
    .directive("taskListResult", ['$filter', '$translate', 'boardTaskService', 'debounce', '$uibModal', 'NgTableParams', 'excelService', 'isTeamsMode',
        function ($filter, $translate, boardTaskService, debounce, $uibModal, NgTableParams, excelService, isTeamsMode) {
            return {
                templateUrl: 'taskListResult.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                },
                link: function (scope, elem, attrs) {

                    scope.columns = [];
                    scope.registrations = [];
                    scope.newValuesCounter = 0;
                    scope.totalValues = 0;
                    scope.totalMatchingRecords = 0;
                    scope.allUsers = [];
                    scope.selectedDataList = [];
                    scope.pageCount = 25;
                    let previousFilterName = "";
                    var initialized = false;

                    scope.showFileGenerators = true;
                    if (isTeamsMode) {
                        scope.showFileGenerators = false;
                    }

                    boardTaskService().getTotalNumberOfBoardTasks().then(function (count) {
                        scope.totalValues = count;
                    });

                    // TimePeriods
                    // default timeperiod
                    var timeSettings = {
                        timeframe: "all",
                        useDefaultTimeframes: true,
                        timeframes: [],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.timePeriodOptions = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }
                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);
                    scope.periodChangedHandler = function (timePeriod) {
                        if (!timePeriod.timeframe) return;
                        scope.timePeriodOptions.timePeriod = timePeriod;
                        loadValues();
                    };

                    // Filter handling
                    scope.$watch('filter', function (filter) {
                        if (!filter) {
                            scope.filter = [];
                        }
                        if(initialized)
                        {
                            scope.tableParams.reload();
                        }
                        initialized = true;
                    });
                    
                    scope.openTask = function (taskId, mode) {
                        if (!mode) mode = "Info";
                        boardTaskService().get(taskId).then(function (task) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return mode;
                                    },
                                    options: function () {
                                        return {

                                        };
                                    }
                                }
                            });
                            modalInstance.result.then(function (updateTask) {
                                scope.tableParams.reload();
                            });
                        });
                    }

                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    var loadValues = function () {
                        scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: { lastModified: "desc" },
                            count: scope.pageCount,
                        }, {
                            total:0,
                            getData: scope.getTableValues
                        });
                    }

                    scope.sorting = "";
                    scope.getTableValues = function (params) {
                        if( !initialized ) return;
                        if (!scope.timePeriodOptions) return;
                        var timePeriod = null;
                        if (scope.timePeriodOptions.timePeriod && scope.timePeriodOptions.timePeriod.timeframe != "all") {
                            timePeriod = scope.timePeriodOptions.timePeriod.period;
                        }
                        
                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        if(previousFilterName != scope.filter)
                        {
                            params._params.page = 1;
                        }
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            timePeriod: timePeriod
                        }
                        return boardTaskService().getTableValues(dbParams).then(function (data) {
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // check values
                            data.values.forEach(value => {
                                value.isOverdue = isOverdue(value.dueDate, value.status);
                            });
                            previousFilterName = scope.filter;
                            return data.values;
                        });
                    }
                    scope.allColumns = [

                        {
                            title: $translate.instant("COMMON_TITLE"),
                            field: 'title',
                            visible: true,
                            sortable: 'title',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_STATUS"),
                            field: 'status',
                            visible: true,
                            sortable: 'status',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            field: 'responsibleUser',
                            sortable: 'responsibleUser',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_DUE_DATE"),
                            field: 'dueDate',
                            visible: true,
                            sortable: 'dueDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_BOARD"),
                            field: 'boardName',
                            sortable: 'boardName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_ASSET"),
                            field: 'assetName',
                            sortable: 'assetName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        // {
                        //     title: $translate.instant("COMMON_BOARD"),
                        //     field: 'boardType',
                        //     sortable: 'boardType',
                        //     getValue: renderValue,
                        //     visible: true,
                        //     dataType: "text"
                        // },
                        {
                            title: $translate.instant("BOARD_ROW"),
                            field: 'row',
                            sortable: 'row',
                            getValue: renderValue,
                            visible: false,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("BOARD_COLUMN"),
                            field: 'col',
                            sortable: 'col',
                            getValue: renderValue,
                            visible: false,
                            dataType: "text"
                        },

                        {
                            title: $translate.instant("COMMON_LAST_CHANGED"),
                            field: 'lastModified',
                            visible: true,
                            sortable: 'lastModified',
                            getValue: renderValue,
                            dataType: "date"
                        },
                    ];
                    scope.buildColumns = function (datasource) {
                        scope.columns = $filter('filter')(scope.allColumns, { visible: true });
                    }

                    var getColumns = function () {
                        var columns = [];
                        scope.columns.forEach((c) => {
                            columns.push({
                                field: c.field
                            });
                        })
                        return columns;
                    }

                    scope.selectColumn = function (column) {
                        column.visible = !column.visible;
                        scope.buildColumns();
                    }

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate");
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        }
                        return value;
                    }

                    function isOverdue(date, status) {
                        // Overdue check

                        if (!date) return false;
                        if (status == "OK") return false;
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            return true;
                        }
                        return false;
                    }
                    scope.exportToPDF = function () {
                        var columns = getColumns();
                        columns.push({ field: "id" })
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns
                        }
                        pdfService.createImprovementListReport(dbParams);
                    }

                    scope.exportToExcel = function () {
                        var columns = getColumns();
                        columns.push({ field: "id" })
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns
                        }
                        excelService().exportImprovementValues(dbParams);
                    }
                }

            }
        }]);
