import { saveArrayBufferAsPdf } from "@common/services/filesService"
import { userProfile } from "@common/stores/userStore"

import backend from "@common/services/backendHttp"
import { FileInfo, TableParams, TimePeriod } from "@api"

const resourceUrl  = "pdf"

let lang = "en"
userProfile.subscribe(up => {
    if (up.preferredLanguage)
        lang = up.preferredLanguage
})

export async function printA3 (fileName: string, a3VersionId: number, language: string, timezoneOffset: number) {
    const url = resourceUrl + "/printA3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset
    const data = await backend.post<ArrayBuffer>(url, {})
    saveArrayBufferAsPdf(data, fileName) 
}

export function saveA3(a3VersionId: number, language: string, timezoneOffset: number, format: string) {
    const url = resourceUrl + "/savea3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset + "&format=" + format
    return backend.get<FileInfo>(url)
}

export function previewA3(a3VersionId: number, language: string) {
    const url = resourceUrl + "/previewA3/" + a3VersionId + "?lang=" + language
    return backend.post<null>(url, {})
}

export async function printLearningPage(pageId: number, pageTitle: string, isExternal: boolean) {
    if (!pageTitle)
        pageTitle = "page"
    const fileName = pageTitle + ".pdf"
    
    const url = "learningpage/" + pageId+ "?lang=" + lang + "&isExternal=" + isExternal
    const data = await backend.post<ArrayBuffer>(url, {})
    saveArrayBufferAsPdf(data, fileName)
}

export async function printDeviationReport(deviationId: number) {
    const fileName = "deviation-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/deviationreport/" + deviationId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    const data = await backend.post<ArrayBuffer>(url, {})
    saveArrayBufferAsPdf(data, fileName)
}

                 
export async function printDeviationProjectReport(projectId: number, timePeriod: TimePeriod) {
    const fileName = "deviation-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/DeviationProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    const data = await backend.post<ArrayBuffer>(url, timePeriod)
    saveArrayBufferAsPdf(data, fileName)
}

export async function printProjectHoursReport(projectId: number, timePeriod: TimePeriod) {
    const fileName = "invoice-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/InvoiceProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    const data = await backend.post<ArrayBuffer>(url, timePeriod)
    saveArrayBufferAsPdf(data, fileName)
}

export async function createImprovementListReport(params: TableParams) {
    const fileName = "DigiLEAN - Improvements.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/ImprovementListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    const data = await backend.post<ArrayBuffer>(url, params)
    saveArrayBufferAsPdf(data, fileName)
}

export async function createDeviationListReport(params: TableParams) {
    const fileName = "DigiLEAN - Deviations.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/DeviationListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    const data = await backend.post<ArrayBuffer>(url, params)
    saveArrayBufferAsPdf(data, fileName)
}

export async function createSmartActionListReport(boardId: number) {
    var fileName = "DigiLEAN - Smart Actions.pdf";
    var date = new Date();
    var offset = date.getTimezoneOffset()
    let createSmartActionListReportUrl = resourceUrl + "/CreateSmartActionListReport/" + boardId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
    const data = await backend.post<ArrayBuffer>(createSmartActionListReportUrl, {})
    saveArrayBufferAsPdf(data, fileName)
}