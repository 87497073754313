import * as pdfService from "@common/services/pdfService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("deviationProjectStatusPdfButton", ['$filter', 'projectService','isTeamsMode',
    function ($filter, projectService,isTeamsMode) {
        return {
            templateUrl: 'deviationProjectStatusPdfButton.html',
            restrict: 'E',
            scope: {
                'projectId': '=',
                'timePeriod': '<'
            },
            link: function (scope, elem, attrs) {

                scope.showFileGenerators = true;
                if (isTeamsMode) {
                    scope.showFileGenerators = false;
                }
                scope.projects = [];
                var loadProjects = function () {
                    projectService().getProjectListWithDeviation().then(function (projects) {
                        scope.projects = projects;
                    });
                }
                loadProjects();

                scope.selectProject = function (project) {
                    pdfService.printDeviationProjectReport(project.id, scope.timePeriod);
                };
                scope.$watch("timePeriod", function (timePeriod) {
                    var s = timePeriod;
                });
            }

        }
    }]);
