import * as boardService from "@common/services/boardService"

angular
    .module("DigiLean")
    .directive('teamTasks', ['$filter', '$uibModal', '$state', 'boardTaskService', 'taskDrawingService',
        function ($filter, $uibModal, $state, boardTaskService, taskDrawingService) {
            return {
                templateUrl: 'teamtasks.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.boards = [];

                    scope.$watch('settings', function (settings) {
                        checkSettings();
                    });


                    setTimeout(function () {
                        $("#overdue, #donext1, #donext2, #donext3").sortable({
                            connectWith: ".connectList",
                            update: function (event, ui) {
                                var id = $(ui.item[0]).attr("data-id");
                                moveOverdueTaskToNextMeeting(parseInt(id));
                                var todo = $("#overdue").sortable("toArray");
                                var inprogress = $("#donext1").sortable("toArray");
                                var completed = $("#donext2").sortable("toArray");
                                $('.output').html("ToDo: " + JSON.stringify(todo) + "<br>" + "In Progress: " + JSON.stringify(inprogress) + "<br>" + "Completed: " + JSON.stringify(completed));
                            }
                        }).disableSelection();
                    }, 50);


                    var colorClasses = ["label-primary", "label-warning-light"];

                    scope.daysBetweenMeetings = 7;
                    scope.workList = {};
                    scope.nextweekworkList = {};

                    scope.emptyUser = {
                        id: '',
                        userName: null,
                        profileImageUrl: null,
                        fullName: null,
                        displayName: null
                    }

                    scope.changeStatus = function (task) {
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var statusOptions = {
                            taskId: task.id,
                            status: task.status
                        };
                        boardTaskService().updateStatus(task.id, statusOptions).then(function (taskIds) {

                        });
                    }

                    function moveOverdueTaskToNextMeeting(taskid) {
                        var newDate = moment().add(1, 'days').toDate();
                        boardTaskService().updateDate(taskid, newDate).then(function (taskIds) {

                        });
                    };


                    scope.openConfiguration = function () {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'teamTaskConfiguration.html',
                            controller: 'teamTaskConfiguration',
                            windowClass: 'small-modal-window',
                            resolve: {
                                daysBetweenMeetings: function () {
                                    return scope.daysBetweenMeetings;
                                }
                            }
                        });

                        modalInstance.result.then(function (days) {
                            scope.daysBetweenMeetings = days;
                            updateSettings();
                            getActionList();
                        });
                    };

                    scope.boardChanged = function (board) {
                        scope.selectedBoard = board;
                        getActionList();
                        updateSettings();
                    };

                    scope.subscribe('TaskUpdated', function (task) {
                        if (task.boardId == scope.selectedBoard.id) {
                            getActionList();
                        }
                    });

                    scope.subscribe('TaskStatusUpdated', function (status) {
                        if (isTaskInWorkList(status.taskId)) {
                            getActionList();
                        }
                    });
                    scope.subscribe('TaskMoved', function (taskMoved) {
                        if (isTaskInWorkList(taskMoved.taskId)) {
                            getActionList();
                        }
                    });
                    scope.subscribe("NewTask", function (data) {
                        if (data.boardId == scope.selectedBoard.id) {
                            getActionList();
                        }
                    });
                    scope.subscribe('TaskDeleted', function (id) {
                        if (isTaskInWorkList(id)) {
                            getActionList();
                        }
                    });
                    scope.subscribe('RepeatingTasksDeleted', function (taskIds) {
                        // If current user is involved in task.
                        angular.forEach(taskIds, function (id, key) {
                            if (isTaskInWorkList(id)) {
                                getActionList();
                            }
                        });
                    });

                    scope.subscribe('TaskAssigned', function (status) {

                        if (isTaskInWorkList(status.taskId)) {
                            getActionList();
                        }
                    });

                    scope.openForEdit = function (task, activeTab) {
                        if (!activeTab) activeTab = "Info";
                        boardTaskService().get(task.id).then(function (task) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    };

                    scope.newTask = function () {
                        var task = {
                            id: 0,
                            boardId: scope.selectedBoard.id,
                            title: "",
                            text: "",
                            boardDate: moment().add(1, 'days'),
                            status: 'blank',
                            tags: ""
                        }

                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    var options = {
                                        canChangeBoard: false,
                                        showDueDate: true,
                                        users: scope.users
                                    }
                                    return options;
                                }
                            }
                        });
                    };

                    function isTaskInWorkList(id) {
                        var workList = scope.workList;
                        if (workList.all) {
                            var task = $filter('filter')(workList.all, { id: id });
                            if (task.length > 0) {
                                return true;
                            }
                        }
                        var nextWeekworkList = scope.nextweekworkList;
                        if (nextWeekworkList.all) {
                            var task = $filter('filter')(nextWeekworkList.all, { id: id });
                            if (task.length > 0) {
                                return true;
                            }
                        }
                        return false;
                    };



                    scope.selectUser = function (user, task) {

                        var assignUser = {
                            taskId: task.id,
                            previousUserId: task.responsibleUserId,
                            previousUserName: task.responsibleUser,
                            userId: user.id,
                            userName: user.fullName,
                            userDisplayName: user.displayName
                        }
                        var options = {
                            taskId: task.id,
                            user: assignUser
                        };
                        boardTaskService().assignUser(options).then(function () {

                        });
                    }

                    function setProfileImage(tasklist) {
                        if (!scope.users || !tasklist) return;
                        for (var t = 0; t < tasklist.length; t++) {
                            var task = tasklist[t];
                            if (task && task.responsibleUserId) {
                                for (var i = 0; i < scope.users.length; i++) {
                                    var user = scope.users[i];
                                    if (user.profileImageUrl && user.id == task.responsibleUserId) {
                                        task.responsibleImageUrl = user.profileImageUrl;
                                    }
                                }
                            }
                        }
                    }

                    function getDataForPeriod(startDate, endDate, periodsBackInTime) {
                        var period = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        }
                        boardTaskService().getBoardStatus(scope.selectedBoard.id, period).then(function (data) {
                            scope.workList = data;
                            setProfileImage(scope.workList.completed);
                            setProfileImage(scope.workList.open);
                            setProfileImage(scope.workList.rejected);
                            var updatedperiodsBackInTime = periodsBackInTime + 1;
                            if (data.all.length == 0 && periodsBackInTime < 10) {
                                var newEndDate = startDate.clone().subtract(1, 'days').endOf('day');
                                var newStartDate = startDate.clone().subtract(scope.daysBetweenMeetings, 'days').startOf('day');

                                getDataForPeriod(newStartDate, newEndDate, updatedperiodsBackInTime);
                            } else {
                                return;
                            }
                        });
                    }

                    // Get tasks last week
                    function getActionList() {
                        if (!scope.selectedBoard) return;
                        scope.workList = null;
                        scope.nextweekworkList = null;

                        var startDate = moment().subtract(scope.daysBetweenMeetings, 'days').startOf('day');
                        var endDate = moment().subtract(1, 'days').endOf('day');

                        getDataForPeriod(startDate, endDate, 0);

                        var startDateNext = moment().startOf('day');
                        var endDateNext = moment(startDateNext).add(scope.daysBetweenMeetings - 1, 'days').endOf("day");

                        var periodNext = {
                            from: startDateNext.utc().toDate().toJSON(),
                            to: endDateNext.utc().toDate().toJSON()
                        }
                        boardTaskService().getBoardStatus(scope.selectedBoard.id, periodNext).then(function (data) {

                            setProfileImage(data.open);
                            setProfileImage(data.rejected);
                            scope.nextweekworkList = data;
                        });
                    }
                    scope.createBoard = function () {
                        $state.go('newFlexiboard');
                    }

                    function checkSettings() {
                        if (scope.settings && scope.settings.daysBetweenMeetings) {
                            scope.daysBetweenMeetings = scope.settings.daysBetweenMeetings;
                        }

                        boardService.getAll().then(function (boards) {
                            scope.boards = boards;
                            if (scope.settings && scope.settings.boardId) {
                                scope.selectedBoard = $filter('filter')(boards, { id: scope.settings.boardId }, true)[0];
                            } else {
                                scope.selectedBoard = boards[0];
                            }
                            boardService.getUserListForBoard(scope.selectedBoard.id).then(function (data) {
                                scope.users = data;
                                getActionList();
                            });

                        });
                    }

                    function updateSettings() {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                boardId: scope.selectedBoard.id,
                                daysBetweenMeetings: scope.daysBetweenMeetings
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    scope.$on("widget-resized", function (event, args) {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")

                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 40;
                        var container = $(elem).find(".team-tasks");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    });

                }
            }
        }]);

