import { getUserProfile } from "@common/stores/userStore"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("pageCommentAddedMessage", ['learningService',
        function (learningService) {
            return {
                templateUrl: 'pageCommentAddedMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.page = null;
                    scope.isLoading = true;
                    scope.users = [];
                    scope.selectedCommentId = 0;
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.selectedCommentId = payLoad.Id;
                        learningService().getPage(payLoad.LearningItemId).then(function (page) {
                            scope.page = page;
                            learningService().getLearningMaterial(page.learningMaterialId).then(function (material) {
                                scope.learningMaterial = material;
                            })
                        });
                        // Get current user + users for commenting
                        
                        scope.currentUser = getUserProfile()
                        learningService().getPageComments(payLoad.LearningItemId).then(function (model) {
                            scope.comments = model.comments;
                        });
                    
                        employeeService.getList().then(function (data) {
                            scope.users = data;
                        });
                    });


                    scope.saveComment = function (comment) {
                        comment.learningItemId = scope.page.id;
                        return learningService().addPageComment(scope.page.id, comment);
                    };

                    scope.deleteComment = function (comment) {
                        return learningService().deleteComment(scope.page.id, comment.id);
                    };
                }
            }
        }]);