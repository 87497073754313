import * as profileService from "@common/services/user/profileService"
import * as employeeService from "@common/services/employeeService"

// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('myprofileController', ['$scope', '$filter', '$state', 'authService', 'suggestionService', 'navigationService', '$uibModal', 'learningService', 'learningPublicService',
    function ($scope, $filter, $state, authService, suggestionService, navigationService, $uibModal, learningService, learningPublicService) {


        $scope.hasLearningModule = navigationService().hasModule("LEARNING");
        $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
        $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");

        $scope.profile = null;

        // Check if user has visited settings page
        employeeService.getUserPreference("HasVisitedSettingsPage").then(function (value) {
            if (!value) {
                // go to settings
                $state.go('settings');
            }
        });


        var userid = authService.getCurrentUser().userId;
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            // In case of refresh we need to make sure we 
            userid = authService.getCurrentUser().userId;
            $scope.isMobileUser = profile.user.isMobileUser;
            var hasAccess = $filter('filter')(profile.modules, { name: "DEVIATION" });
            $scope.hasDeviationModule = hasAccess.length > 0;
            var hasImprovementAccess = $filter('filter')(profile.modules, { name: "IMPROVEMENT" });
            $scope.hasImprovementModule = hasImprovementAccess.length > 0;
            var hasLearningAccess = $filter('filter')(profile.modules, { name: "LEARNING" });
            $scope.hasLearningModule = hasLearningAccess.length > 0;
            if ($scope.hasLearningModule) {
                recommendLearning(profile.user.preferredLanguage);
            }
        });

        var getMyProfile = function () {
            profileService.getMyProfile().then(function (profile) {
                $scope.profile = profile;
                $scope.isMobileUser = profile.isMobileUser;
                if ($scope.hasLearningModule) {
                    recommendLearning($scope.profile.preferredLanguage);
                }
                
            });
        }
        getMyProfile();
        suggestionService().getMySuggestions().then(function (suggestions) {
            $scope.suggestionimplemented = 0;
            $scope.suggestions = suggestions;
            suggestions.forEach(function (suggestion) {
                //if(suggestion.status == "Implemented" || suggestion.status == "Evaluated") $scope.suggestionimplemented++;
                if (suggestion.statusImplementedDate) $scope.suggestionimplemented++;

            });
            $scope.suggestions = suggestions;
            $scope.translationData = {
                noOfSuggestions: $scope.suggestions.length
            };
        });


        function recommendLearning(userlanguage) {
            learningPublicService.getUserHelpLearningMaterial(userlanguage).then(function (result) {
                var learningmaterial = result;
                $scope.learningMaterialTitle = learningmaterial.title;
                $scope.learningMaterialLink = learningmaterial.link;
            });
            return;
        }

        function getLearningMaterialLink(material) {
            let link = "/#/learning/"
            link += material.externalId ? "external" : "internal"
            link += `/${material.id}?pageid=0`
            return link
        }

        $scope.openSuggestions = function () {
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'myRegisteredSuggestionsForm.html',
                controller: 'mysuggestionsController',
                windowClass: 'fullDeviation-modal-window',
                resolve: {
                }
            });
        }

        $scope.quickAccess = [];
        employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
            if (settings && settings.value) {
                angular.forEach(settings.value, function (board) {
                    board.isStar = true;
                    board.hasAccess = false;
                });
                $scope.quickAccess = settings.value;
            }
        });
        $scope.updateProfilePhoto = function () {
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'myprofilephoto.html',
                controller: 'myprofilephotoController',
                resolve: {
                }
            });
            modalInstance.result.then(function () {
                getMyProfile();
            }, function () {
            });
        }
    }]);