angular
    .module('DigiLean')
    .directive("threeCTask", ['threeCService',
        function (threeCService) {
            return {
                templateUrl: '3Ctask.html',
                restrict: 'E',
                scope: {
                    "task": "<",
                    "status": "<",
                },
                link: function (scope, elem, attrs) {    
                    scope.threeCStatusChangedHandler = function (status) {
                        var itemStatus = {
                            boardTaskId: scope.task.id,
                            status: status.toString()
                        }
                        threeCService().updateStatus(scope.task.id, itemStatus).then(function (data) {
                        });
                    }

                    scope.subscribe("ThreeCItemStatusUpdated", function (status) {
                        if (scope.task.id == status.boardTaskId && scope.task.status != status.status) {
                            setTimeout(function() {
                                scope.$apply(function() {
                                  scope.task.status = status.status;
                                });
                              }, 1000);
                        }
                    });
                }
            }
        }]);