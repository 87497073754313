﻿import * as documentService from "@common/services/documentService"
import { getFileType } from "@common/services/filesService"

angular.module('DigiLean')
    .component("imagefileinfo", {
        templateUrl: 'imagefileinfo.html',
        bindings: {
            'file': '<',
        },
        controller: ['$element', 'Lightbox', 
            function ($element, Lightbox) {
                const $ctrl = this
                let observer
                
                const handleIntersect = (entries, observer) => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            applyFile($ctrl.file)
                            observer.unobserve(entry.target)
                        }
                    }
                }

                $ctrl.fileInfoId = ""
                $ctrl.$onInit = function() {
                    observer = new IntersectionObserver(handleIntersect)
                    observer.observe($element[0])
                }
                
                
                async function applyFile(file) {
                    if (!file)
                        return;

                    file.validFileUrl = "https://digileanfiles.blob.core.windows.net/public-assets/images/Loading_icon.gif" // Default loading image, will be replaced with actual image

                    const fileType = getFileType(file.fileExtension)

                    $ctrl.fileInfoId = file.id ?? file.fileId

                    if (fileType && fileType.type == "image") {
                        file.fileType = fileType.type

                        const blobNameThumbnail = "tn_" + file.fileName
                        
                        const fileUrlThumbnail = await documentService.getFileUrl(blobNameThumbnail, $ctrl.fileInfoId)
                        if (fileUrlThumbnail) {
                            file.fileUrl = fileUrlThumbnail
                            file.validFileUrl = fileUrlThumbnail

                        } else { // Doesn't have thumbnail so try to load original
                            const fileUrl = await documentService.getFileUrl(file.fileName, $ctrl.fileInfoId)
                            file.fileUrl = fileUrl
                            file.validFileUrl = fileUrl
                        }
                        
                    }

                }

                $ctrl.openLightboxModal = async function () {
                    const fileUrl = await documentService.getFileUrl($ctrl.file.fileName, $ctrl.fileInfoId)
                    var images = [
                        {
                            'url': fileUrl,
                            'thumbUrl': $ctrl.file.validFileUrl
                        }
                    ]
                    Lightbox.openModal(images, 0)
                }

                $ctrl.$onDestroy = function() {
                    if (observer)
                        observer.disconnect()
                }
            }]
    })
