// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('datalistsController', ['$scope','$state','$filter','$translate', '$uibModal','modalService','dataListService',
    function ($scope,$state, $filter, $translate, $uibModal, modalService, dataListService) {
        $scope.lists = [];
        dataListService().getLists().then(function(lists) {
            $scope.lists = lists;
        });
        $scope.addList = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newDataList.html',
                controller: 'newDataListController',
                resolve: {
                }
            });
            modalInstance.result.then(function (list) {
                $scope.lists.push(list); 
                // Route to items
                $state.go('admindatalist', { id: list.id }, { reload: false });
            }, function () {
    
            });
        }
        $scope.editList = function (list) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'editDataList.html',
                controller: 'editDataListController',
                resolve: {
                    dataList: list
                }
            });
            modalInstance.result.then(function (list) {
                var matchingList = $filter('filter')($scope.lists, { id: list.id }, true);
                if (matchingList.length > 0) {
                    matchingList[0].name = list.name;
                    matchingList[0].description = list.description;
                }
            }, function () {
    
            });
        }
        $scope.delete = function (list) {

            dataListService().listInUse(list.id).then(function(result) {
                var canDelete = true;
                if(result.dataSources.length>0 || result.deviationTypes.length>0){
                    canDelete = false;
                }
                if(canDelete){
                    $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION']).then(function (translations) {
                        var modalOptions = {
                            closeButtonText: translations.COMMON_CANCEL,
                            actionButtonText: translations.COMMON_DELETE,
                            headerText: translations.COMMON_DELETE + ' ' + list.name + '?',
                            bodyText: translations.ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION
                        };
                        modalService.showModal({}, modalOptions).then(function (result) {
                            dataListService().deleteList(list.id).then(function (data) {
                                var index = $scope.lists.indexOf(list);
                                $scope.lists.splice(index, 1);
                            });
                        });
                    });
                } else {
                    var datasourcetext = "";
                    var buttons;
                    if(result.dataSources.length > 0) {  
                        var idTranslated = $translate.instant('COMMON_ID')                     
                        for(var s=0; s< result.dataSources.length; s++){
                            datasourcetext = datasourcetext + result.dataSources[s].title + " (" + idTranslated + ":" + result.dataSources[s].id + ")";
                            if(s != result.dataSources.length-1){
                                datasourcetext = datasourcetext + ",  \n";
                            }
                        }
                        buttons = {datasources: {
                            text:  result.dataSources.length + " " + $translate.instant('ADMIN_DATA_DATASOURCES'),
                            className: "digilean-label-historical",
                            closeModal: true,
                            value: "DATASOURCE"
                        }};
                    }
                    var deviationtypestext = "";
                    if(result.deviationTypes.length > 0) {                       
                        for(var s=0; s< result.deviationTypes.length; s++){
                            deviationtypestext = deviationtypestext + result.deviationTypes[s].title;
                            if(s != result.deviationTypes.length-1){
                                deviationtypestext = deviationtypestext + ", ";
                            }
                        }
                        if(buttons){
                            buttons = {datasources: {
                                text:  result.dataSources.length + " " + $translate.instant('ADMIN_DATA_DATASOURCES'),
                                className: "digilean-label-historical",
                                closeModal: true,
                                value: "DATASOURCE"
                            },
                            deviationtypes: {
                                text: result.deviationTypes.length + " " + $translate.instant('COMMON_DEVIATION_TYPES'),
                                className: "digilean-label-historical",
                                closeModal: true,
                                value: "DEVIATIONTYPES"
                            }} ;
                        } else {
                            buttons = {deviationtypes: {
                                text: result.deviationTypes.length + " " + $translate.instant('COMMON_DEVIATION_TYPES'),
                                className: "digilean-label-historical",
                                closeModal: true,
                                value: "DEVIATIONTYPES"
                            }} ;
                        }
                    }
                    swal({
                        title: $translate.instant('ADMIN_DATALIST_DELETE_WARNING'),
                        icon: "warning",
                        buttons: buttons,
                        dangerMode: true,
                        closeOnClickOutside: true,
                        closeOnEsc: true
                    }).then((value) => {
                        switch (value) {

                            case "DATASOURCE":
                                swal({
                                    title: $translate.instant('ADMIN_DATA_DATASOURCES'),
                                    text: datasourcetext,
                                })
                                break;

                            case "DEVIATIONTYPES":
                                swal({
                                    title: $translate.instant('COMMON_DEVIATION_TYPES'),
                                    text: deviationtypestext,
                                })
                                break;

                            default:
                        }
                    });
                }
            });
            
           
        };

     

    }]);