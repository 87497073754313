import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {watch, ref, onMounted, computed} from "vue"
    import { formatDateOnly, formatDateTime } from "@common/services/datetime/dateTimeFormattingLocale"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'DateViewer',
  props: {
        date: {
            type: String,
            required: true
        }
    },
  setup(__props) {

    // date-viewer element
    const props = __props

    const dateFormatted = computed(() => {
        return formatDateOnly(props.date)
    })

    // just to show title/tooltip, accessing shadow root and root element in vue
    // could be useful in other situations
    let dateRef = ref<HTMLSpanElement | null>(null)
    let shadowRoot: ShadowRoot | null = null
    let $element: Element | null = null
    watch(() => props.date, (d) => {
        let formattedDateTime = formatDateTime(d)
        if ($element)
            $element.setAttribute("title", formattedDateTime)
    })
    onMounted(() => {
        shadowRoot = dateRef.value?.getRootNode() as ShadowRoot
        if (shadowRoot)
            $element = shadowRoot.host
    })
    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    ref_key: "dateRef",
    ref: dateRef
  }, _toDisplayString(dateFormatted.value), 513 /* TEXT, NEED_PATCH */))
}
}

})