angular
    .module('DigiLean').directive("projectIncidentSummary", [ 'deviationService','$uibModal',
        function (deviationService, $uibModal) {
            return {
                templateUrl: 'projectIncidentSummary.html',
                restrict: 'E',
                scope: {
                    'projectId': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = true;


                    scope.openDeviations = 0;
                    scope.noResolvedDeviations = 0;
                    scope.noRejectedDeviations = 0;
                    scope.noTotalDeviations = 0;


                    scope.$watch("projectId", function (projectId) {
                        if (!projectId) return;


                        deviationService().getNoProjectDeviationPerStatus(scope.projectId).then(function (statusdata) {
                            scope.openDeviations = statusdata.new + statusdata.inProgress;
                            scope.noResolvedDeviations = statusdata.resolved;
                            scope.noRejectedDeviations = statusdata.rejected;
                            scope.noTotalDeviations = scope.openDeviations + scope.noResolvedDeviations + scope.noRejectedDeviations;
                        });
                    });


                    scope.showDeviations = function () {
                        var currentfilter = [{
                            sourceColumn: "ProjectId",
                            operator: "InList",
                            items: [scope.projectId]
        
                        }];
                        var order = [{
                            sourceColumn: "Status",
                            SortDirection: "ASC"
                        }, {
                            sourceColumn: "Severity",
                            SortDirection: "DESC"
                        }];
                        var options = {
                            filters: currentfilter,
                            sortOrders: order
                        };
        
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'deviationListForm.html',
                            controller: 'deviationListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                    };
        
                }

            }
        }]);
