import * as dragula from "dragula"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import {translateOldDeviationSettings} from "@common/shared/settingsTranslatorDeviation"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive('ongoingDeviation', ['$stateParams', '$filter', '$uibModal', 'deviationService', 'actionlistService', 'modalService', 'debounce', 'authService',
        function ($stateParams, $filter, $uibModal, deviationService, actionlistService, modalService, debounce, authService) {
            return {
                templateUrl: 'ongoingDeviation.html',
                restrict: 'E',
                scope: {
                    'context': '@',
                    'viewMode': '@',
                    'appTitle': '@',
                    'appTheme': '<?',
                    'settings': '<?',
                    'settingsChangedHandler': '&?',
                },
                link: function (scope) {

                    // Default settings, should be overriden by eventual settings
                    let pageNum = 1;
                    let pageSize = 10;
                    let sorting = { lastModified: "desc" };
                    scope.title = translateLabelInstant("COMMON_DEVIATION") + " - " + translateLabelInstant("IMPROVEMENT_ONGOING") // Translate
                    scope.theme = null;

                    scope.allColumns = deviationService().getAllColumns(null);
                    // Default settings for viewMode=List

                    scope.listOptions = {
                        showFilter: false,
                        showFileGenerators: false,
                        showPaging: false,
                        showArchived: false
                    }

                    scope.fullscreenMode = false;
                    // determine if hosted in app and running in fullscreen
                    if (scope.context == "app") {
                        const hasFullScreenElements = document.querySelectorAll("div.modal.full-screen");
                        scope.fullscreenMode = hasFullScreenElements.length > 0;
                    }

                    // Kanban column options
                    const toggleAllKanbanColumns = function (visible) {
                        scope.showNewColumn = visible;
                        scope.showInProgressColumn = visible;
                        scope.showResolvedColumn = visible;
                        scope.showRejectedColumn = visible;
                    }
                    toggleAllKanbanColumns(false); // Default hidden untill filter resolves.

                    scope.timePeriod = null;
                    scope.isSettingsLoaded = false;
                    // default timeperiod
                    var timeSettings = {
                        timeframe: "30",
                        useDefaultTimeframes: true,
                        timeframes: [],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.options = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }


                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                    scope.periodChangedHandler = function (timePeriod) {
                        if (scope.timePeriod && timePeriod.period
                            && scope.timePeriod.from === timePeriod.period.from
                            && scope.timePeriod.to === timePeriod.period.to) {
                            return;
                        }
                        scope.timePeriod = timePeriod;
                        getDataDebounce();
                        scope.settingsChanged();
                    };

                    scope.columnListChangeHandler = function (listColumns) {
                        if (listColumns) {
                            scope.listColumns = listColumns.listColumns;
                        }
                        scope.settingsChanged();
                    }

                    // ViewMode
                    scope.toggleViewMode = function (viewMode) {
                        scope.viewMode = viewMode;
                        scope.settingsChanged();
                    }

                    // Title
                    scope.$watch('appTitle', function (title) {
                        if (title) {
                            scope.title = title;
                        }
                    });
                    // Theme
                    scope.$watch('appTheme', function (theme) {
                        if (theme) {
                            scope.theme = theme;
                        }
                    });
                    // /* FILTER * /;
                    scope.filter = null;

                    var userid = authService.getCurrentUser().userId
                    var dueDate = moment().subtract(1, 'days');
                    
                    scope.$watch('context', function (context) {
                        if (context == "standAlone") {
                            employeeService.getUserPreference("DeviationOngoingFilter").then(function (filterpreferences) {
                                if (filterpreferences && filterpreferences.value) {
                                    var userSettings = filterpreferences.value;
                                    // assign settings
                                    // Check to see if the settings are old, timeframe will be a property on the old settings
                                    if (userSettings.timeframe) {
                                        scope.settings = {
                                            filter: userSettings,
                                            timePeriod: {
                                                timeframe: userSettings.timeframe,
                                                period: userSettings.timePeriod
                                            }
                                        }
                                    } 
                                    else if (userSettings.myResponsible || userSettings.myRegistration || userSettings.project
                                            || userSettings.asset || userSettings.category || userSettings.deviationtype
                                            || userSettings.severity || userSettings.status || userSettings.area) {
                                        scope.settings = {
                                            filter: userSettings
                                        }
                                    }
                                    else {
                                        scope.settings = userSettings;
                                        if (userSettings.viewMode && userSettings.viewMode == "List") {
                                            scope.viewMode = userSettings.viewMode;
                                        }
                                    }
                                    scope.settings = translateOldDeviationSettings(scope.settings, userid, dueDate)
                                }
                            });
                        }
                    });

                    scope.$watch('settings', function (settings) {
                        // Apply other  settings
                        scope.isSettingsLoaded = true;
                        if (settings) {
                            settings = translateOldDeviationSettings(settings, userid, dueDate);

                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            if (settings.filter) {
                                scope.filter = settings.filter;
                            }
                            if (settings.sorting) {
                                sorting = settings.sorting;
                            }
                            if (settings.listColumns) {
                                scope.listColumns = settings.listColumns;
                                scope.listColumns.forEach((c) => {
                                    var column = $filter('filter')(scope.allColumns, { field: c.field }, true);
                                    if (column && column.length >= 0) {
                                        column[0].visible = c.visible;
                                    }
                                })
                            }
                            
                            if (settings.timePeriod) {
                                scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                            }
                        }
                    });



                    var setFilterOptions = function () {
                        scope.filterWithTimePeriod = {
                            filter: scope.filter,
                            timePeriod: scope.timePeriod.period,
                            listColumns: scope.listColumns,
                            sorting: sorting
                        }
                    }
                    scope.tableParamsChanged = function (params) {
                        // When sorting or page size has changed in List
                        sorting = params.sorting;
                        scope.settingsChanged();
                    }

                    scope.settingsChanged = function () {
                        if (!scope.isSettingsLoaded) return;
                        // Raise settings changeHandler
                        var appSettings = {
                            filter: scope.filter,
                            viewMode: scope.viewMode,
                            listColumns: scope.listColumns,
                            sorting: sorting,
                            timePeriod: {
                                period: scope.timePeriod.period,
                                timeframe: scope.timePeriod.timeframe
                            }
                        }
                        if (scope.context == "app" && scope.settingsChangedHandler) {

                            scope.settingsChangedHandler({ settings: appSettings });
                        }
                        if (scope.context == "standAlone" && scope.isSettingsLoaded) {
                            // Handle setting internally
                            employeeService.updateUserPreference("DeviationOngoingFilter", appSettings).then(function () {

                            });
                        }
                    }


                    scope.filterChanged = function (filter) {
                        scope.filter = filter;
                        getDataDebounce();
                        // Raise settings changed event
                        scope.settingsChanged();
                    };

                    function resetData() {
                        scope.data = {
                            new: [],
                            inprogress: [],
                            resolved: [],
                            rejected: []
                        }
                    }
                    resetData()
                    /* GetData */
                    const getdata = async () => {
                        if (!scope.timePeriod) return;
                        let promises = []
                        setFilterOptions();
                        getTotalNumberOfDeviations();
                        toggleKanbanColumnsBasedOnFilter();

                        if (scope.showNewColumn) promises.push(getValuesByStatus(0).then(d => { scope.data.new = d; return d.total }))
                        if (scope.showInProgressColumn) promises.push(getValuesByStatus(10).then(d => { scope.data.inprogress = d; return d.total }))
                        if (scope.showResolvedColumn) promises.push(getValuesByStatus(20).then(d => { scope.data.resolved = d; return d.total }))
                        if (scope.showRejectedColumn) promises.push(getValuesByStatus(30).then(d => { scope.data.rejected = d; return d.total }))

                        Promise.all(promises).then(all => {
                            scope.totalItems = all.reduce((a, b) => a + b, 0);
                        });
                    }

                    const toggleKanbanColumnsBasedOnFilter = function () {
                        if (scope.filter && Array.isArray(scope.filter)) {
                            // Check if filter on status
                            const found = scope.filter.find((item) => item.sourceColumn == 'status')
                            if (found) {
                                scope.showNewColumn = found.items.indexOf(0) > -1;
                                scope.showInProgressColumn = found.items.indexOf(10) > -1
                                scope.showResolvedColumn = found.items.indexOf(20) > -1
                                scope.showRejectedColumn = found.items.indexOf(30) > -1
                                return;
                            }
                        }
                        toggleAllKanbanColumns(true);
                    }

                    const getDataDebounce = debounce(getdata, 500, false);

                    const getValuesByStatus = function (status) {

                        let filters = []
                        if (scope.filter && Array.isArray(scope.filter)) {
                            // copy elements to local filter by spread operator ...
                            filters = [...scope.filter]
                        }

                        const statusFilter = {
                            sourceColumn: "status",
                            operator: "InList",
                            items: [status]
                        }
                        filters.push(statusFilter)
                        const archivedFilter = {
                            sourceColumn: "D.archivedDate",
                            operator: "IsNull"
                        }
                        filters.push(archivedFilter)

                        const sorting = [{
                            direction: "desc",
                            property: "lastModified"
                        }]

                        // We will build params based on the built-in NgTableParams + our sorting array
                        const tableParams = {
                            page: pageNum,
                            count: pageSize,
                            timePeriod: scope.timePeriod.period,
                            sorting,
                            filters,
                        }

                        return deviationService().getOngoingByFilter(tableParams).then(function (data) {
                            if(status < 20){ // status is either new or in progress (not resolved or rejected)
                                data.values.forEach(value => {
                                    value.dateStyleClass = getDateStyle(value.dueDate)
                                })
                            }
                            return data
                        })
                    }

                    /* Paging */
                    scope.pageNumChanged = function ($event) {
                        pageNum = $event.detail.pageNum ? $event.detail.pageNum : 1
                        getDataDebounce()
                    }
                    scope.pageSizeChanged = function ($event) {
                        pageSize = $event.detail.pageSize ? $event.detail.pageSize : 10
                        getDataDebounce()
                    }

                    const getTotalNumberOfDeviations = function () {
                        deviationService().getTotalNumberOfDeviations(false).then(function (count) {
                            scope.totalNumberOfDeviations = count;
                        });
                    }

                    /* Drag and drop */
                    let drake;
                    scope.columnLoaded = function (column) {
                        if (drake && drake.containers && column)
                            drake.containers.push(column)
                    }
                    function setupDragula() {
                        drake = dragula({ revertOnSpill: true }) // append containers
                        drake.on("over", (el, container) => {
                            container.classList.add("hover");
                        });
                        drake.on("out", (el, container) => {
                            container.classList.remove("hover");
                        });
                        drake.on("drop", (el, target, source, sibling) => {
                            const fromStatus = source.dataset.status
                            const toStatus = target.dataset.status
                            const id = el.dataset.id
                            if (fromStatus == toStatus)
                                return false

                            const changedStatus = {
                                deviationId: id,
                                status: parseInt(toStatus)
                            }

                            if (toStatus !== "20") {
                                deviationService().updateStatus(changedStatus).then(() => { }, (err) => {
                                    console.log(err)
                                    source.appendChild(el)
                                    return
                                })
                            } else {
                                checkUncompletedActionItems(changedStatus, fromStatus); 
                            }
                        })
                    }
                    setupDragula();


                    /* Deviation*/
                    scope.openDeviation = function (deviation) {
                        deviationService().get(deviation.id).then(function (deviation) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    }
                    if ($stateParams.id && scope.context != 'app') {
                        var deviation = {
                            id: parseInt($stateParams.id)
                        }
                        scope.openDeviation(deviation);
                    }


                    scope.showArchive = false;


                    /* EVENT HANDLE CODE */

                    var updateDeviation = function (deviationId) {
                        checkAndReplace(scope.data.new.values, deviationId)
                        checkAndReplace(scope.data.inprogress.values, deviationId)
                        checkAndReplace(scope.data.resolved.values, deviationId)
                        checkAndReplace(scope.data.rejected.values, deviationId)
                    }

                    function checkAndReplace(datalist, deviationId) {
                        const deviation = datalist.find(s => s.id == deviationId)
                        if (deviation)
                            replaceDeviation(datalist, deviationId, deviation)
                    }

                    async function replaceDeviation(list, deviationId, existingDeviation) {
                        const dbDeviation = await getDeviationDb(deviationId)
                        const index = list.indexOf(existingDeviation)
                        list.splice(index, 1, dbDeviation)
                        dbDeviation.dateStyleClass = getDateStyle(dbDeviation.dueDate)
                        dbDeviation.cssState = "animated bounce"
                        removeAnmiation(dbDeviation)
                    }

                    async function getDeviationDb(id) {
                        const dbDeviation = await deviationService().get(id)
                        setViewFieldsFromDb(dbDeviation)
                        return dbDeviation
                    }

                    function setViewFieldsFromDb(deviation) {
                        if (deviation.comments && deviation.comments.length > 0)
                            deviation.commentCount = deviation.comments.length
                        if (deviation.asset)
                            deviation.assetName = deviation.asset.name
                        if (deviation.categories)
                            deviation.categories = deviation.categories.map((c => {
                                return {
                                    problemCategoryId: c.problemCategoryId,
                                    problemCategoryTitle: c.problemCategory.title
                                }
                            }));                            
                    }

                    function removeFromList(list, deviationId) {
                        const currentDeviation = list.find(l => l.id == deviationId)
                        if (!currentDeviation)
                            return

                        const index = list.indexOf(currentDeviation)
                        list.splice(index, 1)
                    }
                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = ""
                        }, 1000)
                    }

                    scope.subscribe("DeviationCreated", function (deviation) {
                        getDataDebounce();
                    });
                    scope.subscribe("DeviationConsequencesChanged", function (deviation) {
                        updateDeviation(deviation.deviationId);
                    });

                    scope.subscribe("DeviationUpdated", function (deviation) {
                        updateDeviation(deviation.id);
                    });
                    // When deviation has changed status,we'll sync the changes
                    scope.subscribe("DeviationChangedStatus", function (deviationStatus) {
                        getDataDebounce();
                    });

                    scope.subscribe("DeviationSeverityChanged", function (severityInformation) {
                        updateDeviation(severityInformation.deviationId);
                    });

                    scope.subscribe("DeviationDeleted", function (deviation) {
                        // deviationId, status
                        removeFromList(scope.data.new.values, deviation.id)
                        removeFromList(scope.data.inprogress.values, deviation.id)
                        removeFromList(scope.data.resolved.values, deviation.id)
                        removeFromList(scope.data.rejected.values, deviation.id)
                    });

                    /* OLD CODE - MUST BE REPLACED */


                    scope.newDeviation = function () {
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {};
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    };


                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check

                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }

                    async function checkUncompletedActionItems(changedStatus, oldStatus) {
                        
                        const items = await actionlistService().getActionListForDeviation(changedStatus.deviationId)
                        
                        if (items && items.length > 0) {
                            let notCompletedActionItems = false;
                            
                            for (var a = 0; a < items.length; a++) {
                                if (items[a].status !== 'OK') {
                                    notCompletedActionItems = true;
                                    break;
                                }
                            }
                            if (notCompletedActionItems) {
                                
                                const modalOptions = {
                                    closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                                    actionButtonText: translateLabelInstant("COMMON_CHANGE_STATUS"),
                                    headerText: translateLabelInstant("DASHBOARD_CHANGE_STATUS") + ' ' + changedStatus.statusText + '?',
                                    bodyText: translateLabelInstant("DEVIATION_CHANGE_STATUS_CONFIRMATION")
                                };

                                try {
                                    await modalService.show({}, modalOptions)
                                    // answered OK
                                    return deviationService().updateStatus(changedStatus)
                                }
                                catch(er) {}
                                // answered cancel
                                const rollbackStatus = {
                                    deviationId: changedStatus.deviationId,
                                    status: parseInt(oldStatus)
                                }
                                return deviationService().updateStatus(rollbackStatus)
                            }
                        }
                        deviationService().updateStatus(changedStatus)
                    }

                 

                    scope.selectColumn = function (column) {
                        column.visible = !column.visible;
                        scope.listColumns = scope.allColumns;
                        if (scope.context == "app") {
                            scope.settingsChanged();
                        }
                        setFilterOptions();
                    }

                }
            };
        }]);
