import { BlobInfo, FileInfo } from "@api"
import backend from "@common/services/backendHttp"
import { appInsights } from "@common/services/webclientErrorLogging"

const resourceUrl = "api/document"

let cachedFileUrls = {}

function tryGetCachedUrl(name, id) {
    const key = name + id;
    const cache = cachedFileUrls[key];
    if (!cache)
        return null;
    let expireDate = new Date(cache.expire);
    let now = new Date();
    if (expireDate > now)
        return cache.data;
    
    Reflect.deleteProperty(cachedFileUrls, key);
    return null;
}
function addToCache(name, id, data) {
    if (!name || !id || !data) return;
    const key = name + id;
    const expire = getExpireDateFromUrl(data);
    cachedFileUrls[key] = { expire, data };
}
function getExpireDateFromUrl(url) {
    try {
        const urlObj = new URL(url);
        if (urlObj.searchParams && urlObj.searchParams.has("se")) {
            let expireDate = urlObj.searchParams.get("se");
            return expireDate;
        }
    } catch(e) {
        console.log(e);
    }
    
    let nowPlus9 = new Date();
    nowPlus9.setHours(nowPlus9.getHours() + 9);
    return nowPlus9.toISOString();
}

export async function uploadImageToBlobStorageNoPreview(file: Blob) {
    const url = "api/document/PostImageBlobUploadNoPreview"
    const res = await backend.postFile<FileInfo>(url, file) as Promise<FileInfo>
    return res
}

export async function uploadFileBlobStorage(file: Blob) {
    if (!file)
        return
    const url = resourceUrl + "/PostBlobUpload"
    // backend method supports multiple files thats why it returns array
    const res = await backend.postFile<FileInfo[]>(url, file) as Promise<FileInfo[]>
    return res
}

export async function getFileUrl(blobName: string, fileInfoId: string): Promise<string> {
    if (!blobName) {
        appInsights?.trackTrace({ message: `missing blobName, caller`, severityLevel: 2})
        console.error("Empty blobName")
    }
    
    const cachedUrl = tryGetCachedUrl(blobName, fileInfoId)
    if (cachedUrl)
        return cachedUrl

    const blobInfo: BlobInfo = {
        blobName,
        fileInfoId
    }
    const url = resourceUrl + "/GetFileUrl"
    const fileUrl = await backend.post<string>(url, blobInfo)
    addToCache(blobName, fileInfoId, fileUrl)
    return fileUrl
}

export async function getAll() {
    const url = "api/document"
    return backend.get(url)
}

export async function getFile(id: string) {
    const url = resourceUrl + "/getFile/" + id
    return backend.get(url)
}