<script setup lang="ts">

    // CE monitor-job-log
    import {ref, onMounted} from "vue"
    import { JobRun, LogMessage } from "@api";
    import { PropType } from "vue"
    import { refreshRunLog } from "@common/components/monitoring/integrations/loggMonitor"
    import { getLastErrors } from "@common/services/data/monitoringService"

    const props = defineProps({
        run: { type: Object as PropType<JobRun> },
        connectorid: { type: String },
        days: { type: Number }
    })

    const logs = ref<LogMessage[] | null>(null)
    let loading = ref(false)

    const getLogs = async () => {
        loading.value = true
        if (props.run) {
            const logsloaded = await refreshRunLog(props.run)
            logs.value = logsloaded
        } else if (props.connectorid) {
            const errors = await getLastErrors(props.connectorid, props.days)
            logs.value = errors
        }
        loading.value = false
    }
    onMounted(() => {
        getLogs()
    })
</script>
<style>
    .div-spinner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }
    .error {
        color: var(--digilean-error);
    }
</style>
<template>
    <h3>
        <translate-label label="ADMIN_DATA_JOB_SHOWLOGS"></translate-label>:
    </h3>
    <div class="div-spinner" v-if="loading">
        <spinner-icon .spin="loading"></spinner-icon>
    </div>
    <logmessage-viewer v-for="log in logs" .log="log"></logmessage-viewer>
</template>