import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = [".date"]

import { computed } from "vue"
    import { authUser } from "@common/stores/userStore"
    import { observableComposable } from "@common/directives/rxstate"
    import { parseToken, parseExpire } from "@common/services/tokenService"

    
export default /*@__PURE__*/_defineComponent({
  __name: 'UserTokenViewer',
  setup(__props) {

    const authUserObs = observableComposable(authUser)
    const tokenParsed = computed(() => {
        if (authUserObs.value && authUserObs.value.token) {
            var token = parseToken(authUserObs.value.token)
            return JSON.stringify(token, null, 4)
        }
    })

    const expired = computed(() => {
        if (authUserObs.value && authUserObs.value.token) {
            var token = parseToken(authUserObs.value.token)
            var exp = parseExpire(token.exp)
            return exp
        }
        return {
            expiredDate: "",
            parsedDiff: ""
        }
    })


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("textarea", {
      cols: "60",
      rows: "30",
      value: tokenParsed.value
    }, null, 8 /* PROPS */, _hoisted_1),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createTextVNode("Expire date: ")),
      _createElementVNode("datetime-viewer", {
        ".date": expired.value.expiredDate
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
    ]),
    _createElementVNode("p", null, "Expire In: " + _toDisplayString(expired.value.parsedDiff), 1 /* TEXT */)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})